import React, { ReactElement } from 'react';

import { Heading, Stack, StackProps, Text } from '@newday/core';

export interface ApplicationStatusDialogProps extends StackProps {
  children?: ReactElement;
  title: string;
  subtitle: string;
}

export const ApplicationStatusDialog: React.FC<ApplicationStatusDialogProps> = (
  props
) => {
  const { children, title, subtitle, ...rest } = props;

  return (
    <Stack w="full" spacing={6} {...rest}>
      <Heading fontSize={{ base: '3xl', md: '4xl' }} lineHeight="shorter">
        {title}
      </Heading>
      <Text
        fontSize={{ base: 'lg', md: 'xl' }}
        color="black"
        lineHeight="shorter"
        fontWeight="bold"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
      {children}
    </Stack>
  );
};
