import { Heading, OrderedList, ListItem, Text, Link } from '@newday/core';
import { ResponsivePageLayout, ContentWrapper } from '../../components';

const MainLI = ({ children }) => (
  <ListItem
    mt={2}
    fontSize="md"
    fontWeight="semibold"
    css={{
      '&::marker': {
        content: 'counters(list-item, ".") ".  "',
      },
    }}
  >
    {children}
  </ListItem>
);
const InnerLI = ({ children }) => (
  <ListItem
    mt={2}
    fontSize="md"
    fontWeight="normal"
    css={{
      '&::marker': {
        content: 'counters(list-item, ".") "  "',
      },
    }}
  >
    {children}
  </ListItem>
);

const content = [
  <Text key="first-item">
    We are committed to protecting your personal data (anonymous or otherwise)
    that we collect about you online. This Section tells you how we use cookies,
    why, and how this allows us to improve our service. It also tells you how
    you can manage what cookies are stored on your device. We call it our{' '}
    <strong>&apos;Cookies Notice&apos;</strong>.
  </Text>,

  `By using our websites (through any device) you agree that this Cookies Notice applies to that use.`,

  `We reserve the right to make changes to our Cookie Notice. Any such changes shall appear on all our websites and become effective immediately. Your continued use of our website is taken as meaning that you agree to any such changes.`,

  `Cookies are files containing small amounts of information which are downloaded to the device (e.g. Mobile Phone, Tablet or PC/Laptop) you use when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website on the same domain. Cookies do lots of different and useful jobs, such as remembering your preferences, and generally improving your online experience. We also use Cookies as part of our application processes and when you access your online account for fraud prevention and detection purposes.`,

  `There are different types of cookies. They all work in the same way, but have minor differences:`,
  [
    <Text key="session">
      <strong>Session cookies</strong> - Session cookies last only for the
      duration of your visit and are deleted when you close your browser. These
      facilitate various tasks such as allowing a website to identify that a
      user of a particular device is navigating from page to page, supporting
      website security or basic functionality. Some of the cookies we use are
      session cookies. For example, they help us to ensure the security of your
      online account servicing session, and can also keep you signed in while
      you move between pages or service your account. Our session cookies used
      for security are designed to be very difficult to read, change, access or
      use except by us when you have an active online account manager session.
      They contain no personal information that can be used to identify an
      individual (for example, our cookie named Identity.Cookie).
    </Text>,

    <Text key="persistent">
      <strong>Persistent cookies</strong> - Persistent cookies last after you
      have closed your browser, and allow a website to remember your actions and
      preferences. Sometimes persistent cookies are used by websites to provide
      targeted advertising based upon the browsing history of the device. We use
      persistent cookies in a few ways, for example, to remember your username
      for log in so you don&apos;t have to. We also use persistent cookies to
      allow us to analyse customer visits to our site, for example our cookie
      named \_ga. These cookies help us to understand how customers arrive at
      and use our site so we can improve the service.
    </Text>,

    <Text key="first-and-third-party">
      <strong>First and third party cookies</strong> - Whether a cookie is a
      first or third party cookie depends on which website the cookie comes
      from. First party cookies are those set by or on behalf of the website
      visited. All other cookies are third party cookies. We use both first
      party and third party cookies.
    </Text>,

    <Text key="strictly-necessary">
      <strong>Strictly necessary cookies</strong> - These cookies are essential
      in order to enable you to move around the website and use its features,
      and ensuring the security of your online banking experience. Without these
      cookies services you have asked for, such as applying for products and
      managing your accounts, cannot be provided. These cookies don&apos;t
      gather information about you for the purposes of marketing.
    </Text>,

    <Text key="performance">
      <strong>Performance cookies</strong> - These cookies help to collect
      information about how visitors use a web site, for instance which pages
      visitors go to most often, and if they get error messages from web pages.
      These cookies may collect information that identifies a visitor. All
      information these cookies collect is only used to improve how a website
      works, the user experience and to optimise our advertising. By using our
      websites you agree that we can place these types of cookies on your
      device, however you can block these cookies using your browser settings.
    </Text>,

    <Text key="functionality">
      <strong>Functionality cookies</strong> - These cookies allow the website
      to remember choices you make (such as your user name). The information
      these cookies collect is anonymised (i.e. it does not contain your name,
      address, account details, etc.) and they do not track your browsing
      activity across other websites. By using our websites you agree that we
      can place these types of cookies on your device, however you can block
      these cookies using your browser settings.
    </Text>,

    <Text key="targeting">
      <strong>Targeting cookies</strong> - These cookies collect several pieces
      of information about your browsing habits. They are usually placed by
      advertising networks. They remember that you have visited a website and
      this information is shared with other organisations such as media
      publishers. These organisations do this in order to provide you with
      targeted adverts more relevant to you and your interests. This type of
      advertising is called online behavioural advertising and those companies
      providing this advertising are working with the UK&apos;s Internet
      Advertising Bureau to deliver more information to consumers. To highlight
      this information, publishers of advertising will, look to place an icon in
      the top right hand corner of an advert. This icon when clicked, will take
      you through to the website &apos;youronlinechoices&apos;
      (www.youronlinechoices.com/uk) where there is more help and guidance. By
      using our websites you agree that we can place these types of cookies on
      your device, however you can block these cookies using your browser
      settings.
    </Text>,
  ],
  `If you wish to restrict or block the cookies which are set by any website, including our website, you should do this through the browser settings for each browser you use, on each device you use to access the internet.`,

  `Please be aware that some of our services, for example online account management, will not function if your browser does not accept cookies. However, you can allow cookies from specific websites by making them "trusted websites" in your internet browser.`,

  <Text key="cookie-website">
    You may wish to visit the website{' '}
    <Link
      href="https://ico.org.uk/for-the-public/online/cookies/"
      rel="noopener noreferrer"
      isExternal
    >
      https://ico.org.uk/for-the-public/online/cookies/
    </Link>{' '}
    which contains comprehensive information on how to do this on a wider
    variety of browsers.
  </Text>,
];

export const CookiePolicyPage = () => {
  return (
    <ResponsivePageLayout>
      <ContentWrapper py={8} maxContentWidth="5xl">
        <Heading
          as="h1"
          fontSize={{ base: '3xl', md: '4xl' }}
          fontWeight="bold"
          mb={5}
        >
          Cookie policy
        </Heading>
        <OrderedList mx={0} css={{ listStyle: 'none' }}>
          <ListItem>
            <Heading
              as="h4"
              color="black"
              fontSize="xl"
              fontWeight="bold"
              mt={9}
            >
              How do we use cookies and why on our website?
            </Heading>
          </ListItem>

          <OrderedList mt={5} px={4}>
            {content.map((item, index) => {
              const nextItem = content[index + 1];
              return nextItem instanceof Array ? (
                <MainLI key={index}>
                  {item}
                  <OrderedList key={`nested-list-${item}`}>
                    {nextItem.map((subitem, subItemIndex) => (
                      <InnerLI key={subItemIndex}>{subitem}</InnerLI>
                    ))}
                  </OrderedList>
                </MainLI>
              ) : (
                <MainLI key={index}>{item}</MainLI>
              );
            })}
          </OrderedList>
        </OrderedList>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
