import { ComponentStyleConfig } from '@chakra-ui/react';
import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const styles: SystemStyleObject = {
  backgroundColor: 'white',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'brand.greyScale.500',
  borderRadius: 'sm',
  color: 'brand.primary.500',
};

const checkedStyles: SystemStyleObject = {
  ...styles,
  borderColor: 'brand.primary.500',
  boxShadow: 'none',
};

export const Checkbox: ComponentStyleConfig = {
  defaultProps: {
    size: 'md',
  },
  sizes: {
    md: {
      control: { w: '18px', h: '18px' },
      icon: { width: '12px', height: '10px' },
      label: { fontSize: 'md' },
    },
  },
  baseStyle: () => {
    return {
      control: {
        ...styles,
        _checked: {
          ...styles,
          _hover: checkedStyles,
          _focus: checkedStyles,
        },
        _hover: checkedStyles,
        _focus: checkedStyles,
      },
    };
  },
};
