import axios from 'axios';
import { useQuery } from 'react-query';

import { BffRoutes, EligibilityGetResponse } from '@newday/plum-types';

const fetchEligibilityQuote = ({
  queryKey,
}): Promise<EligibilityGetResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, applicationId] = queryKey;
  return axios
    .get(BffRoutes.eligibility.replace(':applicationId', applicationId))
    .then((response) => response.data);
};

export const useEligibilityQuote = (applicationId: string) => {
  return useQuery<EligibilityGetResponse, unknown, EligibilityGetResponse>(
    ['plum', 'get-eligibility', applicationId],
    fetchEligibilityQuote
  );
};
