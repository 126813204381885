import {
  AffordabilityResponse,
  DependantsCount,
  EmploymentStatus,
  FinancialSituation,
  MaritalStatus,
  ResidentialStatus,
} from '@newday/plum-types';

export const getMockBffAffordability = (
  overrides?: Partial<AffordabilityResponse>
): AffordabilityResponse => ({
  accommodationCosts: 500,
  dependants: DependantsCount.none,
  employmentStatus: EmploymentStatus.fullTime,
  expenditureUtilitiesCost: 0,
  financialSituation: FinancialSituation.improve,
  grossAnnualIncome: 100000,
  maritalStatus: MaritalStatus.single,
  residentialStatus: ResidentialStatus.ownerOccupier,
  ...overrides,
});
