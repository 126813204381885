import React from 'react';
import { Heading, Text } from '@newday/core';
import { ExclamationMarkIcon } from '@newday/icons';
import { ContentWrapper, ResponsivePageLayout } from '../../components';
import { ApplicationReference } from '../../features';

export const DisbursementFailedPage: React.FC = () => {
  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <ContentWrapper py={8} maxContentWidth="5xl">
        <ExclamationMarkIcon size="lg" />
        <Heading fontSize="2xl" fontWeight="bold" mt={2}>
          We’ve cancelled your loan application
        </Heading>
        <Text mt={2} fontWeight="semibold">
          Sorry, your bank account didn’t accept the money for your loan.
        </Text>
        <Text mt={2} mb={8}>
          For your security, we’ve cancelled your application. We’ve also
          removed it from your credit record, so it won’t affect your credit
          score.
        </Text>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
