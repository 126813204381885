import React from 'react';
import {
  BrandLogo,
  Box,
  Flex,
  useBreakpointValue,
  Hide,
  Show,
} from '@newday/core';
import { useBrand } from '../../app';
import { Footer, CookieConsentBanner } from '../../components';

export type ResponsivePageLayoutProps = {
  children: React.ReactNode;
} & ConditionalProps;

type ConditionalProps =
  | {
      headerStepper?: React.ReactElement;
      centeredLogo?: never;
    }
  | {
      centeredLogo?: boolean;
      headerStepper?: never;
    };

export const ResponsivePageLayout = ({
  headerStepper,
  centeredLogo,
  children,
}: ResponsivePageLayoutProps) => {
  const { brand } = useBrand();
  const logoTransform = useBreakpointValue({
    base: 'scale(1)',
    md: 'scale(1.6)',
    lg: 'scale(1.6)',
  });

  const renderHeaderStepper = (mdPlusView: boolean) => {
    if (headerStepper && mdPlusView) {
      return React.cloneElement(headerStepper, { mdPlus: mdPlusView });
    }

    return headerStepper;
  };

  return (
    <>
      <header role="banner">
        <Flex direction="column" maxW="5xl" width="full" m="0 auto">
          <CookieConsentBanner />
          <Box
            display="flex"
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems="center"
            my={{ base: 4, md: 8 }}
            mx={{ base: 'auto', md: centeredLogo ? 'auto' : 16 }}
          >
            <BrandLogo brand={brand} transform={logoTransform} />
            {headerStepper && (
              <Hide below="md">
                <Box w="xs">{renderHeaderStepper(true)}</Box>
              </Hide>
            )}
          </Box>
        </Flex>
      </header>
      <main role="main">
        <Flex direction="column">
          {headerStepper && (
            <Show below="md">{renderHeaderStepper(false)}</Show>
          )}
          {children}
        </Flex>
      </main>
      <footer role="contentinfo">
        <Footer py={8} />
      </footer>
    </>
  );
};
