import { useMemo } from 'react';

const debounce = (delay) => {
  let timer;
  return (fn) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn();
    }, delay);
  };
};

export const useDebounce = (delay) => {
  return useMemo(() => debounce(delay), []);
};
