import {
  employmentStatusQuestion,
  grossAnnualIncomeQuestion,
  employmentStartDateQuestion,
  maritalStatusQuestion,
  dependantsCountQuestion,
  otherHouseholdIncomeQuestion,
  educationCostQuestion,
  residentialStatusQuestion,
  financialSituationQuestion,
} from './general-questions.data';

import {
  accommodationCostsQuestion,
  councilTaxQuestion,
  expenditureUtilitiesCostQuestion,
  otherQuestion,
} from './costs-questions.data';

import { Sections, SectionStyles } from '@newday/plum-form';

import { GeneralQuestions } from '@newday/plum-types';

const eligibilityQuestions: Sections<GeneralQuestions> = [
  {
    sectionTitle: 'Employment and income',
    questions: [
      employmentStatusQuestion,
      employmentStartDateQuestion,
      grossAnnualIncomeQuestion,
    ],
    layout: SectionStyles.alternate,
  },
  {
    sectionTitle: 'About you',
    questions: [
      maritalStatusQuestion,
      otherHouseholdIncomeQuestion,
      dependantsCountQuestion,
      educationCostQuestion,
    ],
    layout: SectionStyles.default,
  },
  {
    sectionTitle: 'Your outgoings',
    questions: [residentialStatusQuestion],
    layout: SectionStyles.alternate,
  },
  {
    items: [
      {
        title: 'How much do you pay towards your rent or mortgage each month?',
        description:
          'We only need to know about how much you spend personally.',
        layout: SectionStyles.default,
        questions: [
          accommodationCostsQuestion,
          councilTaxQuestion,
          expenditureUtilitiesCostQuestion,
          otherQuestion,
        ],
      },
    ],
    layout: SectionStyles.alternate,
  },
  {
    questions: [financialSituationQuestion],
    layout: SectionStyles.alternate,
  },
];

export { eligibilityQuestions };
