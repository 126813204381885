import { ComponentStyleConfig } from '@chakra-ui/react';

export const Spinner: ComponentStyleConfig = {
  sizes: {
    '2xl': {
      height: 141,
      width: 141,
    },
  },
};
