import React from 'react';
import { Box, Heading, List, Text } from '@newday/core';
import { BalloonBox } from '../../../components';
import { useFeatureFlags } from '../../../app';
import { LI } from '../doc-upload-page';

export const DocUploadHero = () => {
  const { MISTHO } = useFeatureFlags();
  return (
    <BalloonBox variant="dark">
      {MISTHO ? (
        <>
          <Heading fontSize="3xl" fontWeight="bold" color="white">
            Nearly there – but we’ll need proof of your income
          </Heading>
          <Text fontWeight="semibold" mt={2} color="white">
            Proving your income will help us check that this loan will be
            affordable for you.
          </Text>
        </>
      ) : (
        <>
          <Heading fontSize="3xl" fontWeight="bold" color="white">
            Nearly there – next, let’s confirm your income
          </Heading>
          <Text fontWeight="semibold" mt={2} color="white">
            By proving your income, you’ll help us check this loan is affordable
            for you.
          </Text>

          <Box p={4} mt={4} backgroundColor="white" borderRadius="base">
            <Text
              fontWeight="semibold"
              fontSize="sm"
              mb={2}
              color="brand.primary"
            >
              How to do it
            </Text>
            <List spacing={2} ml={4}>
              <LI>
                Choose one of the options below and find the document(s) shown
              </LI>
              <LI>
                Make sure the name and address details exactly match your loan
                application
              </LI>
              <LI>
                Scan or photograph the whole of each page, then attach and
                submit your documents
              </LI>
            </List>
          </Box>
        </>
      )}
    </BalloonBox>
  );
};
