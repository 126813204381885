import { Icon, keyframes } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}
`;

export const Spinner = ({
  size,
  variant,
  color,
  speed = '1s',
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon
      viewBox="0 0 16 17"
      fill="none"
      __css={{ ...styles, animation: `${spin} infinite ${speed} linear` }}
      {...props}
    >
      <path
        d="M8.07382 0.5C6.55822 0.5 5.0849 0.933311 3.81304 1.75309C2.57561 2.55069 1.58276 3.67539 0.941907 5.00563L2.11767 5.58268C3.23599 3.26152 5.51826 1.81959 8.07382 1.81959C11.7234 1.81959 14.6926 4.81639 14.6926 8.5C14.6926 12.1836 11.7234 15.1804 8.07382 15.1804C5.90146 15.1804 3.87091 14.0851 2.64495 12.3194L4.96426 9.98974H0V14.9763L1.70574 13.2629C2.27334 14.0353 2.97599 14.7026 3.78589 15.2293C5.06411 16.0606 6.54685 16.5 8.07382 16.5C12.4443 16.5 16 12.9112 16 8.5C16 4.08877 12.4443 0.5 8.07382 0.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};
