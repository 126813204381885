import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const CardUsageIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 21 16" __css={styles} {...props} fill="none">
      <path
        d="M0.75 12.85C4 12.85 2.7 13.5 11.15 13.5C13.75 13.5 16.35 12.85 17 10.25M0.75 6.25727H2.8144C2.93831 6.25734 3.06068 6.22984 3.17265 6.17678C3.28463 6.12372 3.38339 6.04641 3.4618 5.95047L6.9718 2.49333L10.2903 3.1572C10.7635 3.25167 11.0746 3.62953 10.8683 4.4572C10.8282 4.61858 10.7407 4.76428 10.6172 4.87561C10.4936 4.98695 10.3397 5.05884 10.175 5.08207L8.55 5.31867C8.45221 5.32845 8.3574 5.35789 8.27126 5.40521C8.18512 5.45253 8.10943 5.51677 8.04873 5.59406C7.98802 5.67136 7.94356 5.76012 7.918 5.85502C7.89244 5.94992 7.88632 6.04901 7.9 6.14633V8.3624C7.84562 8.61499 7.71202 8.84362 7.51867 9.015L5.71773 10.4355M10.5 10.25H18.95C19.2948 10.25 19.6254 10.113 19.8692 9.86924C20.113 9.62544 20.25 9.29478 20.25 8.95V1.8C20.25 1.45522 20.113 1.12456 19.8692 0.880761C19.6254 0.636964 19.2948 0.5 18.95 0.5H9.2C8.85522 0.5 8.52456 0.636964 8.28076 0.880761C8.03696 1.12456 7.9 1.45522 7.9 1.8V2.68227M10.9264 3.75H20.25M17.65 6.35H16.35"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
