import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  useMultiStyleConfig,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ReactElement } from 'react';

export interface LinkProps extends ChakraLinkProps {
  isDisabled?: boolean;
  to?: string;
}

export const Link = (props: LinkProps): ReactElement => {
  const { children, isDisabled, href, isExternal, target, ...rest } = props;

  const styles = useMultiStyleConfig('Link', {
    variant: isDisabled ? 'disabled' : 'default',
  });

  const external = isExternal || target === '_blank';

  return (
    <ChakraLink
      __css={styles}
      href={isDisabled ? undefined : href}
      isExternal={external}
      {...rest}
    >
      {children}
      {external && (
        <VisuallyHidden>(Opens in new tab or window)</VisuallyHidden>
      )}
    </ChakraLink>
  );
};
