import React from 'react';
import { Heading, Text, UnorderedList, ListItem } from '@newday/core';

import { ContentWrapper, ResponsivePageLayout } from '../../components';

export const EligibilityTermsPageContent = () => (
  <>
    <Heading as="h1" fontSize="3xl" mb="1.5rem">
      Eligibility Terms &amp; Conditions
    </Heading>
    <Text fontWeight="bold" fontSize="1.125rem" mb="1.25rem">
      If you proceed with an eligibility check, you agree that:
    </Text>
    <UnorderedList mb="1.25rem" spacing={5}>
      <ListItem>
        The personal information you have provided is complete and accurate.
      </ListItem>
      <ListItem>
        NewDay Ltd and NewDay Cards Ltd (‘we’) may use the personal information
        that you have provided, that we already hold about you and that we
        receive from credit reference agencies to check your eligibility for a
        loan.
      </ListItem>
      <ListItem>
        We carry out a search of your credit record and use it with our own
        credit scoring techniques to give an indication of whether you are
        likely to be accepted for a loan. The search will leave a ‘footprint’ on
        your credit file but will have no impact on your credit rating. You’ll
        be able to see this search on your credit file, but others will not be
        able to see it. If you choose to proceed with your application following
        the eligibility check, we will carry out a full credit search that will
        result in a hard footprint on your credit file.
      </ListItem>
      <ListItem>
        We store any information we receive about you during the eligibility
        check securely, whether or not you proceed with your application.
      </ListItem>
    </UnorderedList>
    <Text fontWeight="bold" fontSize="1.125rem">
      The results of the eligibility check do not constitute an offer of credit.
      After we complete our checks, you may still be declined, or offered a
      different loan or APR.
    </Text>
  </>
);

export const EligibilityTermsPage: React.FC = () => (
  <ResponsivePageLayout>
    <ContentWrapper py={8} maxContentWidth="5xl">
      <EligibilityTermsPageContent />
    </ContentWrapper>
  </ResponsivePageLayout>
);
