import {
  UploadProofResponse,
  DeleteProofResponse,
  ProofObject,
} from '../types';

export const getMockForDeleteProof =
  () =>
  (overrides?: DeleteProofResponse): DeleteProofResponse =>
    overrides;

export const getMockUploadProof = (
  overrides?: Partial<UploadProofResponse>
): UploadProofResponse => ({
  id: '123',
  ...overrides,
});

export const getMockProof = (
  overrides?: Partial<ProofObject>
): ProofObject => ({
  id: '123',
  type: 'income',
  fileName: 'my-payslip-20220824.pdf',
  submitted: false,
  meta: {
    proofType: 'payslip',
    fileSize: 12345,
  },
  ...overrides,
});
