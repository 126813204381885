import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { TwoColorIconProps } from '../icon';

export const TickBoxIcon = ({
  size,
  variant,
  color,
  ...props
}: TwoColorIconProps) => {
  const styles = useIconStyleConfig('TwoColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 60 60" __css={styles} {...props}>
      <path
        d="M29.7247 58.2216C45.276 58.2216 57.8828 45.6148 57.8828 30.0635C57.8828 14.5122 45.276 1.90543 29.7247 1.90543C14.1735 1.90543 1.56665 14.5122 1.56665 30.0635C1.56665 45.6148 14.1735 58.2216 29.7247 58.2216Z"
        fill="white"
      />
      <path
        d="M29.7247 58.9838C13.7614 58.9838 0.762146 45.9845 0.762146 30.0212C0.762146 14.0579 13.7614 1.05857 29.7247 1.05857C45.688 1.05857 58.6873 14.0579 58.6873 30.0212C58.6873 46.0268 45.688 58.9838 29.7247 58.9838ZM29.7247 2.70995C14.6506 2.70995 2.41352 14.9894 2.41352 30.0635C2.41352 45.1376 14.693 57.4171 29.7671 57.4171C44.8412 57.4171 57.1206 45.1376 57.1206 30.0635C57.0783 14.9894 44.7988 2.70995 29.7247 2.70995Z"
        fill="currentColor"
      />
      <path
        d="M16.4715 17.657H39.7601C40.734 17.657 41.5385 18.4615 41.5385 19.4354V42.0889C41.5385 43.0628 40.734 43.8673 39.7601 43.8673H16.4715C15.4976 43.8673 14.6931 43.0628 14.6931 42.0889V19.4354C14.6931 18.4615 15.4976 17.657 16.4715 17.657Z"
        // fill="#00D8B0"
      />
      <path
        d="M49.3296 22.3994L45.9845 18.9273C45.6881 18.6309 45.2223 18.6309 44.9259 18.9273L30.9104 32.6888L27.6076 29.386C27.3112 29.0896 26.8455 29.0896 26.5491 29.386L23.1193 32.7735C22.8229 33.0699 22.8229 33.5356 23.1193 33.832L30.3176 41.0727C30.614 41.3691 31.0798 41.3691 31.3762 41.0727L49.3296 23.5004C49.626 23.1616 49.626 22.6958 49.3296 22.3994Z"
        fill="white"
      />
      <path
        d="M30.868 42.0466C30.487 42.0466 30.0635 41.8772 29.7671 41.5808L22.5265 34.3402C21.9337 33.7474 21.9337 32.7735 22.5688 32.1807L25.9986 28.7932C26.5914 28.2004 27.6076 28.2004 28.2004 28.7932L30.9527 31.5455L44.3755 18.3769C44.6719 18.0805 45.0529 17.9111 45.4764 17.9111C45.8998 17.9111 46.2809 18.0805 46.5773 18.3769L49.9647 21.849C50.5575 22.4418 50.5575 23.4157 49.9647 24.0085L31.9266 41.6232C31.6302 41.9196 31.2491 42.0466 30.868 42.0466ZM23.7544 33.2816L30.868 40.4376L48.6944 22.9499L45.434 19.6048L30.9104 33.832L27.0995 29.9788L23.7544 33.2816Z"
        fill="currentColor"
      />
      <path
        d="M39.0826 44.5448H16.9372C15.4129 44.5448 14.1426 43.2745 14.1426 41.7502V19.6471C14.1426 18.1228 15.4129 16.8525 16.9372 16.8525H39.0826C40.6069 16.8525 41.8772 18.1228 41.8772 19.6471V22.6535C41.8772 23.1193 41.4961 23.458 41.0727 23.458C40.6493 23.458 40.2682 23.0769 40.2682 22.6535V19.6471C40.2682 19.012 39.7177 18.4615 39.0826 18.4615H16.9372C16.3021 18.4615 15.7516 19.012 15.7516 19.6471V41.7925C15.7516 42.4277 16.3021 42.9781 16.9372 42.9781H39.0826C39.7177 42.9781 40.2682 42.4277 40.2682 41.7925V33.832C40.2682 33.3663 40.6493 33.0275 41.0727 33.0275C41.4961 33.0275 41.8772 33.4086 41.8772 33.832V41.7502C41.8772 43.3169 40.6069 44.5448 39.0826 44.5448Z"
        fill="currentColor"
      />
      <path
        d="M41.8772 31.4608H40.2682V33.8321H41.8772V31.4608Z"
        fill="currentColor"
      />
    </Icon>
  );
};
