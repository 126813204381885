const Heading = {
  baseStyle: {
    color: 'brand.primary',
  },
  sizes: {
    // sm = 30em / base font size = 0 - 480 (em * baseFontSize)
    // md = 48em / base font size = 480 - 768 (em * baseFontSize)
    // lg = 62em / base font size = 768 - 992 (em * baseFontSize)
    // xl = 80em / base font size = 992 - 1280 (em * baseFontSize)
    // 2xl = 96em / base font size = 1280 - 1536 (em * baseFontSize)
    '4xl': {
      /**
       * FIXME: We don't have a mobile size for 4xl
       */
      fontSize: ['2em', null, null, '2.25em', null],
      fontWeight: '700',
      lineHeight: '125%',
    },
    '3xl': {
      fontSize: ['1.75em', null, null, '1.75em', null],
      fontWeight: '700',
      lineHeight: '125%',
    },
    '2xl': {
      fontSize: ['1.5em', null, null, '1.5em', null],
      fontWeight: '700',
      lineHeight: '125%',
    },
    xl: {
      fontSize: ['1.25em', null, null, '1.25em', null],
      fontWeight: '700',
      lineHeight: '125%',
    },
    lg: {
      fontSize: ['1.125em', null, null, '1.125em', null],
      fontWeight: '500',
      lineHeight: '125%',
    },
    /**
     * FIXME: We're missing md, sm, xs fontSizes, weights for
     * mobile and desktop
     *
     * FIXME: Mobile does not conform to sized-based naming system, but
     * DOM heading tags
     *
     */
    md: {
      fontSize: ['1em', null, null, '1em', null],
      fontWeight: '500',
      lineHeight: '125%',
    },
    sm: {
      fontSize: ['1em', null, null, '1em', null],
      fontWeight: '500',
      lineHeight: '125%',
    },
    xs: {
      fontSize: ['1em', null, null, '1em', null],
      fontWeight: '500',
      lineHeight: '125%',
    },
  },
};

export default Heading;
