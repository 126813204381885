import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import theme from '../theme';
import type { ThemeDict } from '../theme';
import { Global } from '@emotion/react';

const defaultGlobalStyles = `html {
  font-size: 16px;
}
`;

interface ExtendedThemeProviderProps {
  brandTheme: ThemeDict;
  themes?: ThemeDict[];
  globalStyles?: string;
}

const ExtendedThemeProvider: React.FC<ExtendedThemeProviderProps> = ({
  brandTheme,
  themes,
  globalStyles = defaultGlobalStyles,
  children,
}) => {
  const extendedTheme = React.useMemo(
    () => extendTheme(theme, brandTheme, ...(themes || [])),
    [brandTheme, themes]
  );

  extendedTheme['breakpoints'] = {
    ...extendedTheme['breakpoints'],
    xxs: '23em',
    xs: '25em',
  };

  extendedTheme['sizes'] = {
    ...extendedTheme['sizes'],
    68: '17rem',
  };

  return (
    <ChakraProvider theme={extendedTheme} resetCSS={true}>
      <Global styles={globalStyles} />
      {children}
    </ChakraProvider>
  );
};

export { ExtendedThemeProvider };
