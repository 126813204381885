import { StyleConfig } from '@chakra-ui/theme-tools';

const Button: StyleConfig = {
  variants: {
    primary: {
      color: 'white',
      backgroundColor: 'brand.primary',
      _hover: {
        color: 'white',
        backgroundColor: 'brand.tertiary',
      },
    },
    secondary: {
      color: 'brand.primary',
      backgroundColor: 'white',
      _hover: {
        color: 'white',
        backgroundColor: 'brand.tertiary',
      },
    },
    tertiary: {
      color: 'brand.primary',
      backgroundColor: 'white',
      borderColor: 'brand.primary',
      _hover: {
        color: 'white',
        backgroundColor: 'brand.primary',
      },
    },
  },
};

export default Button;
