import axios from 'axios';
import { useMutation } from 'react-query';

import type { UpdateAddressResponse } from '@newday/plum-types';
import { BffRoutes } from '@newday/plum-types';

export const useUpdateAddress = (applicationId: string) =>
  useMutation((addressId: string) =>
    axios.post<UpdateAddressResponse>(
      BffRoutes.updateAddress.replace(':applicationId', applicationId),
      { addressId }
    )
  );
