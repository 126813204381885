import { ComponentStyleConfig } from '@chakra-ui/react';

const TwoColorIcon: ComponentStyleConfig = {
  variants: {
    primary: {
      color: 'brand.primary',
      fill: 'brand.tertiary',
    },
    secondary: {
      color: 'brand.primary',
      fill: 'brand.secondary',
    },
  },
  sizes: {
    xs: {
      width: 4,
      height: 4,
    },
    sm: {
      width: 6,
      height: 6,
    },
    md: {
      width: 10,
      height: 10,
    },
    lg: {
      width: '3.75rem',
      height: '3.75rem',
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
};

export { TwoColorIcon };
