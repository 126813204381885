import { StyleConfig } from '@chakra-ui/theme-tools';

const Button: StyleConfig = {
  variants: {
    secondary: {
      color: 'white',
    },
    'tertiary-dark-bg': {
      _hover: {
        color: 'brand.primary',
        backgroundColor: 'brand.secondary',
        _disabled: {
          color: 'white',
        },
      },
      _focus: {
        color: 'brand.primary',
        backgroundColor: 'brand.secondary',
      },
    },
  },
};

export default Button;
