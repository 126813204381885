import { BffRoutes, PlumRedirectResponse } from '@newday/plum-types';
import axios from 'axios';
import { useQuery } from 'react-query';

export const useRedirect = (
  applicationId: string,
  enabled: boolean,
  key = 'redirect'
) =>
  useQuery(
    [key, applicationId],
    () =>
      axios.get<PlumRedirectResponse>(
        `${BffRoutes.redirect
          .replace(':applicationId', applicationId)
          .replace(':fallback?', '')}`
      ),
    { enabled, cacheTime: 0 }
  );
