import { Text, LinkButton } from '@newday/core';
import { LatePaymentIcon } from '@newday/icons';
import { useBrand } from '../../app';
import {
  Hero,
  Faqs,
  BoostCreditScoreTipsList,
  ResponsivePageLayout,
  ContentWrapper,
} from '../../components';
import { ApplicationReference } from '../../features';
import { withBackButtonAlert } from '../../hocs';
import {
  gtmTrackEvent,
  Events,
  FormActions,
  FormNames,
} from '../../utils/gtm-track-event';

declare const LINK_AQUA_CREDIT_COACH;

const QuoteIneligiblePageComponent = () => {
  const handleClick = () => {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_name: FormNames.APPLICATION,
      form_action: FormActions.CLICK_CTA,
      link_text: 'help me boost my credit score',
    });
  };
  const { brand, brandWithArticle } = useBrand();
  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <Hero
        icon={<LatePaymentIcon variant="primary" />}
        title="Unfortunately you're not eligible for a loan right now"
        backgroundColor={brand === 'aqua' ? undefined : 'brand.greyScale.200'}
        fullWidth
      >
        <>
          <Text fontWeight="semibold" mt={2} mb={4} color="black">
            {`Thanks for completing our eligibility check. We're very sorry that
            we can't offer you ${brandWithArticle} personal loan today.`}
          </Text>
          {brand === 'aqua' ? (
            <>
              <Text fontWeight="normal" pb={4} color="black">
                {`We know this is disappointing for you, and we'd like to help. You
        could improve your chances of getting a loan in the future by
        building better credit - and we've got lots of tools and tips to
        support you in Aqua Coach.`}
              </Text>
              <LinkButton
                onClick={handleClick}
                href={LINK_AQUA_CREDIT_COACH}
                target="_blank"
                w={{ base: 'full', md: '2xs' }}
              >
                Help me boost my credit score
              </LinkButton>
            </>
          ) : (
            <>
              <Text fontWeight="normal" mb={4} color="black">
                {`We know this is disappointing for you, and we'd like to help. You
        could improve your chances of getting a loan in the future by
        improving your credit score.`}
              </Text>
              <BoostCreditScoreTipsList />
            </>
          )}
        </>
      </Hero>

      {brand === 'aqua' ? (
        <ContentWrapper maxContentWidth="5xl">
          <Faqs
            title="Frequently asked questions"
            items={[
              {
                buttonLabel:
                  'How do you decide whether I can have a personal loan?',
                content: `We're committed to responsible lending, so it's important for us to make sure that ${brandWithArticle} personal loan is right for you before we offer you a loan. This will include us checking that your loan repayments will be affordable for you, now and in the future. To do this, we'll look at things we already know about you as a card customer, as well as information provided by credit reference agencies, plus your income and monthly expenses. You'll also need to be a UK resident aged 18 or over, and to have had ${brandWithArticle} credit card for six months or more.`,
              },
              {
                buttonLabel:
                  "Will you tell credit reference agencies (CRAs) that I've applied for a loan?",
                content: `Checking your eligibility for ${brandWithArticle} personal loan doesn't affect your credit score and won't be reported to the CRAs. If you're eligible and go on to make a full application to take out a loan, we're required to report the details of your application to the CRAs.`,
              },
              {
                buttonLabel:
                  "If I'm not eligible for a loan now, can I try again later?",
                content: `Yes, we may send you another invitation to apply for ${brandWithArticle} personal loan in the future, if we think that's the right thing for us to do as a responsible lender. You can track your credit score at any time - plus discover a range of credit-building tools and tips - using Aqua Coach.`,
              },
            ]}
          />
        </ContentWrapper>
      ) : null}
    </ResponsivePageLayout>
  );
};

export const QuoteIneligiblePage = withBackButtonAlert(
  QuoteIneligiblePageComponent
);
