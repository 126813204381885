import { ComponentStyleConfig } from '@chakra-ui/react';
import { blacken } from '@chakra-ui/theme-tools';

export const RadioButton: ComponentStyleConfig = {
  baseStyle: ({ theme: { colors } }) => {
    const brandPrimary = 'brand.primary.500';
    return {
      py: '0.75rem',
      flexGrow: 1,
      color: 'black',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5rem',
      fontWeight: '400',
      backgroundColor: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'brand.greyScale.300',
      cursor: 'pointer',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _focus: {
        borderWidth: '1px',
        borderColor: 'white',
        boxShadow: 'outline',
      },
      _hover: {
        backgroundColor: blacken('white', 10),
        borderColor: 'brand.primary.500',
        borderWidth: '1px',
      },
      _checked: {
        color: 'white',
        backgroundColor: brandPrimary,
        borderWidth: '1px',
        borderColor: 'brand.greyScale.300',
        _hover: {
          backgroundColor: blacken(brandPrimary, 10),
          borderColor: 'brand.primary.500',
        },
      },
      _invalid: {
        color: 'red.600',
        borderWidth: '1px',
        borderColor: 'red.600',
        boxShadow: `0 0 0 1px ${colors.red['600']}`,
      },
      _disabled: {
        opacity: '0.5',
        cursor: 'not-allowed',
      },
    };
  },
  sizes: {
    md: {
      borderRadius: 'brand.field',
      px: '1.25rem',
    },
  },
  defaultProps: {
    size: 'md',
  },
};
