import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { TwoColorIconProps } from '../icon';

export const MinPaymentIcon = ({
  size,
  variant,
  color,
  ...props
}: TwoColorIconProps) => {
  const styles = useIconStyleConfig('TwoColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 60 60" __css={styles} {...props}>
      <path
        d="M29.4094 58.0696C44.948 58.0696 57.5445 45.473 57.5445 29.9344C57.5445 14.3957 44.948 1.79919 29.4094 1.79919C13.8707 1.79919 1.27417 14.3957 1.27417 29.9344C1.27417 45.473 13.8707 58.0696 29.4094 58.0696Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4094 2.6044C14.3155 2.6044 2.07942 14.8404 2.07942 29.9344C2.07942 45.0283 14.3155 57.2643 29.4094 57.2643C44.5033 57.2643 56.7394 45.0283 56.7394 29.9344C56.7394 14.8404 44.5033 2.6044 29.4094 2.6044ZM0.468994 29.9344C0.468994 13.951 13.4261 0.993973 29.4094 0.993973C45.3927 0.993973 58.3498 13.951 58.3498 29.9344C58.3498 45.9177 45.3927 58.8748 29.4094 58.8748C13.4261 58.8748 0.468994 45.9177 0.468994 29.9344Z"
        fill="currentColor"
      />
      <path d="M40.1074 15.1556H24.0628C23.6069 15.1556 23.2373 15.5252 23.2373 15.9811V18.5296C23.2373 18.9855 23.6069 19.3551 24.0628 19.3551H40.1074C40.5633 19.3551 40.9329 18.9855 40.9329 18.5296V15.9811C40.9329 15.5252 40.5633 15.1556 40.1074 15.1556Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0436 15.9734C24.043 15.9748 24.0423 15.9768 24.0423 15.9811V18.5296C24.0423 18.5339 24.043 18.536 24.0436 18.5373C24.0444 18.5391 24.0458 18.5415 24.0483 18.544C24.0507 18.5464 24.0531 18.5479 24.055 18.5486C24.0563 18.5492 24.0583 18.5499 24.0626 18.5499H40.1072C40.1115 18.5499 40.1135 18.5492 40.1149 18.5486C40.1167 18.5479 40.1191 18.5464 40.1216 18.544C40.124 18.5415 40.1255 18.5391 40.1262 18.5373C40.1268 18.536 40.1275 18.5339 40.1275 18.5296V15.9811C40.1275 15.9768 40.1268 15.9748 40.1262 15.9734C40.1255 15.9716 40.124 15.9692 40.1216 15.9667C40.1191 15.9643 40.1167 15.9628 40.1149 15.9621C40.1135 15.9615 40.1115 15.9608 40.1072 15.9608H24.0626C24.0583 15.9608 24.0563 15.9615 24.055 15.9621C24.0531 15.9628 24.0507 15.9643 24.0483 15.9667C24.0458 15.9692 24.0444 15.9716 24.0436 15.9734ZM22.4319 15.9811C22.4319 15.0805 23.162 14.3504 24.0626 14.3504H40.1072C41.0078 14.3504 41.7379 15.0805 41.7379 15.9811V18.5296C41.7379 19.4302 41.0078 20.1603 40.1072 20.1603H24.0626C23.162 20.1603 22.4319 19.4302 22.4319 18.5296V15.9811Z"
        fill="currentColor"
      />
      <path d="M41.8008 19.389H25.7562C25.3003 19.389 24.9307 19.7586 24.9307 20.2145V22.763C24.9307 23.2189 25.3003 23.5885 25.7562 23.5885H41.8008C42.2567 23.5885 42.6263 23.2189 42.6263 22.763V20.2145C42.6263 19.7586 42.2567 19.389 41.8008 19.389Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7369 20.2068C25.7364 20.2082 25.7357 20.2102 25.7357 20.2145V22.763C25.7357 22.7673 25.7364 22.7693 25.7369 22.7707C25.7377 22.7725 25.7392 22.7749 25.7416 22.7774C25.744 22.7798 25.7465 22.7813 25.7483 22.782C25.7496 22.7826 25.7517 22.7833 25.756 22.7833H41.8006C41.8048 22.7833 41.8069 22.7826 41.8082 22.782C41.8101 22.7813 41.8125 22.7798 41.8149 22.7774C41.8173 22.7749 41.8188 22.7725 41.8196 22.7707C41.8202 22.7693 41.8209 22.7673 41.8209 22.763V20.2145C41.8209 20.2102 41.8202 20.2082 41.8196 20.2068C41.8188 20.205 41.8173 20.2026 41.8149 20.2001C41.8125 20.1977 41.8101 20.1962 41.8082 20.1955C41.8069 20.1949 41.8048 20.1942 41.8006 20.1942H25.756C25.7517 20.1942 25.7496 20.1949 25.7483 20.1955C25.7465 20.1962 25.744 20.1977 25.7416 20.2001C25.7392 20.2026 25.7377 20.205 25.7369 20.2068ZM24.1252 20.2145C24.1252 19.3139 24.8553 18.5838 25.756 18.5838H41.8006C42.7012 18.5838 43.4313 19.3139 43.4313 20.2145V22.763C43.4313 23.6636 42.7012 24.3937 41.8006 24.3937H25.756C24.8553 24.3937 24.1252 23.6636 24.1252 22.763V20.2145Z"
        fill="currentColor"
      />
      <path d="M39.9675 23.6901H23.9229C23.467 23.6901 23.0974 24.0597 23.0974 24.5156V27.0641C23.0974 27.5201 23.467 27.8897 23.9229 27.8897H39.9675C40.4234 27.8897 40.793 27.5201 40.793 27.0641V24.5156C40.793 24.0597 40.4234 23.6901 39.9675 23.6901Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9039 24.508C23.9034 24.5093 23.9027 24.5113 23.9027 24.5156V27.0641C23.9027 27.0684 23.9034 27.0705 23.9039 27.0718C23.9047 27.0736 23.9062 27.0761 23.9086 27.0785C23.911 27.0809 23.9135 27.0824 23.9153 27.0832C23.9166 27.0837 23.9187 27.0844 23.923 27.0844H39.9676C39.9718 27.0844 39.9739 27.0837 39.9752 27.0832C39.9771 27.0824 39.9795 27.0809 39.9819 27.0785C39.9843 27.0761 39.9858 27.0736 39.9866 27.0718C39.9871 27.0705 39.9879 27.0684 39.9879 27.0641V24.5156C39.9879 24.5113 39.9872 24.5093 39.9866 24.508C39.9858 24.5061 39.9843 24.5037 39.9819 24.5013C39.9795 24.4989 39.9771 24.4974 39.9752 24.4966C39.9739 24.496 39.9718 24.4953 39.9676 24.4953H23.923C23.9187 24.4953 23.9166 24.496 23.9153 24.4966C23.9135 24.4974 23.911 24.4989 23.9086 24.5013C23.9062 24.5037 23.9047 24.5061 23.9039 24.508ZM22.2922 24.5156C22.2922 23.615 23.0223 22.8849 23.923 22.8849H39.9676C40.8682 22.8849 41.5983 23.615 41.5983 24.5156V27.0641C41.5983 27.9648 40.8682 28.6949 39.9676 28.6949H23.923C23.0223 28.6949 22.2922 27.9648 22.2922 27.0641V24.5156Z"
        fill="currentColor"
      />
      <path d="M39.9675 32.1569H23.9229C23.467 32.1569 23.0974 32.5265 23.0974 32.9824V35.5309C23.0974 35.9869 23.467 36.3565 23.9229 36.3565H39.9675C40.4234 36.3565 40.793 35.9869 40.793 35.5309V32.9824C40.793 32.5265 40.4234 32.1569 39.9675 32.1569Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9039 32.9748C23.9034 32.9761 23.9027 32.9782 23.9027 32.9824V35.531C23.9027 35.5352 23.9034 35.5373 23.9039 35.5386C23.9047 35.5405 23.9062 35.5429 23.9086 35.5453C23.911 35.5477 23.9135 35.5492 23.9153 35.55C23.9166 35.5505 23.9187 35.5513 23.923 35.5513H39.9676C39.9718 35.5513 39.9739 35.5505 39.9752 35.55C39.9771 35.5492 39.9795 35.5477 39.9819 35.5453C39.9843 35.5429 39.9858 35.5405 39.9866 35.5386C39.9871 35.5373 39.9879 35.5352 39.9879 35.531V32.9824C39.9879 32.9782 39.9871 32.9761 39.9866 32.9748C39.9858 32.9729 39.9843 32.9705 39.9819 32.9681C39.9795 32.9657 39.9771 32.9642 39.9752 32.9634C39.9739 32.9629 39.9718 32.9621 39.9676 32.9621H23.923C23.9187 32.9621 23.9166 32.9629 23.9153 32.9634C23.9135 32.9642 23.911 32.9657 23.9086 32.9681C23.9062 32.9705 23.9047 32.9729 23.9039 32.9748ZM22.2922 32.9824C22.2922 32.0818 23.0223 31.3517 23.923 31.3517H39.9676C40.8682 31.3517 41.5983 32.0818 41.5983 32.9824V35.531C41.5983 36.4316 40.8682 37.1617 39.9676 37.1617H23.923C23.0223 37.1617 22.2922 36.4316 22.2922 35.531V32.9824Z"
        fill="currentColor"
      />
      <path d="M43.0708 36.2506H27.0262C26.5703 36.2506 26.2007 36.6202 26.2007 37.0761V39.6246C26.2007 40.0806 26.5703 40.4501 27.0262 40.4501H43.0708C43.5267 40.4501 43.8963 40.0806 43.8963 39.6246V37.0761C43.8963 36.6202 43.5267 36.2506 43.0708 36.2506Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.007 37.0685C27.0064 37.0698 27.0057 37.0719 27.0057 37.0761V39.6247C27.0057 39.6289 27.0064 39.631 27.007 39.6323C27.0077 39.6342 27.0092 39.6366 27.0116 39.639C27.0141 39.6414 27.0165 39.6429 27.0183 39.6437C27.0196 39.6442 27.0217 39.645 27.026 39.645H43.0706C43.0749 39.645 43.0769 39.6442 43.0782 39.6437C43.0801 39.6429 43.0825 39.6414 43.0849 39.639C43.0874 39.6366 43.0888 39.6342 43.0896 39.6323C43.0902 39.631 43.0909 39.6289 43.0909 39.6247V37.0761C43.0909 37.0719 43.0902 37.0698 43.0896 37.0685C43.0888 37.0666 43.0874 37.0642 43.0849 37.0618C43.0825 37.0594 43.0801 37.0579 43.0782 37.0571C43.0769 37.0566 43.0749 37.0558 43.0706 37.0558H27.026C27.0217 37.0558 27.0196 37.0566 27.0183 37.0571C27.0165 37.0579 27.0141 37.0594 27.0116 37.0618C27.0092 37.0642 27.0077 37.0666 27.007 37.0685ZM25.3953 37.0761C25.3953 36.1755 26.1254 35.4454 27.026 35.4454H43.0706C43.9712 35.4454 44.7013 36.1755 44.7013 37.0761V39.6247C44.7013 40.5253 43.9712 41.2554 43.0706 41.2554H27.026C26.1254 41.2554 25.3953 40.5253 25.3953 39.6247V37.0761Z"
        fill="currentColor"
      />
      <path d="M40.3909 40.7677H24.3463C23.8903 40.7677 23.5208 41.1373 23.5208 41.5932V44.1417C23.5208 44.5976 23.8903 44.9672 24.3463 44.9672H40.3909C40.8468 44.9672 41.2164 44.5976 41.2164 44.1417V41.5932C41.2164 41.1373 40.8468 40.7677 40.3909 40.7677Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3273 41.5855C24.3267 41.5868 24.326 41.5889 24.326 41.5932V44.1417C24.326 44.146 24.3267 44.148 24.3273 44.1493C24.328 44.1512 24.3295 44.1536 24.3319 44.156C24.3344 44.1585 24.3368 44.1599 24.3386 44.1607C24.34 44.1613 24.342 44.162 24.3463 44.162H40.3909C40.3933 44.162 40.395 44.1618 40.3963 44.1615C40.3973 44.1612 40.398 44.161 40.3985 44.1607C40.3996 44.1603 40.4008 44.1596 40.4021 44.1587C40.4031 44.158 40.4042 44.1571 40.4052 44.156C40.4077 44.1536 40.4091 44.1512 40.4099 44.1493C40.4105 44.148 40.4112 44.146 40.4112 44.1417V41.5932C40.4112 41.5889 40.4105 41.5868 40.4099 41.5855C40.4091 41.5837 40.4077 41.5813 40.4052 41.5788C40.4028 41.5764 40.4004 41.5749 40.3986 41.5742C40.3972 41.5736 40.3952 41.5729 40.3909 41.5729H24.3463C24.342 41.5729 24.34 41.5736 24.3386 41.5742C24.3368 41.5749 24.3344 41.5764 24.3319 41.5788C24.3295 41.5813 24.328 41.5837 24.3273 41.5855ZM22.7156 41.5932C22.7156 40.6926 23.4457 39.9625 24.3463 39.9625H40.3909C41.2915 39.9625 42.0216 40.6926 42.0216 41.5932V44.1417C42.0216 45.0423 41.2915 45.7724 40.3909 45.7724H24.3463C23.4457 45.7724 22.7156 45.0423 22.7156 44.1417V41.5932Z"
        fill="currentColor"
      />
      <path d="M38.1345 27.9235H22.0899C21.634 27.9235 21.2644 28.2931 21.2644 28.749V31.2975C21.2644 31.7535 21.634 32.1231 22.0899 32.1231H38.1345C38.5904 32.1231 38.96 31.7535 38.96 31.2975V28.749C38.96 28.2931 38.5904 27.9235 38.1345 27.9235Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0709 28.7414C22.0704 28.7427 22.0697 28.7448 22.0697 28.749V31.2976C22.0697 31.3018 22.0704 31.3039 22.0709 31.3052C22.0717 31.3071 22.0732 31.3095 22.0756 31.3119C22.078 31.3143 22.0805 31.3158 22.0823 31.3166C22.0836 31.3171 22.0857 31.3179 22.09 31.3179H38.1345C38.1388 31.3179 38.1409 31.3171 38.1422 31.3166C38.1441 31.3158 38.1465 31.3143 38.1489 31.3119C38.1513 31.3095 38.1528 31.3071 38.1536 31.3052C38.1541 31.3039 38.1548 31.3018 38.1548 31.2976V28.749C38.1548 28.7448 38.1541 28.7427 38.1536 28.7414C38.1528 28.7395 38.1513 28.7371 38.1489 28.7347C38.1465 28.7323 38.144 28.7308 38.1422 28.73C38.1409 28.7295 38.1388 28.7287 38.1345 28.7287H22.09C22.0857 28.7287 22.0836 28.7295 22.0823 28.73C22.0805 28.7308 22.078 28.7323 22.0756 28.7347C22.0732 28.7371 22.0717 28.7395 22.0709 28.7414ZM20.4592 28.749C20.4592 27.8484 21.1893 27.1183 22.09 27.1183H38.1345C39.0352 27.1183 39.7653 27.8484 39.7653 28.749V31.2976C39.7653 32.1982 39.0352 32.9283 38.1345 32.9283H22.09C21.1893 32.9283 20.4592 32.1982 20.4592 31.2976V28.749Z"
        fill="currentColor"
      />
      <path
        d="M22.9578 45.1069C29.0367 45.1069 33.9646 40.179 33.9646 34.1001C33.9646 28.0211 29.0367 23.0932 22.9578 23.0932C16.8789 23.0932 11.9509 28.0211 11.9509 34.1001C11.9509 40.179 16.8789 45.1069 22.9578 45.1069Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9578 23.8984C17.3236 23.8984 12.7562 28.4658 12.7562 34.1001C12.7562 39.7343 17.3236 44.3017 22.9578 44.3017C28.592 44.3017 33.1594 39.7343 33.1594 34.1001C33.1594 28.4658 28.592 23.8984 22.9578 23.8984ZM11.1458 34.1001C11.1458 27.5764 16.4342 22.288 22.9578 22.288C29.4814 22.288 34.7699 27.5764 34.7699 34.1001C34.7699 40.6237 29.4814 45.9121 22.9578 45.9121C16.4342 45.9121 11.1458 40.6237 11.1458 34.1001Z"
        fill="currentColor"
      />
      <path
        d="M22.3355 40.2766C23.0463 40.2766 23.6225 39.7004 23.6225 38.9896C23.6225 38.2789 23.0463 37.7027 22.3355 37.7027C21.6248 37.7027 21.0486 38.2789 21.0486 38.9896C21.0486 39.7004 21.6248 40.2766 22.3355 40.2766Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3609 36.3048C22.8056 36.3048 23.1661 36.6653 23.1661 37.11V39.5781C23.1661 40.0228 22.8056 40.3833 22.3609 40.3833C21.9162 40.3833 21.5557 40.0228 21.5557 39.5781V37.11C21.5557 36.6653 21.9162 36.3048 22.3609 36.3048Z"
        fill="white"
      />
      <path
        d="M22.8266 37.0042C22.8258 37.0961 22.7977 37.1857 22.7461 37.2617C22.6944 37.3377 22.6214 37.3968 22.5362 37.4314C22.451 37.466 22.3576 37.4746 22.2675 37.4561C22.1775 37.4377 22.0949 37.393 22.0302 37.3277C21.9655 37.2624 21.9215 37.1795 21.9039 37.0892C21.8863 36.999 21.8957 36.9056 21.9311 36.8208C21.9665 36.7359 22.0262 36.6634 22.1026 36.6125C22.1791 36.5615 22.269 36.5343 22.3609 36.5343C22.4224 36.5343 22.4833 36.5465 22.5401 36.5701C22.5969 36.5938 22.6484 36.6285 22.6917 36.6722C22.735 36.7158 22.7692 36.7677 22.7924 36.8247C22.8155 36.8817 22.8272 36.9427 22.8266 37.0042Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4888 37.3145C22.5099 37.3059 22.5302 37.2952 22.5493 37.2825C22.5859 37.258 22.6173 37.2269 22.6418 37.1908C22.6546 37.172 22.6655 37.1518 22.6744 37.1306C22.6913 37.0899 22.7001 37.0466 22.7005 37.003C22.7007 36.9802 22.6986 36.9574 22.6942 36.9348C22.69 36.9133 22.6837 36.8923 22.6755 36.8721C22.6587 36.8308 22.6339 36.7929 22.6021 36.7609C22.5704 36.7289 22.5328 36.7037 22.4916 36.6865C22.4715 36.6782 22.4506 36.6717 22.4291 36.6673C22.4066 36.6627 22.3837 36.6604 22.361 36.6604C22.3174 36.6604 22.274 36.6688 22.2332 36.6854C22.2119 36.694 22.1916 36.7048 22.1726 36.7174C22.1364 36.7416 22.1049 36.7727 22.0802 36.8091C22.0672 36.8281 22.0563 36.8483 22.0476 36.8693C22.0308 36.9095 22.0219 36.9528 22.0214 36.9968C22.0212 37.0198 22.0234 37.0426 22.0277 37.065C22.0319 37.0864 22.0382 37.1074 22.0464 37.1277C22.0633 37.1693 22.0882 37.2071 22.1198 37.2389C22.1514 37.2708 22.189 37.2961 22.2303 37.3133C22.2506 37.3218 22.2715 37.3282 22.2928 37.3326C22.3152 37.3372 22.338 37.3395 22.361 37.3395C22.405 37.3395 22.4484 37.3309 22.4888 37.3145ZM21.6561 35.9425C21.8648 35.8033 22.1101 35.7291 22.3609 35.729C22.5288 35.729 22.695 35.7623 22.85 35.8269C23.0049 35.8915 23.1455 35.9861 23.2637 36.1054C23.3819 36.2246 23.4752 36.3661 23.5384 36.5216C23.6016 36.6771 23.6333 36.8436 23.6318 37.0115C23.6295 37.2623 23.5531 37.5069 23.412 37.7144C23.271 37.9218 23.0717 38.0829 22.8393 38.1774C22.6069 38.2718 22.3517 38.2953 22.106 38.245C21.8602 38.1946 21.6349 38.0727 21.4583 37.8945C21.2817 37.7163 21.1618 37.4898 21.1137 37.2437C21.0656 36.9974 21.0914 36.7425 21.1879 36.511C21.2844 36.2794 21.4473 36.0816 21.6561 35.9425Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1582 33.5836C16.1582 33.1389 16.5187 32.7784 16.9634 32.7784H28.8169C29.2617 32.7784 29.6222 33.1389 29.6222 33.5836C29.6222 34.0283 29.2617 34.3888 28.8169 34.3888H16.9634C16.5187 34.3888 16.1582 34.0283 16.1582 33.5836Z"
        fill="currentColor"
      />
    </Icon>
  );
};
