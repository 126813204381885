import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';
import { components } from './components';

export default {
  colors,
  fonts,
  radii: {
    brand: {
      field: '0.3125rem',
      cta: '0.375rem',
    },
  },
  components,
};
