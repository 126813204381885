import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const IncomeIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 41 40" __css={styles} {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.5 40c11.046 0 20-8.954 20-20s-8.954-20-20-20S.5 8.954.5 20s8.954 20 20 20Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.5 38.333c10.125 0 18.333-8.208 18.333-18.333 0-10.125-8.208-18.333-18.333-18.333C10.375 1.667 2.167 9.875 2.167 20c0 10.125 8.208 18.333 18.333 18.333ZM40.5 20c0 11.046-8.954 20-20 20S.5 31.046.5 20s8.954-20 20-20 20 8.954 20 20Z"
        clipRule="evenodd"
      />
      <path
        fill="white"
        d="M14 21.625a.975.975 0 1 0 1.95 0 .975.975 0 0 0-1.95 0Zm.975 5.525a.975.975 0 1 1 0-1.95.975.975 0 0 1 0 1.95Zm4.55-5.525a.975.975 0 1 0 1.95 0 .975.975 0 0 0-1.95 0Zm.975 5.525a.975.975 0 1 1 0-1.95.975.975 0 0 1 0 1.95Zm4.55-5.525a.975.975 0 1 0 1.95 0 .975.975 0 0 0-1.95 0Zm.975 5.525a.975.975 0 1 1 0-1.95.975.975 0 0 1 0 1.95Z"
      />
      <path
        fill="white"
        fillRule="evenodd"
        d="M24.4 12.2h-7.8v-1.95a.65.65 0 1 0-1.3 0v1.95h-3.25a1.95 1.95 0 0 0-1.95 1.95v14.3a1.95 1.95 0 0 0 1.95 1.95h16.9a1.95 1.95 0 0 0 1.95-1.95v-14.3a1.95 1.95 0 0 0-1.95-1.95H25.7v-1.95a.65.65 0 1 0-1.3 0v1.95Zm-8.45 3.25a.65.65 0 0 1-.65-.65v-1.3h-3.25a.65.65 0 0 0-.65.65v3.25h18.2v-3.25a.65.65 0 0 0-.65-.65H25.7v1.3a.65.65 0 1 1-1.3 0v-1.3h-7.8v1.3a.65.65 0 0 1-.65.65ZM11.4 18.7v9.75c0 .359.291.65.65.65h16.9a.65.65 0 0 0 .65-.65V18.7H11.4Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
