import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const SafeIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 25 25" __css={styles} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 23.9583C18.8283 23.9583 23.9583 18.8283 23.9583 12.5C23.9583 6.17174 18.8283 1.04167 12.5 1.04167C6.17174 1.04167 1.04167 6.17174 1.04167 12.5C1.04167 18.8283 6.17174 23.9583 12.5 23.9583ZM25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z"
        fill="currentColor"
      />
      <g clipPath="url(#clip0_3429_34862)">
        <path
          d="M9.65625 11.987L10.9833 13.8726C11.0337 13.9477 11.1013 14.0097 11.1805 14.0534C11.2597 14.0971 11.3481 14.1213 11.4385 14.124C11.529 14.1267 11.6187 14.1078 11.7003 14.0689C11.782 14.03 11.8531 13.9721 11.908 13.9002L16.1563 8.52308M7.625 6.4057C7.40951 6.4057 7.20285 6.4913 7.05048 6.64368C6.8981 6.79605 6.8125 7.00271 6.8125 7.2182V12.0932C6.8125 14.7002 10.7309 17.6014 12.0629 18.4632C12.1929 18.548 12.3448 18.5932 12.5 18.5932C12.6552 18.5932 12.8071 18.548 12.9371 18.4632C14.2691 17.6014 18.1875 14.7002 18.1875 12.0932V7.2182C18.1875 7.1115 18.1665 7.00585 18.1257 6.90727C18.0848 6.80869 18.025 6.71912 17.9495 6.64368C17.8741 6.56823 17.7845 6.50838 17.6859 6.46755C17.5874 6.42672 17.4817 6.4057 17.375 6.4057H7.625Z"
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3429_34862">
          <rect
            width="13"
            height="13"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};
