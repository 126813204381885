import { ComponentStyleConfig } from '@chakra-ui/react';

const Card: ComponentStyleConfig = {
  parts: ['wrapper', 'body', 'footer', 'image'],
  baseStyle: {
    wrapper: {
      bgColor: 'white',
      p: 3,
    },
    body: {
      mb: 2,
    },
    footer: {},
  },
  defaultProps: {
    variant: 'rounded',
  },
  variants: {
    rounded: {
      wrapper: {
        borderRadius: 'base',
      },
    },
  },
};

export default Card;
