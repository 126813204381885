import { Heading, SimpleGrid, Stack, Text } from '@newday/core';
import {
  BalloonBox,
  BoostCreditScoreTips,
  FaqPanel,
  BoostCreditScoreHelp,
  ResponsivePageLayout,
  ContentWrapper,
} from '../../components';
import { useBrand } from '../../app';
import { ApplicationReference } from '../../features';

export const HardCheckIneligiblePage = () => {
  const { brand } = useBrand();

  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <BalloonBox variant={brand === 'aqua' ? 'light' : 'generic'}>
        <>
          <Heading fontSize="2xl" lineHeight={9} fontWeight="bold">
            We’re sorry that your loan application wasn’t successful
          </Heading>
          <Text fontSize="md" lineHeight="base" fontWeight="semibold" mt={2.5}>
            After carrying out our full checks and reviewing the additional
            information you gave us, unfortunately we can’t offer you a loan
            today.
          </Text>
          {brand === 'aqua' ? (
            <Text fontSize="md" lineHeight="base" fontWeight="normal" mt={2.5}>
              We know this is disappointing for you, and we’d like to help. You
              could improve your chances of getting a loan in the future by
              building better credit.
            </Text>
          ) : null}
        </>
      </BalloonBox>
      <ContentWrapper>
        {brand === 'aqua' ? (
          <>
            <Heading
              mt={4}
              mb={4}
              color="black"
              fontSize="lg"
              fontWeight="bold"
              textAlign={{ md: 'center' }}
            >
              Helping you boost your credit score
            </Heading>
            <SimpleGrid gap={4} mb={8} columns={{ base: 1, md: 3 }}>
              <BoostCreditScoreHelp />
            </SimpleGrid>
          </>
        ) : (
          <Stack gap={4} mb={8}>
            <BoostCreditScoreTips />
            <FaqPanel />
          </Stack>
        )}
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
