import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const ProfileIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 25 25" __css={styles} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 23.9583C18.8283 23.9583 23.9583 18.8283 23.9583 12.5C23.9583 6.17174 18.8283 1.04167 12.5 1.04167C6.17174 1.04167 1.04167 6.17174 1.04167 12.5C1.04167 18.8283 6.17174 23.9583 12.5 23.9583ZM25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z"
        fill="currentColor"
      />
      <path
        d="M12.5 12.5C10.7082 12.5 9.25 11.0418 9.25 9.25C9.25 7.45817 10.7082 6 12.5 6C14.2918 6 15.75 7.45817 15.75 9.25C15.75 11.0418 14.2918 12.5 12.5 12.5ZM12.5 6.8125C11.1561 6.8125 10.0625 7.90612 10.0625 9.25C10.0625 10.5939 11.1561 11.6875 12.5 11.6875C13.8439 11.6875 14.9375 10.5939 14.9375 9.25C14.9375 7.90612 13.8439 6.8125 12.5 6.8125Z"
        fill="white"
      />
      <path
        d="M17.7812 19C17.557 19 17.375 18.818 17.375 18.5938C17.375 15.9054 15.1883 13.7188 12.5 13.7188C9.81171 13.7188 7.625 15.9054 7.625 18.5938C7.625 18.818 7.443 19 7.21875 19C6.9945 19 6.8125 18.818 6.8125 18.5938C6.8125 15.4575 9.36375 12.9062 12.5 12.9062C15.6362 12.9062 18.1875 15.4575 18.1875 18.5938C18.1875 18.818 18.0055 19 17.7812 19Z"
        fill="white"
      />
    </Icon>
  );
};
