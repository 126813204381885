import { Icon } from '@chakra-ui/icons';
import { OneColorIconProps, useIconStyleConfig } from '../icon';

export const QuestionMarkIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 19 18" __css={styles} {...props}>
      <g fill="#3a3a3a">
        <path d="m7.8125 6.75011c.00016-.85814.64432-1.5795 1.49696-1.67639s1.64224.46154 1.83494 1.29776-.2728 1.6839-1.0819 1.96994c-.67454.23849-1.12537.87642-1.125 1.59188v.7542c0 .3107.25184.5625.5625.5625s.5625-.2518.5625-.5625v-.7545c-.0001-.23878.1502-.45142.375-.53092 1.3484-.47674 2.1243-1.88954 1.8032-3.28323-.3212-1.3937-1.6372-2.32442-3.05826-2.16293-1.42108.16148-2.49467 1.36375-2.49494 2.79397-.00006.31066.25173.56255.56239.56261s.56255-.25173.56261-.56239z" />
        <path d="m8.65625 13.2188c0 .4659.37776.8437.84375.8437s.8438-.3778.8438-.8437c0-.466-.37781-.8438-.8438-.8438-.46584.0002-.84375.3779-.84375.8438z" />
        <path
          clipRule="evenodd"
          d="m9.5 18c4.9706 0 9-4.0294 9-9 0-4.97056-4.0294-9-9-9-4.97056 0-9 4.02944-9 9 0 4.9706 4.02944 9 9 9zm0-1.125c-4.34924 0-7.875-3.5258-7.875-7.875 0-4.34924 3.52576-7.875 7.875-7.875 4.3492 0 7.875 3.52576 7.875 7.875 0 4.3492-3.5258 7.875-7.875 7.875z"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  );
};
