import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@newday/core';
import { ExclamationMarkIcon } from '@newday/icons';

type DocUploadModal = {
  isOpen: boolean;
  onClose: () => void;
  handleResetSelection: () => void;
};

export const DocUploadModal: React.FC<DocUploadModal> = ({
  isOpen,
  onClose,
  handleResetSelection,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent p="1.5rem">
      <ModalCloseButton />
      <ModalHeader
        display="flex"
        alignItems="center"
        flexDirection="column"
        mx="auto"
        mb="1.25rem"
        p="0 3.5rem"
      >
        <ExclamationMarkIcon mb="1.25rem" />
        <Heading as="h2" color="brand.primary" textAlign="center">
          Your files will be lost if you exit
        </Heading>
      </ModalHeader>
      <ModalBody p="0" mb="1.25rem" textAlign={{ base: 'left', md: 'center' }}>
        Your proof of income documents must all be from one category. Exiting
        this category now will delete the files you&apos;ve attached.
      </ModalBody>
      <ModalFooter
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'stretch' }}
        gap="0.75rem"
        p={{ base: '0 0.5rem', md: '0' }}
      >
        <Button
          onClick={() => handleResetSelection()}
          flexBasis={{ base: '100%', md: '50%' }}
        >
          Delete files and exit
        </Button>
        <Button
          onClick={onClose}
          variant="tertiary"
          flexBasis={{ base: '100%', md: '50%' }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
