import { ComponentStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
  baseStyle: ({ theme }) => {
    const { colors } = theme;
    const styles = {
      backgroundColor: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'brand.quaternary',
    };

    const checkedStyles = {
      backgroundColor: 'white',
      borderColor: 'brand.primary',
      color: 'brand.primary',
    };

    const invalidStyles = {
      borderColor: 'red.600',
      boxShadow: `0 0 0 1px ${colors.red['600']}`,
    };

    return {
      control: {
        ...styles,
        borderRadius: 'brand.field',
        _checked: {
          ...checkedStyles,
          _hover: checkedStyles,
          _focus: checkedStyles,
        },
        _indeterminate: styles,
        _focus: styles,
        _invalid: {
          ...invalidStyles,
          _checked: {
            color: 'red.600',
          },
          _focus: invalidStyles,
          _hover: invalidStyles,
        },
      },
    };
  },
  sizes: {
    sm: {
      control: { w: 25, h: 25 },
      icon: { fontSize: '1rem' },
      label: { fontSize: 'sm' },
    },
    md: {
      control: { w: 25, h: 25 },
      icon: { fontSize: '1rem' },
      label: { fontSize: 'md' },
    },
    lg: {
      control: { w: 25, h: 25 },
      icon: { fontSize: '1rem' },
      label: { fontSize: 'lg', marginLeft: 3 },
    },
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
  variants: {
    default: {},
    primary: {
      backgroundColor: 'brand.primary',
      fontWeight: 'bold',
      color: 'white',
      padding: 3,
    },
  },
};

export default Checkbox;
