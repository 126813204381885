import { BffRoutes } from '@newday/plum-types';
import axios from 'axios';
import { useQuery } from 'react-query';

export const useEmploymentStatus = (applicationId: string) =>
  useQuery(['employment-status', applicationId], () =>
    axios
      .get(
        `${BffRoutes.employmentStatus.replace(':applicationId', applicationId)}`
      )
      .then((response) => response.data)
  );
