import React from 'react';
import {
  Slider as ChakraSlider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderProps,
} from '@chakra-ui/react';
import { ReactElement } from 'react';

export const Slider = (props: SliderProps): ReactElement => {
  return (
    <ChakraSlider {...props}>
      <SliderTrack tabIndex={-1}>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
    </ChakraSlider>
  );
};
