import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  useStyleConfig,
  forwardRef,
} from '@chakra-ui/react';

import { ReactElement } from 'react';
import { TickIcon } from '@newday/icons';

export type CheckboxProps = ChakraCheckboxProps & {
  variant?: 'default' | 'primary';
};

const CheckboxIcon = ({ isChecked }: CheckboxProps) => {
  return isChecked ? <TickIcon size="xs" /> : null;
};

export const Checkbox = forwardRef<CheckboxProps, 'input'>(
  (props, ref): ReactElement => {
    const { variant } = props;
    const styles = useStyleConfig('Checkbox', { variant });

    return (
      <ChakraCheckbox
        __css={styles}
        ref={ref}
        {...props}
        icon={<CheckboxIcon />}
      />
    );
  }
);
