import {
  Circle,
  Stack,
  StackProps,
  Text,
  useMultiStyleConfig,
  forwardRef,
  VisuallyHidden,
} from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import { CheckIcon, TickIcon } from '@newday/icons';
import React, { ReactElement } from 'react';

export interface StepProps extends StackProps {
  index: number;
  label: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  icon?: ReactElement;
  state: 'active' | 'inactive' | 'completed';
}

const LiComponent = forwardRef<StackProps, 'li'>((props, ref) => {
  return <Stack {...props} ref={ref} />;
});

export const Step = (props: StepProps): ReactElement => {
  const { index, icon, label, state, variant = 'primary' } = props;

  const stylesByState = useMultiStyleConfig('Step', { variant });
  const styles = (stylesByState[state] || {}) as Record<string, CSSObject>;

  const completedIcon = variant === 'secondary' ? <CheckIcon /> : <TickIcon />;

  const accessibilityId = `${label}-step-label`
    .replace(/\s+/g, '-')
    .toLowerCase();

  return (
    <LiComponent
      width="2.5rem"
      alignItems="center"
      as="li"
      aria-labelledby={accessibilityId}
      {...(state === 'active' ? { 'aria-current': 'step' } : {})}
      {...props}
    >
      <Circle size="2.5rem" sx={styles['circle']}>
        {state === 'completed'
          ? React.cloneElement(completedIcon, { ...styles['icon'] })
          : icon
          ? React.cloneElement(icon, { ...styles['icon'] })
          : index}
      </Circle>
      <Text
        textAlign="center"
        fontSize="sm"
        fontWeight={600}
        color={styles['label']?.color}
        id={accessibilityId}
      >
        {state === 'active' && <VisuallyHidden>(Current step)</VisuallyHidden>}
        {label}
      </Text>
    </LiComponent>
  );
};
