import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const PlusIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 18 18" __css={styles} {...props}>
      <path
        d="M1.5 9H16.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 1.5V16.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
