/* istanbul ignore file */
import type { PlumSupportedBrand } from '@newday/plum-types';
import { useEffect } from 'react';
import { useBrand } from '../brand-provider';
import { useFeatureFlags } from '../use-feature-flags';
import injectMouseflowScript from './mouseflow';
import { useConfig } from '../config-provider';

const useMouseflow = () => {
  const brand = useBrand().brand as PlumSupportedBrand;

  const { MOUSEFLOW } = useFeatureFlags();
  const { variables } = useConfig();

  const mouseflow_id = variables?.mouseflow?.[brand];

  useEffect(() => {
    if (MOUSEFLOW && mouseflow_id) {
      injectMouseflowScript(mouseflow_id);
    }
  }, [MOUSEFLOW, brand, mouseflow_id]);
};

export default useMouseflow;
