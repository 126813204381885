import { ComponentStyleConfig } from '@chakra-ui/react';

export const Link: ComponentStyleConfig = {
  variants: {
    default: {
      color: 'brand.tertiary.500',
      outline: 'none',
      textDecoration: 'underline',

      _hover: {
        textDecoration: 'none',
      },
      _focusVisible: {
        boxShadow: 'outline',
        borderRadius: 'md',
      },
      _focus: {
        border: 'none',
      },
    },
    disabled: {
      color: 'brand.greyScale.600',
      cursor: 'not-allowed',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
};
