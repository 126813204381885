import { Box, Text, Skeleton } from '@newday/core';

export type ApplicationReferenceProps = {
  reference: string;
  isLoaded?: boolean;
};

export const ApplicationReference: React.FC<ApplicationReferenceProps> = ({
  reference,
  isLoaded = true,
}) => (
  <Skeleton data-testid="reference-skeleton" isLoaded={isLoaded} width="full">
    <Box backgroundColor="brand.primary" py={3} px={8}>
      <Text
        textAlign="center"
        fontSize="md"
        fontWeight="semibold"
        color="white"
      >
        Application reference: {reference}
      </Text>
    </Box>
  </Skeleton>
);
