export default {
  baseStyle: {
    fontWeight: 600,
  },
  variants: {
    default: {
      color: 'brand.primary',
      textDecoration: 'underline',
      _hover: {
        fontWeight: 'bold',
      },
      _focus: {
        borderRadius: 'brand.cta',
        borderWidth: 1,
        borderStyle: 'dashed',
        boxShadow: 'none',
        borderColor: 'brand.primary',
      },
    },
    disabled: {
      color: 'brand.greyScale.600',
      cursor: 'not-allowed',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
