import { Heading, Stack, Text } from '@newday/core';
import React, { ReactElement } from 'react';

export type AdvicePanelProps = {
  description: string | React.ReactNode;
  icon: ReactElement;
  title: string;
};

export const AdvicePanel: React.FC<AdvicePanelProps> = ({
  description,
  icon,
  title,
}) => (
  <Stack
    borderWidth="1px"
    borderColor="brand.quaternary"
    borderRadius="md"
    gap={2.5}
    py={5}
    px={3}
    alignItems="center"
    textAlign="center"
  >
    {icon && React.cloneElement(icon, { size: 'lg' })}
    <Heading fontSize="md">{title}</Heading>
    <Text fontSize="sm" fontWeight="400">
      {description}
    </Text>
  </Stack>
);
