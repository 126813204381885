import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const DownloadIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 16 16" __css={styles} {...props}>
      <path
        d="M0.46 7.05C0.519931 7.04867 0.579524 7.05932 0.63528 7.08134C0.691036 7.10336 0.741831 7.1363 0.784682 7.17822C0.827534 7.22013 0.861578 7.27019 0.884815 7.32545C0.908052 7.38071 0.920015 7.44006 0.92 7.5V11.57C0.919917 11.8369 0.973257 12.1011 1.07688 12.3471C1.1805 12.5931 1.33231 12.8158 1.52335 13.0022C1.7144 13.1886 1.94082 13.3348 2.18927 13.4324C2.43772 13.5299 2.70318 13.5767 2.97 13.57H13C13.2668 13.5767 13.5323 13.5299 13.7807 13.4324C14.0292 13.3348 14.2556 13.1886 14.4466 13.0022C14.6377 12.8158 14.7895 12.5931 14.8931 12.3471C14.9967 12.1011 15.0501 11.8369 15.05 11.57V7.43C15.05 7.308 15.0985 7.191 15.1847 7.10473C15.271 7.01847 15.388 6.97 15.51 6.97C15.632 6.97 15.749 7.01847 15.8353 7.10473C15.9215 7.191 15.97 7.308 15.97 7.43V11.56C15.9648 11.9478 15.8829 12.3308 15.7291 12.6868C15.5752 13.0428 15.3525 13.3649 15.0736 13.6344C14.7948 13.904 14.4653 14.1157 14.1043 14.2574C13.7433 14.3991 13.3578 14.468 12.97 14.46H3C2.61224 14.468 2.22673 14.3991 1.8657 14.2574C1.50466 14.1157 1.17523 13.904 0.896371 13.6344C0.617516 13.3649 0.394752 13.0428 0.240912 12.6868C0.0870714 12.3308 0.00519531 11.9478 1.37222e-08 11.56L1.37222e-08 7.5C-1.47896e-05 7.44006 0.0119477 7.38071 0.0351851 7.32545C0.0584225 7.27019 0.0924663 7.22013 0.135318 7.17822C0.178169 7.1363 0.228964 7.10336 0.28472 7.08134C0.340476 7.05932 0.400069 7.04867 0.46 7.05Z"
        fill="currentColor"
      />
      <path
        d="M8.30999 10.92C8.21921 10.9821 8.1095 11.0104 7.99999 11C7.94072 11.0005 7.88194 10.9892 7.82702 10.9669C7.77211 10.9446 7.72214 10.9117 7.67999 10.87L4.75999 8.07001C4.71796 8.02901 4.68456 7.98003 4.66175 7.92593C4.63895 7.87183 4.6272 7.81371 4.6272 7.75501C4.6272 7.6963 4.63895 7.63818 4.66175 7.58408C4.68456 7.52998 4.71796 7.481 4.75999 7.44001C4.84624 7.3539 4.96312 7.30554 5.08499 7.30554C5.20686 7.30554 5.32375 7.3539 5.40999 7.44001L7.52999 9.52001V0.450005C7.52999 0.328006 7.57846 0.211003 7.66472 0.124736C7.75099 0.0384696 7.86799 -0.00999451 7.98999 -0.00999451C8.11199 -0.00999451 8.22899 0.0384696 8.31526 0.124736C8.40153 0.211003 8.44999 0.328006 8.44999 0.450005V9.52001L10.58 7.44001C10.6662 7.3539 10.7831 7.30554 10.905 7.30554C11.0269 7.30554 11.1437 7.3539 11.23 7.44001C11.272 7.481 11.3054 7.52998 11.3282 7.58408C11.351 7.63818 11.3628 7.6963 11.3628 7.75501C11.3628 7.81371 11.351 7.87183 11.3282 7.92593C11.3054 7.98003 11.272 8.02901 11.23 8.07001L8.30999 10.92Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </Icon>
  );
};
