import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import {
  BankDetailsRequest,
  BankDetailsResponse,
  BffRoutes,
} from '@newday/plum-types';

const postBankDetails = ({
  applicationId,
  sortCode,
  accountNumber: bankAccount,
}: {
  applicationId: string;
  sortCode: string;
  accountNumber: string;
}): Promise<BankDetailsResponse> => {
  return axios
    .post<BankDetailsResponse, AxiosResponse, BankDetailsRequest>(
      `${BffRoutes.bankDetails.replace(':applicationId', applicationId)}`,
      {
        sortCode,
        bankAccount,
      }
    )
    .then((response) => response.data);
};

export const useBankDetails = () => {
  return useMutation(postBankDetails);
};
