import colors from './foundations/colors';
import fonts from './foundations/fonts';

export default {
  colors,
  radii: {
    brand: {
      field: '0.3125rem',
      cta: '0.375rem',
    },
  },
  fonts,
};
