import { Heading, Stack, useMediaQuery, useTheme } from '@newday/core';
import { getMockRepaymentInformation } from '@newday/plum-mocks';
import { convertISODateToLocale } from '@newday/util-formatters';
import React from 'react';
import { useApplicationId } from '../../app';
import { Breakdown } from '../../components';
import { formatCurrencyToTwoDecimalPlaces } from '../../utils';

import { useRepaymentInformation } from './queries';

type RepaymentBreakdownComponentProps = {
  isLoaded?: boolean;
  paymentAmount: number;
  paymentDate: string;
};

const RepaymentBreakdownComponent: React.FC<
  RepaymentBreakdownComponentProps
> = ({ isLoaded = true, paymentAmount, paymentDate }) => {
  const { breakpoints } = useTheme();
  const [isAboveMdViewport] = useMediaQuery(`(min-width: ${breakpoints.md})`);

  return (
    <Stack w="full" gap={1.5}>
      <Heading fontSize="md" fontWeight="bold">
        Get ready for your first repayment
      </Heading>
      <Breakdown
        items={[
          {
            content: isLoaded
              ? formatCurrencyToTwoDecimalPlaces(paymentAmount)
              : undefined,
            label: 'Payment amount',
          },
          {
            content: isLoaded
              ? convertISODateToLocale(paymentDate, 'en-GB', {
                  dateStyle: 'medium',
                })
              : undefined,
            label: 'Payment date',
          },
          {
            content: 'Direct Debit',
            label: 'Payment method',
          },
        ]}
        itemsPerRow={2}
        variant="light"
        layout={
          /* istanbul ignore next */ isAboveMdViewport ? 'singleRow' : undefined
        }
      />
    </Stack>
  );
};

export const RepaymentBreakdown: React.FC = () => {
  const { applicationId } = useApplicationId();
  const { data, isSuccess, isLoading } = useRepaymentInformation(applicationId);

  if (isSuccess && data.paymentStartDate && data.monthlyPayment) {
    const { paymentStartDate, monthlyPayment } = data;
    return (
      <RepaymentBreakdownComponent
        paymentAmount={monthlyPayment}
        paymentDate={paymentStartDate}
      />
    );
  }

  const {
    paymentStartDate: mockPaymentStartDate,
    monthlyPayment: mockMonthlyPayment,
  } = getMockRepaymentInformation();
  if (isLoading && mockPaymentStartDate && mockMonthlyPayment) {
    return (
      <RepaymentBreakdownComponent
        isLoaded={false}
        // These values below will not appear to users
        // we use then so skeleton knows how to calculate
        // the apropriate height
        paymentAmount={mockMonthlyPayment}
        paymentDate={mockPaymentStartDate}
      />
    );
  }

  return null;
};
