import { useRef, RefObject } from 'react';
import { Link, LinkProps, useStyleConfig } from '@chakra-ui/react';
import { useLink, AriaLinkOptions } from '@react-aria/link';
import { ButtonProps } from '../button';

export type NavigationButtonProps = Omit<LinkProps, 'variant' | 'isDisabled'> &
  Omit<ButtonProps, 'isDisabled'> & { to: string };

export const NavigationButton = (props: NavigationButtonProps) => {
  const ref = useRef() as RefObject<HTMLAnchorElement>;
  const { linkProps } = useLink(
    { ...(props as AriaLinkOptions), elementType: 'button' },
    ref
  );

  const styles = useStyleConfig('Button', props);

  return (
    <Link {...props} {...linkProps} ref={ref} sx={styles} __css={styles}>
      {props.children}
    </Link>
  );
};
