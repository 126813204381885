import { FrontEligibilityQuestions as EligibilityQuestions } from '@newday/plum-types';
import React, { createContext, useContext, useState } from 'react';

type EligibilityQuestionsContextData = {
  eligibilityQuestions?: Partial<EligibilityQuestions>;
  setEligibilityQuestions: (eligibilityQuestions: EligibilityQuestions) => void;
  hasExistingData?: boolean;
  setHasExistingData: (hasExistingData: boolean) => void;
};

const EligibilityQuestionsContext =
  createContext<EligibilityQuestionsContextData>(
    {} as EligibilityQuestionsContextData
  );

export const EligibilityQuestionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [eligibilityQuestions, setEligibilityQuestions] = useState<
    EligibilityQuestions | undefined
  >();
  const [hasExistingData, setHasExistingData] = useState(false);

  return (
    <EligibilityQuestionsContext.Provider
      value={{
        eligibilityQuestions,
        setEligibilityQuestions,
        hasExistingData,
        setHasExistingData,
      }}
    >
      {children}
    </EligibilityQuestionsContext.Provider>
  );
};

export const useEligibilityQuestions = () => {
  return useContext(EligibilityQuestionsContext);
};
