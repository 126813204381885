import React from 'react';
import { Text, Input, Flex } from '@newday/core';
import type { InputProps } from '@newday/core';

export interface SortCodeInputProps
  extends Omit<InputProps, 'value' | 'defaultValue' | 'placeholder'> {
  value?: string;
  defaultValue?: string;
  placeholder?: string;
}

const partialSortCodePartPattern = /^[0-9]{1,2}/;
const partialSortCodePattern =
  /^([0-9]{1,2})?[-]([0-9]{1,2})?[-]([0-9]{1,2})?$/;
const partialSortCodeRegExp = new RegExp(partialSortCodePattern);
export const sortCodePattern = /^[0-9]{2}[-][0-9]{2}[-][0-9]{2}$/;

const isValidSortCodeValue = (sortCodeValue: string) =>
  partialSortCodeRegExp.test(sortCodeValue);

const getInvalidSortCodeErrorMessage = (propName: string) =>
  `Invalid ${propName} passed to SortCodeInput - the ${propName} must contain two "-"s and numbers`;

const sanitizeValuePart = (value: string): string =>
  (value.match(partialSortCodePartPattern) || [''])[0];

const SortCodeInputPart = (props) => (
  <Input
    {...props}
    type="text"
    maxLength={2}
    w={{ base: 14, xxs: 16 }}
    autoComplete="off"
  />
);

const SortCodeInput: React.FC<SortCodeInputProps> = ({
  defaultValue,
  value: valueProp,
  placeholder,
  onChange,
  onBlur,
  ...props
}) => {
  const controlled = !defaultValue && valueProp && onChange;

  const [valueState, setValue] = React.useState<string>(
    defaultValue && isValidSortCodeValue(defaultValue) ? defaultValue : '--'
  );

  if (defaultValue && valueProp) {
    throw new Error(
      'An uncontrolled SortCodeInput should not receive a value prop'
    );
  }

  if (controlled && !isValidSortCodeValue(valueProp)) {
    const message = getInvalidSortCodeErrorMessage('value');
    throw new Error(message);
  }

  if (defaultValue && !isValidSortCodeValue(defaultValue)) {
    const message = getInvalidSortCodeErrorMessage('defaultValue');
    throw new Error(message);
  }

  if (placeholder && !isValidSortCodeValue(placeholder)) {
    const message = getInvalidSortCodeErrorMessage('placeholder');
    throw new Error(message);
  }

  const value = controlled ? valueProp : valueState;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, nextSibling } = event.target;
    const index = Number(name.substring(name.length - 1));
    const valueParts = value?.split('-');
    const nextValue = sanitizeValuePart(event.target.value);
    valueParts[index] = nextValue;
    const nextValues = valueParts?.join('-');
    if (!controlled) {
      setValue(nextValues);
    }
    if (onChange) {
      const changeEvent = {
        ...event,
        target: {
          ...event.target,
          value: nextValues,
        },
      };
      onChange(changeEvent);
    }
    if (nextValue.length === 2 && nextSibling?.nextSibling) {
      (nextSibling?.nextSibling as HTMLInputElement).focus();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      const { relatedTarget } = event;
      if (!relatedTarget) {
        onBlur(event);
      } else {
        const relatedInputElement =
          'name' in relatedTarget && (relatedTarget as HTMLInputElement);

        if (
          !relatedInputElement ||
          !relatedInputElement.name ||
          !relatedInputElement.name.includes('sort-code-')
        ) {
          onBlur(event);
        }
      }
    }
  };

  const valueParts = value?.split('-');
  const placeholderParts = React.useMemo(
    () => (placeholder ? placeholder.split('-') : undefined),
    [placeholder]
  );

  return (
    <fieldset className="plum-mouseflow-hidden">
      <Text as="legend" mb={1} color="brand.primary" fontWeight="semibold">
        Sort code
      </Text>
      <Flex alignItems="center">
        <SortCodeInputPart
          {...props}
          aria-label="First two digits of sort code"
          id="sort-code-0"
          name="sort-code-0"
          value={valueParts[0]}
          placeholder={placeholderParts ? placeholderParts[0] : undefined}
          onChange={handleChange}
          onBlur={handleBlur}
          mr={{ xxs: 1, xs: 3 }}
        />
        <Text>&#8212;</Text>
        <SortCodeInputPart
          {...props}
          aria-label="Second two digits of sort code"
          id="sort-code-1"
          name="sort-code-1"
          value={valueParts[1]}
          placeholder={placeholderParts ? placeholderParts[1] : undefined}
          onChange={handleChange}
          onBlur={handleBlur}
          mx={{ xxs: 1, xs: 3 }}
        />
        <Text>&#8212;</Text>
        <SortCodeInputPart
          {...props}
          aria-label="Third two digits of sort code"
          id="sort-code-2"
          name="sort-code-2"
          value={valueParts[2]}
          placeholder={placeholderParts ? placeholderParts[2] : undefined}
          onChange={handleChange}
          onBlur={handleBlur}
          ml={{ xxs: 1, xs: 3 }}
        />
      </Flex>
    </fieldset>
  );
};

export { SortCodeInput };
