import { Text, Button } from '@newday/core';
import { Hero, ResponsivePageLayout } from '../../components';
import { ApplicationReference } from '../../features';
import { useFeatureFlags } from '../../app';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';
import { useNavigate } from 'react-router-dom';
import { FeRoutes } from '@newday/plum-types';

const linkText = 'Try again';

export const DocUploadUnsuccessPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(FeRoutes.documentUpload);
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_action: FormActions.CLICK_CTA,
      form_name: FormNames.DOC_UPLOAD,
      link_text: linkText,
    });
  };
  const { MISTHO } = useFeatureFlags();

  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <Hero title="Hmm, that didn’t work" fullWidth>
        {MISTHO ? (
          <>
            <Text fontWeight="bold" color="black">
              Something went wrong when you tried to submit your proof of
              income.
            </Text>
            <Text pt={2} color="black" pb={2}>
              Please use the button below to give it another go or to try an
              alternative method to upload your proof of income. In case you
              need to come back to this later, we&apos;ve emailed you a link to
              your application.
            </Text>
            <Button onClick={handleClick} maxW={{ base: 'full', md: 32 }}>
              {linkText}
            </Button>
          </>
        ) : (
          <>
            <Text fontWeight="bold" color="black">
              Something went wrong when you tried to upload your document(s).
            </Text>
            <Text pt={2} color="black">
              We&apos;ve emailed you a link to come back and complete your
              application.
            </Text>
          </>
        )}
      </Hero>
    </ResponsivePageLayout>
  );
};
