import axios from 'axios';
import { useQuery } from 'react-query';

import { BffRoutes, RepaymentInformation } from '@newday/plum-types';

const fetchRepaymentInformation = ({
  queryKey,
}): Promise<RepaymentInformation> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, applicationId] = queryKey;
  return axios
    .get(
      BffRoutes.repaymentInformation.replace(':applicationId', applicationId)
    )
    .then((response) => response.data);
};

export const useRepaymentInformation = (applicationId: string) => {
  return useQuery<RepaymentInformation, unknown, RepaymentInformation>(
    ['plum', 'get-repayment-information', applicationId],
    fetchRepaymentInformation
  );
};
