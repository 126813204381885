import axios from 'axios';
import { useQuery } from 'react-query';

import { ApplicationReference, BffRoutes } from '@newday/plum-types';

const fetchApplicationReference = ({
  queryKey,
}): Promise<ApplicationReference> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, applicationId] = queryKey;
  const applicationReference = BffRoutes.applicationReference.replace(
    ':applicationId',
    applicationId
  );
  return axios.get(applicationReference).then((response) => response.data);
};

export const useApplicationReference = (applicationId: string) => {
  return useQuery(
    ['plum', 'application-reference', applicationId],
    fetchApplicationReference
  );
};
