import React from 'react';
import { useApplicationId } from '../../app';
import { ApplicationReference as ApplicationReferenceComponent } from '../../components';
import { useApplicationReference } from '../../shared/queries';

import { getMockApplicationReference } from '@newday/plum-mocks';

export const ApplicationReference: React.FC = () => {
  const { applicationId } = useApplicationId();
  const { data, isSuccess, isLoading } = useApplicationReference(applicationId);
  const { reference: mockReference } = getMockApplicationReference();

  if (isSuccess && data.reference) {
    return <ApplicationReferenceComponent reference={data.reference} />;
  }

  if (isLoading) {
    return (
      <ApplicationReferenceComponent
        reference={mockReference}
        isLoaded={false}
      />
    );
  }

  return null;
};
