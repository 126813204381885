import { StyleConfig } from '@chakra-ui/theme-tools';

export const Button: StyleConfig = {
  variants: {
    primary: {
      color: 'white',
    },
    secondary: {
      color: 'white',
    },
  },
};
