import React from 'react';
import { HStack, StackProps, Text } from '@newday/core';
import { LockIcon } from '@newday/icons';
import { useBrand } from '../../app';

export type CreditScoreUnaffectedProps = Omit<StackProps, 'css'>;

export const CreditScoreUnaffected: React.FC<CreditScoreUnaffectedProps> = (
  props: CreditScoreUnaffectedProps
) => {
  const { brand } = useBrand();
  /* istanbul ignore next */
  const bgColor = brand === 'aqua' ? 'brand.quaternary' : 'white';

  return (
    <HStack p={2} spacing={2} bg={bgColor} {...props}>
      <LockIcon />
      <Text fontSize="sm" fontWeight="semibold">
        Checking your eligibility won’t impact your credit score
      </Text>
    </HStack>
  );
};
