import React from 'react';
import { Box, Text, Heading, VStack, Link } from '@newday/core';
import { MailAlertIcon } from '@newday/icons';
import { FeRoutes } from '@newday/plum-types';
import {
  ContentWrapper,
  HeaderStepper,
  ResponsivePageLayout,
} from '../../components';
import { ApplicationReference, RepaymentBreakdown } from '../../features';
import {
  gtmTrackEvent,
  Events,
  FormActions,
  FormNames,
} from '../../utils/gtm-track-event';
import { useBrand } from '../../app';

const SuccessfulApplicationPage: React.FC = () => {
  const { uppercaseBrandName } = useBrand();
  const handleFaqLinkClick = () => {
    gtmTrackEvent({
      event: Events.FORM_LINK,
      form_name: FormNames.APPLICATION_COMPLETE,
      form_action: FormActions.CLICK_LINK,
      link_text: 'frequently asked questions',
      'link url': `${window.origin}${FeRoutes.faqs}`,
    });
    window.open(FeRoutes.faqs, '_blank');
  };

  return (
    <ResponsivePageLayout headerStepper={<HeaderStepper activeStep={5} />}>
      <ApplicationReference />
      <ContentWrapper py={4} maxContentWidth="5xl">
        <MailAlertIcon size="lg" />
        <Heading fontSize="2xl" fontWeight="bold" mt={2}>
          All done! Congratulations, your loan is on its way
        </Heading>
        <Text mt={2} fontWeight="semibold">
          The money could be in your account within a couple of hours, although
          some banks may take around 48 hours to process the payment.
        </Text>
        <Text fontSize="md" mb={8} mt={2}>
          You may also want to look at our{' '}
          <Link
            onClick={handleFaqLinkClick}
            color="black"
            isExternal
            tabIndex={0}
          >
            frequently asked questions
          </Link>
          .
        </Text>

        <RepaymentBreakdown />

        <Box
          mt={8}
          p={4}
          borderColor="brand.primary"
          borderWidth="1px"
          borderRadius="md"
        >
          <Heading fontSize="md" fontWeight="semibold" mb={2}>
            Customer care for your loan{' '}
          </Heading>
          <VStack spacing={4}>
            <Text fontSize="sm" fontWeight="normal">
              {uppercaseBrandName} personal loans are provided by NewDay Ltd in
              partnership with Antelope Loans Funding Limited. Once NewDay has
              sent you the loan amount, it will transfer ownership of your loan
              account to Antelope Loans Funding Limited, who has arranged for
              the management of the loan and will be your point of contact for
              any questions. You’ll get an email to let you know when the
              transfer is complete (usually within five working days). Antelope
              Loans Funding Limited will also collect your Direct Debit
              payments.
            </Text>
          </VStack>
        </Box>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
export { SuccessfulApplicationPage };
