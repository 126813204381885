import { ComponentStyleConfig } from '@chakra-ui/react';
import { whiten } from '@chakra-ui/theme-tools';

const Themebox: ComponentStyleConfig = {
  variants: {
    'solid-100': {
      backgroundColor: 'white',
    },
    'solid-200': {
      backgroundColor: 'brand.greyScale.200',
    },
    'solid-400': {
      backgroundColor: 'brand.greyScale.400',
    },
    'solid-600': {
      backgroundColor: 'brand.greyScale.600',
    },
    'solid-800': {
      color: 'white',
      backgroundColor: 'brand.greyScale.800',
    },
    'solid-900': {
      color: 'white',
      backgroundColor: 'black',
    },
    'brand-quaternary-600': ({ theme }) => {
      const wrapperBgColor = whiten('brand.quaternary', 60)(theme);
      return {
        backgroundColor: `${wrapperBgColor}`,
      };
    },
    'brand-quaternary-800': ({ theme }) => {
      const wrapperBgColor = whiten('brand.quaternary', 80)(theme);
      return {
        backgroundColor: `${wrapperBgColor}`,
      };
    },
    'solid-brand-light': ({ theme }) => {
      const { colors } = theme;
      return {
        backgroundColor: `${colors.brand.primary}66`,
      };
    },
    'solid-brand-dark': {
      color: 'white',
      backgroundColor: 'brand.primary',
    },
  },
};

export default Themebox;
