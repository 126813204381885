import type { UserDetails } from '@newday/plum-types';

export const getMockUserDetails = (
  overrides?: Partial<UserDetails>
): UserDetails => ({
  title: 'mr', // should be lower case as the API returns it this way
  fullName: 'Jeanette Smith',
  dateOfBirth: '15/10/1990',
  emailAddress: 'jeanette.smith@email.com',
  mobileNumber: '07123456789',
  postCode: 'SS9 5FQ',
  addresses: [
    {
      id: 'GBR|32973bb0-aa7c-4621-a2e1-d40724a772d1|7.7308OGBREAnmBwAAAAABAwEAAAABlRvkkgAgAAAAAAAAMjIAAP..ZAAAAAD.....AAAAAAAAAAAAAAAAAAAAdzMgOWx5AAAAAAA-$6',
      address: '22 Rosemont Road, LONDON W3 9LY',
    },
    {
      id: 'GBR|32973bb0-aa7c-4621-a2e1-d40724a772d1|7.730sOGBREAnmBwAAAAABAwEAAAABlRvkkgAgAAAAAAAAMjQAAP..ZAAAAAD.....AAAAAAAAAAAAAAAAAAAAdzMgOWx5AAAAAAA-$6',
      address: '24 Rosemont Road, LONDON W3 9LY',
    },
    {
      id: 'GBR|32973bb0-aa7c-4621-a2e1-d40724a772d1|7.730cOGBREAnmBwAAAAABAwEAAAABlRvkkgAgAAAAAAAAMjYAAP..ZAAAAAD.....AAAAAAAAAAAAAAAAAAAAdzMgOWx5AAAAAAA-$6',
      address: '26 Rosemont Road, LONDON W3 9LY',
    },
    {
      id: 'GBR|32973bb0-aa7c-4621-a2e1-d40724a772d1|7.7304OGBREAnmBwAAAAABAwEAAAABlRvkkgAgAAAAAAAAMjgAAP..ZAAAAAD.....AAAAAAAAAAAAAAAAAAAAdzMgOWx5AAAAAAA-$6',
      address: '28 Rosemont Road, LONDON W3 9LY',
    },
  ],
  ...overrides,
});
