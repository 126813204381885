import colors from './foundations/colors';
import { components } from './components';

export default {
  fonts: {
    heading: 'Gibson, sans-serif',
    body: 'Gibson, sans-serif',
  },
  colors,
  components,
  radii: {
    brand: {
      field: '0.3125rem',
      cta: '0.375rem',
    },
  },
};
