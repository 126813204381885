import { Box } from '@newday/core';
import { HttpErrorMessages } from '@newday/plum-types';
import React from 'react';
import { ContentWrapper, ResponsivePageLayout } from '../../components';
import { ApplicationStatusDialog } from '../../components/application-status-dialog';

export const ErrorPageComplexRefer: React.FC = () => (
  <ResponsivePageLayout>
    <Box variant="brand-quaternary-800">
      <ContentWrapper maxContentWidth="5xl" py={14}>
        <ApplicationStatusDialog
          title={HttpErrorMessages[500].error}
          subtitle={HttpErrorMessages[500].errorMessage}
        />
      </ContentWrapper>
    </Box>
    <Box variant="brand-quaternary-600" height={40} />
  </ResponsivePageLayout>
);
