import React from 'react';
import {
  OrderedList,
  ListItem,
  List,
  Heading,
  Stack,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th as ChakraTh,
  Tbody,
  Td as ChakraTd,
  Link,
} from '@newday/core';

import { ContentWrapper, ResponsivePageLayout } from '../../components';
import { useBrand } from '../../app';

const MainLI = ({ children }) => (
  <ListItem
    css={{
      fontSize: 'lg',
      fontWeight: 700,
      '&::marker': {
        content: 'counters(list-item, ".") ".  "',
      },
    }}
  >
    {children}
  </ListItem>
);
const InnerLI = ({ children }) => (
  <ListItem
    css={{
      fontSize: 'md',
      fontWeight: 600,
      '&::marker': {
        content: 'counters(list-item, ".") "   "',
      },
    }}
  >
    {children}
  </ListItem>
);

const AlphaLI = ({ children }) => (
  <ListItem
    css={{
      '&::marker': {
        content: '"(" counter(list-item, lower-alpha) ")  "',
      },
    }}
  >
    {children}
  </ListItem>
);

const RomanLI = ({ children }) => (
  <ListItem
    css={{
      '&::marker': {
        content: '"(" counter(list-item, lower-roman) ")  "',
      },
    }}
  >
    {children}
  </ListItem>
);

const Td = ({ children }) => (
  <ChakraTd p={2} border="1px" verticalAlign="top">
    {children}
  </ChakraTd>
);

const Th = ({ children }) => (
  <ChakraTh minW="200px" p={2} border="1px" color="black">
    {children}
  </ChakraTh>
);

export const PrivacyPageContent: React.FC = () => {
  const { brandWithArticle, uppercaseBrandName } = useBrand();

  return (
    <Stack spacing={2}>
      <Heading
        as="h1"
        fontSize={{ base: '3xl', md: '4xl' }}
        fontWeight="bold"
        mb={5}
      >
        Privacy Notice
      </Heading>
      <OrderedList spacing={3} mx={0} px={6}>
        <MainLI>
          <Heading fontSize="xl" color="inherit" as="h2" id="introduction">
            Introduction
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              In this Privacy Notice (referred to throughout as notice) we, our
              or us refers to NewDay Ltd, its parent company NewDay Cards Ltd
              and any other companies in the group to which they belong
              (referred to throughout this notice as the NewDay Group). You or
              your refers to anyone whose personal data we process.
            </InnerLI>
            <InnerLI>
              This notice explains how we will use and look after your personal
              information when you apply for {brandWithArticle} loan. It also
              explains how we will share your personal data with Antelope Loans
              Funding Limited (referred to in the rest of this notice as{' '}
              <strong>Antelope Loans Funding</strong>). If you are successful in
              applying for {brandWithArticle} loan, after we have issued the
              loan money to you, we will transfer ownership of the loan to
              Antelope Loans Funding. Antelope Loans (a trading style of Link
              Financial Outsourcing Limited) (referred to in the rest of this
              notice as <strong>Antelope Loans</strong>) will then be
              responsible for administering your loan agreement on behalf of
              Antelope Loans Funding. It is therefore important that you also
              review Antelope Loan&apos;s privacy policy, which is available
              here:{' '}
              <Link
                href="https://www.antelopeloans.com/privacy-statement/"
                rel="noopener noreferrer"
                isExternal
              >
                antelopeloans.com/privacy-statement
              </Link>
              .
            </InnerLI>
            <InnerLI>
              We may change this notice from time to time and we will put any
              changes on our website{' '}
              <Link
                href="https://www.newday.co.uk/"
                rel="noopener noreferrer"
                isExternal
              >
                newday.co.uk{' '}
              </Link>{' '}
              and, where appropriate, changes will be notified to you by email
              or by post. Please check our website frequently to see any updates
              or changes to our notice.
            </InnerLI>
            <InnerLI>
              This notice covers the following:
              <List mt={2} spacing={2}>
                <ListItem>
                  <Link href="#introduction">1. Introduction</Link>
                </ListItem>
                <ListItem>
                  <Link href="#notice">
                    2. Whom should I contact if I have questions about this
                    notice?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#what-personal-data">
                    3. What are personal data and special categories of personal
                    data?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#where-personal-data">
                    4. Where do we obtain your personal data from?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#why-personal-data">
                    5. What personal data do we use and why?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#share-personal-data">
                    6. What personal data do we share with third parties and
                    why?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#cra-personal-data">
                    7. What personal data do we share with CRAs and why?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#legal-personal-data">
                    8. What is the legal basis that permits us to use your
                    personal data?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#fraud-personal-data">
                    9. What are the consequences of processing your personal
                    data for fraud prevention or anti-money laundering purposes?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#no-personal-data">
                    10. What happens if you do not provide information that we
                    request?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#retain-personal-data">
                    11. How long do we retain your personal data for?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#secure-personal-data">
                    12. What measures do we have in place to safeguard your
                    personal data?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#transfer-personal-data">
                    13. When do we transfer your personal data overseas?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#cookies">
                    14. How do I find out about cookies?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#credit-score">
                    15. Why do we use credit scoring and automated decision
                    making as part of your application?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#profiling-personal-data">
                    16. How do we use your personal data for profiling?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#rights-personal-data">
                    17. What rights do you have in relation to your personal
                    data?
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#marketing">18. Your marketing preferences</Link>
                </ListItem>
                <ListItem>
                  <Link href="#phone-calls">
                    19. Why do we need to record phone calls with you?
                  </Link>
                </ListItem>
              </List>
            </InnerLI>
            <InnerLI>
              The table at the end of this notice provides an overview of the
              personal data that we collect, the purposes for which we use that
              personal data, the legal basis which permits us to use your
              personal data and the rights that you have in relation to your
              personal data.
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading fontSize="xl" color="inherit" as="h2" id="notice" mt={4}>
            Who should I contact if I have questions about this notice?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              Should you wish to contact us about any aspect of this notice you
              can contact our data protection officer using the following email
              address{' '}
              <Link href="mailto:dpofficer@newday.co.uk">
                dpofficer@newday.co.uk
              </Link>
              . Details of Antelope Loan&apos;s data protection officer can be
              found in the Antelope Loan&apos;s privacy notice or on their
              website. If you are unable to find these details please contact
              our data protection officer using the email address above.
            </InnerLI>
            <InnerLI>
              NewDay Ltd and NewDay Cards Ltd are registered with the UK
              Information Commissioner&apos;s Office (referred to throughout
              this notice as ICO). NewDay Ltd&apos;s registration number is
              Z272680X and NewDay Cards Ltd&apos;s registration number is
              Z5599433.
            </InnerLI>
            <InnerLI>
              If you are unhappy with the way in which we are processing your
              personal data you have the right to raise a complaint directly
              with the ICO. Their contact details are Information
              Commissioner&apos;s Office, Wycliffe House, Water Lane, Wilmslow,
              Cheshire, SK9 5AF. You can also call them on{' '}
              <Link href="tel:+443031231113">0303 123 1113</Link> or email them
              on{' '}
              <Link href="mailto:icocasework@ico.org.uk">
                icocasework@ico.org.uk
              </Link>
              .
            </InnerLI>
            <InnerLI>
              For more information about credit reference agencies (referred to
              throughout this notice as CRAs) and how they use your personal
              data please read the{' '}
              <Link
                href="http://www.experian.co.uk/crain/index.html"
                rel="noopener noreferrer"
                isExternal
              >
                Credit Reference Agency Information Notice (CRAIN){' '}
              </Link>
              . CRAIN covers the activities of Experian, Equifax and TransUnion.
              We also use credit reference services provided by Crediva. You can
              find a copy of{' '}
              <Link
                href="https://www.crediva.co.uk/crediva-processing-notice"
                rel="noopener noreferrer"
                isExternal
              >
                Crediva&apos;s privacy notice here{''}
              </Link>
              . For a hard copy of this notice please call Customer Services
              (see section 18.2).
            </InnerLI>
            <InnerLI>
              For more information about fraud prevention agencies (referred to
              throughout this notice as FPAs) and how they use your personal
              data please contact us by calling our Compliance Team on 0371 522
              5146.
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="what-personal-data"
            mt={4}
          >
            What are personal data and special categories of personal data?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              Personal data is any information that tells us something about
              you. This could include information such as name, contact details,
              date of birth, medical information and bank account details.
            </InnerLI>
            <InnerLI>
              Special category personal data is any information relating to your
              ethnic or racial origin, political opinions, religious or
              philosophical beliefs, trade union membership, biometric data used
              to uniquely identify you, health data or your sexual life. This
              personal data is more sensitive and we need to have further
              justifications for collecting, storing and using this type of
              personal data. There are also additional restrictions on the
              circumstances in which we are permitted to collect and use
              criminal conviction data.
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="where-personal-data"
            mt={4}
          >
            Where do we obtain your personal data from?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              We get personal data about you from a number of sources as set out
              below:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  Personal data you provide when you apply to us for one of our
                  products;
                </InnerLI>
                <InnerLI>
                  Personal data Antelope Loans Ltd gives to us (a) about how you
                  repay your loan; and (b) about changes in your circumstances,
                  for example if you become bankrupt or if you are no longer
                  living at the contact address we hold for you;
                </InnerLI>
                <InnerLI>
                  Personal data you give to us in emails, letters, via online
                  servicing (including via chat and chatbots) and during phone
                  calls (including any phone numbers that you use to contact us
                  on);
                </InnerLI>
                <InnerLI>
                  Personal data we obtain about the IP address, operating
                  system, devices and browser that you use, including the
                  location of any devices used by you;
                </InnerLI>
                <InnerLI>
                  Personal data you give when you participate in surveys,
                  promotions or competitions;
                </InnerLI>
                <InnerLI>
                  Personal data we receive when making a decision about your
                  application or account, including personal data we receive
                  from enquiries and searches made at CRAs and FPAs, or from
                  publicly available sources, for example the electoral roll;
                </InnerLI>
                <InnerLI>
                  Personal data we have about any account you have with us
                  including details of transactions and payments; and
                </InnerLI>
                <InnerLI>
                  Personal data we collect using analytics tools to track
                  website page content and click/touch, movement, scroll, and
                  keystroke activity.
                </InnerLI>
              </OrderedList>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="why-personal-data"
            mt={4}
          >
            What personal data do we use and why?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              We are a controller for the personal data that we obtain when
              assessing your application and issuing your loan.
            </InnerLI>
            <InnerLI>
              We hold and use the following personal data about you:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  Personal data such as your name, address, address history,
                  phone numbers, email address, date of birth, passport
                  information, driving licence information employment details,
                  identifiers assigned to your computer or other internet
                  connected device including your Internet Protocol (IP)
                  address, device data (which includes information about the
                  type of device you use and the specific device ID that is
                  linked to the devices that you use to access our services) and
                  financial information such as your bank account details,
                  income and expenditure and other information necessary to
                  assess your financial status. We use this information in order
                  to verify your identity, assess whether you are eligible for a
                  loan, issue your loan (if you are approved), and for the
                  purposes of preventing fraud and money laundering;
                </InnerLI>
                <InnerLI>
                  Personal data relating to your demographic (which can include
                  information such as age, gender, place of residence, and can
                  include socio-economic factors such as occupation, family
                  status, or income), lifestyle and information about how you
                  repay your loan. We use this information to improve our
                  products and support the development of our business;
                </InnerLI>
                <InnerLI>
                  Personal data relating to your financial associates. A
                  &quot;financial associate&quot; is someone with whom you have
                  a personal relationship that creates a joint financial unit in
                  a similar way to a married couple. You will have been living
                  at the same address at the time. It is not intended to include
                  temporary arrangements such as students or rented flat sharers
                  or business relationships. CRAs may link together the records
                  of people that are part of a financial unit. They may do this
                  when people are known to be linked, such as being married or
                  have jointly applied for credit or have joint accounts. They
                  may also link people together if they, themselves, state that
                  they are financially linked. For more information about how
                  CRAs use your personal data in this manner please read the{' '}
                  <Link
                    href="http://www.experian.co.uk/crain/index.html"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    Credit Reference Agency Information Notice{' '}
                  </Link>
                  ;
                </InnerLI>
                <InnerLI>
                  Personal data that we obtain when you take out any of our
                  other products or use any of our services, including details
                  of other services you have through us, such as Aqua Coach or
                  our eligibility checking services you have subscribed to, or
                  any card insurance provided to you by any other third party;
                </InnerLI>
                <InnerLI>
                  Any other personal data we need to assess your application,
                  issue your loan, make decisions about you or fulfil our legal
                  or regulatory obligations.
                </InnerLI>
              </OrderedList>
            </InnerLI>
            <InnerLI>
              In addition to the purposes stated above, we use the personal data
              we hold for the following purposes:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  To make credit decisions about you, assess lending risks and
                  to validate the details that you have provided to us;
                </InnerLI>
                <InnerLI>
                  To issue your loan and manage any application(s), any
                  agreement(s) or handle any correspondence you may have with us
                  and to conduct financial reviews (for example assessing your
                  eligibility for further loans);
                </InnerLI>
                <InnerLI>
                  To enable us to perform other administrative and operational
                  purposes including the testing of systems, auditing and other
                  compliance related activities to ensure that we are complying
                  with our legal and regulatory obligations;
                </InnerLI>
                <InnerLI>
                  To monitor and analyse our business, including customer and
                  declines modelling and statistical trend and transactional
                  analysis, to identify, develop or improve products, and to
                  carry out market research;
                </InnerLI>
                <InnerLI>
                  To provide you with cards, products and services and tell you
                  about changes to these products and services;
                </InnerLI>
                <InnerLI>
                  To send you marketing in accordance with the provisions of{' '}
                  <Link href="#phone-calls">Section 19</Link>;
                </InnerLI>
                <InnerLI>
                  To trace your whereabouts in the event we are unable to
                  contact you in relation to the products or services we provide
                  to you;
                </InnerLI>
                <InnerLI>To recover any debt you owe us;</InnerLI>
                <InnerLI>
                  To provide information on an anonymised basis to independent
                  external bodies such as government departments and agencies,
                  universities and similar to carry out research;
                </InnerLI>
                <InnerLI>
                  To comply with our legal and regulatory obligations and to
                  identify, prevent, detect or tackle fraud, money laundering,
                  terrorism and other crimes; and/or
                </InnerLI>
                <InnerLI>
                  To create anonymised data sets that can be used for big data
                  analytics by ourselves and third parties. For example, we
                  might create an anonymised dataset that shows information
                  about loans and repayment trends for different types of
                  customers.
                </InnerLI>
              </OrderedList>
            </InnerLI>
            <InnerLI>
              We will send you information directly in the form of service
              messages related to your loan. We want to make sure that we
              provide excellent customer service and we use various means of
              communication to do this including phone, post, email, push
              notifications and SMS.
            </InnerLI>
            <InnerLI>
              When we are managing your account it is not our intention to
              routinely process special categories of personal data. However, in
              certain circumstances, (for example if you become incapacitated)
              we may be provided with special categories of personal data about
              your health or medical information which we will hold and process
              to deal with your application and loan agreement (as may be
              applicable) .
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="share-personal-data"
            mt={4}
          >
            What personal data do we share with third parties and why?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              We will keep your personal data confidential and only share it
              with other third parties and organisations for the purposes
              explained below:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  With Antelope Loans Funding, which is the company that we
                  transfer ownership of your {uppercaseBrandName} loan to after
                  it has been issued. More details about this transfer will be
                  provided to you during the loan application process. We will
                  also send you a letter or email to confirm that the transfer
                  of ownership has taken place. We transfer details of your
                  loan, bank account details and contact details to Antelope
                  Loans when we transfer your loan to them. We will also provide
                  Antelope Loans with details of your loan application and our
                  assessment of your loan application if they need this to
                  resolve a complaint or to defend a legal claim. You can find
                  full details of how Antelope Loans uses your personal data in
                  Antelope Loan&apos;s privacy policy, which is available
                  [here].
                </InnerLI>
                <InnerLI>
                  With any third parties who carry out services on our behalf to
                  help us operate our business, to provide services, to collect
                  payment or to recover debts. All these third parties have a
                  contract with us and have agreed to keep your personal data
                  confidential and secure and only to use it for the purposes
                  that we allow.
                </InnerLI>
                <InnerLI>
                  With any person working within the NewDay Group to enable us
                  to perform our obligations in relation to the provision of the
                  services or products to you;
                </InnerLI>
                <InnerLI>
                  With any payment system provider we may use to enable us to
                  process payments in relation to the services and/or products
                  provided to you;
                </InnerLI>
                <InnerLI>
                  With any firm that provides analytical, market research or
                  similar services to us;
                </InnerLI>
                <InnerLI>
                  With your close relative, carer or helper, where you are
                  unable to handle your own affairs because of mental capacity,
                  ill health or other similar issues and we have been provided
                  with authority to disclose your personal data;
                </InnerLI>
                <InnerLI>
                  With any third party you have authorised to talk to us about
                  your account (which could include Debt Management Companies);
                </InnerLI>
                <InnerLI>
                  With any regulators, including the Financial Conduct Authority
                  (referred to throughout this notice as FCA), the ICO, the
                  Financial Ombudsman Service and other authorities including
                  law enforcement agencies and tax authorities, (including those
                  overseas), where we are required to disclose any personal data
                  in order to comply with our legal or regulatory obligations;
                </InnerLI>
                <InnerLI>
                  With any third parties or authorities including any CRAs
                  and/or FPAs for the purposes of undertaking checks for
                  preventing or detecting fraud and money laundering, or crime
                  or to verify your identity or to rectify any inaccurate
                  personal data. When personal data is shared with a CRA and/or
                  FPA, the CRA and/or FPA may share your personal data with
                  other third parties (including law enforcement agencies) for
                  the purposes set out in the{' '}
                  <Link
                    href="http://www.experian.co.uk/crain/index.html"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    Credit Reference Agency Information Notice{' '}
                  </Link>{' '}
                  and as described in{' '}
                  <Link
                    href="https://www.crediva.co.uk/crediva-processing-notice"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    Crediva&apos;s privacy notice{' '}
                  </Link>
                  . We and FPAs may also enable law enforcement agencies to
                  access and use your personal data to detect, investigate and
                  prevent crime;
                </InnerLI>
                <InnerLI>
                  With social media sites and digital advertising networks (for
                  example Google) for online targeted marketing as well as
                  broadcast channels (for example TV) for targeted marketing;{' '}
                  <strong>
                    Please see{' '}
                    <Link href="#rights-personal-data">Section 17</Link> for
                    more information about how we use your personal data for
                    profiling, <Link href="#marketing">Section 18</Link> about
                    the right to object to the processing of your personal data
                    and <Link href="#phone-calls">Section 19</Link> about where
                    we rely on your consent to process your personal data;
                  </strong>
                </InnerLI>
                <InnerLI>
                  With any potential buyer, transferee, or merger partner and
                  their advisers in relation to any business transfer or
                  re-organisation if we decide to sell, transfer or merge part
                  or all of our business or assets, or any associated rights or
                  interests or to acquire another business. If we disclose your
                  personal data for this purpose, we will ensure that your
                  personal data is kept confidential and only used in relation
                  to the possible transaction(s). If the transaction(s) go
                  ahead, the purchaser, transferee or merger partner may use
                  your personal data in the same way as set out in this notice
                  or provide you with a new privacy notice explaining how your
                  personal data will be used by them; and
                </InnerLI>
                <InnerLI>
                  With any debt purchaser in the event that we sell your debt.
                  If we disclose your personal data for this purpose, we will
                  enter into a contract with the third party debt purchaser
                  requiring them to keep your personal data confidential and
                  process it in accordance with the terms of this notice.
                  Following the sale of your debt, the debt purchaser will
                  become a controller of your personal data and you can request
                  information from them detailing how your personal data will
                  subsequently be used. On the sale of your debt you will be
                  provided with the debt purchaser&apos;s contact details as
                  part of our other regulatory obligations.
                </InnerLI>
              </OrderedList>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="cra-personal-data"
            mt={4}
          >
            What personal data do we share with CRAs and why?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              In order to process your quote or application, we will perform
              credit and identity checks on you with one or more CRAs. Where you
              take financial services from us, we may also make periodic
              searches at CRAs to manage your account with us. Where you have
              been declined for a credit product and have decided to appeal the
              decision, we will perform another search with certain CRAs. A
              “soft search” allows us to make a check on your credit file and
              will leave a footprint so that you can see that we made the check,
              but the search will not show up to other lenders and will not
              affect your credit rating. We use soft searches when we carry out
              eligibility checks, in order to provide quotes and where you have
              appealed a decline decision. If you make an application for one of
              our products, we perform a “hard search” with certain CRAs. These
              searches are visible to other lenders and may affect your credit
              rating.
            </InnerLI>
            <InnerLI>
              To do this, we will supply your personal data to CRAs and they
              will give us information about you. This will include information
              from your credit application and about your financial situation
              and financial history. CRAs will supply to us both public
              (including the electoral register) and shared credit, financial
              situation and financial history information and fraud prevention
              information.
            </InnerLI>
            <InnerLI>
              We will use this personal data to:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  Assess your creditworthiness and whether you can afford to
                  take the product;
                </InnerLI>
                <InnerLI>
                  Verify the accuracy of the data you have provided to us;
                </InnerLI>
                <InnerLI>
                  Prevent criminal activity, fraud and money laundering;
                </InnerLI>
                <InnerLI>Manage your account(s);</InnerLI>
                <InnerLI>Trace and recover debts; and</InnerLI>
                <InnerLI>
                  Ensure any offers provided to you are appropriate to your
                  circumstances.
                </InnerLI>
              </OrderedList>
            </InnerLI>
            <InnerLI>
              We will continue to exchange personal data about you with CRAs
              while you have a relationship with us. We will also inform some
              CRAs about your settled accounts. If you borrow and do not repay
              in full and on time, those CRAs will record the outstanding debt.
              This information may be supplied to other organisations by CRAs.
            </InnerLI>
            <InnerLI>
              When CRAs receive a search from us they will place a search
              footprint on your credit file that may be seen by other lenders.
            </InnerLI>
            <InnerLI>
              If you are making a joint application, or tell us that you have a
              spouse or financial associate, we will link your records together,
              so you should make sure you discuss this with them, and share with
              them this information, before lodging the application. The CRAs we
              share data with will also link your records together and these
              links will remain on your and their files until such time as you
              or your partner successfully files for a disassociation with the
              CRAs to break that link.
            </InnerLI>
            <InnerLI>
              The identities of the CRAs, their role also as fraud prevention
              agencies, the data they hold, the ways in which they use and share
              personal data, data retention periods and your data protection
              rights with the CRAs are explained in more detail:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  In relation to TransUnion, Equifax and Experian: in their
                  Credit Reference Agency Information Notice at{' '}
                  <Link
                    href="http://www.experian.co.uk/crain/index.html"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    http://www.experian.co.uk/crain/index.html{' '}
                  </Link>
                  . CRAIN is also accessible from each of these three CRAs -
                  clicking on any of these three links will also take you to the
                  same CRAIN document: TransUnion{' '}
                  <Link
                    href="https://transunion.co.uk/crain"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    transunion.co.uk/crain{' '}
                  </Link>
                  ; Equifax{' '}
                  <Link
                    href="https://www.equifax.co.uk/crain"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    www.equifax.co.uk/crain{' '}
                  </Link>
                  ; Experian{' '}
                  <Link
                    href="https://www.experian.co.uk/crain"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    www.experian.co.uk/crain{' '}
                  </Link>
                  ;
                </InnerLI>
                <InnerLI>
                  In relation to Crediva in their Privacy Notice at{' '}
                  <Link
                    href="https://www.crediva.co.uk/crediva-processing-notice"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    https://www.crediva.co.uk/crediva-processing-notice{' '}
                  </Link>
                  .
                </InnerLI>
              </OrderedList>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="legal-personal-data"
            mt={4}
          >
            What is the legal basis that permits us to use your personal data?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              We are only permitted to use your personal data and special
              categories of personal data if we have a legal basis for doing so
              as set out under data protection legislation. We rely on the
              following legal bases to use your personal data:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  Where we have your consent (<strong>Consent</strong>);
                </InnerLI>
                <InnerLI>
                  Where we need information to enter into a contract, or perform
                  the contract we have entered into, with you (
                  <strong>Contractual Performance</strong>);
                </InnerLI>
                <InnerLI>
                  Where we need to comply with a legal or regulatory obligation
                  (<strong>Legal Obligation</strong>); or
                </InnerLI>
                <InnerLI>
                  Where it is necessary for our legitimate interests (or those
                  of a third party) and your interests and fundamental rights do
                  not override those interests (
                  <strong>Legitimate Interest</strong>);
                </InnerLI>
              </OrderedList>
            </InnerLI>
            <InnerLI>
              In more limited circumstances we may also rely on the following
              legal bases:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  Where we need to protect your interests (or someone
                  else&apos;s interests) (<strong>Vital Interest</strong>); or
                </InnerLI>
                <InnerLI>
                  Where it is needed in the public interest or for official
                  purposes (<strong>Public Task</strong>).
                </InnerLI>
              </OrderedList>
            </InnerLI>
            <InnerLI>
              When we and FPAs process your personal data, we do so on the basis
              that we have a legitimate interest in preventing fraud and money
              laundering, and to verify your identity, in order to protect our
              business and to comply with laws that apply to us.
            </InnerLI>
            <InnerLI>
              The table at the end of this notice provides more detail about the
              personal data that we use, the legal basis that we rely on in each
              case and your rights.
            </InnerLI>
            <InnerLI>
              Some information is classified as special categories of personal
              data under data protection legislation. We may process special
              categories of personal information and criminal conviction
              information in the following circumstances:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  In limited circumstances with your explicit consent, in which
                  case we will explain the purpose for which the information
                  will be used at the point where we ask for your consent; and
                </InnerLI>
                <InnerLI>
                  We will use information about your physical and mental health
                  or disability status to comply with our legal obligations,
                  including to ensure we are complying with our obligations to
                  treat you fairly and to assess your ability to repay your
                  debt.
                </InnerLI>
              </OrderedList>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="fraud-personal-data"
            mt={4}
          >
            What are the consequences of processing your personal data for fraud
            prevention or anti-money laundering purposes?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              If we, or a FPA, determine that you pose a fraud or money
              laundering risk, we may refuse to provide a product to you, or we
              may stop providing existing products to you.
            </InnerLI>
            <InnerLI>
              A record of any fraud or money laundering risk will be retained by
              the FPAs, and may result in others refusing to provide services,
              financing or employment to you. If you have any questions about
              this, please contact us by calling our Compliance Team on{' '}
              <Link href="tel:+443715225146">0371 522 5146</Link>.
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="no-personal-data"
            mt={4}
          >
            What happens if you do not provide information that we request?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              We need some of your personal data in order to perform our
              contract with you. For example, we need to know your address and
              phone number in order to issue and administer your loan. We also
              need some information so that we can comply with our legal
              obligations. For example, we need to know your credit history so
              we can assess your ability to repay your loan to ensure that we
              are lending responsibly.
            </InnerLI>
            <InnerLI>
              Where personal data is needed for these purposes, if you do not
              provide it we will not be able to perform our contract with you
              and may not be able to offer you a product. If you do not provide
              the requested information, we may continue to process your
              personal data for other purposes as set out in this notice.
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="retain-personal-data"
            mt={4}
          >
            How long do we retain your personal data for?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              We will only retain your personal data for as long as it is
              required in relation to the purposes it was obtained for by us as
              detailed in this notice. The periods for which we hold your
              personal data are set out below:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  When you take out {brandWithArticle} loan; we only keep
                  details of your personal information and details of your loan
                  for such period of time as is necessary to permit us to comply
                  with any legal or statutory obligations after your loan has
                  been transferred to Antelope Loans and as may be required to
                  enable us to establish or defend any legal claims that may
                  arise in relation to your loan.
                </InnerLI>
                <InnerLI>
                  When you apply for a loan but are declined; we will keep your
                  personal data for statistical analysis, fraud prevention and
                  credit scoring purposes. If you have consented to receiving
                  marketing from us, in the event your application is declined,
                  we will continue to retain your personal data in order to send
                  marketing to you.{' '}
                  <strong>
                    Please note that you can withdraw your consent to receive
                    marketing from us at any time by contacting Customer
                    Services, using the unsubscribe link in any email we send
                    you or on your online account management page. Please see{' '}
                    <Link href="#rights-personal-data">Section 17</Link> for
                    more information about how we use your personal data for
                    profiling, <Link href="#marketing">Section 18</Link> about
                    the right to object to the processing of your personal data
                    and <Link href="#phone-calls">Section 19</Link> about where
                    we rely on your consent to process your personal data;
                  </strong>
                </InnerLI>
                <InnerLI>
                  FPAs can hold your personal data for different periods of
                  time, and if you are considered to pose a fraud or money
                  laundering risk, your data can be held for up to six years by
                  them. For more details about how long FPAs hold your personal
                  data please contact us by calling our Compliance Team on 0371
                  522 5146.
                </InnerLI>
              </OrderedList>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="secure-personal-data"
            mt={4}
          >
            What measures do we have in place to safeguard your personal data?
          </Heading>
          <OrderedList mt={2} spacing={2} mx={2.5}>
            <InnerLI>
              We know how important it is to keep your personal data secure. We
              protect your personal data when being transferred and when stored
              in IT systems.
            </InnerLI>
            <InnerLI>
              A variety of technologies are employed to protect the systems that
              are used to store and manage personal data. To protect our systems
              and networks from attack we use firewalls, encrypted hard drives,
              antivirus software, host based intruder detection software and
              authenticated access protection solutions. Our security solutions
              are designed in line with industry standards to protect the
              personal data we store or transmit.
            </InnerLI>
            <InnerLI>
              Access to personal information is strictly controlled. We ensure
              access to personal data is restricted to individuals on a need to
              know basis. Training is provided to individuals working within the
              group who need to access personal data to ensure that personal
              data is managed securely.
            </InnerLI>
            <InnerLI>
              When we share your personal data with third parties and
              organisations as set out in{' '}
              <Link href="#share-personal-data">Section 6</Link> we undertake
              appropriate due diligence with them to ensure they are complying
              with the terms of their contract.
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="transfer-personal-data"
            mt={4}
          >
            When do we transfer your personal data overseas?
          </Heading>
          <OrderedList mt={2} spacing={2}>
            <InnerLI>
              We and third parties with whom we share personal data with may
              transfer your personal data overseas so that we can manage your
              application and your loan and provide other services from
              countries within the European Economic Area (<strong>EEA</strong>)
              (where similar data protection standards apply as in the UK) and
              countries outside the EEA. When personal data is transferred to
              countries outside of the UK and the EEA those countries may not
              offer an equivalent level of protection for personal information
              to the laws in the UK. Where this is the case we and those third
              parties will ensure that appropriate safeguards are put in place
              to protect your personal information. For example, whenever FPAs
              transfer your personal data outside of the UK and the EEA, they
              impose contractual obligations on the recipients of that personal
              data to protect your personal data to the standard required in the
              UK. They may also require the recipient to subscribe to
              ‘international frameworks&apos; intended to enable secure data
              sharing.
            </InnerLI>
            <InnerLI>
              The countries to which your personal data is transferred and the
              safeguards in place where personal data are transferred outside
              the UK and the EEA are set out below:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>United Kingdom</InnerLI>
                <InnerLI>Germany (EEA)</InnerLI>
                <InnerLI>Ireland (EEA)</InnerLI>
                <InnerLI>Netherlands (EEA)</InnerLI>
                <InnerLI>Poland (EEA)</InnerLI>
                <InnerLI>USA</InnerLI>
                <InnerLI>India</InnerLI>
                <InnerLI>Canada</InnerLI>
                <InnerLI>Australia</InnerLI>
                <InnerLI>France (EEA)</InnerLI>
                <InnerLI>Spain (EEA)</InnerLI>
                <InnerLI>Iceland (EEA)</InnerLI>
              </OrderedList>
              <List mt={2} spacing={2}>
                <ListItem>
                  <Text>
                    The safeguards that we rely on to protect personal data that
                    is transferred outside of the UK and the EEA include:
                  </Text>
                  <Text>
                    European Commission approved model contract clauses.{' '}
                    <Link
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                      rel="noopener noreferrer"
                      isExternal
                    >
                      Further details about the model contract clauses is
                      available here.
                    </Link>
                  </Text>
                </ListItem>
              </List>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading fontSize="xl" color="inherit" as="h2" id="cookies" mt={4}>
            How do I find out about cookies?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              We are committed to protecting your personal data (anonymous or
              otherwise) that we collect about you online.{' '}
              <Link
                href="https://www.newday.co.uk/site-services/cookies-policy/"
                rel="noopener noreferrer"
                isExternal
              >
                Our Cookies Notice{' '}
              </Link>{' '}
              explains how we use cookies, why, and how this allows us to
              improve our service. It also tells you how you can manage what
              cookies are stored on your device. Please read the Cookies Notice
              to find out more.
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="credit-score"
            mt={4}
          >
            Why do we use credit scoring and automated decision making as part
            of your application?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              When you check your eligibility with us or apply to us for credit
              we may use an automated system known as credit scoring to help us
              decide whether to lend to you. It is a way of assessing how much
              you are able to afford to borrow and how you are likely to manage
              your account. Most lenders use credit scoring to help make fair
              and informed decisions about lending.
            </InnerLI>
            <InnerLI>
              Credit scoring takes account of information from three sources:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  The information you give us on your application;
                </InnerLI>
                <InnerLI>
                  Information provided by CRAs and FPAs, including information
                  about how you manage similar accounts; and
                </InnerLI>
                <InnerLI>
                  Information that may already be held about you by us.
                </InnerLI>
              </OrderedList>
            </InnerLI>
            <InnerLI>
              Credit scoring methods are regularly reviewed to ensure they
              remain fair, effective and unbiased. Using credit scoring helps us
              to lend responsibly.
            </InnerLI>
            <InnerLI>
              You have rights in relation to automated decision making. Please
              see{' '}
              <Link href="#marketing">
                <strong>Section 18</strong>
              </Link>{' '}
              for more details of these rights.
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="profiling-personal-data"
            mt={4}
          >
            How do we use your personal data for profiling?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              We use various profiling techniques to assist us with running our
              business. By “profiling” we mean the automated analysis of
              personal data about an individual to evaluate certain things about
              that individual – basically drawing conclusions about an
              individual based on a statistical model.
            </InnerLI>
            <InnerLI>
              We use these techniques for instance in the course of evaluating
              applications for products, to manage your account, to guard
              against fraud and for marketing and targeted advertising purposes.
            </InnerLI>
            <InnerLI>
              <strong>
                You have a right to object in relation to profiling. Please see{' '}
                <Link href="#rights-personal-data">Section 17.1.7</Link> for
                more details of this right.
              </strong>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="rights-personal-data"
            mt={4}
          >
            What rights do you have in relation to your personal data?
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              You have a number of rights in relation to your personal data,
              these include the right to:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  <strong>Information</strong> - Be informed about how we use
                  your personal data;
                </InnerLI>
                <InnerLI>
                  <strong>Access</strong> - Obtain access to your personal data
                  that we hold;
                </InnerLI>
                <InnerLI>
                  <strong>Rectification</strong> - Request that your personal
                  data is corrected if you believe it is incorrect or
                  incomplete;
                </InnerLI>
                <InnerLI>
                  <strong>Erasure</strong> - You do not have a blanket right to
                  ask for deletion of your data. A request that we erase your
                  personal data is only valid in the following circumstances:
                  <OrderedList mt={2} spacing={2} px={2}>
                    <AlphaLI>
                      If we are continuing to process personal data beyond the
                      period when it is necessary to do so for the purpose for
                      which it was originally collected;
                    </AlphaLI>
                    <AlphaLI>
                      If we are relying on consent as the legal basis for
                      processing and you withdraw consent;
                    </AlphaLI>
                    <AlphaLI>
                      If we are relying on legitimate interest as the legal
                      basis for processing and you object to this processing and
                      there is no overriding compelling ground which enables us
                      to continue with the processing;
                    </AlphaLI>
                    <AlphaLI>
                      If the personal data has been processed unlawfully (i.e.
                      where we do not have a lawful basis to process your
                      personal data under the data protection legislation); or
                    </AlphaLI>
                    <AlphaLI>
                      If it is necessary to delete the personal data to comply
                      with a legal obligation;
                    </AlphaLI>
                  </OrderedList>
                </InnerLI>
                <InnerLI>
                  <strong>Restriction</strong> - Ask us to restrict our data
                  processing activities where you consider that:
                  <OrderedList mt={2} spacing={2} px={2}>
                    <AlphaLI>Personal data is inaccurate;</AlphaLI>
                    <AlphaLI>
                      Our processing of your personal data is unlawful;
                    </AlphaLI>
                    <AlphaLI>
                      Where we no longer need the personal data but you require
                      us to keep it to enable you to establish, exercise or
                      defend a legal claim; or
                    </AlphaLI>
                    <AlphaLI>
                      Where you have raised an objection to our use of your
                      personal data.
                    </AlphaLI>
                  </OrderedList>
                </InnerLI>
                <InnerLI>
                  <strong>Portability</strong> - Request a copy of certain
                  personal data that you have provided to us in a commonly used
                  electronic format. This right relates to personal data that
                  you have provided to us that we need in order to perform our
                  agreement with you and personal data where we are relying on
                  consent to process your personal data.
                </InnerLI>
                <InnerLI>
                  <strong>Objection</strong> - Object to our processing of your
                  personal data where we are relying on legitimate interests or
                  exercise of a public interest task to make the processing
                  lawful. If you raise an objection, we will carry out an
                  assessment to determine whether we have an overriding
                  legitimate ground which entitles us to continue to process
                  your personal data. If you object to us using your personal
                  data for marketing purposes, we will always stop such use of
                  your data.
                </InnerLI>
                <InnerLI>
                  <strong>Automated decisions</strong> - Not be subject to
                  automated decisions which produce legal effects or which could
                  have a similarly significant effect on you.
                  <OrderedList mt={2} spacing={2} px={2}>
                    <AlphaLI>
                      As part of the processing of your personal data, decisions
                      may be made by automated processes. This means we may
                      automatically decide that you pose a fraud or money
                      laundering risk if:
                      <OrderedList mt={2} spacing={2}>
                        <RomanLI>
                          our processing reveals your behaviour to be consistent
                          with money laundering or known fraudulent conduct; or
                          is inconsistent with your previous submissions; or
                        </RomanLI>
                        <RomanLI>
                          you appear to have deliberately hidden your true
                          identity.
                        </RomanLI>
                      </OrderedList>
                    </AlphaLI>
                    <AlphaLI>
                      If you apply to us for credit but we are unable to offer
                      you credit, you can contact us within 21 days of the date
                      of your application to ask to have the decision
                      reconsidered.
                    </AlphaLI>
                  </OrderedList>
                </InnerLI>
                <InnerLI>
                  We will always tell you about the outcome of a request you
                  have made in relation to the rights discussed above (except in
                  relation to changes in marketing preferences, which are mostly
                  automated).
                </InnerLI>
                <InnerLI>
                  If you would like to exercise any of your rights or find out
                  more, please contact our customer services team on 0333 220
                  2520^. The table at the end of this notice provides more
                  details about the personal data that we use, the legal basis
                  that we rely on in each case and your rights.
                </InnerLI>
                <InnerLI>
                  You can also request a CRA to provide you with information
                  they hold about you. For more information about how to access
                  your information held by CRAs, please read the{' '}
                  <Link
                    href="http://www.experian.co.uk/crain/index.html"
                    rel="noopener noreferrer"
                    isExternal
                  >
                    Credit Reference Agency Information Notice{' '}
                  </Link>
                </InnerLI>
              </OrderedList>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading fontSize="xl" color="inherit" as="h2" id="marketing" mt={4}>
            Your marketing preferences
          </Heading>
          <OrderedList mt={2} mx={2.5} spacing={2}>
            <InnerLI>
              As part of the application process for a product or to check your
              eligibility for one of our products, we will ask you if you wish
              to receive marketing from us. We will not send marketing to you if
              you ask us not to. We conduct marketing via direct mail, phone,
              email, SMS and online, including via social media and other
              digital advertising channels (for example Google), broadcast
              channels (for example TV) and our mobile apps.
            </InnerLI>
            <InnerLI>
              If you agreed to receiving marketing from us, but no longer wish
              to receive marketing from us you can opt-out in a number of ways
              as follows:
              <OrderedList mt={2} spacing={2}>
                <InnerLI>
                  Writing to Customer Services Team, NewDay Ltd, PO Box 700
                  Leeds LS99 2BD; or
                </InnerLI>
                <InnerLI>Phoning us on 0333 220 2520^ or</InnerLI>
                <InnerLI>
                  Clicking on any unsubscribe link which will be provided on all
                  marketing emails or using any unsubscribe functionality in any
                  text messages from us; or
                </InnerLI>
                <InnerLI>
                  Following the instructions in any other form of marketing you
                  have received from us; or
                </InnerLI>
                <InnerLI>
                  Changing your marketing preferences on your online account
                  management page.
                </InnerLI>
              </OrderedList>
            </InnerLI>
          </OrderedList>
        </MainLI>
        <MainLI>
          <Heading
            fontSize="xl"
            color="inherit"
            as="h2"
            id="phone-calls"
            mt={4}
          >
            Why do we need to record phone calls with you?
          </Heading>
          <List mt={2} mx={2.5} spacing={2}>
            <ListItem>
              <Text mt={2}>
                We may monitor or record phone calls with you in case we need to
                check that we have carried out your instructions correctly, to
                resolve queries or issues, for regulatory purposes, to help
                improve the quality of our service, to manage your account and
                to help detect or prevent fraud or other crimes. Conversations
                may also be monitored for staff training purposes.
              </Text>
              <Text mt={2}>
                ^ Calls are charged at a standard national rate. Call costs from
                mobiles and other operators may vary so please check before
                calling. Calls may be recorded and monitored for training and
                security purposes and to help us manage your account.
              </Text>
            </ListItem>
          </List>
        </MainLI>
      </OrderedList>

      <Text fontWeight="bold" pt={8}>
        Part 1 - Quick check of NewDay&apos;s use of your personal data
        (including processing undertaken where NewDay relies on legitimate
        interests)
      </Text>

      <TableContainer whiteSpace="unset">
        <Table variant="simple">
          <Thead>
            <Tr backgroundColor="brand.greyScale.200">
              <Th>Purpose</Th>
              <Th>Type of personal data used</Th>
              <Th>Legal basis for processing</Th>
              <Th>Which rights apply?*</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                Assessing credit risk for credit applications to ensure that
                NewDay meet its regulatory obligations to lend responsibly and
                to enter into a contract with you
              </Td>
              <Td>
                Application data including bank account details full name, date
                of birth, current and past addresses, phone number and e-mail
                address, employment information including salary, residential
                type, marital status and details of financial associates
              </Td>
              <Td>Contractual Performance and Legal Obligation</Td>
              <Td>
                The generally applicable rights plus the right to data
                portability
              </Td>
            </Tr>
            <Tr>
              <Td>
                Account management to enable NewDay to operate and manage your
                account including sending service messages via post, SMS, email,
                WhatsApp, push notifications
              </Td>
              <Td>
                Loan administration data, customer communications, customer
                service records, complaint records and associated documentation,
                current addresses, phone number and e-mail address
              </Td>
              <Td>Contractual Performance</Td>
              <Td>
                The generally applicable rights plus the right to data
                portability in relation to transaction data
              </Td>
            </Tr>
            <Tr>
              <Td>
                Supporting debtor tracing and debt collections to enable NewDay
                to recover any unpaid monies from you
              </Td>
              <Td>
                Contact details, including full name, date of birth, current and
                past addresses, phone number and e-mail address and account data
                (including details of financial associates) and transaction data
              </Td>
              <Td>Legitimate Interest</Td>
              <Td>The generally applicable rights plus the right to object</Td>
            </Tr>
            <Tr>
              <Td>To sell or transfer your loan to a third party</Td>
              <Td>
                Application data including bank account details full name, date
                of birth, current addresses, phone number and email address,
                employment information including salary, residential type,
                marital status and details of financial associates and
                transaction data
              </Td>
              <Td>Legitimate Interests</Td>
              <Td>The generally applicable rights plus the right to object</Td>
            </Tr>
            <Tr>
              <Td>To sell or transfer your debt to another third party</Td>
              <Td>
                Application data including bank account details full name, date
                of birth, current and past addresses, phone number and e-mail
                address, employment information including salary, residential
                type, marital status and details of financial associates and
                transaction data
              </Td>
              <Td>Legitimate Interests</Td>
              <Td>The generally applicable rights plus the right to object</Td>
            </Tr>
            <Tr>
              <Td>
                Promoting responsible lending and helping to prevent
                over-indebtedness
              </Td>
              <Td>
                Application data including previous application details and
                account data and details of financial associates and transaction
                data{' '}
              </Td>
              <Td>Legal Obligation</Td>
              <Td>The generally applicable rights</Td>
            </Tr>
            <Tr>
              <Td>
                Helping prevent and detect crime and anti-money laundering
                services and verify identity including compliance with
                Politically Exposed Persons (PEPS) and Sanctions Regulations
              </Td>
              <Td>
                Application data including previous application details, account
                data, transaction data
              </Td>
              <Td>Legal Obligation</Td>
              <Td>The generally applicable rights</Td>
            </Tr>
            <Tr>
              <Td>
                Helping prevent fraud in order to protect us and you against any
                fraudulent activity on your account
              </Td>
              <Td>
                Application data including previous application details, account
                data, device data and transaction data
              </Td>
              <Td>Legitimate Interest</Td>
              <Td>The generally applicable rights plus the right to object</Td>
            </Tr>
            <Tr>
              <Td>
                Complying with and supporting compliance with legal and
                regulatory requirements to which NewDay is subject
              </Td>
              <Td>
                Application data including previous application details,
                transaction data, customer communications, customer service
                records, complaint records and associated documentation
              </Td>
              <Td>Legal Obligation</Td>
              <Td>The generally applicable rights </Td>
            </Tr>
            <Tr>
              <Td>Business analytics including system testing</Td>
              <Td>
                Application data, contract details including previous
                application details, information from business partners e.g.
                Experian, information from marketing information providers,
                transaction data, customer communications, customer service
                records, complaint records and associated documentation
              </Td>
              <Td>Legitimate Interest Consent</Td>
              <Td>The generally applicable rights plus the right to object</Td>
            </Tr>
            <Tr>
              <Td>
                Marketing purposes including direct mail and targeted digital
                marketing to send you offers about our goods and services{' '}
              </Td>
              <Td>
                Previous application details, information from business partners
                e.g. Experian, information from marketing information providers,
                names, addresses, account data
              </Td>
              <Td>Legitimate Interest</Td>
              <Td>The generally applicable rights plus the right to object </Td>
            </Tr>
            <Tr>
              <Td>
                Electronic marketing purposes including, SMS and email, targeted
                marketing to send you offers about our goods and services and
                those of the retailer
              </Td>
              <Td>
                Previous application details, information from business partners
                e.g. Experian, information from marketing information providers,
                names, addresses, email address, phone number and account data.
              </Td>
              <Td>
                Consent or legitimate interests depending on the sign up journey
                that you followed when applying for your loan
              </Td>
              <Td>The generally applicable rights plus the right to object </Td>
            </Tr>
            <Tr>
              <Td>Information generated by Cookies</Td>
              <Td>Browser data</Td>
              <Td>Consent</Td>
              <Td>The generally applicable rights plus the right to object </Td>
            </Tr>
            <Tr>
              <Td>Assessing your ability to repay any credit owed to us</Td>
              <Td>
                Sensitive categories of personal data including details relating
                to your health, criminal convictions or alleged offences.
                Information from business partners e.g. Experian.
              </Td>
              <Td>Legal Obligation</Td>
              <Td>The generally applicable rights</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Text>
        *The following generally applicable rights always apply: right to be
        informed, right of access, right to rectification, right to erasure,
        right to restriction and rights in relation to automated decision
        making. Please see the{' '}
        <Text as="em" fontWeight="bold">
          &quot;What rights do you have in relation to your personal data&quot;
        </Text>{' '}
        <Link href="#marketing">Section 18</Link> above for more details of your
        rights and how to exercise them or go the Information
        Commissioner&apos;s website at www.ico.org.uk.
      </Text>
      <Text>NDX049 0221</Text>
    </Stack>
  );
};

export const PrivacyPage: React.FC = () => (
  <ResponsivePageLayout>
    <ContentWrapper py={8} maxContentWidth="5xl">
      <PrivacyPageContent />
    </ContentWrapper>
  </ResponsivePageLayout>
);
