import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useOptimizelyState } from './use-optimizely-state';

type OptimizelyToggles = {
  [key: string]: boolean;
};

type OptimizelyTogglesContextData = {
  optimizelyToggles: OptimizelyToggles;
  setOptimizelyTogglesOnCookieAccept: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const OptimizelyTogglesContext = createContext<OptimizelyTogglesContextData>(
  {} as OptimizelyTogglesContextData
);

export const getOptimizelyToggles = (): OptimizelyToggles => {
  const optimizelyToggles = {};

  if (
    window['optimizelyToggles'] &&
    Object.keys(window['optimizelyToggles']).length
  ) {
    Object.entries(window['optimizelyToggles']).map(
      ([experimentName, toggleValue]) => {
        if (
          /^[a-zA-Z0-9_]+$/i.test(experimentName) &&
          typeof toggleValue === 'boolean'
        ) {
          optimizelyToggles[experimentName] = toggleValue;
        }
      }
    );
  }

  return optimizelyToggles;
};

export const OptimizelyTogglesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [optimizelyTogglesOnCookieAccept, setOptimizelyTogglesOnCookieAccept] =
    useState(false);
  const [optimizelyToggles, setOptimizelyToggles] = useState({});
  const location = useLocation();
  const optimizelyVariantDecided = useOptimizelyState(
    optimizelyTogglesOnCookieAccept
  );

  useEffect(() => {
    if (optimizelyVariantDecided) {
      const interval = setInterval(() => {
        /* optimizelyToggles are not immediately available due to latency issues with AB testing */
        if (window['optimizelyToggles']) {
          setOptimizelyToggles(getOptimizelyToggles());
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [location, optimizelyVariantDecided]);

  return (
    <OptimizelyTogglesContext.Provider
      value={{ optimizelyToggles, setOptimizelyTogglesOnCookieAccept }}
    >
      {children}
    </OptimizelyTogglesContext.Provider>
  );
};

export const useOptimizelyToggles = () => useContext(OptimizelyTogglesContext);
