export default {
  brand: {
    primary: {
      50: '#e8f4f9',
      100: '#8ac9e3',
      500: '#1492c6',
      700: '#0a4963',
    },
    secondary: {
      50: '#fdf2e8',
      100: '#f6c08b',
      500: '#EC8117',
      700: '#76410c',
    },
    tertiary: {
      50: '#ececec',
      100: '#a2a2a2',
      500: '#444444',
      700: '#292929',
      default: '#444444',
    },
    quaternary: {
      50: '#fbfbfb',
      100: '#e9e9e9',
      500: '#D3D3D3',
      700: '#6a6a6a',
    },
    grayScale: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
  },
};
