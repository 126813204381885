import {
  MisthoCreateTransactionPostResponse,
  MisthoGetTransactionBffResponse,
  MisthoGetTransactionResponse,
  MisthoTransactionStatus,
} from '@newday/plum-types';

export const getMockMisthoGetTransactionResponse = (
  overrides?: Partial<MisthoGetTransactionResponse>
): MisthoGetTransactionResponse => ({
  _id: '_id',
  client_id: 'client_id',
  created_at: '123',
  callback_token: 'callback_token',
  environment: 'environment',
  ref_id: 'ref_id',
  status: MisthoTransactionStatus.CREATED,
  updated_at: 'updated_at',
  ...overrides,
});

export const getMockMisthoCreateTransactionPostResponse = (
  override?: Partial<MisthoCreateTransactionPostResponse>
): MisthoCreateTransactionPostResponse => ({
  callback_token: 'callback_token',
  mistho_url: 'mistho_url',
  transaction_id: 'transaction_id',
  ...override,
});

export const getMockMisthoGetTransactionBffResponse = (
  overrides?: Partial<MisthoGetTransactionBffResponse>
): MisthoGetTransactionBffResponse => ({
  misthoEnabled: true,
  transactionId: 'transaction_id',
  ...overrides,
});
