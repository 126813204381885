import { Heading, Text } from '@newday/core';
import { ThumbsUpIcon } from '@newday/icons';
import {
  AdvicePanel,
  BalloonBox,
  ContentWrapper,
  ResponsivePageLayout,
} from '../../components';
import { ApplicationReference } from '../../features';
import { useBrand } from '../../app';

export const ReturnBankDetailsDeclinedPage: React.FC = () => {
  const { brandWithArticle } = useBrand();

  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <BalloonBox fullWidth>
        <>
          <Heading fontSize="3xl" fontWeight={700}>
            Welcome back
          </Heading>
          <Text fontWeight="semibold" mt={2} mb={4} color="black">
            We’re sorry that your loan application wasn’t successful.
          </Text>
          <Text mb={4} color="black">
            We tried to verify the bank details you gave in your loan
            application, but we weren’t able to do so.
          </Text>
          <Text mb={4} color="black">
            To receive a loan and make repayments, you need a valid UK bank
            account on which you’re a named account holder, that accepts Faster
            Payments. Unfortunately, the bank account you gave us didn’t meet
            these requirements.
          </Text>
          <Text color="black">
            Thanks for taking the time to apply for {brandWithArticle} personal
            loan. We hope we can help you in future.
          </Text>
        </>
      </BalloonBox>
      <ContentWrapper py={4} maxContentWidth="5xl">
        <AdvicePanel
          icon={<ThumbsUpIcon />}
          title="Access help and support"
          description="Visit our frequently asked questions page to get answers on popular
          topics."
        />
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
