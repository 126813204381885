const formatPercentageFactory = (decimalPlaces = 1) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'percent',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
  return formatter.format;
};

export const formatPercentageByDecimalPlaces = (
  number: number,
  decimalPlaces?: number
) => {
  if (number > 1 && number <= 100) {
    return formatPercentageFactory(decimalPlaces)(number / 100);
  }

  if (number < 1) {
    return formatPercentageFactory(decimalPlaces)(number);
  }

  if (number === 1) {
    console.error('Should it be 1% or 100%?');
    return;
  }

  console.error('Are you sure this is a percentage?');
};
