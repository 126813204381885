/* istanbul ignore file */
/* eslint-disable sonarjs/cognitive-complexity */
import axios from 'axios';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@newday/core';
import { BffRoutes, RouteMocks } from '@newday/plum-types';

import { useDevTool } from './queries';

import { PlumIcon } from '../../icons';

type DevToolProps = {
  children: React.ReactNode;
};

type PlumMockIdsMap = {
  [routes in BffRoutes]?: string;
};

const cleanBffRoute = (bffRoute: string) => {
  const regex = /^\/[^/]+/;
  const result = bffRoute.match(regex);

  return result ? result[0] : '';
};

export const mockStorageKey = 'plum_mock';

export const DevTool = ({ children }: DevToolProps) => {
  const { data: mocks } = useDevTool();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [plumMockIdsMap, setPlumMockIdsMap] = useState<PlumMockIdsMap | null>(
    null
  );

  const [isMockingEnabled, setIsMockingEnabled] = useState(() => {
    const storedValue = sessionStorage.getItem(mockStorageKey);
    return storedValue === 'true';
  });

  useEffect(() => {
    sessionStorage.setItem(mockStorageKey, isMockingEnabled ? 'true' : 'false');
  }, [isMockingEnabled]);

  useEffect(() => {
    if (!plumMockIdsMap && mocks) {
      const initialPlumMockIdsMap = Object.values(mocks).reduce(
        (plumMockIdsMap: PlumMockIdsMap, bffRoutes) => {
          Object.keys(bffRoutes).forEach((bffRoute) => {
            const bffRouteClean = cleanBffRoute(bffRoute);

            plumMockIdsMap[bffRouteClean] = 'default';
          });
          return plumMockIdsMap;
        },
        {}
      );

      setPlumMockIdsMap(initialPlumMockIdsMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mocks]);

  useEffect(() => {
    if (plumMockIdsMap) {
      const interceptor = axios.interceptors.request.use(function (config) {
        const url = new URL(config.url as string, config.baseURL);
        const pageRoute = url.pathname;

        const getExtraHeaders = () => {
          const getKey = () => {
            if (/\/documents$/.test(pageRoute)) {
              return BffRoutes.htmlDocuments;
            }

            if (/\/update-address/.test(pageRoute)) {
              return BffRoutes.updateAddress;
            }

            if (/redirect/.test(pageRoute)) {
              return BffRoutes.redirect;
            }

            if (/\/proofList$/.test(pageRoute)) {
              return BffRoutes.proofList;
            }

            if (/\/proof$/.test(pageRoute)) {
              return BffRoutes.proof;
            }

            return Object.keys(plumMockIdsMap).find((bffRoute) =>
              pageRoute.includes(bffRoute)
            );
          };

          const key = getKey();
          const cleanKey = key && cleanBffRoute(key);

          const headers: Record<string, string> = {
            'plum_mock': 'true'
          };
          
          if (cleanKey && plumMockIdsMap[cleanKey]) {
            headers['plum_mock_id'] = plumMockIdsMap[cleanKey];
          }
  
          return headers;
        };

        if (isMockingEnabled === true) {
          return {
            ...config,
            url: pageRoute,
            headers: {
              ...config.headers,
              ...getExtraHeaders(),
            },
          };
        }

        return config;
      });
      return () => axios.interceptors.request.eject(interceptor);
    }
  }, [plumMockIdsMap, isMockingEnabled]);

  const MockOptions = ({
    bffRoute,
    mockOptions,
  }: {
    bffRoute: string;
    mockOptions: RouteMocks<unknown>;
  }) => {
    const bffRouteClean = cleanBffRoute(bffRoute);

    return (
      <RadioGroup value={plumMockIdsMap?.[bffRouteClean]}>
        <Stack spacing={5} direction="row" alignItems="flex-start" my={2}>
          {Object.keys(mockOptions).map((mockOption) => {
            return (
              <Radio
                onChange={() =>
                  setPlumMockIdsMap({
                    ...plumMockIdsMap,
                    [bffRouteClean]: mockOption,
                  })
                }
                key={mockOption}
                colorScheme="purple"
                value={mockOption}
                isChecked={plumMockIdsMap?.[bffRouteClean] === mockOption}
              >
                {mockOption}
              </Radio>
            );
          })}
        </Stack>
      </RadioGroup>
    );
  };

  const { tabs, panels } = useMemo(
    () =>
      Object.entries(mocks || {}).reduce(
        (
          acc: { tabs: ReactElement[]; panels: ReactElement[] },
          [pageRoute, bffRoutes]
        ) => {
          acc.tabs.push(<Tab key={pageRoute}>{pageRoute}</Tab>);
          acc.panels.push(
            <TabPanel key={pageRoute}>
              {Object.entries(bffRoutes).map(([bffRoute, mockOptions]) => {
                return (
                  <Box
                    borderTopWidth={1}
                    mt={4}
                    pt={2}
                    key={bffRoute}
                    display="flex"
                    flexDirection="column"
                    alignItems="start"
                  >
                    <Text>{bffRoute}</Text>
                    <MockOptions
                      bffRoute={bffRoute}
                      mockOptions={mockOptions}
                    />
                  </Box>
                );
              })}
            </TabPanel>
          );
          return acc;
        },
        { tabs: [], panels: [] }
      ),
    [plumMockIdsMap]
  );

  return (
    <>
      <PlumIcon
        onClick={onOpen}
        overflow="visible !important"
        position="fixed"
        top="0"
        left="0"
        data-testid="plum-dev-tool"
      />
      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent p="2rem" overflow="scroll">
          <DrawerCloseButton />
          <HStack spacing="4rem">
            <Stack alignItems="flex-start">
              <Heading>Dev tool!</Heading>
            </Stack>
            <Switch
              id="mocking"
              onChange={() => setIsMockingEnabled(!isMockingEnabled)}
              isChecked={isMockingEnabled}
              size="lg"
              colorScheme="purple"
            />
          </HStack>

          <Tabs
            variant="soft-rounded"
            colorScheme="purple"
            mt="2rem"
            display="flex"
          >
            <TabList display="flex" flexDirection={'column'}>
              {tabs}
            </TabList>
            <TabPanels>{panels}</TabPanels>
          </Tabs>
        </DrawerContent>
      </Drawer>
      {children}
    </>
  );
};
