import { LocalStorageTypes, PlumSupportedBrand } from '@newday/plum-types';
import { createContext, ReactNode, useContext } from 'react';
import { capitalizeFirstLetter } from '../utils';

export type BrandProviderProps = {
  brand?: PlumSupportedBrand;
  children: ReactNode;
};

export type BrandContextData = {
  brand: PlumSupportedBrand;
  brandArticle: string;
  brandWithArticle: string;
  uppercaseBrandName: string;
};

type BrandToBrandDisplayName = {
  [key in PlumSupportedBrand]: string;
};

const brandDisplayName: Partial<BrandToBrandDisplayName> = {
  pulsepersonal: 'pulse',
};

const BrandContext = createContext<BrandContextData>({} as BrandContextData);

export const BrandProvider = ({
  brand = 'aqua',
  children,
}: BrandProviderProps) => {
  const uppercaseBrandName = capitalizeFirstLetter(
    brandDisplayName[brand] ?? brand
  );

  const brandArticle = {
    aqua: 'an',
    bip: 'a',
    fluid: 'a',
    marbles: 'a',
    opus: 'an',
    pulse: 'a',
    pulsepersonal: 'a',
  }[brand];
  const brandWithArticle = `${brandArticle} ${uppercaseBrandName}`;

  localStorage.setItem(LocalStorageTypes.BRAND, brand);

  return (
    /* 
        This context is super dumb right now but we may need to change
        once we move out of eServicing
    */
    <BrandContext.Provider
      value={{ brand, brandArticle, brandWithArticle, uppercaseBrandName }}
    >
      {children}
    </BrandContext.Provider>
  );
};

export const useBrand = () => {
  return useContext(BrandContext);
};
