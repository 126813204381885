// TODO: Add GrayScale colors from Figma and export into Brand
// TODO: Use typings and not every brand has quaternary

export default {
  brand: {
    primary: '#000000',
    secondary: '#000000',
    tertiary: '#000000',
    quaternary: '#000000',
    textOnPrimaryBg: '#ffffff',
    success: '#276749',
    greyScale: {
      100: '#f9f9f9',
      200: '#f2f2f2',
      300: '#e3e3e3',
      400: '#c6c6c6',
      500: '#aaaaaa',
      600: '#8e8e8e',
      700: '#717171',
      800: '#4d4d4d',
      900: '#333333',
      1000: '#1a1a1a',
    },
  },
};
