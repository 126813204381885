import { Box, LinkButton, Skeleton, Text } from '@newday/core';
import { useApplicationId, useFeatureFlags } from '../../app';
import {
  ContentWrapper,
  Hero,
  ReferStepper,
  ResponsivePageLayout,
} from '../../components';
import { ApplicationReference } from '../../features';
import { useUserDetails } from '../../shared/queries';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';

const linkText = 'Go back to the Aqua app';

declare const LINK_AQUA_CREDIT_HUB;

export const DocUploadSuccessPage: React.FC = () => {
  const { applicationId } = useApplicationId();

  const handleClick = () => {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_action: FormActions.CLICK_CTA,
      form_name: FormNames.DOC_UPLOAD,
      link_text: linkText,
    });
  };

  const { data: userDetails, isSuccess: isSuccessUserDetails } = useUserDetails(
    applicationId,
    { obfuscateEmail: true }
  );

  const { MISTHO } = useFeatureFlags();

  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      {MISTHO ? (
        <>
          <Hero title="Thanks!" fullWidth>
            <>
              <Text fontWeight="bold" color="black">
                You&apos;ve successfully submitted your proof of income.
              </Text>
            </>
          </Hero>
        </>
      ) : (
        <>
          <Hero title="Thanks!" fullWidth>
            <>
              <Text fontWeight="bold" color="black">
                You&apos;ve successfully uploaded your proof of income.
              </Text>
            </>
          </Hero>
        </>
      )}
      <ContentWrapper py={8}>
        <Text
          as="legend"
          mb={2}
          fontSize="lg"
          color="brand.primary"
          fontWeight="bold"
        >
          What happens next?
        </Text>
        <ReferStepper activeStep={2} py={3} />
        <Box my={4}>
          <Skeleton isLoaded={isSuccessUserDetails}>
            <Text mb={4}>
              We&apos;ll review your proof of income to see if we need anything
              else. You&apos;ll receive an update by email at{' '}
              <Text
                as="span"
                textDecoration="underline"
                fontWeight="semibold"
                whiteSpace="nowrap"
              >
                {userDetails?.emailAddress}
              </Text>{' '}
              within about <strong>three working days.</strong>
            </Text>
          </Skeleton>

          <Text>
            Once we have enough information to decide, we&apos;ll let you know
            whether we can offer you a loan. If so, we&apos;ll pay the money
            into your account.
          </Text>
        </Box>
        <LinkButton onClick={handleClick} href={LINK_AQUA_CREDIT_HUB}>
          {linkText}
        </LinkButton>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
