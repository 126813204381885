import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  sizes: {
    md: {
      field: {
        borderRadius: 'brand.field',
        px: '1.25rem',
        fontSize: '1.125rem',
        height: '3rem',
      },
    },
  },
  variants: {
    outline: ({ theme }) => {
      const { colors } = theme;
      const styles = {
        backgroundColor: colors.white,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'brand.greyScale.300',
      };

      const invalidStyles = {
        backgroundColor: 'red.50',
        borderWidth: '1px',
        borderColor: 'red.600',
        boxShadow: `0 0 0 1px ${colors.red['600']}`,
      };
      return {
        field: {
          py: '0.75rem',
          color: 'black',
          ...styles,
          _placeholder: {
            color: 'gray.500',
          },
          _hover: styles,
          _focus: {
            borderWidth: '1px',
            // borderColor: 'brand.primary.500',
            boxShadow: `outline`,
          },
          _invalid: {
            ...invalidStyles,
            _hover: invalidStyles,
            _focus: invalidStyles,
          },
        },
      };
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'md',
  },
};
