import type { Proof } from '@newday/plum-types';

export const getMockProof = (overrides?: Partial<Proof>): Proof => ({
  id: '123',
  type: 'payslip',
  fileName: 'my-payslip',
  size: 1234,
  submitted: false,
  ...overrides,
});
