import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const OnlinePlatformIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 25 25" __css={styles} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 23.9583C18.8283 23.9583 23.9583 18.8283 23.9583 12.5C23.9583 6.17174 18.8283 1.04167 12.5 1.04167C6.17174 1.04167 1.04167 6.17174 1.04167 12.5C1.04167 18.8283 6.17174 23.9583 12.5 23.9583ZM25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.625 6.40625C7.625 6.18188 7.80689 6 8.03125 6H16.9688C17.1931 6 17.375 6.18188 17.375 6.40625V12.0938C17.375 12.3181 17.1931 12.5 16.9688 12.5H8.03125C7.80689 12.5 7.625 12.3181 7.625 12.0938V6.40625ZM8.4375 6.8125V11.6875H16.5625V6.8125H8.4375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9406 12.5C8.71431 12.5 8.49248 12.563 8.29997 12.682C8.10747 12.8009 7.95188 12.9712 7.85065 13.1736L6.85615 15.162C6.82517 15.224 6.81053 15.2928 6.81364 15.362C6.81675 15.4312 6.8375 15.4985 6.87392 15.5574C6.91035 15.6163 6.96122 15.6649 7.02172 15.6986C7.08222 15.7324 7.15034 15.75 7.21961 15.75H10.0624C10.2868 15.75 10.4686 15.9319 10.4686 16.1562C10.4686 16.3806 10.2868 16.5625 10.0624 16.5625H7.21985C7.21981 16.5625 7.21989 16.5625 7.21985 16.5625C7.0121 16.5626 6.80767 16.5094 6.6262 16.4084C6.44469 16.3072 6.29206 16.1613 6.18281 15.9846C6.07355 15.8078 6.0113 15.6061 6.00196 15.3985C5.99263 15.1909 6.03652 14.9844 6.12947 14.7986C6.12947 14.7986 6.12947 14.7986 6.12947 14.7986L7.12397 12.8101C7.29269 12.4728 7.552 12.1891 7.87285 11.9908C8.1937 11.7925 8.56342 11.6875 8.94059 11.6875H16.0592C16.2836 11.6875 16.4654 11.8694 16.4654 12.0938C16.4654 12.3181 16.2836 12.5 16.0592 12.5H8.9406C8.9406 12.5 8.9406 12.5 8.9406 12.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6875 13.7188C11.6875 13.4944 11.8694 13.3125 12.0938 13.3125H18.5938C18.8181 13.3125 19 13.4944 19 13.7188V18.5938C19 18.8181 18.8181 19 18.5938 19H12.0938C11.8694 19 11.6875 18.8181 11.6875 18.5938V13.7188ZM12.5 14.125V18.1875H18.1875V14.125H12.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6875 15.3438C11.6875 15.1194 11.8694 14.9375 12.0938 14.9375H18.5938C18.8181 14.9375 19 15.1194 19 15.3438C19 15.5681 18.8181 15.75 18.5938 15.75H12.0938C11.8694 15.75 11.6875 15.5681 11.6875 15.3438Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 16.9688C15.75 16.7444 15.9319 16.5625 16.1562 16.5625H16.9688C17.1931 16.5625 17.375 16.7444 17.375 16.9688C17.375 17.1931 17.1931 17.375 16.9688 17.375H16.1562C15.9319 17.375 15.75 17.1931 15.75 16.9688Z"
        fill="white"
      />
    </Icon>
  );
};
