import { Breakdown, BreakdownProps } from '../breakdown';
import {
  formatCurrency,
  formatCurrencyToTwoDecimalPlaces,
  formatPercentageByDecimalPlaces,
} from '../../utils';
import { useLoanBreakdown } from '../../shared/hooks/useLoanBreakdown';

export interface LoanBreakdownProps
  extends Omit<BreakdownProps, 'items' | 'itemsPerRow'> {
  apr: number;
  durationYears: number;
  isLoaded?: boolean;
  loanAmount: number;
  verbose?: boolean;
}

export const LoanBreakdown = ({
  apr,
  durationYears,
  isLoaded = true,
  loanAmount,
  variant,
  verbose = false,
  ...rest
}: LoanBreakdownProps) => {
  const { totalAmount, monthlyPayment } = useLoanBreakdown({
    loanAmount,
    durationYears,
    apr,
  });

  const calculatedItems = [
    {
      content: isLoaded
        ? `${formatPercentageByDecimalPlaces(apr)} APR`
        : undefined,
      label: 'Representative rate',
    },
    {
      content: isLoaded
        ? formatCurrencyToTwoDecimalPlaces(monthlyPayment)
        : undefined,
      label: 'Monthly payment',
    },
    {
      content: isLoaded
        ? formatCurrencyToTwoDecimalPlaces(totalAmount)
        : undefined,
      label: 'Total amount payable',
    },
  ];

  const selectedItems = [
    {
      content: formatCurrency(loanAmount),
      label: 'Loan amount',
    },
    {
      content: `${durationYears} years`,
      label: 'Loan term',
    },
  ];

  return (
    <Breakdown
      variant={variant}
      items={verbose ? [...selectedItems, ...calculatedItems] : calculatedItems}
      {...rest}
    />
  );
};
