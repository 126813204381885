import {
  ChatAndQuestionMarkIcon,
  OnlinePlatformIcon,
  ProfileIcon,
  SafeIcon,
} from '@newday/icons';
import {
  Flex,
  Stack,
  Heading,
  Accordion as CoreAccordion,
  AccordionItem as CoreAccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
} from '@newday/core';
import { Hero, ResponsivePageLayout, ContentWrapper } from '../../components';
import { useBrand } from '../../app';

type AccordionItemProps = {
  question: string;
  children: React.ReactNode;
};

const AccordionItem = ({ question, children }: AccordionItemProps) => (
  <CoreAccordionItem key={question}>
    {({ isExpanded }) => (
      <>
        <AccordionButton
          justifyContent="space-between"
          textAlign="left"
          _focus={{
            border: '1px',
            borderColor: 'brand.primary',
            borderRadius: 'md',
            borderStyle: 'dashed',
          }}
        >
          {question}
          <AccordionIcon expanded={isExpanded} />
        </AccordionButton>
        <AccordionPanel>
          <Text fontSize="md" lineHeight={6} css={{ whiteSpace: 'pre-line' }}>
            {children}
          </Text>
        </AccordionPanel>
      </>
    )}
  </CoreAccordionItem>
);

type FaqSectionProps = {
  Icon: React.JSXElementConstructor<{ width: string; height: string }>;
  sectionTitle: string;
  children: React.ReactNode;
};

const FaqSection = ({ Icon, sectionTitle, children }: FaqSectionProps) => (
  <Flex key={sectionTitle} direction="column">
    <Icon width="8" height="8" />
    <Heading mt={2} mb={4} fontSize="xl" fontWeight="bold">
      {sectionTitle}
    </Heading>
    <CoreAccordion>{children}</CoreAccordion>
  </Flex>
);

export const FaqsPage = () => {
  const { brandWithArticle, uppercaseBrandName } = useBrand();

  return (
    <ResponsivePageLayout>
      <Hero
        variant="dark"
        icon={<ChatAndQuestionMarkIcon />}
        title="Frequently asked questions"
      />
      <ContentWrapper>
        <Stack w="full" my={8} mx="auto" spacing={8}>
          <FaqSection
            Icon={OnlinePlatformIcon}
            sectionTitle="About NewDay and Antelope Loans"
          >
            <AccordionItem question="Who are NewDay and Antelope Loans?">
              NewDay Ltd is providing your {uppercaseBrandName} personal loan in
              partnership with Antelope Loans Funding Limited. <br /> <br />
              NewDay Ltd is one of the UK&apos;s leading consumer credit
              providers – it issues your {uppercaseBrandName} credit card.{' '}
              <br />
              <br />
              NewDay Ltd will manage your application for {
                brandWithArticle
              }{' '}
              personal loan. If your application is successful, your loan
              agreement will be signed by NewDay Ltd and it will send your loan
              amount to you. After this, NewDay Ltd will transfer ownership of
              your loan account to Antelope Loans Funding Limited, who will then
              be the lender in respect of your loan and collect your monthly
              Direct Debit payments. NewDay Ltd will send you an email to let
              you know that ownership of your loan account has been transferred.
              <br />
              <br />
              Antelope Loans (a trading style of Link Financial Outsourcing
              Limited) will manage your loan account on behalf of Antelope Loans
              Funding Limited. Antelope Loans will send you a copy of your
              signed loan agreement to keep for your records. It will send you
              all further communications relating to your loan account. Antelope
              Loans will also be your point of contact if you have any queries
              about your loan account. Please see ‘who can I talk to if I have a
              question?’ below for Antelope Loans’ contact details.
              <br />
              <br />
              Antelope Loans is an established presence in the credit world,
              managing millions of customers’ accounts across various European
              countries. It provides customer service with the human touch,
              backed up by state-of-the art technology.
            </AccordionItem>
          </FaqSection>

          <FaqSection
            Icon={SafeIcon}
            sectionTitle="About your personal loan applicaton"
          >
            <AccordionItem
              question={`How much could I borrow with ${brandWithArticle} personal loan?`}
            >
              {uppercaseBrandName} provides personal loans from £1000 up to
              £7500. Whether we&apos;re able to offer you a loan and, if so, the
              actual loan amount we can give you, will depend on our assessment
              of your application and your individual circumstances.
            </AccordionItem>

            <AccordionItem
              question={`How much will ${brandWithArticle} personal loan cost me each month?`}
            >
              Use our Instant Quote calculator to check the interest rate we may
              be able to offer you, and your possible monthly payments, if you
              borrow between £1000 and £7500. Please note, all figures shown in
              the Instant Quote calculator are estimates only. Whether
              we&apos;re able to offer you a loan and, if so, how much you can
              borrow and the interest rate, monthly payments and total amount
              payable will depend on our assessment of your application and your
              individual circumstances.
            </AccordionItem>

            <AccordionItem question="Can I save my personal loan application and come back later?">
              Yes, you can come back within 14 days of starting your application
              to complete it. After that, you&apos;ll need to start it again
              from the beginning. Keep in mind that if your circumstances change
              in the meantime, you may no longer be eligible for{' '}
              {brandWithArticle} personal loan.
            </AccordionItem>

            <AccordionItem question="How long will it take to finish my personal loan application?">
              Normally no more than ten minutes. You&apos;ll need to read the
              pre-contract information and your loan agreement carefully,
              though, to make sure you understand them before submitting your
              application. After you&apos;ve applied, sometimes you&apos;ll need
              to give us more information, or we may have to run other checks,
              before we can decide if we can offer you a loan. That decision may
              take up to three days from the date you send us the extra
              information or we tell you we&apos;re running other checks. Once
              your application is complete, the money will often be in your
              account within 15 minutes, and normally in no more than two hours.
              It may occasionally take up to 48 hours, though.
            </AccordionItem>

            <AccordionItem question="Will I need a bank account to apply for a personal loan?">
              Yes, you&apos;ll need to use a UK personal current account, and
              you must be a named person on that account. Your account will also
              need to be able to receive Faster Payments. If your loan is
              approved, that&apos;s where we&apos;ll send your money. Your
              monthly Direct Debit payments will also come out of the same
              account.
            </AccordionItem>

            <AccordionItem question="Will applying for a personal loan impact my credit score?">
              Checking your eligibility for {brandWithArticle} loan doesn&apos;t
              affect your credit score and won&apos;t be reported to the credit
              reference agencies (CRAs). If you&apos;re eligible and go on to
              make a full application to take out a loan, we&apos;re required to
              report the details of your application to the CRAs.
            </AccordionItem>

            <AccordionItem
              question={`Can I apply for another ${uppercaseBrandName} personal loan if I already have one?`}
            >
              Yes, as long as you&apos;re currently {brandWithArticle} card
              customer and have had a loan with us for at least nine months, you
              may be able to apply for another loan. You&apos;ll need to have
              kept up to date with your payments on both your loan and your
              credit card(s) to be eligible. If we think our personal loans
              might be suitable for you, you&apos;ll see a banner advertising
              them in the app – simply tap it to apply. You can&apos;t have more
              than two {uppercaseBrandName} personal loans running at the same
              time, and you&apos;ll need to go through the application process
              and pass our eligibility checks for each loan.
            </AccordionItem>

            <AccordionItem question="If I have to send extra documents to complete my personal loan application, how long will you take to review them?">
              If we need any extra documents to decide whether we can offer you{' '}
              {brandWithArticle} personal loan, we&apos;ll let you know when you
              apply. We&apos;ll also send you an email explaining which
              documents to provide and how to upload them. <br />
              <br />
              Once you send us your documents, we&apos;ll review them as quickly
              as possible during our normal working hours. You&apos;ll then
              receive an email with our decision – usually within three working
              days.
            </AccordionItem>

            <AccordionItem question="How do you decide whether I can have a personal loan?">
              We&apos;re committed to responsible lending, so it&apos;s
              important for us to make sure that {brandWithArticle} personal
              loan is right for you before we offer you a loan. This will
              include us checking that your loan repayments will be affordable
              for you, now and in the future. To do this, we&apos;ll look at
              things we already know about you as a card customer, as well as
              information provided by credit reference agencies, plus your
              income and monthly expenses. You&apos;ll also need to be a UK
              resident aged 18 or over, and to have had {brandWithArticle}{' '}
              credit card for six months or more
            </AccordionItem>

            <AccordionItem
              question={`If my application isn't successful, can I apply for ${brandWithArticle} personal loan again in the future?`}
            >
              Yes, if we think our personal loans may be suitable for you,
              you&apos;ll see a banner advertising them in the{' '}
              {uppercaseBrandName} app. Simply tap this to check your
              eligibility and apply.
            </AccordionItem>

            <AccordionItem question="Will you tell credit reference agencies (CRAs) that I've applied for a personal loan?">
              Checking your eligibility for {brandWithArticle} loan doesn&apos;t
              affect your credit score and won&apos;t be reported to the credit
              reference agencies (CRAs). If you&apos;re eligible and go on to
              make a full application to take out a loan, we&apos;re required to
              report the details of your application to the CRAs.
            </AccordionItem>

            <AccordionItem question="What if I change my mind after taking out a personal loan?">
              You can change your mind and withdraw from your loan agreement up
              to 14 days after the date when you receive a copy of your signed
              agreement. You&apos;ll need to contact Antelope Loans on 02920
              858791* or at customerenquiries@antelopeloans.com within this
              14-day period to confirm you want to withdraw from you loan
              agreement. You don&apos;t need to give a reason. You&apos;ll then
              have to repay the loan amount, along with any interest that has
              accrued up to the day you repay the money, within 30 days of the
              date you tell Antelope Loans that you want to withdraw from your
              loan agreement.
              <br />
              <br />* The Antelope Loans team is on hand to help from 8am -
              8.30pm Monday to Thursday, 9am - 5pm on Friday and 9am - 12.30pm
              on Saturday. Calls are charged at a standard national rate. Call
              costs from mobiles and other operators may vary, so please check
              before calling. Calls may be recorded for training and security
              purposes and to help us manage your account.
            </AccordionItem>
          </FaqSection>

          <FaqSection
            Icon={ProfileIcon}
            sectionTitle="About your personal loan"
          >
            <AccordionItem question="Who can I talk to if I have a question?">
              {uppercaseBrandName} personal loans are provided by NewDay in
              partnership with Antelope Loans Funding Limited. See &apos;who are
              NewDay and Antelope Loans?&apos; above for more information about
              this partnership and what it means for your personal loan account.{' '}
              <br /> <br />
              If you have any queries about your <b>application</b>, including
              if you&apos;re waiting for a decision or haven&apos;t yet received
              your loan, please contact <b>NewDay Ltd</b> on 03301 654877*.{' '}
              <br />
              <br />
              If you have any questions about your loan account{' '}
              <b>after your loan has been sent to you</b>, please contact{' '}
              <b>Antelope Loans</b> on 02920 858791* or at
              customerenquiries@antelopeloans.com.
              <br />
              <br />* NewDay&apos;s lines are open on Monday to Friday 9am to
              7pm and Saturday 9am to 5pm. The Antelope Loans team is on hand to
              help from 8am - 6pm Monday to Thursday, 9am - 5pm on Friday and
              9am - 12.30pm on Saturday. Calls are charged at a standard
              national rate. Call costs from mobiles and other operators may
              vary, so please check before calling. Calls may be recorded and
              monitored for training and security purposes and to help us manage
              your account.
            </AccordionItem>

            <AccordionItem question="How is interest calculated on my personal loan?">
              Interest is calculated on your outstanding balance daily, at the
              fixed interest rate shown in your credit agreement. It applies
              from the date your loan arrives in your bank account until the
              date you repay your loan in full.
              <br />
              <br />
              Interest will be applied to your loan account each month on your
              payment date. This means you may pay interest on interest.
            </AccordionItem>

            <AccordionItem question="How is my monthly payment amount calculated?">
              Your monthly payments are set at the same amount for the whole
              term of the loan – apart from the last payment, which may be
              adjusted. We work them out by taking the total amount you&apos;ll
              need to repay (that&apos;s the loan amount plus the total amount
              of interest you&apos;ll pay on the loan) and dividing it by the
              number of months you&apos;re paying it back across. When we do
              this, we assume you&apos;ll make your monthly payments in full and
              on time every month, and that you won&apos;t be repaying the loan
              before the end of the original term. So, if you pay more or less
              in any month, that could change your regular payment amounts
              afterwards.
            </AccordionItem>

            <AccordionItem question="What is a representative APR?">
              APR stands for Annual Percentage Rate, and it represents the
              yearly cost of the money borrowed, including interest and any
              upfront fees, expressed as a percentage of the loan amount. Where
              you see the &apos;representative APR&apos; for a loan advertised,
              it means that 51% of applicants get that rate or lower. The
              representative APR allows you to compare the possible yearly cost
              to you of different loan products.
            </AccordionItem>

            <AccordionItem question="How do I make my monthly payments?">
              Your payments will be collected automatically by Direct Debt each
              month, from the same bank account your loan amount is sent to.
              You&apos;ll be asked to provide details of that bank account
              during the application process. It will need to be a UK bank
              account that can receive Faster Payments, and you must be a named
              person on that account.
            </AccordionItem>
          </FaqSection>
        </Stack>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
