import { ReactNode } from 'react';
import { Box, Flex, FlexProps, useMultiStyleConfig } from '@newday/core';
import { ContentWrapper } from '../content-wrapper';

export interface BalloonBoxProps extends Omit<FlexProps, 'css'> {
  arrowHeight?: number;
  variant?: 'light' | 'dark' | 'generic';
  children: ReactNode;
  mb?: string;
  fullWidth?: boolean;
}

export const BalloonBox = ({
  arrowHeight = 25,
  variant = 'light',
  fullWidth,
  children,
  ...props
}: BalloonBoxProps) => {
  const styles = useMultiStyleConfig('BalloonBox', { variant });

  return (
    <Flex direction="column">
      <Flex __css={styles.wrapper} {...props}>
        <ContentWrapper
          maxContentWidth={fullWidth ? '5xl' : '3xl'}
          px={fullWidth ? { xl: 8 } : { xl: 0 }}
        >
          <Box width="full" mx="auto">
            {children}
          </Box>
        </ContentWrapper>
      </Flex>
      <Flex
        borderTopWidth={arrowHeight}
        borderRightWidth={arrowHeight}
        borderLeftWidth={arrowHeight}
        __css={styles.arrow}
      />
    </Flex>
  );
};
