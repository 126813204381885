export const getMockHtmlDocument = () =>
  `
    <!DOCTYPE html>
<html class="newdaytemplate" lang="en">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  </head>
  <body class="newdaytemplate">
    <document>
    <h1>hey!</h1>
    </document>
    </body>
  </html>
    `;

export const getMockPdfDocument = () => {
  return new Uint8Array([
    57, 49, 44, 49, 49, 49, 44, 57, 56, 44, 49, 48, 54, 44, 49, 48, 49, 44, 57,
    57, 44, 49, 49, 54, 44, 51, 50, 44, 54, 54, 44, 49, 48, 56, 44, 49, 49, 49,
    44, 57, 56, 44, 57, 51,
  ]);
};

export const getMockPdfDocumentBuffer = (data: string) => Buffer.from(data);
