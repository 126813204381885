import React, { createContext, useContext, useState } from 'react';

type SelectedAddressIdContextData = {
  selectedAddressId?: string;
  setSelectedAddressId: (selectedAddressId: string) => void;
};

const SelectedAddressIdContext = createContext<SelectedAddressIdContextData>(
  {} as SelectedAddressIdContextData
);

export const SelectedAddressIdProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedAddressId, setSelectedAddressId] = useState<
    string | undefined
  >();

  return (
    <SelectedAddressIdContext.Provider
      value={{ selectedAddressId, setSelectedAddressId }}
    >
      {children}
    </SelectedAddressIdContext.Provider>
  );
};

export const useSelectedAddressId = () => {
  return useContext(SelectedAddressIdContext);
};
