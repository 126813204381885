import { BffRoutes } from '@newday/plum-types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

const fetchToken =
  (token) =>
  ({ queryKey }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, __, applicationId, route] = queryKey;

    const generateTokenRoute = `${BffRoutes.generateToken}/?applicationId=${applicationId}&token=${token}`;

    const refreshTokenRoute = `${BffRoutes.refreshToken.replace(
      ':applicationId',
      applicationId
    )}`;

    const bffRoute =
      route === BffRoutes.generateToken
        ? generateTokenRoute
        : refreshTokenRoute;

    return axios
      .get(bffRoute, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response?.headers['bearer'];
      })
      .catch((e) => {
        console.error('Authentication Error', e);
      });
  };

export const useJWT = (
  applicationId: string,
  token: string,
  route: string
): UseQueryResult<string> => {
  return useQuery(['plum', 'token', applicationId, route], fetchToken(token), {
    enabled: !!applicationId && !!token && !!route,
  });
};
