import {
  Antelope,
  BrandLogo,
  Divider,
  Flex,
  HStack,
  Link,
  Stack,
  StackProps,
  Text,
} from '@newday/core';
import { FeRoutes } from '@newday/plum-types';
import { Link as RouterLink } from 'react-router-dom';
import { useBrand } from '../../app';
import { ContentWrapper } from '../content-wrapper';

export const Footer = (props: Omit<StackProps, 'css'>) => {
  const { brand, uppercaseBrandName } = useBrand();

  return (
    <Flex
      backgroundColor="brand.greyScale.200"
      w="full"
      justifyContent="center"
    >
      <ContentWrapper maxContentWidth="5xl">
        <Stack
          spacing={6}
          py={6}
          alignItems={['center', 'flex-start', 'flex-start']}
          {...props}
        >
          <Text fontWeight="semibold">
            {uppercaseBrandName} personal loans are provided by NewDay Ltd in
            partnership with Antelope Loans Funding Limited. Loans are only
            available to UK residents aged 18+. Subject to status. Terms apply.
            The APR, term and loan amount that apply to your loan agreement will
            depend on our assessment of your application.
          </Text>
          <HStack justify="space-between" w={['full', 'auto', 'auto']}>
            <Link
              fontWeight="bold"
              textDecoration="none"
              to={FeRoutes.faqs}
              as={RouterLink}
              isExternal
            >
              FAQs
            </Link>
            <Text fontWeight="bold" color="brand.primary">
              |
            </Text>
            <Link
              fontWeight="bold"
              textDecoration="none"
              to={FeRoutes.cookiePolicy}
              as={RouterLink}
              isExternal
            >
              Cookie policy
            </Link>
            <Text fontWeight="bold" color="brand.primary">
              |
            </Text>
            <Link
              fontWeight="bold"
              textDecoration="none"
              to={FeRoutes.privacy}
              as={RouterLink}
              isExternal
            >
              Privacy Notice
            </Link>
          </HStack>
          <Divider borderTopWidth="1px" borderTopColor="brand.greyScale.600" />
          <Flex justifyContent="center" alignItems="center">
            <BrandLogo brand={brand} />
            <Divider
              orientation="vertical"
              borderColor="black"
              height={12}
              mx={4}
            />
            <Antelope />
          </Flex>
          {brand !== 'fluid' ? (
            <Text fontSize="sm">
              Credit is provided by NewDay Ltd. NewDay Ltd and NewDay Cards Ltd
              are companies registered in England and Wales with registered
              numbers 7297722 and 4134880 respectively. They form part of the
              NewDay group of companies. The registered office for these
              companies is 7 Handyside Street, London, N1C 4DA. NewDay Ltd and
              NewDay Cards Ltd are authorised and regulated by the Financial
              Conduct Authority (FCA) with numbers 690292 and 682417
              respectively. NewDay Ltd is also authorised by the FCA under the
              Payment Services Regulations 2017 (ref no: 555318) for the
              provision of payment services. {uppercaseBrandName} is a trademark
              of NewDay Cards Ltd, which is used under licence by NewDay Ltd.
            </Text>
          ) : (
            <>
              <Text fontSize="sm">
                Credit is provided by NewDay Ltd. NewDay Ltd and NewDay Cards
                Ltd are registered in England and Wales (nos 7297722 and
                4134880, registered office 7 Handyside Street, London, N1C 4DA)
                and form part of the NewDay group of companies. NewDay Ltd and
                NewDay Cards Ltd are authorised and regulated by the Financial
                Conduct Authority (ref nos 690292 and 682417). NewDay Ltd is
                also authorised by the Financial Conduct Authority under the
                Payment Services Regulations 2017 for the provision of payment
                services (ref no 555318). Fluid is a trademark of TM Connect
                Limited and is used under licence by NewDay Ltd.
              </Text>
              <Text fontSize="sm">
                Fluid personal loans are provided by NewDay Ltd in partnership
                with Antelope Loans Funding Limited, a company registered in
                England and Wales, with registered number 13618625, that has its
                registered office at The Peak, 5 Wilton Road, London, England,
                SW1V 1AN. Fluid personal loans are managed by Antelope Loans on
                behalf of Antelope Loans Funding Limited. Antelope Loans is a
                trading name of Link Financial Outsourcing Limited, a company
                registered in England and Wales, with registered number 7059696,
                that has its registered office at The Peak, 2nd Floor, 5 Wilton
                Road, London, England, SW1V 1AN and is authorised and regulated
                by the Financial Conduct Authority, with number 606817.
              </Text>
            </>
          )}

          <Text fontSize="sm">
            If your application is successful, NewDay Ltd will send your loan
            amount to you. After this, it will transfer ownership of your loan
            to Antelope Loans Funding Limited, who will then be the lender in
            respect of your loan. Antelope Loans Funding Limited is a company
            registered in England, with registered number 13618625. Its
            registered office is at The Peak, 5 Wilton Road, London, England,
            SW1V 1AN.
          </Text>
          <Text fontSize="sm">
            Antelope Loans will manage your loan on behalf of Antelope Loans
            Funding Limited. Antelope Loans is a trading name of Link Financial
            Outsourcing Limited, a company registered in England, with
            registered number 7059696, that has its registered office at The
            Peak, 2nd Floor, 5 Wilton Road, London, England, SW1V 1AN and is
            authorised and regulated by the Financial Conduct Authority, firm
            reference number 606817.
          </Text>
        </Stack>
      </ContentWrapper>
    </Flex>
  );
};
