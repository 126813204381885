import {
  Divider,
  Flex,
  FlexProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Fragment, ReactElement } from 'react';
import { CSSObject } from '@emotion/react';
import { Step } from '../step';

export interface StepperProps extends FlexProps {
  activeStep?: number;
  steps: Array<{ label: string; icon?: ReactElement }>;
  variant?: 'primary' | 'secondary' | 'tertiary';
}

type StepStates = 'active' | 'completed' | 'inactive';

export const Stepper = (props: StepperProps): ReactElement => {
  const { activeStep = 1, steps, variant = 'primary', ...rest } = props;

  const stylesByState = useMultiStyleConfig('Stepper', { variant });

  const getStepState = (stepNumber: number): StepStates => {
    if (stepNumber === activeStep) return 'active';
    if (stepNumber < activeStep) return 'completed';

    return 'inactive';
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="row"
      {...rest}
      as="ol"
    >
      {steps.map(({ label, icon }, idx) => {
        const stepNumber = idx + 1;
        const isNotLastStep = stepNumber < steps.length;
        const state = getStepState(stepNumber);
        const styles = (stylesByState[state] || {}) as Record<
          string,
          CSSObject
        >;
        return (
          <Fragment key={label}>
            <Step
              index={stepNumber}
              label={label}
              state={state}
              variant={variant}
              icon={icon}
              style={{
                flexDirection: 'column',
                flex: isNotLastStep ? 1 : 'unset',
              }}
            />
            {isNotLastStep ? (
              <Divider __css={styles['divider']} aria-hidden="true" />
            ) : null}
          </Fragment>
        );
      })}
    </Flex>
  );
};
