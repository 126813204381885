/* 
  This file is omitted from jest coverage reports.

  This is because the logic here is covered by cypress tests
*/
import { Component } from 'react';
import { ErrorPage } from '../pages/error-page';

type ErrorProps = Error & {
  title: string;
  subtitle: string;
};
class ErrorBoundary extends Component {
  state: { hasError: boolean; error: ErrorProps };

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {} as ErrorProps };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  render() {
    if (this.state.hasError) {
      const {
        error: { title = '', subtitle = '' },
      } = this.state;

      return <ErrorPage title={title} subtitle={subtitle} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
