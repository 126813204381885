import {
  Button,
  Heading,
  LinkButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@newday/core';
import { ExclamationMarkIcon, PhoneIcon } from '@newday/icons';
import React from 'react';

import { getUpdateUserDetailsLink, useBrand } from '../../app';
import {
  ContentWrapper,
  HeaderStepper,
  Hero,
  ResponsivePageLayout,
} from '../../components';
import { UserDetails } from '../../features';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';
const ContactDetailsPage: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { brand, uppercaseBrandName } = useBrand();
  const updateUserDetailsLink = getUpdateUserDetailsLink(brand);
  const formName = FormNames.CONTACT_DETAILS;

  const handleNeedToUpdateClick = () => {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_name: formName,
      form_action: FormActions.CLICK_CTA,
      link_text: 'no I need to update my details',
    });
    onOpen();
  };

  const handleGoToMobileAppClick = () => {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_name: formName,
      form_action: FormActions.CLICK_CTA_POPUP,
      link_text: 'go to account settings',
    });
  };

  const handleCancelDetailsUpdateClick = () => {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_name: formName,
      form_action: FormActions.CLICK_CTA_POPUP,
      link_text: 'cancel',
    });
    onClose();
  };

  return (
    <ResponsivePageLayout headerStepper={<HeaderStepper activeStep={1} />}>
      <Hero
        icon={<PhoneIcon variant="primary" />}
        title="Are your contact details up to date?"
        subtitle="Please check we have the right information below."
        variant="dark"
      />
      <ContentWrapper>
        <VStack flexDirection="column" py={8} spacing={5}>
          <UserDetails handleNeedToUpdateClick={handleNeedToUpdateClick} />
          <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent textAlign="center" padding={2}>
              <ModalHeader>
                <ExclamationMarkIcon />
                <Heading mt={3}>
                  Update your contact details in your account settings
                </Heading>
              </ModalHeader>

              <ModalBody>
                <Text>
                  Once you’re done, head back to the {uppercaseBrandName} app’s
                  home screen and restart your eligibility check by tapping your
                  loan offer banner.
                </Text>
              </ModalBody>

              <ModalFooter>
                <VStack w="full" px={4}>
                  <LinkButton
                    onClick={handleGoToMobileAppClick}
                    href={updateUserDetailsLink}
                    w="full"
                  >
                    Go to account settings
                  </LinkButton>
                  <Button
                    variant="tertiary"
                    w="full"
                    onClick={handleCancelDetailsUpdateClick}
                  >
                    Cancel
                  </Button>
                </VStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
export { ContactDetailsPage };
