import React from 'react';

export const Marbles = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-testid="logo-marbles"
    width="112"
    height="26"
    viewBox="0 0 112 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="marbles-logo-label"
    role="img"
    {...props}
  >
    <title id="marbles-logo-label">Marbles logo</title>
    <path
      d="M4.99917 8.85261V6.98278H0V25.3742H4.99917V12.7065C4.99917 11.1037 5.26028 9.80662 6.86468 9.80662C8.50624 9.80662 8.76735 11.1037 8.76735 12.7065V25.3748H13.7665V12.7065C13.7665 11.1037 14.0276 9.80662 15.632 9.80662C17.2364 9.80662 17.4975 11.1037 17.4975 12.7065V25.3748H22.4967V10.6083C22.4967 8.08965 20.4452 6.60229 18.0576 6.60229C15.9682 6.60229 14.5877 7.32726 13.3188 8.96813C12.4983 7.47945 11.1178 6.60229 9.32674 6.60229C7.46187 6.60229 6.04353 7.21174 5.07357 8.85328H4.99917"
      fill="black"
    />
    <path
      d="M48.0541 6.98344H43.2788V25.3748H48.278V15.378C48.278 13.5462 48.3896 10.9514 52.7922 11.2184V6.60162C50.74 6.60162 49.0612 7.28858 48.1284 9.38737H48.054V6.98344"
      fill="black"
    />
    <path d="M77.4148 0H72.4163V25.3747H77.4148V0Z" fill="black" />
    <path
      d="M103.121 19.1931H98.159C97.7856 24.6118 100.845 25.7185 105.247 25.7185C109.389 25.7185 112 23.3139 112 20.147C112 14.2324 103.12 14.3472 103.12 11.4854C103.12 10.1877 103.717 9.50071 104.836 9.50071C105.396 9.50071 105.77 9.57747 106.105 9.92062C106.441 10.2644 106.627 11.0274 106.59 12.5154H111.551C111.888 8.24165 108.717 6.60077 105.321 6.60077C102.038 6.60077 98.1582 7.82174 98.1582 11.7524C98.1582 17.6663 107.038 16.7891 107.038 20.681C107.038 21.902 106.18 22.589 105.172 22.589C104.202 22.589 103.643 22.2458 103.382 21.7112C103.121 21.1017 103.046 20.3387 103.121 19.1931Z"
      fill="black"
    />
    <path
      d="M39.8454 25.3748H35.1823L34.9956 22.9336H34.9212C33.8762 24.8415 32.3841 25.7186 30.2203 25.7186C26.7504 25.7186 25.4822 23.0477 25.4822 19.8808C25.4822 13.814 30.0708 13.6231 34.7345 13.6611V12.1731C34.7345 10.6083 34.5478 9.50224 32.6824 9.50224C30.9292 9.50224 30.8169 10.7993 30.8169 12.326H25.8921C25.8921 10.0743 26.5638 8.62432 27.7576 7.82326C28.877 6.94544 30.5558 6.60229 32.3841 6.60229C38.8011 6.60229 39.6594 9.46414 39.6594 12.7832V21.9795C39.6594 23.047 39.771 24.23 39.8454 25.3748ZM32.1975 22.5897C35.1824 22.5897 34.7346 18.5063 34.7346 16.4461C32.1975 16.5983 30.332 16.4074 30.332 19.6511C30.332 20.9482 30.5931 22.5897 32.1975 22.5897Z"
      fill="black"
    />
    <path
      d="M60.0674 0.000732422V8.70037H60.1047C61.1118 7.13625 62.38 6.60224 64.1711 6.60224C69.2454 6.60224 69.0959 12.3259 69.0959 16.0649C69.0959 19.8807 69.3204 25.7186 64.0967 25.7186C62.1941 25.7186 60.7764 25.1085 59.8429 23.3147H59.8064V25.3748H55.0676V0.000732422H60.0674ZM62.0446 9.80714C59.9925 9.80714 60.0675 12.3258 60.0675 15.9887C60.0675 20.6056 60.4031 22.5903 62.0446 22.5903C63.9101 22.5903 64.1711 20.6056 64.1711 15.9887C64.1705 12.3253 64.2455 9.80714 62.0446 9.80714Z"
      fill="black"
    />
    <path
      d="M87.974 22.4369C89.7279 22.4369 90.175 20.6811 90.175 19.1932H95.1735C95.0997 21.1391 94.5032 22.78 93.2342 23.963C92.1141 25.1079 90.3237 25.718 87.9733 25.718C81.4813 25.718 80.6609 21.7119 80.6609 15.9115C80.6609 10.9515 81.3319 6.60162 87.9733 6.60162C94.7263 6.60162 95.5097 11.1037 95.3229 17.0182H85.6601C85.6608 19.1931 85.8102 22.4369 87.974 22.4369ZM87.974 9.80719C85.6608 9.80719 85.6608 12.5929 85.6608 14.1571H90.3245C90.3244 12.3253 90.3988 9.80719 87.974 9.80719Z"
      fill="black"
    />
  </svg>
);
