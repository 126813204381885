import type { Config } from '@newday/plum-types';

export const getMockConfig = (overrides?: Partial<Config>): Config => ({
  resources: {
    plum: {
      bffUri: 'http://localhost:9090',
      mouseflow: {
        aqua: '1',
        bip: '2',
        fluid: '3',
        marbles: '4',
        opus: '5',
        pulse: '6',
        pulsepersonal: '7',
      },
    },
  },
  ...overrides,
});
