import { TwoColorIcon, OneColorIcon } from '@newday/icons';
import components from '../theme/components';
import foundations from '../theme/foundations';

const NavigationButton = components.Button;

export default {
  ...foundations,
  components: {
    ...components,
    NavigationButton,
    TwoColorIcon,
    OneColorIcon,
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ThemeDict = Record<string, any>;
