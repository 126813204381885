import { useWatch } from 'react-hook-form';
import { Condition } from './form-conditions.types';

const useConditionsMatched = (conditions?: Condition[]) => {
  if (!conditions) return true;
  const watches = useWatch({
    name: conditions.map(({ question }) => question),
  });

  return conditions.every(({ values }, index) => {
    return values.includes(watches[index]);
  });
};

export { useConditionsMatched };
