import { useQuery } from 'react-query';
import axios from 'axios';

import { BffRoutes } from '@newday/plum-types';
import type { MocksResponse } from '@newday/plum-types';

export const useDevTool = () =>
  useQuery(['plum', 'mocks'], () =>
    axios.get<MocksResponse>(BffRoutes.mocks).then((response) => response.data)
  );
