import { Checkbox } from './checkbox';
import { Button } from './button';
import { Accordion } from './accordion';
import { Modal } from './modal';
import { Heading } from './heading';
import { Container } from './container';
import { Spinner } from './spinner';
import { RadioButton } from './radio-button';
import { Input } from './input';

export const components = {
  Accordion,
  Button,
  Checkbox,
  Modal,
  Heading,
  Container,
  Spinner,
  RadioButton,
  Input,
};
