import { Text } from '@newday/core';
import { TickBoxIcon } from '@newday/icons';
import React from 'react';
import { HeaderStepper, Hero, ResponsivePageLayout } from '../../components';
import { ApplyForYourLoan, QuoteLoanBreakdown } from '../../features';

export const QuoteEligiblePageComponent: React.FC = () => {
  return (
    <ResponsivePageLayout headerStepper={<HeaderStepper activeStep={3} />}>
      <Hero
        icon={<TickBoxIcon variant="primary" />}
        title="Good news! You’re eligible for a loan"
        subtitle="Here’s what we’d like to offer you:"
        variant="dark"
      >
        <>
          <QuoteLoanBreakdown />

          <Text
            fontSize="sm"
            fontWeight="semibold"
            lineHeight="shorter"
            color="white"
          >
            The offer is subject to final fraud, affordability and other checks.
          </Text>
        </>
      </Hero>

      <ApplyForYourLoan />
    </ResponsivePageLayout>
  );
};

export const QuoteEligiblePage = QuoteEligiblePageComponent;
