import React, { useEffect, useState } from 'react';
import { MisthoForm, ProviderList } from '@mistho/react';
import '@mistho/react/lib/styles/style.css';
import { Provider } from '@mistho/react/lib/browser';
import { Skeleton, Box, Text, Spinner } from '@newday/core';
import { useApplicationId } from '../../app';
import { useCreateMisthoTransaction } from '../../shared/queries';
import { useNavigate } from 'react-router-dom';
import { FeRoutes } from '@newday/plum-types';

export type ProofOfIncomeMisthoProps = {
  existingTransactionId: string | null;
};

export const ProofOfIncomeMistho = ({
  existingTransactionId,
}: ProofOfIncomeMisthoProps) => {
  const navigate = useNavigate();
  const { applicationId } = useApplicationId();
  const { mutate: createTransaction, isLoading: isCreateTransactionLoading } =
    useCreateMisthoTransaction(applicationId);
  const [transactionId, setTransactionId] = useState(existingTransactionId);
  const [provider, setProvider] = useState<Provider>();

  useEffect(() => {
    if (!transactionId) {
      createTransaction(undefined, {
        onSuccess: (data) => setTransactionId(data.transactionId),
      });
    }
  }, [createTransaction, transactionId]);

  return (
    <>
      <Box width="full" maxHeight="720px" overflowY="scroll">
        <Skeleton
          data-testid="mistho-sdk-skeleton"
          minHeight="150px"
          maxH="full"
          isLoaded={!isCreateTransactionLoading}
        >
          {transactionId && (
            <Box data-testid="mistho-sdk">
              {!provider ? (
                <ProviderList
                  transaction_id={transactionId}
                  withSearchBar
                  onClick={
                    /* istanbul ignore next */
                    (selectedProvider) => setProvider(selectedProvider)
                  }
                />
              ) : (
                /* istanbul ignore next */
                <MisthoForm
                  provider={provider}
                  transactionID={transactionId}
                  streamingDimensions={{ width: 100, height: 100 }}
                  renderProcessing={
                    /* istanbul ignore next */
                    () => (
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="center"
                        mt={4}
                      >
                        <Spinner />
                        <Text
                          textAlign="center"
                          fontSize="13px"
                          color="#393e46"
                          mt={4}
                        >
                          Loading...
                        </Text>
                      </Box>
                    )
                  }
                  onComplete={
                    /* istanbul ignore next */
                    () => navigate(FeRoutes.documentUploadSuccess)
                  }
                  onFail={
                    /* istanbul ignore next */
                    () => navigate(FeRoutes.documentUploadUnsuccess)
                  }
                  onError={
                    /* istanbul ignore next */
                    () => navigate(FeRoutes.documentUploadUnsuccess)
                  }
                />
              )}
            </Box>
          )}
        </Skeleton>
      </Box>
      <Text textAlign="center" fontSize="13px" color="#393e46" mt={4}>
        Mistho will share your income verification data with NewDay
      </Text>
    </>
  );
};
