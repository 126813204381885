import { Text } from '@newday/core';
import { MinPaymentIcon } from '@newday/icons';
import { HeaderStepper, Hero, ResponsivePageLayout } from '../../components';
import { ApplyForYourLoan, QuoteLoanBreakdown } from '../../features';

export const QuoteDownsellPageComponent = () => {
  return (
    <ResponsivePageLayout headerStepper={<HeaderStepper activeStep={3} />}>
      <Hero
        icon={<MinPaymentIcon variant="primary" />}
        title="Good news – you’re eligible for a loan"
        subtitle="However, based on what you told us in your application, we’re making you a different offer that we think should be affordable for you."
        variant="dark"
      >
        <>
          <Text fontWeight="semibold" color="white" mt={2} mb={4}>
            Here’s what you could borrow:
          </Text>
          <QuoteLoanBreakdown />
          <Text
            fontSize="sm"
            fontWeight="semibold"
            lineHeight="shorter"
            color="white"
            mt={4}
          >
            The offer is subject to final fraud, affordability and other checks.
          </Text>
        </>
      </Hero>

      <ApplyForYourLoan />
    </ResponsivePageLayout>
  );
};

export const QuoteDownsellPage = QuoteDownsellPageComponent;
