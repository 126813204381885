import React, { useEffect, useState } from 'react';
import { MisthoProvider } from '@mistho/react';
import { ContentWrapper, ResponsivePageLayout } from '../../components';
import { DocUploadHero } from './doc-upload-hero';
import { ProofOfIncomeDocuments, ProofOfIncomeMistho } from '../../features';
import { useApplicationId, useFeatureFlags } from '../../app';
import {
  Box,
  Button,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Skeleton,
  Text,
  Tooltip,
} from '@newday/core';
import { IncomeIcon, LiMarker, QuestionMarkIcon } from '@newday/icons';
import { useNavigate } from 'react-router-dom';
import { FeRoutes } from '@newday/plum-types';
import {
  useEmploymentStatus,
  useGetMisthoTransaction,
} from '../../shared/queries';
import { Events, gtmTrackEvent } from '../../utils/gtm-track-event';

export const LI = ({ children }) => (
  <ListItem fontSize="sm" display="flex" alignItems="baseline">
    <ListIcon as={LiMarker} width="7px" height="auto" />
    {children}
  </ListItem>
);

const UploadMethod = ({ children, title, buttonText, onClick }) => (
  <Box
    border="1px solid"
    borderColor="brand.primary"
    borderRadius="md"
    padding="1.25rem"
  >
    <Heading as="h2" color="brand.primary" mb={2}>
      {title}
    </Heading>
    <Box backgroundColor="white">
      <Text fontWeight="semibold" fontSize="sm" mb={2} color="brand.primary">
        How to do it
      </Text>
      {children}
      <Button
        mt={4}
        width="full"
        variant="tertiary"
        borderWidth="1px"
        _focus={{ borderWidth: '2px' }}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Box>
  </Box>
);

export const DocUploadPage = () => {
  const navigate = useNavigate();
  const { MISTHO } = useFeatureFlags();
  const { applicationId } = useApplicationId();

  const { data: employmentData, isLoading: isEmploymentLoading } =
    useEmploymentStatus(applicationId);
  const { data: misthoData, isLoading: isMisthoLoading } =
    useGetMisthoTransaction(applicationId, MISTHO);

  const [misthoFlowEnabled, setMisthoFlowEnabled] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<'mistho' | 'manual'>();
  const [isEmploymentStatusRetired, setIsEmploymentStatusRetired] =
    useState(false);

  useEffect(() => {
    if (misthoData?.misthoEnabled === false) {
      navigate(FeRoutes.documentUploadSuccess);
    }
  }, [misthoData, navigate]);

  useEffect(() => {
    setIsEmploymentStatusRetired(
      employmentData?.employmentStatus === 'retired'
    );
    setMisthoFlowEnabled(MISTHO && !isEmploymentStatusRetired);
  }, [MISTHO, employmentData, isEmploymentStatusRetired]);

  /* istanbul ignore next */
  const handleSelection = (
    mode: 'real_time_mistho' | 'manual_upload' | 'skip_upload'
  ) => {
    gtmTrackEvent({
      event: Events.INCOME_VERIFICATION_SELECTION,
      verification_mode: mode,
    });

    if (mode === 'skip_upload') {
      navigate(FeRoutes.documentUploadSkipped);
    } else if (mode === 'real_time_mistho') {
      setSelectedFlow('mistho');
    } else if (mode === 'manual_upload') {
      setSelectedFlow('manual');
    }
  };

  const handleMisthoSelection = () => {
    setSelectedFlow('mistho');
    handleSelection('real_time_mistho');
  };

  const handleManualSelection = () => {
    setSelectedFlow('manual');
    handleSelection('manual_upload');
  };

  const handleSkip = () => {
    handleSelection('skip_upload');
  };

  return (
    <ResponsivePageLayout>
      <DocUploadHero />
      <ContentWrapper py={8}>
        <Skeleton isLoaded={!isEmploymentLoading && !isMisthoLoading}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            w="full"
          >
            <IncomeIcon />
            <Heading as="h2" color="brand.primary" my={2}>
              Proof of income
            </Heading>
            {misthoFlowEnabled ? (
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                w="full"
              >
                {selectedFlow ? (
                  <Link
                    role="button"
                    onClick={() => setSelectedFlow(undefined)}
                    mb={4}
                    textDecorationColor="brand.tertiary"
                    textUnderlineOffset={1}
                    color="gray.800"
                  >
                    Select different upload method +
                  </Link>
                ) : (
                  <>
                    <Text fontSize="sm" mb={4}>
                      Select ONE of these options:
                    </Text>
                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      gap={4}
                      mt={{ base: 8, md: 4 }}
                      mb={{ md: 8 }}
                    >
                      <UploadMethod
                        title="Real-time income verification"
                        buttonText="Select instant verification"
                        onClick={handleMisthoSelection}
                      >
                        <List spacing={2} ml={0}>
                          <LI>
                            Find and select your your employer, payroll or
                            benefits provider platform
                          </LI>
                          <LI>Log in to your provider platform</LI>
                          <LI>
                            Consent to share your data
                            <Tooltip
                              hasArrow
                              fontSize="sm"
                              bg="brand.primary"
                              placement="right-end"
                              label="Please note: the information you provide is the same as you'd share in a manual document upload (like your National Insurance number, tax code or employment status). We don't store or share this data; we only use it to verify your income."
                            >
                              <span>
                                <QuestionMarkIcon
                                  ml="4px"
                                  height="18px"
                                  width="auto"
                                  alignSelf="center"
                                />
                              </span>
                            </Tooltip>
                          </LI>
                        </List>
                      </UploadMethod>
                      <UploadMethod
                        title="Manual document upload"
                        buttonText="Upload now"
                        onClick={handleManualSelection}
                      >
                        <List spacing={2} ml={0}>
                          <LI>
                            Make sure the name and address details exactly match
                            your loan application
                          </LI>
                          <LI>
                            Scan or photograph the whole of each page, then
                            attach and submit your documents
                          </LI>
                        </List>
                      </UploadMethod>
                    </SimpleGrid>
                  </>
                )}

                {selectedFlow === 'mistho' && (
                  <MisthoProvider>
                    <ProofOfIncomeMistho
                      existingTransactionId={misthoData?.transactionId}
                    />
                  </MisthoProvider>
                )}
                {selectedFlow === 'manual' && <ProofOfIncomeDocuments />}
              </Box>
            ) : (
              <ProofOfIncomeDocuments />
            )}
            <Link
              as="button"
              mt={8}
              color="brand.primary"
              textUnderlineOffset={1}
              textDecorationColor="brand.tertiary"
              onClick={handleSkip}
            >
              Skip and upload later
            </Link>
          </Box>
        </Skeleton>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
