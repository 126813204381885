import { StyleConfig } from '@chakra-ui/theme-tools';

const Button: StyleConfig = {
  variants: {
    primary: {
      _hover: {
        color: 'white',
        _disabled: {
          color: 'brand.primary',
        },
      },
    },
    secondary: {
      color: 'white',
    },
  },
};

export default Button;
