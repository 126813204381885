import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const AlertIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 16 16" __css={styles} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="currentColor"
      />
      <path
        d="M8.5 9V3.5C8.5 3.22386 8.27614 3 8 3C7.72386 3 7.5 3.22386 7.5 3.5V9C7.5 9.27614 7.72386 9.5 8 9.5C8.27614 9.5 8.5 9.27614 8.5 9Z"
        fill="white"
      />
      <path
        d="M7.25 11.25C7.25 11.6642 7.58579 12 8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5C7.58592 10.5002 7.25 10.8359 7.25 11.25Z"
        fill="white"
      />
    </Icon>
  );
};
