import { useApplicationId } from '../../app';
import { Breakdown } from '../../components';
import {
  formatCurrency as formatCurrencyWithoutDecimals,
  formatCurrencyToTwoDecimalPlaces,
  formatPercentageByDecimalPlaces,
} from '../../utils';
import { useEligibilityQuote } from './queries';

export const QuoteLoanBreakdown = () => {
  const { applicationId } = useApplicationId();

  const { data: eligibilityQuoteData } = useEligibilityQuote(applicationId);

  const { loanAmount, apr, term, monthlyPayment, totalAmount } =
    eligibilityQuoteData?.product || {};

  return (
    <Breakdown
      items={[
        {
          content: loanAmount
            ? formatCurrencyWithoutDecimals(loanAmount)
            : undefined,
          label: 'Loan amount',
        },
        {
          content: term ? `${term} years` : undefined,
          label: 'Loan term',
        },
        {
          content: apr
            ? `${formatPercentageByDecimalPlaces(apr)} pa (fixed)`
            : undefined,
          label: 'Interest rate',
        },
        {
          content: monthlyPayment
            ? formatCurrencyToTwoDecimalPlaces(monthlyPayment)
            : undefined,
          label: 'Monthly payment',
        },
        {
          content: totalAmount
            ? formatCurrencyToTwoDecimalPlaces(totalAmount)
            : undefined,
          label: 'Total amount payable',
        },
      ]}
    />
  );
};
