/* eslint "sonarjs/no-duplicate-string": "off" */
import { whiten } from '@chakra-ui/theme-tools';

export const BreakdownTheme = {
  parts: ['container', 'item', 'label', 'content'],
  variants: {
    default: {
      container: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'brand.primary',
        backgroundColor: 'brand.primary',
      },
      item: {
        backgroundColor: 'white',
        color: 'brand.primary',
      },
    },
    light: {
      container: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'brand.primary',
        backgroundColor: 'brand.primary',
      },
      item: {
        backgroundColor: whiten('brand.quaternary', 80),
        color: 'brand.primary',
      },
    },
    dark: {
      container: {
        backgroundColor: 'white',
      },
      item: {
        backgroundColor: 'brand.primary',
        color: 'white',
      },
    },
  },
  sizes: {
    md: {
      item: {
        padding: 3,
      },
      label: {
        mb: 1,
        fontSize: 'sm',
        fontWeight: 'semibold',
        lineHeight: 'short',
      },
      content: {
        fontSize: 'xl',
        fontWeight: 'bold',
        lineHeight: 'base',
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
};
