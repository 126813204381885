import type { Question } from '../form-question';
import type { Condition } from '../form-conditions';

export enum SectionStyles {
  default = 'default',
  alternate = 'alternate',
}

interface BaseSection {
  title?: string;
  sectionTitle?: string;
  description?: string;
  layout: SectionStyles;
}
export interface SubSection<QuestionIds = string, OptionValues = string>
  extends BaseSection {
  questions: Question<QuestionIds, OptionValues>[];
  conditions?: Condition<QuestionIds, OptionValues>[];
}

export interface Section<QuestionIds = string, OptionValues = string>
  extends BaseSection {
  items: SectionItem<QuestionIds, OptionValues>[];
  conditions?: Condition<QuestionIds, OptionValues>[];
}

type SectionItem<QuestionIds, OptionValues> =
  | SubSection<QuestionIds, OptionValues>
  | Question<QuestionIds, OptionValues>;

export type SectionGroup<QuestionIds, OptionValues> =
  | SubSection<QuestionIds, OptionValues>
  | Section<QuestionIds, OptionValues>;

export type Sections<
  QuestionIds = string,
  OptionValues = string
> = SectionGroup<QuestionIds, OptionValues>[];
