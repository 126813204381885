import { HStack, Text, StackProps } from '../index';
import { AlertIcon } from '@newday/icons';
import { ReactElement } from 'react';
import { IconProps } from '@chakra-ui/icons';
export interface ValidationMessageProps extends StackProps {
  message: string;
  iconSize?: 'xs' | 'sm' | 'md';
  iconOffset?: IconProps['mt'];
}

export const ValidationMessage = ({
  message,
  color = 'red.600',
  iconSize = 'sm',
  iconOffset = '0px',
  ...props
}: ValidationMessageProps): ReactElement => {
  return (
    <HStack
      role="alert"
      aria-live="assertive"
      spacing="0.5rem"
      {...props}
      alignItems={'start'}
    >
      <AlertIcon color={color} size={iconSize} mt={iconOffset} />
      <Text fontSize="sm" color={color}>
        {message}
      </Text>
    </HStack>
  );
};
