import { whiten } from '@chakra-ui/theme-tools';

export const BalloonBoxTheme = {
  baseStyle: {
    wrapper: {
      p: '2rem',
    },
    arrow: {
      alignSelf: 'center',
      borderRightColor: 'transparent',
      borderBottomWidth: 0,
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      borderStyle: 'solid',
      height: 0,
      width: 0,
    },
  },
  variants: {
    light: {
      wrapper: {
        bg: whiten('brand.quaternary', 80),
      },
      arrow: {
        borderTopColor: whiten('brand.quaternary', 80),
      },
    },
    dark: {
      wrapper: {
        bg: 'brand.primary',
      },
      arrow: {
        borderTopColor: 'brand.primary',
      },
    },
    generic: {
      wrapper: {
        bg: 'brand.greyScale.200',
      },
      arrow: {
        borderTopColor: 'brand.greyScale.200',
      },
    },
  },
  defaultProps: {
    variant: 'light',
  },
};
