import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { TwoColorIconProps } from '../icon';

export const PageIcon = ({
  size,
  variant,
  color,
  ...props
}: TwoColorIconProps) => {
  const styles = useIconStyleConfig('TwoColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 60 60" __css={styles} {...props}>
      <path
        d="M29.7269 58.1754C45.2655 58.1754 57.862 45.5788 57.862 30.0402C57.862 14.5016 45.2655 1.90503 29.7269 1.90503C14.1882 1.90503 1.59167 14.5016 1.59167 30.0402C1.59167 45.5788 14.1882 58.1754 29.7269 58.1754Z"
        fill="white"
      />
      <path
        d="M29.7269 2.71025C14.633 2.71025 2.39693 14.9463 2.39693 30.0402C2.39693 45.1341 14.633 57.3702 29.7269 57.3702C44.8208 57.3702 57.0569 45.1341 57.0569 30.0402C57.0569 14.9463 44.8208 2.71025 29.7269 2.71025ZM0.786499 30.0402C0.786499 14.0569 13.7436 1.09982 29.7269 1.09982C45.7102 1.09982 58.6673 14.0569 58.6673 30.0402C58.6673 46.0236 45.7102 58.9806 29.7269 58.9806C13.7436 58.9806 0.786499 46.0236 0.786499 30.0402Z"
        fill="currentColor"
      />
      <path d="M17.7676 42.4737V16.2266L19.6006 14.6772H34.1381L34.4218 20.8834L35.1245 21.874L36.2548 22.1534H41.7583L41.898 43.7437L41.1952 45.4371L39.7813 46.2838L19.8843 46.1441L18.1909 45.0137L17.7676 42.4737Z" />
      <path
        d="M34.3372 16.3993C34.7819 16.3993 35.1424 16.7598 35.1424 17.2045V20.3669C35.1424 20.6563 35.2574 20.9339 35.4621 21.1386C35.6667 21.3433 35.9443 21.4582 36.2338 21.4582H39.3919C39.8366 21.4582 40.1971 21.8187 40.1971 22.2634C40.1971 22.7082 39.8366 23.0687 39.3919 23.0687H36.2338C35.5172 23.0687 34.83 22.784 34.3233 22.2773C33.8166 21.7706 33.532 21.0834 33.532 20.3669V17.2045C33.532 16.7598 33.8925 16.3993 34.3372 16.3993Z"
        fill="currentColor"
      />
      <path
        d="M18.0607 14.8518C18.6881 14.2244 19.539 13.872 20.4262 13.872H34.5551C35.4374 13.8742 36.283 14.2249 36.9079 14.8477L41.7741 19.6884C42.3968 20.3134 42.7475 21.159 42.7498 22.0413L42.7498 22.0433L42.7498 43.7607C42.7498 44.6479 42.3973 45.4988 41.77 46.1261C41.1426 46.7535 40.2917 47.1059 39.4045 47.1059H20.4389C19.5517 47.1059 18.7008 46.7535 18.0734 46.1261C17.4461 45.4988 17.0936 44.6479 17.0936 43.7607V42.4906C17.0936 42.0459 17.4541 41.6854 17.8988 41.6854C18.3436 41.6854 18.7041 42.0459 18.7041 42.4906V43.7607C18.7041 44.2208 18.8868 44.662 19.2122 44.9874C19.5375 45.3127 19.9788 45.4955 20.4389 45.4955H39.4045C39.8646 45.4955 40.3059 45.3127 40.6312 44.9874C40.9566 44.662 41.1394 44.2208 41.1394 43.7607L41.1394 22.0454C41.1394 22.0452 41.1394 22.045 41.1394 22.0448C41.138 21.5879 40.9566 21.15 40.6343 20.8262C40.634 20.8259 40.6337 20.8255 40.6334 20.8252L35.7712 15.9884C35.4473 15.6656 35.0091 15.4838 34.5519 15.4824H20.4262C19.9661 15.4824 19.5248 15.6652 19.1995 15.9905C18.8741 16.3159 18.6914 16.7571 18.6914 17.2172V32.377C18.6914 32.8218 18.3309 33.1823 17.8861 33.1823C17.4414 33.1823 17.0809 32.8218 17.0809 32.377V17.2172C17.0809 16.33 17.4334 15.4791 18.0607 14.8518Z"
        fill="currentColor"
      />
      <path
        d="M22.1313 28.3638C22.1313 27.9191 22.4919 27.5586 22.9366 27.5586H36.9068C37.3515 27.5586 37.712 27.9191 37.712 28.3638C37.712 28.8085 37.3515 29.169 36.9068 29.169H22.9366C22.4919 29.169 22.1313 28.8085 22.1313 28.3638Z"
        fill="currentColor"
      />
      <path
        d="M22.1313 25.1168C22.1313 24.6721 22.4919 24.3116 22.9366 24.3116H36.9068C37.3515 24.3116 37.712 24.6721 37.712 25.1168C37.712 25.5615 37.3515 25.922 36.9068 25.922H22.9366C22.4919 25.922 22.1313 25.5615 22.1313 25.1168Z"
        fill="currentColor"
      />
      <path
        d="M22.1821 31.7505C22.1821 31.3058 22.5426 30.9453 22.9873 30.9453H36.9576C37.4023 30.9453 37.7628 31.3058 37.7628 31.7505C37.7628 32.1952 37.4023 32.5557 36.9576 32.5557H22.9873C22.5426 32.5557 22.1821 32.1952 22.1821 31.7505Z"
        fill="currentColor"
      />
      <path
        d="M34.012 35.0961C34.0136 35.5408 33.6545 35.9027 33.2098 35.9043L22.9903 35.9424C22.5456 35.9441 22.1838 35.5849 22.1821 35.1402C22.1805 34.6955 22.5396 34.3337 22.9843 34.332L33.2038 34.2939C33.6485 34.2923 34.0103 34.6514 34.012 35.0961Z"
        fill="currentColor"
      />
    </Icon>
  );
};
