import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@newday/core';
import { LatePaymentIcon } from '@newday/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ContentWrapper,
  HeaderStepper,
  Hero,
  Loading,
  ResponsivePageLayout,
} from '../../components';

import { BankDetailsAndDirectDebitForm } from '../../components/';
import { DirectDebitGuarantee } from './direct-debit-guarantee';

import {
  BankDetailsResponse,
  BankDetailsResponseWithRemainingAttempts,
  BankDetailStatuses,
  FeRoutes,
} from '@newday/plum-types';
import { useApplicationId } from '../../app';
import { DirectDebitGuaranteeIcon } from '../../icons';
import { useRedirect } from '../../shared/queries';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';
import { useBankDetails } from './queries';

const isApplicationSuccessful = ({ status }: BankDetailsResponse) =>
  status === BankDetailStatuses.success;

const isReferRequired = ({ status }: BankDetailsResponse) =>
  status === BankDetailStatuses.referGeneral;

const isRetryBankValidationResponse = (
  response: BankDetailsResponse
): response is BankDetailsResponseWithRemainingAttempts =>
  response.status === BankDetailStatuses.retryBankValidation &&
  (response as BankDetailsResponseWithRemainingAttempts).remainingAttempts !==
    undefined;

const getReferralResponseMap = () => ({
  [BankDetailStatuses.referGeneral]: FeRoutes.referGeneral,
});

export const getValidationErrorMessage = (
  response: BankDetailsResponseWithRemainingAttempts
) => {
  return response.remainingAttempts === 1
    ? "We can't validate these details. This is your last attempt, so please enter a different bank to continue your application."
    : "We can't validate these details. Please check and try again";
};

export const BankDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { applicationId } = useApplicationId();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { data: redirectResponse, isSuccess: redirectSuccess } = useRedirect(
    applicationId,
    shouldRedirect,
    'bank-details-redirect'
  );
  const {
    mutate,
    data: bankDetailsResponse,
    isLoading: isLoadingBankDetails,
  } = useBankDetails();
  const [errorMessage, setErrorMessage] = useState('');

  const referralResponseMap = getReferralResponseMap();

  useEffect(() => {
    if (redirectSuccess && redirectResponse) {
      gtmTrackEvent({
        event: Events.FORM_CTA,
        form_name: FormNames.BANK_DETAILS,
        form_action: FormActions.SUBMIT_FORM,
        link_text: 'bank validation failed, referral required',
      });
      navigate(redirectResponse.data.redirect);
    }
  }, [redirectResponse, redirectSuccess, navigate]);

  useEffect(() => {
    if (bankDetailsResponse?.status === BankDetailStatuses.redirect) {
      setShouldRedirect(true);
    }
  }, [bankDetailsResponse]);

  useEffect(() => {
    if (!bankDetailsResponse) {
      return;
    }

    if (isApplicationSuccessful(bankDetailsResponse)) {
      gtmTrackEvent({
        event: Events.FORM_CTA,
        form_name: FormNames.BANK_DETAILS,
        form_action: FormActions.SUBMIT_FORM,
        link_text: 'add my loan payment details',
      });
      navigate(FeRoutes.successfulApplication);
    }

    if (isRetryBankValidationResponse(bankDetailsResponse)) {
      gtmTrackEvent({
        event: Events.FORM_ERROR,
        form_name: FormNames.BANK_DETAILS,
        form_errorMessage: getValidationErrorMessage(bankDetailsResponse),
      });
      setErrorMessage(getValidationErrorMessage(bankDetailsResponse));
    }

    if (isReferRequired(bankDetailsResponse)) {
      gtmTrackEvent({
        event: Events.FORM_CTA,
        form_name: FormNames.BANK_DETAILS,
        form_action: FormActions.SUBMIT_FORM,
        link_text: 'bank validation failed, referral required',
      });
      navigate(referralResponseMap[bankDetailsResponse.status]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankDetailsResponse]);

  const handleSubmit = (values) => {
    mutate({
      applicationId,
      sortCode: values['sort-code'].replace(/-/g, ''),
      accountNumber: values['account-number'],
    });
  };

  return (
    <ResponsivePageLayout headerStepper={<HeaderStepper activeStep={4} />}>
      <Hero
        icon={<LatePaymentIcon variant="primary" />}
        title="Set up your loan payment details"
        subtitle="Choose the bank account where you’d like to receive your loan. To protect you against fraud, we’ll collect your monthly repayments from the same account by Direct Debit."
        variant="dark"
      />
      <ContentWrapper py={8}>
        <BankDetailsAndDirectDebitForm
          onSubmit={handleSubmit}
          bankDetailsInvalid={Boolean(errorMessage)}
          bankDetailsInvalidMessage={errorMessage}
        >
          <Flex my={4} justifyContent="space-between">
            <DirectDebitGuaranteeIcon />
            <Text
              as="button"
              type="button"
              onClick={onOpen}
              textAlign={'left'}
              fontSize="sm"
              fontWeight="bold"
              pb={1}
              width={'max-content'}
              borderBottomColor="brand.tertiary"
              borderBottomWidth="2px"
            >
              View Direct Debit Guarantee
            </Text>
          </Flex>
        </BankDetailsAndDirectDebitForm>
      </ContentWrapper>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backgroundColor="rgba(0,0,0,0.9)" />
        <ModalContent
          padding={2}
          borderRadius="none"
          maxWidth="full"
          w={{ base: 'xs', md: '2xl' }}
        >
          <ModalHeader>
            <ModalCloseButton
              backgroundColor="brand.primary"
              color="white"
              padding={6}
              borderRadius={0}
              top={0}
              right={0}
              _hover={{
                backgroundColor: 'brand.primary',
              }}
            />
          </ModalHeader>
          <ModalBody>
            <DirectDebitGuarantee />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Loading
        isLoading={isLoadingBankDetails}
        title="Please wait while we process your payment details..."
        secondsToLoad={20}
      />
    </ResponsivePageLayout>
  );
};
