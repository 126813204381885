/* istanbul ignore file */
/* eslint-disable */
import type { PlumSupportedBrand } from '@newday/plum-types';

type BrandGTMCodes = {
  [brand in Exclude<PlumSupportedBrand, 'bip'>]: string;
};

export const brandGTMCodes: BrandGTMCodes = {
  aqua: 'GTM-T459S4Q',
  fluid: 'GTM-NLD4XX6',
  marbles: 'GTM-K654V5P',
  opus: 'GTM-5LP83GL',
  pulse: 'GTM-KQNB8PG8',
  pulsepersonal: 'GTM-M8CGRZM2',
};

export const setupGtm = (brand: PlumSupportedBrand) => {
  const scriptElm = window.document.createElement('script');
  scriptElm.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${brandGTMCodes[brand]}');
  `;

  window.document.head.insertBefore(
    scriptElm,
    window.document.head.childNodes[0]
  );
};
