import type { ComponentStyleConfig } from '@chakra-ui/react';
import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const focusStyles: SystemStyleObject = {
  borderColor: 'brand.primary.500',
  borderWidth: '2px',
  borderStyle: 'solid',
  boxShadow: 'none',
};

export const Button: ComponentStyleConfig = {
  defaultProps: {
    size: 'md',
  },
  sizes: {
    md: {
      height: '3rem',
    },
  },
  baseStyle: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '150%',
    paddingY: '0.75rem',
    paddingX: '1rem',
    width: '100%',
    color: 'brand.primary.500',
    _hover: focusStyles,
  },
  variants: {
    outline: {
      color: 'brand.primary.500',
      backgroundColor: 'white',
      borderColor: 'brand.primary.500',
      _hover: { backgroundColor: 'brand.primary.100' },
      _focus: focusStyles,
    },
  },
};
