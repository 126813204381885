export enum RiskGroup {
  high = 'High',
  low = 'Low',
}

export type AprAndRiskGroup = {
  apr: number;
  riskGroup: RiskGroup;
};

export interface LoanDetails {
  loanTerm: number;
  loanAmount: number;
}
