import axios from 'axios';
import { useQuery } from 'react-query';

import { BrandName } from '@newday/core';
import { AprAndRiskGroup, BffRoutes } from '@newday/plum-types';
import { useBrand } from '../../app';

type QueryKey = [string, string, string];
interface FetchParams {
  queryKey: QueryKey;
}

const fetchAprAndRiskGroup =
  (brand: BrandName) =>
  ({ queryKey }: FetchParams): Promise<AprAndRiskGroup> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, __, applicationId] = queryKey;
    return axios
      .get(
        `${BffRoutes.aprAndRiskGroup
          .replace(':applicationId', applicationId)
          .replace(':brand', brand)}`
      )
      .then((response) => response.data);
  };

export const useAprAndRiskGroup = (applicationId: string) => {
  const { brand } = useBrand();

  return useQuery<AprAndRiskGroup, unknown, AprAndRiskGroup, QueryKey>(
    ['plum', 'apr-lookup', applicationId],
    fetchAprAndRiskGroup(brand)
  );
};
