import { BffRoutes } from '@newday/plum-types';
import axios from 'axios';
import { QueryCache, useQuery } from 'react-query';

const queryCache = new QueryCache();

const fetchRefreshToken =
  (applicationId: string) => /* istanbul ignore next */ () => {
    return axios.get(
      BffRoutes.refreshToken.replace(':applicationId', applicationId)
    );
  };

export const useRefreshToken = (applicationId: string, enabled) => {
  return useQuery(
    ['plum', 'refreshToken', applicationId],
    fetchRefreshToken(applicationId),
    {
      enabled,
      cacheTime: 0,
      retry: false,
      onSuccess: /* istanbul ignore next */ () => {
        // This is tested via cypress, not jest
        queryCache.find(['plum', 'refreshToken'])?.destroy();
      },
    }
  );
};
