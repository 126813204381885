import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { TwoColorIconProps } from '../icon';

export const LiMarker = ({
  size,
  variant,
  color,
  ...props
}: TwoColorIconProps) => {
  const styles = useIconStyleConfig('TwoColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 7 7" __css={styles} {...props}>
      <path
        d="M7 3.5C7 5.433 5.433 7 3.5 7C1.567 7 0 5.433 0 3.5C0 1.567 1.567 0 3.5 0C5.433 0 7 1.567 7 3.5Z"
        fill="brand.tertiary"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.16C4.96908 6.16 6.16 4.96908 6.16 3.5C6.16 2.03092 4.96908 0.84 3.5 0.84C2.03092 0.84 0.84 2.03092 0.84 3.5C0.84 4.96908 2.03092 6.16 3.5 6.16ZM3.5 7C5.433 7 7 5.433 7 3.5C7 1.567 5.433 0 3.5 0C1.567 0 0 1.567 0 3.5C0 5.433 1.567 7 3.5 7Z"
        fill="currentColor"
      />
    </Icon>
  );
};
