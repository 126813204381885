import { BffRoutes } from '@newday/plum-types';
import axios from 'axios';
import { useQuery } from 'react-query';

export const useInitialRoute = (
  applicationId: string,
  enabled: boolean,
  key: string,
  currentPath?: string
) =>
  useQuery(
    [key, applicationId],
    () =>
      axios.get(
        `${BffRoutes.initialRoute
          .replace(':applicationId', applicationId)
          .replace(':currentPath?', currentPath ? btoa(currentPath) : '')}`
      ),
    { enabled, cacheTime: 0 }
  );
