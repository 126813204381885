export const colors = {
  brand: {
    primary: {
      50: '#E7E6E9',
      100: '#85808F',
      500: '#0A001E',
      700: '#05000F',
    },
    secondary: {
      50: '#F3FCE6',
      100: '#C4F080',
      500: '#89E100',
      700: '#457100',
    },
    tertiary: {
      50: '#F2EEFE',
      100: '#C0A8F9',
      500: '#8050F2',
      700: '#402879',
    },
    quaternary: {
      50: '#FAFAFA',
      100: '#E8E8E8',
      500: '#D0D0D0',
      700: '#686868',
    },
    greyScale: {
      50: '#F7FAFC',
      100: '#F1F0F2',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
      1000: '#000000',
    },
  },
};
