/* eslint "sonarjs/no-duplicate-string": "off" */
import { ComponentStyleConfig } from '@chakra-ui/react';

const StepperCurrencyInput: ComponentStyleConfig = {
  parts: ['button', 'input', 'container', 'buttonIcon', 'currencyIcon'],
  baseStyle: () => {
    const button = {
      backgroundColor: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'brand.primary',
      color: 'brand.primary',
      _focus: {
        backgroundColor: 'brand.primary',
        borderWidth: '1px',
        borderColor: 'brand.primary',
        color: 'white',
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    };
    return {
      field: {
        py: '0.75rem',
        paddingLeft: '2rem',
        height: '2.375rem',
        fontWeight: 700,
        _invalid: {
          backgroundColor: 'red.50',
        },
        _disabled: {
          opacity: 0.5,
        },
      },
      container: {
        position: 'relative',
        width: '100%',
        bg: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'brand.primary',
      },
      currencyIcon: {
        position: 'absolute',
        top: '0.75rem',
        left: '0.75rem',
        width: '14px',
      },
      buttonLeft: {
        ...button,
        borderRight: 'transparent',
        borderLeftRadius: 5,
      },
      buttonRight: {
        ...button,
        borderLeft: 'transparent',
        borderRightRadius: 5,
      },
      buttonIcon: {
        color: 'inherit',
      },
    };
  },
  variants: {
    _invalid: () => {
      const button = {
        backgroundColor: 'red.600',
        borderColor: 'red.600',
        color: 'white',
      };
      return {
        container: {
          borderColor: 'red.600',
        },
        buttonLeft: {
          ...button,
        },
        buttonRight: {
          ...button,
        },
      };
    },
    _disabled: {
      container: {
        opacity: 0.5,
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: 0,
      },
      buttonLeft: {
        px: '0.875rem',
      },
      buttonRight: {
        px: '0.875rem',
      },
      currencyIcon: {
        width: '14px',
        height: '14px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default StepperCurrencyInput;
