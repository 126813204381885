import React, { useCallback, useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
  HStack,
  Text,
  useDisclosure,
} from '@newday/core';
import { ExclamationMarkIcon } from '@newday/icons';

export function withBackButtonAlert<T>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName ||
    WrappedComponent.name ||
    'WithBackButtonAlert';

  const ComponentWithTheme = (props: T) => {
    const cancelButtonRef = useRef<HTMLButtonElement>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const closeTab = () => {
      window.open('about:blank', '_self');
      window.close();
    };

    const onBackButtonEvent = useCallback(
      (e: Event) => {
        e.preventDefault();
        onOpen();
        window.history.pushState(null, '', window.location.pathname);
      },
      [onOpen]
    );

    useEffect(() => {
      window.history.pushState(null, '', window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    }, [onBackButtonEvent]);

    return (
      <>
        <WrappedComponent {...props} />
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelButtonRef}
          onClose={onClose}
          size="sm"
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              borderWidth="1px"
              borderColor="brand.primary"
              textAlign="center"
              p={6}
            >
              <AlertDialogHeader p={0}>
                <ExclamationMarkIcon />
                <Heading mt={3}>End loan application</Heading>
              </AlertDialogHeader>

              <Text my={5} px={6}>
                The details you’ve entered will be lost if you continue.
              </Text>

              <AlertDialogFooter p={0}>
                <HStack w="full" px={4}>
                  <Button variant="tertiary" w="full" onClick={closeTab}>
                    Continue
                  </Button>
                  <Button w="full" onClick={onClose} ref={cancelButtonRef}>
                    Cancel
                  </Button>
                </HStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  };

  ComponentWithTheme.displayName = `withTheme(${displayName})`;

  return ComponentWithTheme;
}
