import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, Box, Text, ValidationMessage } from '@newday/core';

const DirectDebitCheckbox = () => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="direct-debit"
      defaultValue=""
      rules={{ required: true }}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { invalid },
      }) => {
        const field = { name, value, onChange, onBlur };
        return (
          <>
            <Text mb={4} fontSize="sm">
              Instruction to your Bank or Building society. Please pay Antelope
              Loans Funding Limited Direct Debits from the account detailed in
              this Instruction subject to the safeguards assured by the Direct
              Debit guarantee. I understand that this instruction may remain
              with Antelope Loans Funding Limited and, if so, details will be
              passed electronically to my Bank/Building Society.
            </Text>
            <Box p={5} bg={invalid ? 'red.600' : 'brand.primary'} color="white">
              <Checkbox
                size="sm"
                {...field}
                isInvalid={invalid}
                alignItems="flex-start"
              >
                By ticking this box, I am authorising Antelope Loans Funding
                Limited to take the payment by Direct Debit.
              </Checkbox>
            </Box>
            {invalid && (
              <ValidationMessage
                mt={3}
                message="Please click the checkbox to continue"
              />
            )}
          </>
        );
      }}
    />
  );
};

export { DirectDebitCheckbox };
