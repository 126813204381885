import colors from './foundations/colors';
import Button from './components/button';

export default {
  colors,
  radii: {
    brand: {
      field: '0.3125rem',
      cta: '0.375rem',
    },
  },
  components: {
    Button,
  },
};
