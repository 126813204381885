import { ComponentStyleConfig } from '@chakra-ui/react';
import { orient } from '@chakra-ui/theme-tools';

const Slider: ComponentStyleConfig = {
  baseStyle: {
    track: {
      backgroundColor: 'grey.400',
    },
    thumb: {
      backgroundColor: 'brand.tertiary',
    },
    filledTrack: {
      backgroundColor: 'brand.tertiary',
    },
  },
  sizes: {
    md: (props) => {
      return {
        thumb: { w: '25px', h: '25px' },
        track: {
          borderRadius: 'full',
          ...orient({
            orientation: props.orientation,
            horizontal: { h: '8px' },
            vertical: { w: '8px' },
          }),
        },
      };
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default Slider;
