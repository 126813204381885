import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const ListIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 16 16" __css={styles} {...props}>
      <path
        d="M1.5 16C0.672667 16 0 15.3273 0 14.5V1.5C0 0.672667 0.672667 0 1.5 0H14.5C15.3273 0 16 0.672667 16 1.5V14.5C16 15.3273 15.3273 16 14.5 16H1.5ZM1.5 1C1.224 1 1 1.224 1 1.5V14.5C1 14.776 1.224 15 1.5 15H14.5C14.776 15 15 14.776 15 14.5V1.5C15 1.224 14.776 1 14.5 1H1.5Z"
        fill="currentColor"
      />
      <path
        d="M5 7.5C4.86667 7.5 4.74067 7.448 4.64667 7.35333L2.64667 5.35333C2.552 5.25933 2.5 5.13333 2.5 5C2.5 4.86667 2.552 4.74067 2.64667 4.64667C2.74067 4.552 2.86667 4.5 3 4.5C3.13333 4.5 3.25933 4.552 3.35333 4.64667L4.946 6.23867L7.6 2.7C7.694 2.57467 7.844 2.5 8 2.5C8.10867 2.5 8.21267 2.53467 8.3 2.6C8.40667 2.68 8.476 2.79733 8.49467 2.92933C8.51333 3.06133 8.48 3.19333 8.4 3.3L5.4 7.3C5.31267 7.416 5.18 7.48867 5.03533 7.49867C5.02333 7.49933 5.01133 7.5 5 7.5Z"
        fill="currentColor"
      />
      <path
        d="M9.5 6C9.224 6 9 5.776 9 5.5C9 5.224 9.224 5 9.5 5H12.5C12.776 5 13 5.224 13 5.5C13 5.776 12.776 6 12.5 6H9.5Z"
        fill="currentColor"
      />
      <path
        d="M4.99999 13.5C4.86666 13.5 4.74066 13.448 4.64666 13.3533L2.64666 11.3533C2.45199 11.1587 2.45199 10.8413 2.64666 10.646C2.74132 10.5513 2.86666 10.4993 2.99999 10.4993C3.13332 10.4993 3.25932 10.5513 3.35332 10.646L4.94599 12.2387L7.59999 8.69934C7.69399 8.57401 7.84399 8.49934 8.00066 8.49934C8.10932 8.49934 8.21332 8.53401 8.29999 8.59934C8.52066 8.76468 8.56532 9.07868 8.39999 9.29934L5.39999 13.2993C5.31266 13.4153 5.17999 13.488 5.03532 13.498C5.02532 13.4993 5.01266 13.5 4.99999 13.5Z"
        fill="currentColor"
      />
      <path
        d="M9.5 12C9.224 12 9 11.776 9 11.5C9 11.224 9.224 11 9.5 11H12.5C12.776 11 13 11.224 13 11.5C13 11.776 12.776 12 12.5 12H9.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};
