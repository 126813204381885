import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@newday/core';
import { ThumbsUpIcon } from '@newday/icons';
import { FeRoutes } from '@newday/plum-types';
import { AdvicePanel } from '../advice-panel/advice-panel';

export const FaqPanel: React.FC = () => (
  <AdvicePanel
    icon={<ThumbsUpIcon />}
    title="Access help and support"
    description={
      <>
        Visit our{' '}
        <Link
          fontWeight="semibold"
          color="black"
          to={FeRoutes.faqs}
          as={RouterLink}
          isExternal
        >
          frequently asked questions
        </Link>{' '}
        page to get answers on popular topics.
      </>
    }
  />
);
