import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const CircledDownArrowIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 25 25" __css={styles} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 23.9583C18.8283 23.9583 23.9583 18.8283 23.9583 12.5C23.9583 6.17174 18.8283 1.04167 12.5 1.04167C6.17174 1.04167 1.04167 6.17174 1.04167 12.5C1.04167 18.8283 6.17174 23.9583 12.5 23.9583ZM25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4953 14.1554L16.8524 9.72183L18.25 11.2121L12.469 17.0946L6.83921 11.1481L8.26322 9.68524L12.4953 14.1554Z"
        fill="currentColor"
      />
    </Icon>
  );
};
