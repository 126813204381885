export const colors = {
  brand: {
    primary: {
      50: '#FAF5FF',
      100: '#E9D8FD',
      500: '#805AD5',
      700: '#553C9A',
    },
    secondary: {
      50: '#FFFAF0',
      100: '#FEEBC8',
      500: '#DD6B20',
      700: '#9C4221',
    },
    tertiary: {
      50: '#E6FFFA',
      100: '#B2F5EA',
      500: '#319795',
      default: '#319795',
      700: '#285E61',
    },
    quaternary: {
      50: '#EBF8FF',
      100: '#BEE3F8',
      500: '#3182CE',
      700: '#2C5282',
    },
    greyScale: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
      1000: '#000000',
    },
  },
};
