import React, { createContext, useState, useContext } from 'react';

type LoanDetails = {
  durationYears: number;
  amount: number;
};

type LoanDetailsContextData = {
  loanDetails: LoanDetails;
  setLoanDetails: (loanDetails: Partial<LoanDetails>) => void;
};

const LoanDetailsContext = createContext<LoanDetailsContextData>(
  {} as LoanDetailsContextData
);

export const LoanDetailsProvider: React.FC = ({ children }) => {
  const [loanDetails, setLoanDetailsState] = useState<LoanDetails>({
    durationYears: 4,
    amount: 5000,
  });

  const setLoanDetails = (ld: Partial<LoanDetails>) => {
    const nextState: LoanDetails = Object.entries(ld).reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      { ...loanDetails }
    );
    setLoanDetailsState(nextState);
  };

  return (
    <LoanDetailsContext.Provider value={{ loanDetails, setLoanDetails }}>
      {children}
    </LoanDetailsContext.Provider>
  );
};

export const useLoanDetails = () => {
  return useContext(LoanDetailsContext);
};
