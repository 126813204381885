import { whiten } from '@chakra-ui/theme-tools';
import {
  Button,
  Select,
  Skeleton,
  Stack,
  Text,
  useTheme,
  ValidationMessage,
  VStack,
} from '@newday/core';
import { FeRoutes } from '@newday/plum-types';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplicationId, useSelectedAddressId } from '../../app';
import { Loading } from '../../components';
import { useUserDetails } from '../../shared/queries';
import { capitalizeFirstLetter } from '../../utils';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';
import { useUpdateAddress } from './queries';

type ContactFieldInformationProps = {
  bgColor: string;
  label: string;
  value?: React.ReactNode;
  isLoaded?: boolean;
};
const ContactFieldInformation = ({
  bgColor,
  label,
  value = 'Any value to get the right height',
  isLoaded = true,
}: ContactFieldInformationProps) => (
  <VStack w="full" align="flex-start" p={2} spacing={1} bgColor={bgColor}>
    <Text fontSize="xs" lineHeight="normal" fontWeight="semibold">
      {label}
    </Text>
    <Skeleton w="full" data-testid="skeleton" isLoaded={isLoaded}>
      {value}
    </Skeleton>
  </VStack>
);

const UserDetailsTextWrapper = ({ value }: { value: string }) => (
  <Text fontWeight="semibold" data-mf-replace="*****">
    {value}
  </Text>
);

const contactCopy =
  "You'll receive emails to let you know when statements, notices and other important communications are ready for you to view in your online account. Plus, we may send other essential information by email. So please check that the email address above is correct and let us know if it changes.";
const confirmButtonLabel = 'Yes, all my details are correct';

const UserDetails = ({
  handleNeedToUpdateClick,
}: {
  handleNeedToUpdateClick?: () => void;
}) => {
  const { applicationId } = useApplicationId();
  const { selectedAddressId, setSelectedAddressId } = useSelectedAddressId();
  const [errorMessage, setErrorMessage] = useState('');
  const requiredFields = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (errorMessage && selectedAddressId) {
      setErrorMessage('');
    }
  }, [errorMessage, selectedAddressId]);

  const {
    mutate: updateAddress,
    isSuccess: updateAddressSuccess,
    isLoading: isUpdateAddressLoading,
  } = useUpdateAddress(applicationId);

  useEffect(() => {
    if (updateAddressSuccess) {
      navigate(FeRoutes.eligibility);
    }
  }, [updateAddressSuccess, navigate]);

  const {
    data: userDetails,
    isSuccess,
    isLoading,
  } = useUserDetails(applicationId);

  const theme = useTheme();
  const darkBgColor = 'brand.greyScale.200';
  const lightBgColor = whiten(darkBgColor, 50)(theme);

  const confirmUserDetails = () => {
    if (selectedAddressId) {
      setErrorMessage('');
      updateAddress(selectedAddressId, {
        onSuccess: () =>
          gtmTrackEvent({
            event: Events.FORM_CTA,
            form_name: FormNames.CONTACT_DETAILS,
            form_action: FormActions.SUBMIT_FORM,
            link_text: 'yes all my details are correct',
          }),
      });
    } else {
      setErrorMessage('This step is required');
      /* ignoring coverage of else as nothing should happen on that path */
      /* istanbul ignore else */
      if (requiredFields.current) {
        requiredFields.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  if (isSuccess && userDetails) {
    return (
      <>
        <VStack w="full" spacing="px">
          <ContactFieldInformation
            bgColor={darkBgColor}
            label="Full name"
            value={
              <UserDetailsTextWrapper
                value={`${capitalizeFirstLetter(userDetails.title)} ${
                  userDetails.fullName
                }`}
              />
            }
          />
          <ContactFieldInformation
            bgColor={lightBgColor}
            label="Date of birth"
            value={<UserDetailsTextWrapper value={userDetails.dateOfBirth} />}
          />
          <ContactFieldInformation
            bgColor={darkBgColor}
            label="Email address"
            value={<UserDetailsTextWrapper value={userDetails.emailAddress} />}
          />
          <ContactFieldInformation
            bgColor={lightBgColor}
            label="Mobile number"
            value={<UserDetailsTextWrapper value={userDetails.mobileNumber} />}
          />

          <ContactFieldInformation
            bgColor={darkBgColor}
            label={`Current address: ${userDetails.postCode}`}
            value={
              <div ref={requiredFields}>
                <Select
                  borderWidth={errorMessage ? '2px' : '1px'}
                  borderColor={errorMessage ? 'red.600' : 'brand.quaternary'}
                  bg={errorMessage ? 'red.50' : 'white'}
                  borderRadius="md"
                  _focus={{
                    border: '2px',
                    borderColor: errorMessage ? 'red.600' : 'brand.primary',
                  }}
                  aria-label="Please select your address"
                  size="md"
                  placeholder="Please select your address"
                  onChange={(e) => {
                    setSelectedAddressId(e.target.value);
                  }}
                >
                  {userDetails.addresses.map(({ id, address }) => (
                    <option key={id} value={id} data-mf-replace="*****">
                      {address}
                    </option>
                  ))}
                </Select>
                {errorMessage && (
                  <ValidationMessage mt={2} message={errorMessage} />
                )}
              </div>
            }
          />
        </VStack>
        <Text my={5}>{contactCopy}</Text>
        <Stack direction={{ base: 'column', md: 'row' }} w="full">
          <Button w="full" onClick={confirmUserDetails}>
            {confirmButtonLabel}
          </Button>
          {handleNeedToUpdateClick && (
            <Button
              w="full"
              variant="tertiary"
              onClick={handleNeedToUpdateClick}
            >
              No, my details need updating
            </Button>
          )}
        </Stack>
        <Loading
          isLoading={isUpdateAddressLoading}
          title="We’re updating your address..."
          secondsToLoad={20}
        />
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <VStack w="full" spacing="px">
          <ContactFieldInformation
            bgColor={darkBgColor}
            label="Full name"
            isLoaded={false}
          />
          <ContactFieldInformation
            bgColor={lightBgColor}
            label="Date of birth"
            isLoaded={false}
          />
          <ContactFieldInformation
            bgColor={darkBgColor}
            label="Email address"
            isLoaded={false}
          />
          <ContactFieldInformation
            bgColor={lightBgColor}
            label="Mobile number"
            isLoaded={false}
          />
          <ContactFieldInformation
            bgColor={darkBgColor}
            label="Current address"
            isLoaded={false}
          />
        </VStack>
        <Text my={5}>{contactCopy}</Text>
        <Stack direction={{ base: 'column', md: 'row' }} w="full">
          <Button w="full" disabled>
            {confirmButtonLabel}
          </Button>
          <Button w="full" variant="tertiary" disabled>
            No, my details need updating
          </Button>
        </Stack>
      </>
    );
  }

  return null;
};

export { UserDetails };
