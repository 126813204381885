import {
  DrawerCloseButton as CloseButton,
  CloseButtonProps,
  forwardRef,
} from '@chakra-ui/react';

export const DrawerCloseButton = forwardRef<CloseButtonProps, 'button'>(
  (props, ref) => (
    <CloseButton
      ref={ref}
      borderRadius="radii.primary"
      bgColor="brand.primary"
      _hover={{ backgroundColor: 'brand.primary' }}
      color="white"
      {...props}
    />
  )
);
