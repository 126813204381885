import { ButtonProps } from './../../button/button';
import { SystemStyleObject } from '@chakra-ui/react';
/* eslint-disable sonarjs/no-duplicate-string */
import { blacken, whiten, transparentize } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  fontWeight: 600,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'transparent',
  cursor: 'pointer',
  textDecoration: 'none',
  textAlign: 'center',
};

type ButtonVariants = NonNullable<ButtonProps['variant']>;
const variants: Record<ButtonVariants, SystemStyleObject> = {
  primary: {
    color: 'brand.primary',
    background: 'brand.secondary',
    borderRadius: 'brand.cta',
    _hover: {
      borderColor: 'brand.primary',
      background: blacken('brand.secondary', 10),
      _disabled: {
        borderColor: 'transparent',
        background: transparentize('brand.secondary', 50),
      },
    },
    _focus: {
      borderColor: 'brand.primary',
      boxShadow: 'none',
    },
  },
  secondary: {
    color: 'brand.primary',
    background: 'brand.tertiary',
    borderRadius: 'brand.cta',
    _hover: {
      borderColor: 'brand.primary',
      background: blacken('brand.tertiary', 10),
      _disabled: {
        borderColor: 'transparent',
        background: transparentize('brand.tertiary', 50),
      },
    },
    _focus: {
      borderColor: 'brand.primary',
      boxShadow: 'none',
    },
  },
  tertiary: {
    color: 'brand.primary',
    background: 'white',
    borderRadius: 'brand.cta',
    borderColor: 'brand.primary',
    _hover: {
      color: 'brand.primary',
      background: whiten('brand.primary', 90),
    },
    _focus: {
      boxShadow: 'none',
    },
  },
  'tertiary-dark-bg': {
    color: 'white',
    background: 'transparent',
    borderRadius: 'brand.cta',
    borderColor: 'white',
    _hover: {
      color: 'white',
      background: 'brand.primary',
    },
    _focus: {
      color: 'white',
      background: 'brand.primary',
      boxShadow: 'none',
    },
  },
};

const sizes: Record<string, SystemStyleObject> = {
  xl: {
    py: 3,
    px: 4,
  },
};

const defaultProps = {
  variant: 'primary',
  size: 'xl',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
