import { FeatureToggles } from '@newday/plum-types';
import React, { createContext, useContext } from 'react';
import { useFeatureTogglesQuery } from '../shared/queries/feature-toggles';

const FeatureFlagsContext = createContext<FeatureToggles>({} as FeatureToggles);

export const FeatureFlagsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: featureTogglesData, isLoading } = useFeatureTogglesQuery();

  return isLoading ? null : (
    <FeatureFlagsContext.Provider value={featureTogglesData as FeatureToggles}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
