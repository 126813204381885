export const landingPageBrandContent = ({
  brandWithArticle,
  uppercaseBrandName,
}: {
  brandWithArticle: string;
  uppercaseBrandName: string;
}) => {
  const sharedContent = [
    {
      id: 'faq-1',
      buttonLabel: 'Will applying for a personal loan impact my credit score?',
      content: `Checking your eligibility for ${brandWithArticle} loan doesn't affect your credit score and won't be reported to the credit reference agencies (CRAs). If you're eligible and go on to make a full application to take out a loan, we're required to report the details of your application to the CRAs.`,
    },
    {
      id: 'faq-2',
      buttonLabel: `How much could I borrow with ${brandWithArticle} personal loan?`,
      content: `${uppercaseBrandName} provides personal loans from £1000 up to £7500. Whether we're able to offer you a loan and, if so, the actual loan amount we can give you, will depend on our assessment of your application and your individual circumstances.`,
    },
    {
      id: 'faq-3',
      buttonLabel: 'Who are NewDay and Antelope Loans?',
      content: `NewDay Ltd is providing your ${uppercaseBrandName} personal loan in partnership with Antelope Loans Funding Limited.

    NewDay Ltd is one of the UK's leading consumer credit providers – it issues your ${uppercaseBrandName} credit card.

    NewDay Ltd will manage your application for ${brandWithArticle} personal loan. If your application is successful, your loan agreement will be signed by NewDay Ltd and it will send your loan amount to you. After this, NewDay Ltd will transfer ownership of your loan account to Antelope Loans Funding Limited, who will then be the lender in respect of your loan and collect your monthly Direct Debit payments. NewDay Ltd will send you an email to let you know that ownership of your loan account has been transferred.

    Antelope Loans (a trading style of Link Financial Outsourcing Limited) will manage your loan account on behalf of Antelope Loans Funding Limited. Antelope Loans will send you a copy of your signed loan agreement to keep for your records. It will send you all further communications relating to your loan account. Antelope Loans will also be your point of contact if you have any queries about your loan account.

    Antelope Loans is an established presence in the credit world, managing millions of customers’ accounts across various European countries. It provides customer service with the human touch, backed up by state-of-the art technology.`,
    },
    {
      id: 'faq-4',
      buttonLabel: 'What is a representative APR?',
      content: `APR stands for Annual Percentage Rate, and it represents the yearly cost of the money borrowed, including interest and any upfront fees, expressed as a percentage of the loan amount. Where you see the 'representative APR' for a loan advertised, it means that 51% of applicants get that rate or lower. The representative APR allows you to compare the possible yearly cost to you of different loan products.`,
    },
  ];

  return {
    default: {
      faq: sharedContent,
    },
  };
};
