import { ComponentStyleConfig } from '@chakra-ui/react';

const Banner: ComponentStyleConfig = {
  parts: ['title', 'description'],
  // Styles for the base style
  baseStyle: {
    card: {
      width: '100%',
      padding: '4',
      backgroundColor: 'white',
      borderRadius: 'md',
      mb: '4',
    },
    title: {
      color: 'brand.primary',
      fontWeight: 'semibold',
      overflow: 'auto',
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
};

export default Banner;
