enum FeRoutes {
  bankDetails = '/bank-details',
  cookiePolicy = '/cookie-policy',
  contactDetails = '/contact-details',
  disbursementFailed = '/application-cancelled',
  documentUpload = '/upload',
  documentUploadSuccess = '/upload-success',
  documentUploadSkipped = '/upload-skipped',
  documentUploadUnsuccess = '/upload/unsuccess',
  eligibility = '/affordability-and-expenditure',
  eligibilityTerms = '/terms-and-conditions',
  errorPageComplexRefer = '/error/complex-refer',
  faqs = '/faqs',
  hardCheckIneligible = '/ineligible',
  landingPage = '/landing-page',
  privacy = '/privacy-notice',
  quoteCounterOffer = '/offer/counter-offer',
  quoteEligible = '/offer/eligible',
  quoteIneligible = '/offer/not-eligible',
  referBankAndIANDE = '/additional-information/bank-and-income',
  referBankDetails = '/additional-information/bank',
  referGeneral = '/additional-information',
  referIANDE = '/additional-information/income',
  returnApplicationCompleted = '/return/application-completed',
  returnApplicationDeclined = '/return/additional-information/declined',
  returnBankDetailsDeclined = '/return/additional-information/bank-declined',
  returnReferBankAndIANDE = '/return/additional-information/bank-and-income',
  returnReferGeneral = '/return/additional-information',
  returnReferIANDE = '/return/additional-information/income',
  saveAndReturn = '/login',
  successfulApplication = '/application-complete',
}

enum FeRouteTitles {
  bankDetails = 'Hard Quote Submit | Bank Details',
  contactDetails = 'Contact Details',
  cookiePolicy = 'Cookies',
  disbursementFailed = 'Application | Cancelled',
  documentUpload = 'Document Upload',
  documentUploadSuccess = 'Document Upload | Success',
  documentUploadSkipped = 'Document Upload | Skipped',
  documentUploadUnsuccess = 'Document Upload | Unsuccess',
  eligibility = 'Affordability and Expenditure',
  eligibilityTerms = 'Eligibility Terms and Conditions',
  errorPageComplexRefer = 'Error | Complex refer',
  faqs = 'Frequently Asked Questions',
  hardCheckIneligible = 'Application | Declined',
  landingPage = 'Apply for Personal Loan | Landing Page',
  quoteDownsell = 'Soft Quote Decision | Counter',
  quoteEligible = 'Soft Quote Decision | Eligible',
  quoteIneligible = 'Soft Quote Decision | Not Eligible',
  referBankAndIANDE = '/bank-and-i-and-e-refer',
  referBankDetails = 'Bank Details | Refer',
  referGeneral = 'General | Refer',
  referIANDE = '/i-and-e-refer',
  returnApplicationCompleted = 'Save + Return | Application Complete',
  returnApplicationDeclined = 'Save + Return | Declined',
  returnBankDetailsDeclined = '/return-bank-details-declined',
  returnReferBankAndIANDE = '/return-bank-and-i-and-e-refer',
  returnReferGeneral = '/return-general-refer',
  returnReferIANDE = '/return-i-and-e-refer',
  saveAndReturn = 'Save + Return | Login',
  successfulApplication = 'Application | Complete',
  privacy = 'Privacy Notice',
}

enum BffRoutes {
  affordability = '/affordability/:applicationId',
  applicationReference = '/application-reference/:applicationId',
  applyAndSign = '/apply-and-sign/:applicationId',
  aprAndRiskGroup = '/apr-and-risk-group/:applicationId/:brand',
  bankDetails = '/bank-details/:applicationId',
  content = '/content/:brandName',
  document = '/application/:applicationId/document/:documentType',
  eligibility = '/eligibility/:applicationId',
  employmentStatus = '/employment-status/:applicationId',
  featureToggles = '/feature-toggles',
  generateToken = '/generate-token',
  hashedApplicationId = '/hashed-application-id/:applicationId',
  healthcheck = '/healthcheck',
  heartbeat = '/heartbeat',
  htmlDocuments = '/application/:applicationId/documents',
  initialRoute = '/initial-route/:applicationId/:currentPath?',
  loanOffer = '/loan-offer/:applicationId',
  mistho = '/mistho/:applicationId',
  mocks = '/mocks',
  proof = '/application/:applicationId/proof',
  proofList = '/application/:applicationId/proofList',
  redirect = '/redirect/:applicationId/:fallback?',
  refreshToken = '/refresh-token/:applicationId',
  repaymentInformation = '/repayment-information/:applicationId',
  root = '/',
  saveAndReturn = '/save-and-return',
  updateAddress = '/update-address/:applicationId',
  userDetails = '/user-details/:applicationId',
  version = '/version',
}

type Methods = 'get' | 'post' | 'put' | 'delete';

type ProtectedBffRoutes = {
  [key in BffRoutes]?: Methods[];
};

const protectedBffRoutesMap: ProtectedBffRoutes = {
  [BffRoutes.affordability]: ['get'],
  [BffRoutes.applyAndSign]: ['get'],
  [BffRoutes.aprAndRiskGroup]: ['get'],
  [BffRoutes.bankDetails]: ['post'],
  [BffRoutes.document]: ['get'],
  [BffRoutes.mistho]: ['post', 'get'],
  [BffRoutes.eligibility]: ['get', 'post'],
  [BffRoutes.employmentStatus]: ['get'],
  [BffRoutes.htmlDocuments]: ['get'],
  [BffRoutes.loanOffer]: ['get'],
  [BffRoutes.proof]: ['post', 'put', 'delete'],
  [BffRoutes.proofList]: ['get', 'delete'],
  [BffRoutes.redirect]: ['get'],
  [BffRoutes.refreshToken]: ['get'],
  [BffRoutes.repaymentInformation]: ['get'],
  [BffRoutes.initialRoute]: ['get'],
  [BffRoutes.updateAddress]: ['post'],
  [BffRoutes.userDetails]: ['get'],
};

const protectedBffRoutes = Object.entries(protectedBffRoutesMap)
  .map(([route, methods]) =>
    methods.map((method) => {
      return { route, method };
    })
  )
  .flat();

export { FeRoutes, FeRouteTitles, BffRoutes, protectedBffRoutes };
