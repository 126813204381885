import { BffRoutes } from '@newday/plum-types';
import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { DateFormat } from '../../components';

export const useSaveAndReturn = (applicationId: string) => {
  return useMutation(
    ({
      postcode,
      dateOfBirth: { year, month, day },
    }: {
      postcode: string;
      dateOfBirth: DateFormat;
    }) => {
      return axios.post(`${BffRoutes.saveAndReturn}`, {
        applicationId,
        postcode,
        dateOfBirth: `${year}-${month}-${day}`,
      });
    },
    {
      useErrorBoundary: (error: AxiosError) => error?.response?.status !== 401,
    }
  );
};
