import React from 'react';
import { useApplicationId, useLoanDetails } from '../../app';
import { LoanBreakdown as LoanBreakdownComponent } from '../../components';
import type { LoanBreakdownProps as LoanBreakdownComponentProps } from '../../components';
import { useAprAndRiskGroup } from '../../shared/queries';

interface LoanBreakdownProps
  extends Pick<LoanBreakdownComponentProps, 'verbose' | 'variant' | 'layout'> {
  isLoaded?: boolean;
}

const LoanBreakdown: React.FC<LoanBreakdownProps> = ({
  verbose = false,
  variant,
  layout,
}) => {
  const {
    loanDetails: { amount, durationYears },
  } = useLoanDetails();

  const { applicationId } = useApplicationId();

  const { data: aprAndRiskGroup, isSuccess } =
    useAprAndRiskGroup(applicationId);

  if (isSuccess && aprAndRiskGroup) {
    return (
      <LoanBreakdownComponent
        apr={aprAndRiskGroup.apr}
        durationYears={durationYears}
        loanAmount={amount}
        variant={variant}
        verbose={verbose}
        layout={layout}
      />
    );
  }

  return (
    <LoanBreakdownComponent
      apr={10} // any apr just to calculate skeleton height
      durationYears={durationYears}
      isLoaded={false}
      loanAmount={amount}
      variant={variant}
      verbose={verbose}
      layout={layout}
    />
  );
};

export { LoanBreakdown };
