import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
} from './accordion';
import Banner from './banner';
import Button from './button';
import Checkbox from './checkbox';
import Input from './input';
import Heading from './heading';
import Link from './link';
import RadioButton from './radio-button';
import Themebox from './themebox';
import Slider from './slider';
import Step from './step';
import Stepper from './stepper';
import StepperCurrencyInput from './stepper-currency-input';
import Card from './card';

const NumberInput = Input;
export default {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  Banner,
  Button,
  Card,
  Checkbox,
  Input,
  NumberInput,
  Heading,
  Link,
  RadioButton,
  Themebox,
  Slider,
  Step,
  Stepper,
  StepperCurrencyInput,
};
