import React, { useEffect } from 'react';
import { useBrand } from './brand-provider';

const FontLoader = ({ children }: { children: React.ReactNode }) => {
  const { brand } = useBrand();

  useEffect(() => {
    import(`../styles/brands/${brand}/index.js`).catch(() => {
      console.error('failed to load fonts');
    });
  }, [brand]);

  return <>{children}</>;
};

export { FontLoader };
