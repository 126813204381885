import { Accordion } from './accordion';
import { Container } from './container';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { Heading } from './heading';
import { Modal } from './modal';
import { Spinner } from './spinner';

export const components = {
  Accordion,
  Container,
  Button,
  Checkbox,
  Heading,
  Modal,
  Spinner,
};
