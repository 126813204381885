import React from 'react';
import { Text, Box, UnorderedList, ListItem } from '@newday/core';

export const BoostCreditScoreTipsList: React.FC = () => (
  <Box color="black">
    <Text>Here are a few tips to help you look your best for lenders:</Text>
    <UnorderedList ml="2rem">
      <ListItem>
        Pay your bills and credit card or loan payments on time
      </ListItem>
      <ListItem>Stay inside your credit limit</ListItem>
      <ListItem>
        Avoid using your credit card for cash transactions, if possible
      </ListItem>
      <ListItem>Try not to apply to several lenders at the same time</ListItem>
      <ListItem>Avoid getting County Court Judgements</ListItem>
    </UnorderedList>
  </Box>
);
