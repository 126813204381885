import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';
import { components } from './components';

export default {
  styles: {
    global: {
      'html, body': {
        color: 'black',
        lineHeight: '1.625rem',
        letterSpacing: '0.3px',
        fontSize: '15px',
      },
    },
  },
  colors,
  fonts,
  radii: {
    brand: {
      field: '1.8125rem',
      cta: '1.8125rem',
    },
  },
  lineHeights: {
    11: '2.75rem',
    12: '3.625rem',
    13: '4.5rem',
  },
  space: {
    7.5: '1.875rem',
    15: '3.75rem',
  },
  components,
};
