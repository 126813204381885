import { Text } from '@newday/core';
import {
  ContentWrapper,
  Hero,
  ReferStepper,
  ResponsivePageLayout,
} from '../../components';
import { ApplicationReference } from '../../features';

export const ReturnGeneralReferPage: React.FC = () => {
  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <Hero
        title="We’re reviewing your loan application at the moment"
        fullWidth
      />
      <ContentWrapper py={4} maxContentWidth="5xl">
        <ReferStepper activeStep={1} skipDocumentUploadedStep />
        <Text mb={6} mt={4}>
          Once we’ve finished our checks, we’ll be able to decide if we can
          offer you a loan. This normally takes around{' '}
          <strong>three working days</strong>.
        </Text>
        <Text>
          Watch out for an email from us confirming our decision, or check this
          page for updates.
        </Text>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
