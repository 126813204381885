import { ComponentStyleConfig } from '@chakra-ui/react';

const OneColorIcon: ComponentStyleConfig = {
  variants: {
    primary: {
      color: 'brand.primary',
    },
  },
  sizes: {
    xs: {
      width: 4,
      height: 4,
    },
    sm: {
      width: 6,
      height: 6,
    },
    md: {
      width: 10,
      height: 10,
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
};

export { OneColorIcon };
