import { Box, Icon, IconProps } from '@newday/core';

const PlumIcon = (props: IconProps) => (
  <Box>
    <Icon as="button" viewBox="0 0 12 12" transform="scale(0.06)" {...props}>
      <path
        fill="#702963"
        d="M327.701,121.992c-26.364,0-50.882,8.548-71.596,23.111c-20.716-14.563-45.234-23.111-71.597-23.111
	c-70.43,0-128.161,60.181-133.516,136.623c2.707-0.282,5.453-0.429,8.235-0.429c43.505,0,78.774,35.268,78.774,78.775
	c0,34.238-21.85,63.361-52.361,74.221c25.454,51.498,64.543,93.214,116.559,93.214c20.476,0,35.291-4.203,53.907-22.82
	c18.615,18.617,33.43,22.82,53.906,22.82c99.392,0,151.615-152.284,151.615-234.16C461.626,188.363,401.666,121.992,327.701,121.992
	z"
      />
      <path
        fill="#08AA6E"
        d="M256.794,29.311C238.565,5.82,196.903,7.636,196.903,7.636s-12.105,39.906,6.124,63.396
	c18.229,23.494,59.891,21.676,59.891,21.676S275.023,52.804,256.794,29.311z"
      />
      <path
        fill="#702963"
        d="M327.701,114.388c-22.31,0-44.178,5.834-63.992,16.958v-2.989c0-9.883,1.622-19.394,4.609-28.284
		l0.317-0.014l1.444-4.757c13.15-32.754,45.231-55.943,82.629-55.943V24.15c-31.203,0-59.243,13.79-78.358,35.59
		c-0.567-11.865-3.498-24.714-11.549-35.09C245.053,1.777,209.472,0,198.99,0c-1.552,0-2.418,0.041-2.418,0.041l-5.386,0.247
		l-1.56,5.141c-0.54,1.783-13.011,43.972,7.393,70.265c14.373,18.523,40.437,23.211,55.399,24.324
		c-2.55,9.013-3.917,18.519-3.917,28.338v2.99c-19.816-11.124-41.684-16.958-63.993-16.958c-36.015,0-70.326,14.898-96.615,41.951
		c-25.963,26.717-41.762,62.851-44.488,101.745l-0.634,9.032l9.005-0.937c2.476-0.258,4.982-0.388,7.448-0.388
		c39.244,0,71.17,31.927,71.17,71.171c0,30.038-19.011,56.986-47.307,67.057l-8.055,2.868l3.789,7.666
		c14.242,28.813,31.116,51.79,50.155,68.295c22.315,19.345,46.95,29.154,73.221,29.154c19.294,0,35.383-3.492,53.906-19.965
		c18.522,16.473,34.611,19.965,53.906,19.965c25.169,0,48.875-9.02,70.458-26.81c18.535-15.277,35.138-36.684,49.348-63.622
		c23.943-45.392,39.414-104.794,39.414-151.331C469.23,184.301,405.741,114.388,327.701,114.388z M209.034,66.371
		c-11.922-15.363-8.489-40.154-6.183-51.072c10.684,0.471,35.968,3.251,47.936,18.672c11.922,15.365,8.489,40.157,6.183,51.074
		C246.288,84.575,221.002,81.795,209.034,66.371z M416.365,414.472c-35.888,68.039-77.644,82.32-106.354,82.32
		c-18.12,0-31.219-3.282-48.529-20.593l-5.377-5.377l-5.378,5.377c-17.312,17.311-30.412,20.593-48.531,20.593
		c-22.506,0-43.789-8.558-63.259-25.437c-15.908-13.791-30.276-32.708-42.779-56.305c29.895-14.152,49.444-44.531,49.444-78.09
		c0-47.566-38.65-86.274-86.19-86.379c4.022-32.022,17.831-61.463,39.387-83.644c23.4-24.079,53.838-37.342,85.708-37.342
		c23.759,0,47.005,7.513,67.225,21.727l4.374,3.074l4.373-3.075c20.218-14.214,43.464-21.727,67.224-21.727
		c69.654,0,126.321,63.091,126.321,140.64C454.022,314.487,439.241,371.103,416.365,414.472z"
      />
      <path
        fill="#702963"
        d="M431.114,228.87l-14.512,4.551c3.698,11.79,5.573,24.177,5.573,36.815h15.208
		C437.384,256.053,435.275,242.135,431.114,228.87z"
      />
      <path
        fill="#702963"
        d="M327.701,146.235v15.208c33.21,0,64.396,20.546,81.384,53.618l13.528-6.949
		C403.007,169.946,366.64,146.235,327.701,146.235z"
      />
    </Icon>
  </Box>
);

export { PlumIcon };
