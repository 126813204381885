import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();

// The value for APPINSIGHTS_KEY is set in the webpack.config.js file
// Refer to the README file for in depth explanation on how this value is retrieved during deployment
declare const APPINSIGHTS_KEY;

export const initializeAppInsights = () => {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: APPINSIGHTS_KEY,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
};
