import React, { PropsWithChildren } from 'react';
import {
  FormProvider,
  UnpackNestedValue,
  useForm,
  UseFormProps,
} from 'react-hook-form';
import { Flex } from '@newday/core';

export interface FormProps<FormValues> extends UseFormProps {
  options?: UseFormProps<FormValues>;
  onChange?: (data: Partial<FormValues>, isValid: boolean) => void;
  onSubmit: (data: UnpackNestedValue<FormValues>) => void;
  onSubmitError?: () => void;
  shouldResetDefaultValues?: boolean;
}

const Form = <FormValues,>({
  children,
  options = {},
  onChange,
  onSubmit,
  onSubmitError,
  shouldResetDefaultValues,
}: PropsWithChildren<FormProps<FormValues>>) => {
  const methods = useForm<FormValues>({
    mode: 'onTouched',
    ...options,
  });

  React.useEffect(() => {
    if (shouldResetDefaultValues) {
      methods.reset(options.defaultValues);
    }
  }, [shouldResetDefaultValues]);

  React.useEffect(() => {
    if (onChange) {
      const {
        formState: { isValid },
        getValues,
      } = methods;
      const data = getValues();
      onChange(data as Partial<FormValues>, isValid);
    }
  }, [methods.formState.isValid]);

  React.useEffect(() => {
    if (onChange) {
      const { watch } = methods;

      const subscription = watch((data) => {
        const { isValid } = methods.formState;
        onChange(data as Partial<FormValues>, isValid);
      });

      return () => subscription.unsubscribe();
    }
  }, [onChange]);

  return (
    <FormProvider {...methods}>
      <form
        data-testid="plum-form"
        onSubmit={methods.handleSubmit((data) => onSubmit(data), onSubmitError)}
      >
        <Flex direction="column">{children}</Flex>
      </form>
    </FormProvider>
  );
};

export { Form };
