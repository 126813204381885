type UseBrandContent = {
  <T>(content: Record<string, T>, brand: string): T | undefined;
};

export function hasKey<
  BrandObjectType extends Record<string, unknown>,
  BrandKey extends PropertyKey
>(obj: BrandObjectType, key: BrandKey): key is BrandKey {
  return key in obj;
}

export const useBrandContent: UseBrandContent = (content, brand) => {
  if (hasKey(content, brand)) {
    return content[brand];
  }

  if (hasKey(content, 'default')) {
    return content.default as typeof brand;
  }

  return undefined;
};
