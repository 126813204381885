import { VisuallyHidden } from '@chakra-ui/react';
import { Button, ButtonProps } from '../button';
import { LinkProps } from '../link';

export type LinkButtonProps = Omit<LinkProps, 'variant' | 'isDisabled'> &
  Omit<ButtonProps, 'isDisabled'>;

export const LinkButton = (props: LinkButtonProps) => {
  const { children, target, ...rest } = props;

  return (
    <Button {...rest} as="a">
      {children}
      {target === '_blank' && (
        <VisuallyHidden>(Opens in new tab or window)</VisuallyHidden>
      )}
    </Button>
  );
};
