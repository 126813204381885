import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const PoundSignIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 16 16" __css={styles} {...props}>
      <path
        d="M12.9762 16H3.02381C2.3254 16 2.3254 15 3.02381 15C4.18098 15 5.11905 14.1046 5.11905 13V9H3.54762C3.25833 9 3.02381 8.77614 3.02381 8.5C3.02381 8.22386 3.25833 8 3.54762 8H5.11905V4C5.11905 1.79086 6.99519 0 9.30952 0C11.6239 0 13.5 1.79086 13.5 4C13.5 4.27614 13.2655 4.5 12.9762 4.5C12.6869 4.5 12.4524 4.27614 12.4524 4C12.4524 2.34315 11.0453 1 9.30952 1C7.57377 1 6.16667 2.34315 6.16667 4V8H11.9286C12.2179 8 12.4524 8.22386 12.4524 8.5C12.4524 8.77614 12.2179 9 11.9286 9H6.16667V13C6.16667 13.7684 5.86406 14.4692 5.3664 15H12.9762C13.2655 15 13.5 15.2239 13.5 15.5C13.5 15.7761 13.2655 16 12.9762 16Z"
        fill="currentColor"
      />
    </Icon>
  );
};
