import { LocalStorageTypes, PlumSupportedBrand } from '@newday/plum-types';
import Cookies from 'js-cookie';
import { setupGtm } from './gtm-setup';

export enum Events {
  FORM_LINK = 'form_link',
  FORM_ERROR = 'form_error',
  FORM_CTA = 'form_cta',
  FORM_SIGNATURE = 'form_signature',
  COOKIE_CONSENT_UPDATE = 'cookieConsentUpdate',
  PAGE_VIEW = 'virtualPageviewPlum',
  FORM_INTERACTION = 'form_interaction',
  INCOME_VERIFICATION_SELECTION = 'income_verification_selection',
}

export enum FormActions {
  CLICK_LINK = 'click_link',
  CLICK_CTA = 'click_cta',
  CLICK_CTA_POPUP = 'click_cta_popup',
  SUBMIT_FORM = 'submit_form',
  ESIGN_FORM = 'esign_form',
}

export enum FormNames {
  APPLICATION = 'Application',
  APPLICATION_COMPLETE = 'Application Complete',
  APPLICATION_COUNTER = 'Application - Counter Offer',
  CHECK_ELIGIBILITY = 'Check Eligibility',
  CONTACT_DETAILS = 'Contact Details',
  BANK_DETAILS = 'Bank Details',
  LOAN_QUOTE_CALC = 'Loan quote calculator',
  DOC_UPLOAD = 'Document Upload',
  SAVE_AND_RETURN = 'Save + Return | Login',
}

export enum DataLayerEvents {
  MARKETING_COOKIE_CONSENT = 'marketingCookieConsent',
  ANALYTICS_COOKIE_CONSENT = 'analyticsCookieConsent',
  ERROR = 'error',
  ERROR_INFO = 'errorInfo',
  APPINSIGHTS_SESSION_ID = 'appInsightsSessionId',
  APPINSIGHTS_USER_ID = 'appInsightsUserId',
}

interface Event {
  event: Events;
  form_name?: string;
  form_errorMessage?: string;
  form_action?: FormActions;
  link_text?: string;
  virtualPageURL?: string;
  virtualPageTitle?: string;
  form_field_error?: string;
  form_field_name?: string;
  'link url'?: string;
  [DataLayerEvents.MARKETING_COOKIE_CONSENT]?: string;
  [DataLayerEvents.ANALYTICS_COOKIE_CONSENT]?: string;
  [DataLayerEvents.ERROR]?: string;
  [DataLayerEvents.ERROR_INFO]?: string;
  [DataLayerEvents.APPINSIGHTS_SESSION_ID]?: string;
  [DataLayerEvents.APPINSIGHTS_USER_ID]?: string;
  page?: {
    brand: PlumSupportedBrand;
  };
  application?: {
    applicationId?: string;
  };
  verification_mode?: string;
}

export const getAppInsightsIds = (): Partial<Event> => ({
  appInsightsSessionId: Cookies.get('ai_session')?.split('|')[0],
  appInsightsUserId: Cookies.get('ai_user')?.split('|')[0],
});

export const gtmTrackEvent = (data: Event) => {
  const analyticsAllowed = Boolean(Cookies.get('analyticsCookieConsent'));

  if (!analyticsAllowed) return;

  if (!window.dataLayer) {
    setupGtm(
      localStorage.getItem(LocalStorageTypes.BRAND) as PlumSupportedBrand
    );
  }

  data = {
    ...data,
    ...getAppInsightsIds(),
  };

  window.dataLayer?.push(data);
};
