import React from 'react';
import { Text, Heading, VStack } from '@newday/core';
import {
  BalloonBox,
  ContentWrapper,
  ReferStepper,
  ResponsivePageLayout,
} from '../../components';
import { ApplicationReference, RepaymentBreakdown } from '../../features';
import { useBrand } from '../../app';

const ReturnApplicationCompletedPage: React.FC = () => {
  const { uppercaseBrandName } = useBrand();

  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <BalloonBox fullWidth>
        <Heading fontSize="2xl" fontWeight="bold">
          Your loan application is complete.
        </Heading>
      </BalloonBox>
      <ContentWrapper py={4} maxContentWidth="5xl">
        <Heading color="black" fontSize="lg" fontWeight="bold" my={4}>
          The money could be in your bank within a couple of hours, although
          some banks may take up to 48 hours to process the payment.
        </Heading>
        <ReferStepper activeStep={5} mt={4} mb={8} />
        <RepaymentBreakdown />
        <Heading fontSize="md" fontWeight="bold" mt={8} mb={2}>
          Customer care for your loan
        </Heading>
        <VStack spacing={4}>
          <Text fontSize="md" fontWeight="normal">
            {uppercaseBrandName} personal loans are provided by NewDay Ltd in
            partnership with Antelope Loans Funding Limited. NewDay has sent you
            the loan amount, and will now transfer ownership of your new loan
            account to Antelope Loans Funding Limited (usually within five
            working days). You’ll get an email to let you know when the transfer
            is complete.
          </Text>
          <Text fontSize="md" fontWeight="normal">
            Antelope Loans (a trading style of Link Financial Outsourcing
            Limited) will manage your loan on behalf of Antelope Loans Funding
            Limited. It will send all future communications relating to your
            loan account.
          </Text>
          <Text fontSize="md" fontWeight="normal" pb={4}>
            Antelope Loans will be your point of contact for any questions about
            your {uppercaseBrandName} personal loan. You’ll receive an email
            confirming Antelope Loans’ contact details shortly. Antelope Loans
            Funding Limited will collect your Direct Debit payments for your
            loan account.
          </Text>
        </VStack>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
export { ReturnApplicationCompletedPage };
