import React, {
  ChangeEvent,
  FocusEvent,
  SyntheticEvent,
  KeyboardEvent,
} from 'react';
import { ReactElement } from 'react';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
  Text,
} from '@chakra-ui/react';

import { PoundSignIcon } from '@newday/icons';

type CurrencyInputValue = string | number | undefined;

export interface CurrencyInputProps
  extends Omit<InputProps, 'defaultValue' | 'value'> {
  defaultValue?: CurrencyInputValue;
  value?: CurrencyInputValue;
  rightElementContent?: 'per month' | 'per year';
  positive?: boolean;
  noExponent?: boolean;
  maxInputLength?: number;
  isOnlyIntegers?: boolean;
  shouldFormatValue?: boolean;
}

export const CurrencyInput = (props: CurrencyInputProps): ReactElement => {
  const {
    value: valueProp,
    defaultValue: defaultValueProp,
    onChange: onChangeProp,
    onBlur: onBlurProp,
    rightElementContent,
    positive,
    noExponent,
    maxInputLength,
    isOnlyIntegers,
    shouldFormatValue = true,
    ...restProps
  } = props;
  const controlled = !defaultValueProp && valueProp && onChangeProp;

  if (defaultValueProp && valueProp) {
    throw new Error(
      'An uncontrolled CurrencyInput should not receive a value prop'
    );
  }

  const format = (value: CurrencyInputValue) =>
    value ? Number(value).toFixed(2) : undefined;

  const [value, setValue] = React.useState<CurrencyInputValue>(
    format(defaultValueProp)
  );

  const cloneEvent = <EventType extends SyntheticEvent>(
    event: EventType,
    value: string | undefined
  ) => ({
    ...event,
    target: {
      ...event.target,
      value,
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!controlled) {
      setValue(value);
    }
    if (onChangeProp) {
      const changeEvent = cloneEvent<ChangeEvent<HTMLInputElement>>(
        event,
        value
      );
      onChangeProp(changeEvent);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const formattedValue = shouldFormatValue
      ? format(valueProp || value)
      : (valueProp || value)?.toString();
    if (controlled) {
      const changeEvent = cloneEvent<FocusEvent<HTMLInputElement>>(
        event,
        formattedValue
      );
      onChangeProp(changeEvent);
    } else {
      setValue(formattedValue);
    }

    if (onBlurProp) {
      const blurEvent = cloneEvent<FocusEvent<HTMLInputElement>>(
        event,
        formattedValue
      );
      onBlurProp(blurEvent);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (noExponent && event.key.toUpperCase() === 'E') {
      event.preventDefault();
    }
    if (positive && event.key === '-') {
      event.preventDefault();
    }
    if (
      maxInputLength &&
      event.currentTarget.value.length === maxInputLength &&
      !(
        event.key.toUpperCase() === 'BACKSPACE' ||
        event.key.toUpperCase() === 'DELETE' ||
        event.key.toUpperCase() === 'ARROWLEFT' ||
        event.key.toUpperCase() === 'ARROWRIGHT' ||
        event.key.toUpperCase() === 'TAB' ||
        event.key.toUpperCase() === 'ENTER'
      )
    ) {
      event.preventDefault();
    }
    if (isOnlyIntegers && event.key === '.') {
      event.preventDefault();
    }
  };

  const zeroMinAttribute = positive ? { min: 0 } : {};

  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        children={<PoundSignIcon color="black" height="0.75rem" />}
      />
      <Input
        {...restProps}
        {...zeroMinAttribute}
        type="number"
        onChange={handleChange}
        value={valueProp || value || ''}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      {rightElementContent ? (
        <InputRightElement
          pointerEvents="none"
          children={<Text fontSize="sm">{rightElementContent}</Text>}
          width={24}
        />
      ) : null}
    </InputGroup>
  );
};
