import { Stack, Text } from '@newday/core';
import { AlertIcon } from '@newday/icons';

export type AlertProps = {
  title: string;
  message: string;
};

export const Alert = ({ title, message }: AlertProps) => (
  <Stack
    role="alert"
    aria-live="assertive"
    spacing={1}
    backgroundColor="brand.greyScale.200"
    p={4}
    borderRadius="md"
    borderColor="red.600"
    borderWidth="1px"
    bg="red.50"
    alignItems="center"
    textAlign="center"
  >
    <AlertIcon size="sm" color="red.600" />
    <Text fontWeight="bold" fontSize="lg" color="red.600">
      {title}
    </Text>
    <Text fontSize="sm" fontWeight="semibold">
      {message}
    </Text>
  </Stack>
);
