/* eslint-disable sonarjs/no-duplicate-string */
import {
  DependantsCount,
  EmploymentStatus,
  FinancialSituation,
  MaritalStatus,
  ResidentialStatus,
} from '@newday/plum-types';
import {
  ApplicationDecisionResponse,
  ApplicationDisbursementResponse,
  CreateApplicationResponse,
  GetApplication,
  PatchApplication,
} from '../types';

export const getMockApplicationForPatchRequest = (
  override?: Partial<PatchApplication>
): PatchApplication => ({
  title: 'mr',
  firstName: 'string',
  lastName: 'string',
  dateOfBirth: '2000-05-18T10:40:05.485Z',
  email: 'test@test.com',
  mobile: '07345678911',
  homePhone: '01611234567',
  currentAddress: getMockAddress(),
  previousAddress: getMockAddress(),
  grossAnnualIncome: 0,
  otherHouseholdIncome: 0,
  accommodationCosts: 0,
  expenditureEducationCost: 0,
  expenditureUtilitiesCost: 0,
  expenditureOtherCost: 0,
  financialSituation: FinancialSituation.decline,
  dependants: DependantsCount.none,
  employmentStatus: EmploymentStatus.fullTime,
  employmentStartDate: '2022-05-20T10:40:05.485Z',
  residentialStatus: ResidentialStatus.ownerOccupier,
  maritalStatus: MaritalStatus.married,
  unsolicitedCreditLimitIncrease: true,
  statementPreference: 'eStatements',
  newDayMarketingConsent: {
    email: true,
    post: true,
    sms: true,
    phone: true,
    online: true,
  },
  declineConsent: true,
  chaserConsent: true,
  basketAmount: 0,
  basketRef: 'string',
  loanAmount: 0,
  loanTerm: 0,
  loanApr: 0,
  dcmScore: 0,
  marketingRef: 'string',
  verificationId: '1234567890',
  source: 'other',
  assistedTransferData: {
    flag: '1',
    urn: 'string',
    primaryRecordIdentifier: '1',
    accountOpenDate: '2022-05-18T10:40:05.485Z',
    customerNameChanged: true,
    customerConsentSubmitted: true,
    customerDateOfBirthChanged: true,
    customerAddressChanged: true,
    physicalIdCheckCompleted: true,
    alternativeOtpCompleted: true,
    pennyDropSkipped: true,
    balanceTransferRequested: true,
    retailerMarketingConsent: {
      email: true,
      post: true,
      sms: true,
      phone: true,
      online: true,
    },
    consentCreditFraudChecks: true,
    instoreCustomerApplicationConsent: true,
    accessibilityRequirements: 'braille',
    existingCreditLimit: 0,
    knownCustomer: true,
    entryMethod: 'openDoor',
  },
  ...override,
});

type Applicant = GetApplication['applicant'];
type Product = GetApplication['product'];
type Address = Applicant['currentAddress'];
type Contacts = Applicant['contacts'];

export const getMockContacts = (overrides?: Partial<Contacts>): Contacts => ({
  email: 'test@test.com',
  mobile: '07123456789',
  homePhone: '01211234567',
  ...overrides,
});

export const getMockCreateApplicationResponse = (
  overrides?: Partial<CreateApplicationResponse>
): CreateApplicationResponse => ({
  id: 'd3b5ee14-3cfc-47ed-bd97-889080719c8c',
  customerRef: 'fae6bf75-06b2-4d35-8086-94b21e952173',
  modifiedDate: '2023-11-02T13:27:51.6355896Z',
  ...overrides,
});

export const getMockAddress = (overrides?: Partial<Address>): Address => ({
  flatNumber: 'string',
  houseName: 'string',
  houseNumber: 'string',
  street: 'string',
  district: 'string',
  town: 'string',
  postcode: 'string',
  county: 'string',
  country: 'string',
  movedIn: '2022-06-17T16:20:13.286Z',
  timeAtAddress: 'string',
  ...overrides,
});

type ApplicantIdentifiers = {
  externalRef: string;
  marketingRef: string;
  customerRef: string;
  verificationId: string;
};

export const getMockApplicantIdentifiers = (
  overrides?: Partial<ApplicantIdentifiers>
) => ({
  externalRef: 'string',
  marketingRef: 'string',
  customerRef: 'string',
  verificationId: 'string',
  ...overrides,
});

export const getMockAffordability = (
  overrides?: Partial<Applicant['affordability']>
): Applicant['affordability'] => ({
  residentialStatus: ResidentialStatus.ownerOccupier,
  cashWithdrawalRequired: true,
  cashWithdrawalAmount: 0,
  balanceTransferRequired: true,
  balanceTransferAmount: 0,
  accommodationCosts: 0,
  dependants: DependantsCount.none,
  employmentStatus: EmploymentStatus.fullTime,
  financialSituation: FinancialSituation.decline,
  grossAnnualIncome: 0,
  maritalStatus: MaritalStatus.married,
  otherHouseholdIncome: 0,
  basketAmount: 0,
  expenditureUtilitiesCost: 0,
  expenditureEducationCost: 0,
  expenditureOtherCost: 0,
  ...overrides,
});

export const getMockApplicant = (
  overrides?: Partial<Applicant>
): Applicant => ({
  title: 'Mr',
  firstName: 'string',
  lastName: 'string',
  dateOfBirth: '1990-06-17T00:00:00',
  paymentStartDate: '2022-07-20T14:32:01.376Z',
  dcmScore: 0,
  applicantRisk: 'high',
  currentAddress: getMockAddress({ houseName: 'current address' }),
  previousAddress: getMockAddress({
    houseName: 'previous address',
  }),
  contacts: getMockContacts(),
  identifiers: getMockApplicantIdentifiers(),
  affordability: getMockAffordability(),
  applicantConsents: {
    newDayMarketingConsent: {
      email: true,
      post: true,
      sms: true,
      phone: true,
      online: true,
    },
    declineConsent: true,
    chaserConsent: true,
    unsolicitedCreditLimitIncrease: true,
    statementPreference: 'eStatements',
  },
  ...overrides,
});

export const getMockProduct = (overrides?: Partial<Product>): Product => ({
  brand: 'string',
  brandCode: 'aqua',
  productName: 'string',
  apr: 'string',
  creditLimit: 0,
  loanTerm: 0,
  loanApr: 0,
  monthlyPayment: 116.33,
  totalAmount: 0,
  productPricingId: '',
  brandName: 'string',
  name: 'string',
  ...overrides,
});

export const getMockApplicationForGetRequest = (
  overrides?: Partial<GetApplication>
): GetApplication => ({
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  applicationRef: 'string',
  aggregatorId: 'string',
  state: 'appComplete',
  subState: 'refer',
  createdAt: 'string',
  employmentStartDate: '2022-05-20T10:40:05.485Z',
  applicant: getMockApplicant(),
  product: getMockProduct(),
  creditDecision: 0,
  modifiedAt: 'string',
  ...overrides,
});

export const getMockApplicationDecision = (
  overrides?: Partial<ApplicationDecisionResponse>
): ApplicationDecisionResponse => {
  return {
    state: 'sign',
    apr: 18.5,
    creditLimit: 5000,
    customerRef: 'mockCustomerRef',
    ...overrides,
  } as ApplicationDecisionResponse;
};

export const getMockApplicationDisbursement = (
  overrides?: Partial<ApplicationDisbursementResponse>
): ApplicationDisbursementResponse => ({
  apr: 20,
  customerRef: 'mockCustomerRef',
  information: 'information',
  loanAmount: 500,
  ...overrides,
});
