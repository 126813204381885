import { BffRoutes, DecisionAndApplyResponse } from '@newday/plum-types';
import axios from 'axios';
import { useMutation, useQuery, UseQueryResult } from 'react-query';

export const useApplyAndSign = (applicationId: string) =>
  useMutation(() => {
    const applyAndSignAddress = BffRoutes.applyAndSign.replace(
      ':applicationId',
      applicationId
    );
    return axios
      .get<DecisionAndApplyResponse>(applyAndSignAddress)
      .then((response) => response.data);
  });

const fetchHtmlDocs = ({ queryKey }): Promise<Response> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, applicationId] = queryKey;
  const htmlDocumentAddress = BffRoutes.htmlDocuments.replace(
    ':applicationId',
    applicationId
  );
  return axios.get(htmlDocumentAddress).then((response) => response.data);
};

export type HtmlDocs = {
  data: {
    data: string;
  }[];
  isSuccess: boolean;
  isLoading: boolean;
};

export const useHtmlDocuments = (
  applicationId
): UseQueryResult<Response, HtmlDocs> => {
  return useQuery(['plum', 'html-doc', applicationId], fetchHtmlDocs);
};
