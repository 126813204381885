import { ComponentStyleConfig } from '@chakra-ui/react';
import { blacken } from '@chakra-ui/theme-tools';

export const Button: ComponentStyleConfig = {
  defaultProps: {
    size: 'md',
  },
  sizes: {
    md: {
      height: '3rem',
      px: '3rem',
    },
    lg: {
      height: '3.125rem',
      px: '3rem',
      fontSize: 'xl',
    },
  },
  baseStyle: {
    fontWeight: 700,
    fontSize: '1rem',
    fontFamily: 'mono',
    lineHeight: '150%',
    py: '0.75rem',
    width: '100%',
    color: 'brand.primary.500',
    _disabled: {
      opacity: 0.5,
    },
  },
  variants: {
    primary: {
      color: 'brand.primary.500',
      backgroundColor: 'brand.secondary.500',
      borderWidth: '1px',
      borderColor: 'brand.secondary.500',
      _hover: {
        backgroundColor: blacken('brand.secondary.500', 10),
        borderColor: 'brand.primary.500',
        borderWidth: '1px',
        _disabled: {
          opacity: 0.5,
          backgroundColor: 'brand.secondary.500',
          borderColor: 'brand.secondary.500',
          borderWidth: '1px',
        },
      },
      _focus: {
        borderWidth: '1px',
        borderColor: 'white',
        boxShadow: 'outline',
      },
    },
    secondary: {
      color: 'brand.primary.500',
      backgroundColor: 'white',
      borderWidth: '1px',
      borderColor: 'brand.primary.500',
      _hover: {
        backgroundColor: blacken('white', 10),
        borderColor: 'brand.primary.500',
        borderWidth: '1px',
        _disabled: {
          opacity: 0.5,
          backgroundColor: 'white',
          borderColor: 'brand.primary.500',
          borderWidth: '1px',
        },
      },
      _focus: {
        borderWidth: '1px',
        boxShadow: 'outline',
      },
    },
  },
};
