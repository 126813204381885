import type { PlumSupportedBrand } from '@newday/plum-types';

declare global {
  interface Window {
    TEST_BRAND_OVERRIDE?: PlumSupportedBrand;
  }
}

export const detectBrandFromUrl = (url: string): PlumSupportedBrand => {
  if (window.TEST_BRAND_OVERRIDE) {
    return window.TEST_BRAND_OVERRIDE;
  }

  const supportedBrands: PlumSupportedBrand[] = [
    'aqua',
    'opus',
    'marbles',
    'fluid',
    'bip',
    'pulsepersonal',
    'pulse',
  ];

  return supportedBrands.find((brand) => url.includes(brand)) || 'aqua';
};
