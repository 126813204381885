import { Box } from '@newday/core';

type Breakpoints =
  | {
      base?: number | string;
      md?: number | string;
      lg?: number | string;
      xl?: number | string;
    }
  | string
  | number;

export type ContentWrapperProps = {
  children: React.ReactNode;
  maxContentWidth?: 'md' | '3xl' | '5xl';
  px?: Breakpoints;
  py?: Breakpoints;
};

export const ContentWrapper: React.FC<ContentWrapperProps> = ({
  children,
  maxContentWidth = '3xl',
  px = maxContentWidth === '5xl' ? { base: 8 } : { base: 8, lg: 0 },
  py = 0,
}) => (
  <Box
    width="full"
    display="flex"
    flexDirection="column"
    mx="auto"
    px={px}
    py={py}
    maxW={maxContentWidth}
  >
    {children}
  </Box>
);
