import React, { createContext, useContext } from 'react';
import { LocalStorageTypes } from '@newday/plum-types';
import { useSearchParams } from 'react-router-dom';
import jwtDecode from '../utils/decode-jwt-no-error';

type ApplicationIdContextData = {
  applicationId: string;
};

const ApplicationIdContext = createContext<ApplicationIdContextData>(
  {} as ApplicationIdContextData
);

export const ApplicationIdProvider = ({
  children,
  initialValue,
}: {
  children: React.ReactNode;
  initialValue?: string;
}) => {
  const [searchParams] = useSearchParams();
  const applicationIdFromUrl = searchParams.get('applicationId');

  if (applicationIdFromUrl) {
    return (
      <ApplicationIdContext.Provider
        value={{ applicationId: applicationIdFromUrl }}
      >
        {children}
      </ApplicationIdContext.Provider>
    );
  }

  const jwtFromLocalStorage =
    localStorage.getItem(LocalStorageTypes.BEARER) || '';

  const payload = jwtDecode(jwtFromLocalStorage) as {
    applicationId: string;
  };

  if (payload && payload.applicationId) {
    return (
      <ApplicationIdContext.Provider
        value={{ applicationId: payload.applicationId }}
      >
        {children}
      </ApplicationIdContext.Provider>
    );
  }

  return (
    <ApplicationIdContext.Provider
      value={{ applicationId: initialValue || '' }}
    >
      {children}
    </ApplicationIdContext.Provider>
  );
};

export const useApplicationId = () => {
  return useContext(ApplicationIdContext);
};
