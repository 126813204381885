export const formatCurrency = (amount: number) => {
  const { format: formatCurrency } = new Intl.NumberFormat('en-GB', {
    currencyDisplay: 'narrowSymbol',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'currency',
    useGrouping: amount >= 10000 ? true : false,
  });

  return formatCurrency(amount);
};

export const formatCurrencyToTwoDecimalPlaces = (amount: number) => {
  const { format: formatCurrencyToTwoDecimalPlaces } = new Intl.NumberFormat(
    'en-GB',
    {
      currencyDisplay: 'narrowSymbol',
      currency: 'GBP',
      maximumFractionDigits: 2,
      style: 'currency',
      useGrouping: amount >= 10000 ? true : false,
    }
  );

  return formatCurrencyToTwoDecimalPlaces(amount);
};
