import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const TickIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 25 20" __css={styles} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.31922 15.2401L2.83144 9.5995L1 11.4752L8.31922 19L24 2.88245L22.1751 1L8.31922 15.2401Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </Icon>
  );
};
