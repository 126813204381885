import React from 'react';

import {
  Bip,
  BipSecondary,
  Marbles,
  Opus,
  Fluid,
  Antelope,
  Aqua,
  Pulse,
} from './logos';

export const Brands = [
  'aqua',
  'marbles',
  'opus',
  'bip',
  'fluid',
  'antelope',
  'pulse',
  'pulsepersonal',
] as const;

export type BrandLogoProps = React.SVGProps<SVGSVGElement> & {
  brand: typeof Brands[number];
  secondary?: boolean;
};

const getPrimaryLogo = (props: BrandLogoProps) => {
  const { brand } = props;
  return {
    marbles: <Marbles {...props} />,
    opus: <Opus {...props} />,
    bip: <Bip {...props} />,
    fluid: <Fluid {...props} />,
    antelope: <Antelope {...props} />,
    aqua: <Aqua {...props} />,
    pulse: <Pulse {...props} />,
    pulsepersonal: <Pulse {...props} />,
  }[brand];
};

const getSecondaryLogo = (props: BrandLogoProps) => {
  const { brand } = props;
  return {
    bip: <BipSecondary {...props} />,
  }[brand as 'bip'];
};

const isSecondaryLogoSupported = (brand: BrandLogoProps['brand']) =>
  brand === 'bip';

export const BrandLogo = (props: BrandLogoProps) => {
  if (props.secondary && isSecondaryLogoSupported(props.brand)) {
    return <>{getSecondaryLogo(props)}</>;
  }

  return <>{getPrimaryLogo(props)}</>;
};
