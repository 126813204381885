import { BffRoutes, FeatureToggles } from '@newday/plum-types';
import axios from 'axios';
import { useQuery } from 'react-query';

const fetchFeatureToggles = (): Promise<FeatureToggles> =>
  axios
    .get<FeatureToggles>(BffRoutes.featureToggles)
    .then((response) => response.data);

export const useFeatureTogglesQuery = () => {
  return useQuery<FeatureToggles>(
    ['plum', 'feature-toggles'],
    fetchFeatureToggles
  );
};
