import { ComponentStyleConfig } from '@chakra-ui/react';

export const Accordion: ComponentStyleConfig = {
  baseStyle: ({ theme }) => {
    const { colors } = theme;
    return {
      panel: {
        px: '0.5rem',
        pb: '1.25rem',
      },
      button: {
        py: '1rem',
        px: '0.5rem',
        marginBottom: '1px',
        _focus: {
          boxShadow: `0 0 0 1px ${colors.brand.primary['500']}`,
        },
      },
    };
  },
};
