import { Box as ThemeBox, BoxProps, useStyleConfig } from '@chakra-ui/react';
import { ReactElement } from 'react';

export interface CustomBoxProps extends BoxProps {
  variant?: string;
}

export const Box = ({
  variant,
  children,
  ...otherProps
}: CustomBoxProps): ReactElement => {
  const variantStyle = useStyleConfig('Themebox', { variant });

  return (
    <ThemeBox __css={variantStyle} {...otherProps}>
      {children}
    </ThemeBox>
  );
};
