/* eslint-disable sonarjs/no-duplicate-string */
import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { BffRoutes } from '@newday/plum-types';
import { Proof } from '@newday/plum-types';
import { PartialFile } from '../../app/files-provider';

export const useManageFile = () => {
  const { mutateAsync: uploadFile } = useMutation(
    ({
      file,
      applicationId,
      type,
    }: {
      file: PartialFile;
      applicationId: string;
      type: string;
    }) => {
      const formData = new FormData();
      formData.append('uploaded_file', file as Blob);
      formData.append('type', type);
      return axios.post(
        `${BffRoutes.proof.replace(':applicationId', applicationId)}`,
        formData
      );
    },
    {
      useErrorBoundary: false,
    }
  );

  const { mutateAsync: deleteFile } = useMutation(
    ({ id, applicationId }: { id: string; applicationId: string }) =>
      axios.delete(
        `${BffRoutes.proof.replace(
          ':applicationId',
          applicationId
        )}?proofId=${id}`
      ),
    {
      useErrorBoundary: false,
    }
  );

  const { mutateAsync: deleteFiles } = useMutation(
    (applicationId: string) =>
      axios.delete(
        `${BffRoutes.proofList.replace(':applicationId', applicationId)}`
      ),
    {
      useErrorBoundary: false,
    }
  );

  return {
    deleteFile,
    deleteFiles,
    uploadFile,
  };
};

export const useFetchFiles = (applicationId: string) => {
  return useQuery(
    ['plum', 'uploaded-docs', applicationId],
    (): Promise<Proof[]> =>
      axios
        .get(`${BffRoutes.proofList.replace(':applicationId', applicationId)}`)
        .then((response) => response.data)
  );
};

export const useUploadSubmit = (applicationId: string) => {
  return useMutation(
    () => {
      return axios.put(
        `${BffRoutes.proof.replace(':applicationId', applicationId)}`,
        {}
      );
    },
    { useErrorBoundary: false }
  );
};
