import { FeRoutes, FeRouteTitles } from '@newday/plum-types';
import {
  BankDetailsPage,
  BankDetailsReferPage,
  ContactDetailsPage,
  CookiePolicyPage,
  DisbursementFailedPage,
  DocUploadPage,
  DocUploadSkippedPage,
  DocUploadSuccessPage,
  DocUploadUnsuccessPage,
  EligibilityPage,
  EligibilityTermsPage,
  ErrorPageComplexRefer,
  FaqsPage,
  GeneralReferPage,
  HardCheckIneligiblePage,
  LandingPage,
  PrivacyPage,
  QuoteDownsellPage,
  QuoteEligiblePage,
  QuoteIneligiblePage,
  ReturnApplicationCompletedPage,
  ReturnApplicationDeclinedPage,
  ReturnBankAndIAndEReferPage,
  ReturnBankDetailsDeclinedPage,
  ReturnGeneralReferPage,
  ReturnIAndEReferPage,
  SaveAndReturnPage,
  SuccessfulApplicationPage,
} from '../pages';

export const PAGES = [
  {
    path: FeRoutes.landingPage,
    Component: LandingPage,
    title: FeRouteTitles.landingPage,
  },
  {
    path: FeRoutes.contactDetails,
    Component: ContactDetailsPage,
    title: FeRouteTitles.contactDetails,
  },
  {
    path: FeRoutes.eligibility,
    Component: EligibilityPage,
    title: FeRouteTitles.eligibility,
  },
  {
    path: FeRoutes.faqs,
    Component: FaqsPage,
    title: FeRouteTitles.faqs,
    isPublic: true,
  },
  {
    path: FeRoutes.quoteEligible,
    Component: QuoteEligiblePage,
    title: FeRouteTitles.quoteEligible,
  },
  {
    path: FeRoutes.quoteCounterOffer,
    Component: QuoteDownsellPage,
    title: FeRouteTitles.quoteDownsell,
  },
  {
    path: FeRoutes.quoteIneligible,
    Component: QuoteIneligiblePage,
    title: FeRouteTitles.quoteIneligible,
  },
  {
    path: FeRoutes.bankDetails,
    Component: BankDetailsPage,
    title: FeRouteTitles.bankDetails,
  },
  {
    path: FeRoutes.referGeneral,
    Component: GeneralReferPage,
    title: FeRouteTitles.referGeneral,
  },
  {
    path: FeRoutes.referBankDetails,
    Component: BankDetailsReferPage,
    title: FeRouteTitles.referBankDetails,
  },
  {
    path: FeRoutes.hardCheckIneligible,
    Component: HardCheckIneligiblePage,
    title: FeRouteTitles.hardCheckIneligible,
  },
  {
    path: FeRoutes.successfulApplication,
    Component: SuccessfulApplicationPage,
    title: FeRouteTitles.successfulApplication,
  },
  {
    path: FeRoutes.returnApplicationCompleted,
    Component: ReturnApplicationCompletedPage,
    title: FeRouteTitles.returnApplicationCompleted,
  },
  {
    path: FeRoutes.returnApplicationDeclined,
    Component: ReturnApplicationDeclinedPage,
    title: FeRouteTitles.returnApplicationDeclined,
  },
  {
    path: FeRoutes.returnBankDetailsDeclined,
    Component: ReturnBankDetailsDeclinedPage,
    title: FeRouteTitles.returnBankDetailsDeclined,
  },
  {
    path: FeRoutes.returnReferBankAndIANDE,
    Component: ReturnBankAndIAndEReferPage,
    title: FeRouteTitles.returnReferBankAndIANDE,
  },
  {
    path: FeRoutes.returnReferIANDE,
    Component: ReturnIAndEReferPage,
    title: FeRouteTitles.returnReferIANDE,
  },
  {
    path: FeRoutes.returnReferGeneral,
    Component: ReturnGeneralReferPage,
    title: FeRouteTitles.returnReferGeneral,
  },
  {
    path: FeRoutes.saveAndReturn,
    Component: SaveAndReturnPage,
    title: FeRouteTitles.saveAndReturn,
  },
  {
    path: FeRoutes.privacy,
    Component: PrivacyPage,
    title: FeRouteTitles.privacy,
    isPublic: true,
  },
  {
    path: FeRoutes.eligibilityTerms,
    Component: EligibilityTermsPage,
    title: FeRouteTitles.eligibilityTerms,
  },
  {
    path: FeRoutes.cookiePolicy,
    Component: CookiePolicyPage,
    title: FeRouteTitles.cookiePolicy,
    isPublic: true,
  },
  {
    path: FeRoutes.documentUpload,
    Component: DocUploadPage,
    title: FeRouteTitles.documentUpload,
  },
  {
    path: FeRoutes.documentUploadSkipped,
    Component: DocUploadSkippedPage,
    title: FeRouteTitles.documentUploadSkipped,
  },
  {
    path: FeRoutes.documentUploadSuccess,
    Component: DocUploadSuccessPage,
    title: FeRouteTitles.documentUploadSuccess,
  },
  {
    path: FeRoutes.documentUploadUnsuccess,
    Component: DocUploadUnsuccessPage,
    title: FeRouteTitles.documentUploadUnsuccess,
  },
  {
    path: FeRoutes.disbursementFailed,
    Component: DisbursementFailedPage,
    title: FeRouteTitles.disbursementFailed,
  },
  {
    path: FeRoutes.errorPageComplexRefer,
    Component: ErrorPageComplexRefer,
    title: FeRouteTitles.errorPageComplexRefer,
  },
];

export const PAGE_TITLE_BY_PATH = PAGES.reduce((pageTitleByPath, page) => {
  pageTitleByPath[page.path] = page.title;
  return pageTitleByPath;
}, {});

export const PUBLIC_ROUTES = PAGES.filter(({ isPublic }) => isPublic).map(
  ({ path }) => path
);
