import { BalloonBoxTheme } from '../balloon-box';
import { BreakdownTheme } from '../breakdown';
import { HeroTheme } from '../hero';

const plumTheme = {
  components: {
    BalloonBox: BalloonBoxTheme,
    Breakdown: BreakdownTheme,
    Hero: HeroTheme,
  },
};

const globalStyles = `
  html { 
    font-size: 16px; 
  } 
  body { 
    color: #000000;
    font-size: 16px;
  }
`;

export { plumTheme, globalStyles };
