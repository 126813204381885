import { AprAndRiskGroup, RiskGroup } from '@newday/plum-types';

export const getMockAprAndRiskGroup = (
  overrides?: Partial<AprAndRiskGroup>
): AprAndRiskGroup => {
  return {
    riskGroup: RiskGroup.low,
    apr: 18,
    ...overrides,
  };
};
