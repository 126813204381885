import React from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { UploadIcon } from '@newday/icons';
import { Box, Heading, Text } from '@newday/core';

export interface UploadAreaProps {
  onFilesUpload: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  maxFileSize: number;
}

export const UploadArea: React.FC<UploadAreaProps> = ({
  onFilesUpload: onDrop,
  maxFileSize,
}) => {
  const maxSize = maxFileSize * 1024 ** 2;

  const validator = (file) => {
    if (file.size > maxSize) {
      return {
        code: 'file-too-large',
        message: `File is larger than ${maxFileSize} MB`,
      };
    }
    if (file.size < 1) {
      return {
        code: 'invalid-size',
        message: 'Corrupted file',
      };
    }

    return null;
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    validator,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },
  });

  return (
    <Box
      border="1px dashed"
      borderColor="brand.greyScale.600"
      textAlign="center"
      backgroundColor="white"
      p={5}
    >
      <div {...getRootProps({ role: 'button' })}>
        <input data-testid="dropzone" {...getInputProps()} />
        <UploadIcon color="brand.tertiary" width={10} height={10} />
        <Heading as="h3" mt={3} mb={1} color="inherit">
          {isDragActive ? 'Drop it here' : 'Click here'}
        </Heading>
        <Text>
          to attach your file in{' '}
          <strong>PNG, JPG or PDF format. Maximum {maxFileSize} MB.</strong>
        </Text>
      </div>
    </Box>
  );
};
