/* eslint "sonarjs/no-duplicate-string": "off" */
import React from 'react';
import {
  Box,
  Button,
  Input,
  InputProps,
  InputGroup,
  useNumberInput,
  UseNumberInputProps,
  useMultiStyleConfig,
  VisuallyHidden,
} from '@chakra-ui/react';

import { PlusIcon, MinusIcon, PoundSignIcon } from '@newday/icons';

export interface StepperCurrencyInputProps extends UseNumberInputProps {
  isInvalid?: boolean;
  isDisabled?: boolean;
}

export const StepperCurrencyInput = (
  props: StepperCurrencyInputProps
): React.ReactElement => {
  const { isInvalid, isDisabled } = props;

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput(props);

  const { ...inc } = getIncrementButtonProps();
  const { ...dec } = getDecrementButtonProps();
  const input = getInputProps();

  let variant;
  if (isInvalid) {
    variant = '_invalid';
  }
  if (isDisabled) {
    variant = '_disabled';
  }

  const styles = useMultiStyleConfig('StepperCurrencyInput', {
    ...props,
    variant,
  });
  const fieldStyles = styles['field'] as Partial<InputProps>;

  return (
    <InputGroup>
      <Button
        data-testid="decrement-button"
        __css={styles['buttonLeft']}
        {...dec}
      >
        <VisuallyHidden>Decrement</VisuallyHidden>
        <MinusIcon size="xs" __css={styles['buttonIcon']} />
      </Button>
      <Box __css={styles['container']}>
        <PoundSignIcon __css={styles['currencyIcon']} />
        <Input
          data-testid="loan-value"
          variant="unstyled"
          {...fieldStyles}
          {...input}
          textAlign="center"
        />
      </Box>
      <Button
        data-testid="increment-button"
        __css={styles['buttonRight']}
        {...inc}
      >
        <VisuallyHidden>Increment</VisuallyHidden>
        <PlusIcon size="xs" __css={styles['buttonIcon']} />
      </Button>
    </InputGroup>
  );
};
