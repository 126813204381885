import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const CheckIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 25 20" __css={styles} {...props}>
      <path
        d="M21.8624 3.99278L18.645 0.687908C18.5119 0.551707 18.3303 0.473605 18.1399 0.470593C18.046 0.469248 17.9527 0.486512 17.8655 0.521389C17.7783 0.556265 17.6989 0.608064 17.6318 0.673797L8.07001 9.98728L4.91189 6.80941C4.77748 6.67515 4.59527 6.59974 4.40529 6.59974C4.21531 6.59974 4.03311 6.67515 3.89869 6.80941L0.627686 10.0578C0.560678 10.1244 0.507484 10.2035 0.471158 10.2907C0.434833 10.3779 0.416093 10.4714 0.416016 10.5658C0.416781 10.7551 0.49177 10.9365 0.624863 11.071L7.50273 17.9884C7.63691 18.1209 7.81791 18.1953 8.0065 18.1953C8.1951 18.1953 8.3761 18.1209 8.51028 17.9884L21.8483 5.00598C21.9161 4.94055 21.9702 4.86236 22.0076 4.77592C22.045 4.68948 22.0649 4.59649 22.0662 4.50232C22.0676 4.40814 22.0502 4.31464 22.0152 4.22719C21.9803 4.13974 21.9283 4.06008 21.8624 3.99278Z"
        fill="currentColor"
      />
    </Icon>
  );
};
