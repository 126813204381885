import { PlumProduct } from '@newday/plum-types';

export const getMockProduct = (
  overrides?: Partial<PlumProduct>
): PlumProduct => ({
  term: 3,
  loanAmount: 5100,
  apr: 18,
  monthlyPayment: 183.38,
  totalAmount: 6638,
  ...overrides,
});
