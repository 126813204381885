import { whiten } from '@chakra-ui/theme-tools';
import {
  Antelope,
  ExpandedIndex,
  Flex,
  Heading,
  HStack,
  Link,
  NavigationButton,
  SimpleGrid,
  Stack,
  Text,
  useMediaQuery,
  useTheme,
} from '@newday/core';
import { InterestRateIcon } from '@newday/icons';
import { FeRoutes } from '@newday/plum-types';
import { useBrandContent } from '@newday/use-brand-content';
import React, { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useBrand, useLoanDetails } from '../../app';
import {
  ContentWrapper,
  Faqs,
  LoanSelector,
  ResponsivePageLayout,
} from '../../components';
import { LoanBreakdown, RepresentativeExample } from '../../features';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';
import { landingPageBrandContent } from './landing-page-brand-content';

const LandingPage: React.FC = () => {
  const theme = useTheme();
  const { brand, brandWithArticle, uppercaseBrandName } = useBrand();
  /* istanbul ignore next */
  const whitenFactor = brand === 'aqua' ? 0 : 80;
  const wrapperBgColor = whiten('brand.quaternary', whitenFactor)(theme);
  const [isAboveMdViewport] = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );
  const {
    loanDetails: { amount, durationYears },
    setLoanDetails,
  } = useLoanDetails();
  const [faqIndex, setFaqIndex] = useState<ExpandedIndex>();

  const handleCheckMyEligibilityClick = () =>
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_name: FormNames.LOAN_QUOTE_CALC,
      form_action: FormActions.SUBMIT_FORM,
      link_text: 'check my eligibility',
    });

  const handleFaqsClick = () => {
    gtmTrackEvent({
      event: Events.FORM_LINK,
      form_name: FormNames.LOAN_QUOTE_CALC,
      form_action: FormActions.CLICK_LINK,
      link_text: 'see all FAQs',
      'link url': `${window.origin}${FeRoutes.faqs}`,
    });
    window.open(FeRoutes.faqs, '_blank');
  };

  const handleFAQScroll = useCallback(() => {
    document?.getElementById('accordion-button-faq-3')?.scrollIntoView();

    setFaqIndex(2);
  }, [setFaqIndex]);

  const brandContent = useBrandContent(
    landingPageBrandContent({ brandWithArticle, uppercaseBrandName }),
    brand
  );

  return (
    <ResponsivePageLayout centeredLogo>
      <ContentWrapper>
        <Stack spacing={2} pt={8} pb={2}>
          <Heading as="h1" fontSize="3xl" textAlign="center">
            Create your personalised loan quote
          </Heading>

          <Flex justifyContent="center" alignItems="center">
            <Antelope
              data-testid="logo-antelope"
              onClick={handleFAQScroll}
              cursor="pointer"
            />
          </Flex>

          <Text fontSize="md" textAlign="center">
            Provided by NewDay Ltd in partnership with Antelope Loans Funding
            Limited.
          </Text>
          <Text
            fontSize="md"
            textAlign="center"
            fontWeight="bold"
            data-testid="hero-eligibility-reminder"
          >
            Checking your eligibility won’t impact your credit score.
          </Text>
        </Stack>
      </ContentWrapper>

      <Flex mt={6} py={4} flexDirection="column" bg={wrapperBgColor}>
        <ContentWrapper>
          <HStack mb={6}>
            <InterestRateIcon />
            <Heading fontSize="2xl" lineHeight="tall" mt={2}>
              Instant Quote calculator
            </Heading>
          </HStack>
          <SimpleGrid columns={{ base: 1, md: 2 }}>
            <LoanSelector
              loanDetails={{
                amount,
                durationYears,
              }}
              onChangeLoanDetails={setLoanDetails}
            />
            <LoanBreakdown
              variant="dark"
              layout={
                /* istanbul ignore next */ isAboveMdViewport
                  ? 'singleColumn'
                  : undefined
              }
            />
          </SimpleGrid>
          <Text w="full" fontSize="xs" mt={3}>
            The loan offered to you, including how much you can borrow, for how
            long and the APR, will depend on our assessment of your application.
          </Text>

          <RepresentativeExample />
        </ContentWrapper>
      </Flex>

      <Flex
        bg="brand.primary"
        py={{ base: 8, md: 6 }}
        textAlign={{ base: 'left', md: 'center' }}
      >
        <ContentWrapper>
          <Stack>
            <Heading
              size="lg"
              fontWeight="bold"
              color="white"
              mb={4}
              data-testid="CTA-eligibility-reminder"
            >
              Checking your eligibility won’t impact your credit score
            </Heading>
            <NavigationButton
              w="full"
              to={FeRoutes.contactDetails}
              as={RouterLink}
              onClick={handleCheckMyEligibilityClick}
            >
              Check my eligibility
            </NavigationButton>
          </Stack>
        </ContentWrapper>
      </Flex>

      <ContentWrapper>
        <Faqs
          title="Frequently asked questions"
          index={faqIndex}
          onChange={setFaqIndex}
          items={brandContent.faq}
        >
          <Link
            onClick={handleFaqsClick}
            w="fit-content"
            isExternal
            tabIndex={0}
          >
            See all FAQs
          </Link>
        </Faqs>
      </ContentWrapper>

      <Stack bg={wrapperBgColor} py={6}>
        <ContentWrapper>
          <Heading size="xl" pb={2}>
            Not looking for a loan just now?
          </Heading>
          <Text>
            As long as you’ve opted in to our marketing communications, you’ll
            always receive any {uppercaseBrandName} loan offers that we think
            may be suitable for you. So keep an eye out, as you could get
            another invitation to apply in the future.
          </Text>
        </ContentWrapper>
      </Stack>
    </ResponsivePageLayout>
  );
};
export { LandingPage };
