import React, { useState } from 'react';
import { useEligibilityQuestions } from '../../app';
import { useLoanDetails } from '../../app/use-loan-details';
import {
  LoanSelector,
  LoanTextSummary as LoanTextSummaryComponent,
} from '../../components';
import {
  gtmTrackEvent,
  Events,
  FormActions,
  FormNames,
} from '../../utils/gtm-track-event';

const LoanTextSummary: React.FC = () => {
  const {
    loanDetails: { amount, durationYears },
    setLoanDetails,
  } = useLoanDetails();

  const { hasExistingData } = useEligibilityQuestions();

  const [showLoanSelector, setShowLoanSelector] = useState(false);

  const handleUpdateMyLoanDetails = () => {
    gtmTrackEvent({
      event: Events.FORM_LINK,
      form_name: FormNames.CHECK_ELIGIBILITY,
      form_action: FormActions.CLICK_LINK,
      link_text: 'update my loan details',
    });
    setShowLoanSelector(!showLoanSelector);
  };

  return (
    <>
      <LoanTextSummaryComponent
        amount={amount}
        time={durationYears}
        onUpdateMyLoanDetails={handleUpdateMyLoanDetails}
      />
      {showLoanSelector || hasExistingData ? (
        <LoanSelector
          loanDetails={{
            amount,
            durationYears,
          }}
          onChangeLoanDetails={setLoanDetails}
        />
      ) : null}
    </>
  );
};

export { LoanTextSummary };
