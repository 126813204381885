import { create } from 'zustand';

type UserMobileHandoff = {
  setIsUserFromMobileHandoff: (isUserFromMobileHandoff: boolean) => void;
  isUserFromMobileHandoff: boolean;
};

// istanbul ignore next
export const useIsUserFromMobileHandoff = create<UserMobileHandoff>((set) => ({
  setIsUserFromMobileHandoff: (isUserFromMobileHandoff: boolean) =>
    set(() => ({ isUserFromMobileHandoff: isUserFromMobileHandoff })),
  isUserFromMobileHandoff: false,
}));
