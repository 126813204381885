import colors from './foundations/colors';
import { components } from './components';

/**
 * Aqua theme using the new color tokens (e.g., brand.primary.500 instead of brand.primary)
 */
export default {
  colors,
  fonts: {
    heading: 'Boston, sans-serif',
    body: 'Boston, sans-serif',
  },
  radii: {
    brand: {
      field: '0.3125rem',
      cta: '0.375rem',
    },
  },
  components,
};
