// This component is completely covered by cypress tests
/* istanbul ignore file */
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@newday/core';
import { ExclamationMarkIcon } from '@newday/icons';
import React, { useEffect, useRef, useState } from 'react';

export type TokenExpAndIat = {
  exp: number;
  iat: number;
};

export type SessionTimeoutProps = {
  children: React.ReactNode;
  onSessionTimeout: () => void;
  onSessionRenewal: () => void;
  tokenExpAndIat?: TokenExpAndIat;
  countdownDurationInMs: number;
};

const getTimeRemainingText = (secondsRemaining: number) => {
  const seconds = secondsRemaining % 60;
  const minutes = Math.floor(secondsRemaining / 60);

  const minutesText = minutes
    ? minutes > 1
      ? `${minutes} mins`
      : `${minutes} min`
    : '';
  const secondsText = seconds
    ? seconds > 1
      ? `${seconds} secs`
      : `${seconds} sec`
    : '';

  return `${minutesText} ${secondsText}`;
};

const TimerDisplay = ({
  countDownDurationInMs,
}: {
  countDownDurationInMs: number;
}) => {
  const [secondsRemaing, setSecondsRemaining] = useState(
    countDownDurationInMs / 1000
  );

  const intervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSecondsRemaining((secondsRemaing) => secondsRemaing - 1);
    }, 1000);

    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (secondsRemaing <= 0 && intervalRef.current) {
      clearInterval(intervalRef.current);
      setSecondsRemaining(0);
    }
  }, [secondsRemaing]);

  return (
    <Heading fontSize="2xl" mt={8} role="timer">
      {getTimeRemainingText(secondsRemaing)}
    </Heading>
  );
};

const SessionTimeoutModal = ({
  isOpen,
  onClick,
  countDownDurationInMs,
}: {
  isOpen: boolean;
  onClick: () => void;
  countDownDurationInMs: number;
}) => {
  const { onClose } = useDisclosure();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent textAlign="center" p={2}>
        <ModalHeader>
          <ExclamationMarkIcon />
          <Heading mt={3}>Need more time to complete your application?</Heading>
        </ModalHeader>

        <ModalBody>
          <Text>
            To keep your information secure, your online session will expire in
          </Text>
          <TimerDisplay countDownDurationInMs={countDownDurationInMs} />
        </ModalBody>

        <ModalFooter>
          <Button w="full" onClick={onClick}>
            Keep the session
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const getMsUntilSessionTimeout = ({ exp, iat }: TokenExpAndIat): number => {
  return (exp - iat) * 1000;
};

export const SessionTimeout = ({
  children,
  onSessionTimeout,
  onSessionRenewal,
  tokenExpAndIat,
  countdownDurationInMs,
}: SessionTimeoutProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [shouldRenewOpenModalTimer, setShouldRenewOpenModalTimer] =
    useState(false);
  const sessionTimeoutTimer = useRef<NodeJS.Timer>();
  const openModalTimer = useRef<NodeJS.Timer>();

  const msRemaining = tokenExpAndIat
    ? getMsUntilSessionTimeout(tokenExpAndIat)
    : undefined;

  useEffect(() => {
    setIsOpen(false);
    // tested via cypress
    // istanbul ignore next
    if (openModalTimer.current) {
      clearTimeout(openModalTimer.current);
      setShouldRenewOpenModalTimer(true);
    }
  }, [tokenExpAndIat?.exp]);

  useEffect(() => {
    // renew session timer if required
    if (sessionTimeoutTimer.current && msRemaining) {
      clearTimeout(sessionTimeoutTimer.current);

      sessionTimeoutTimer.current = setTimeout(() => {
        onSessionTimeout();
      }, msRemaining);
    }
  }, [msRemaining, onSessionTimeout]);

  useEffect(() => {
    if (!sessionTimeoutTimer.current && msRemaining) {
      sessionTimeoutTimer.current = setTimeout(() => {
        onSessionTimeout();
      }, msRemaining);
    }
    return () => {
      sessionTimeoutTimer.current && clearTimeout(sessionTimeoutTimer.current);
    };
  }, [msRemaining, onSessionTimeout]);

  useEffect(() => {
    if (msRemaining) {
      openModalTimer.current = setTimeout(() => {
        setIsOpen(true);
      }, msRemaining - countdownDurationInMs);
    }
    setShouldRenewOpenModalTimer(false);

    return () => {
      openModalTimer.current && clearTimeout(openModalTimer.current);
    };
  }, [msRemaining, countdownDurationInMs, shouldRenewOpenModalTimer]);

  return (
    <>
      {msRemaining && (
        <SessionTimeoutModal
          isOpen={isOpen}
          onClick={() => {
            onSessionRenewal?.();
          }}
          countDownDurationInMs={countdownDurationInMs}
        />
      )}
      {children}
    </>
  );
};

export default SessionTimeout;
