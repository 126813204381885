import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { TwoColorIconProps } from '../icon';

export const InterestRateIcon = ({
  size,
  variant,
  color,
  ...props
}: TwoColorIconProps) => {
  const styles = useIconStyleConfig('TwoColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 60 60" __css={styles} {...props}>
      <path
        d="M29.7247 58.2216C45.276 58.2216 57.8828 45.6148 57.8828 30.0635C57.8828 14.5122 45.276 1.90543 29.7247 1.90543C14.1735 1.90543 1.56665 14.5122 1.56665 30.0635C1.56665 45.6148 14.1735 58.2216 29.7247 58.2216Z"
        fill="white"
      />
      <path
        d="M29.7248 58.9838C13.7615 58.9838 0.762207 45.9845 0.762207 30.0212C0.762207 14.0579 13.7615 1.05856 29.7248 1.05856C45.6881 1.05856 58.6874 14.0579 58.6874 30.0212C58.6874 46.0268 45.6881 58.9838 29.7248 58.9838ZM29.7248 2.70994C14.6507 2.70994 2.41358 14.9894 2.41358 30.0635C2.41358 45.1376 14.693 57.4171 29.7671 57.4171C44.8412 57.4171 57.1207 45.1376 57.1207 30.0635C57.0784 14.9894 44.7989 2.70994 29.7248 2.70994Z"
        fill="currentColor"
      />
      <path d="M44.7988 20.8328V42.216L43.3168 43.4862H19.266L19.0119 38.4474L18.4615 37.6429L17.5299 37.3889H13.0416L12.9146 19.7742L13.5074 18.4192L14.6506 17.6994L43.1051 17.8264L44.4601 18.7579L44.7988 20.8328Z" />
      <path
        d="M44.7141 17.8264H13.2957V22.4841H44.7141V17.8264Z"
        fill="white"
      />
      <path
        d="M19.0966 42.2583C18.6308 42.2583 18.2921 41.8772 18.2921 41.4538V38.8709C18.2921 38.4475 17.9533 38.1511 17.5723 38.1511H14.9893C14.5236 38.1511 14.1848 37.77 14.1848 37.3465C14.1848 36.9231 14.5659 36.542 14.9893 36.542H17.5723C18.8849 36.542 19.9435 37.6006 19.9435 38.9132V41.4962C19.9011 41.8772 19.5624 42.2583 19.0966 42.2583Z"
        fill="currentColor"
      />
      <path
        d="M42.6394 44.3331H18.9273C18.1652 44.3331 17.4453 44.0367 16.8949 43.4863L12.957 39.5484C12.4065 38.9979 12.1101 38.2781 12.1101 37.5159V19.7742C12.1101 19.012 12.4065 18.2922 12.957 17.7418C13.5074 17.1913 14.2273 16.8949 14.9894 16.8949H42.6817C44.2484 16.8949 45.5611 18.1652 45.5611 19.7742V22.8653C45.5611 23.331 45.18 23.6698 44.7565 23.6698C44.3331 23.6698 43.952 23.2887 43.952 22.8653V19.7742C43.952 19.0967 43.4016 18.5039 42.6817 18.5039H14.9894C14.6507 18.5039 14.3543 18.631 14.1002 18.885C13.8462 19.1391 13.7191 19.4355 13.7191 19.7742V37.4736C13.7191 37.8123 13.8462 38.1511 14.1002 38.3628L18.0381 42.3007C18.2922 42.5547 18.5886 42.6818 18.9273 42.6818H42.6394C43.3169 42.6818 43.9097 42.1313 43.9097 41.4115V29.0473C43.9097 28.5816 44.2908 28.2428 44.7142 28.2428C45.1376 28.2428 45.5187 28.6239 45.5187 29.0473V41.4115C45.5187 43.0205 44.2484 44.3331 42.6394 44.3331Z"
        fill="currentColor"
      />
      <path
        d="M20.1976 20.6634C19.7318 20.6634 19.3931 20.2823 19.3931 19.8589V15.0318C19.3931 14.566 19.7742 14.2273 20.1976 14.2273C20.621 14.2273 21.0021 14.6084 21.0021 15.0318V19.8589C21.0021 20.2823 20.621 20.6634 20.1976 20.6634Z"
        fill="currentColor"
      />
      <path
        d="M37.3465 20.6634C36.8807 20.6634 36.542 20.2823 36.542 19.8589V15.0318C36.542 14.566 36.9231 14.2273 37.3465 14.2273C37.7699 14.2273 38.151 14.6084 38.151 15.0318V19.8589C38.1934 20.2823 37.8123 20.6634 37.3465 20.6634Z"
        fill="currentColor"
      />
      <path
        d="M21.3408 27.3959C21.3408 25.4905 22.6535 24.2626 24.5589 24.2626C26.4643 24.2626 27.8193 25.5329 27.8193 27.3959C27.8193 29.259 26.5067 30.614 24.5589 30.614C22.8652 30.6987 21.4255 29.4284 21.3408 27.7347C21.3408 27.6077 21.3408 27.523 21.3408 27.3959ZM26.1256 27.3959C26.0832 26.5491 25.4057 25.9139 24.5589 25.9563C23.712 25.9986 23.0769 26.6761 23.1192 27.523C23.1616 28.3275 23.7967 28.9626 24.6012 28.9626C25.4057 29.005 26.0832 28.3698 26.1256 27.5653C26.1256 27.523 26.1256 27.4806 26.1256 27.3959ZM25.2787 38.024L24.0931 37.4736C23.9237 37.4312 23.8814 37.2619 23.9237 37.0925C23.9237 37.0501 23.9661 37.0501 23.9661 37.0078L31.4608 24.4319C31.5031 24.2626 31.6725 24.1779 31.8419 24.2626C31.8842 24.2626 31.8842 24.2626 31.8842 24.3049L33.0698 24.8977C33.2392 24.94 33.2815 25.1094 33.2392 25.2788C33.2392 25.3211 33.1969 25.3211 33.1969 25.3635L25.7445 37.897C25.6598 38.0664 25.4481 38.1087 25.2787 38.024ZM29.5554 34.8906C29.5554 32.9852 30.8256 31.7573 32.7734 31.7573C34.7212 31.7573 36.0338 32.9852 36.0338 34.8906C36.0338 36.7961 34.7212 38.0664 32.7734 38.0664C30.8256 38.0664 29.5554 36.7961 29.5554 34.8906ZM34.2978 34.8906C34.3401 34.0861 33.7473 33.451 32.9428 33.4086C32.9005 33.4086 32.8158 33.4086 32.7734 33.4086C31.9689 33.3663 31.2914 34.0014 31.2491 34.7636C31.2491 34.806 31.2491 34.8483 31.2491 34.8906C31.2067 35.6952 31.7995 36.415 32.6041 36.4573C32.6464 36.4573 32.6887 36.4573 32.7311 36.4573C33.5356 36.4997 34.2131 35.9069 34.2554 35.1024C34.2978 35.0177 34.2978 34.933 34.2978 34.8906Z"
        fill="white"
      />
    </Icon>
  );
};
