import { ComponentStyleConfig } from '@chakra-ui/react';

export const Container: ComponentStyleConfig = {
  baseStyle: {
    px: '0',
  },
  variants: {
    md: { maxW: '768px' },
    lg: { maxW: '1145px', mx: { base: '7.5', md: '15' } },
  },
};
