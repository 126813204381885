import amazon from './amazon';
import aqua from './aqua';
import aquacard from './aquacard';
import arcadia from './arcadia';
import argos from './argos';
import bip from './bip';
import bipcard from './bipcard';
import evans from './evans';
import fluid from './fluid';
import johnlewis from './johnlewis';
import whitelabel from './whitelabel';
import houseoffraser from './houseoffraser';
import marbles from './marbles';
import newday from './newday';
import newpay from './newpay';
import opus from './opus';
import pulse from './pulse';
import tui from './tui';
import marblescard from './marblescard';

const brandThemes = {
  // place whitelabel first so it is selected as default in toggle theme
  whitelabel,
  aquacard,
  marbles,
  marblescard,
  aqua,
  amazon,
  arcadia,
  argos,
  bip,
  bipcard,
  evans,
  fluid,
  johnlewis,
  houseoffraser,
  newday,
  newpay,
  opus,
  tui,
  pulse,
  pulsepersonal: pulse,
};

export type BrandName = keyof typeof brandThemes;

const isBrandName = (maybeBrandName: string): maybeBrandName is BrandName =>
  Object.keys(brandThemes).includes(maybeBrandName);

export { brandThemes, isBrandName };
