import React from 'react';

export const BipSecondary = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-testid="secondary-logo-bip"
    width="79"
    height="41"
    viewBox="0 0 79 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="bip-secondary-logo-label"
    role="img"
    {...props}
  >
    <title id="bip-secondary-logo-label">Bip secondary logo</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2441 0.773224C29.1398 0.773224 26.6142 3.27183 26.6142 6.34284C26.6142 9.41436 29.1398 11.9125 32.2441 11.9125C35.3484 11.9125 37.874 9.41436 37.874 6.34284C37.874 3.27183 35.3484 0.773224 32.2441 0.773224ZM28.1496 35.7099H36.8504V13.9378H28.1496V35.7099ZM0 7.86182H8.78034V14.9088C10.3945 13.825 12.6128 13.2057 15.0207 13.2057C21.8471 13.2057 26.6142 17.8326 26.6142 24.4573C26.6142 25.6032 26.4665 26.719 26.1768 27.7722C26.0808 28.1211 25.9672 28.4679 25.8396 28.8035C25.7751 28.9726 25.7069 29.1386 25.6351 29.3026C25.3465 29.9616 24.9938 30.5881 24.5874 31.1641C24.4857 31.3092 24.3793 31.4518 24.2693 31.5918C24.0494 31.8714 23.8124 32.1424 23.5645 32.397C21.4587 34.5645 18.5041 35.7099 15.0207 35.7099C12.6128 35.7099 10.3945 35.0906 8.78034 34.0069V35.2521H0V7.86182ZM17.949 24.4573C17.949 21.4719 15.4172 20.4121 13.249 20.4121C10.5484 20.4121 8.58771 22.0657 8.58771 24.3432V24.5719C8.58771 26.8499 10.5484 28.503 13.249 28.503C15.4172 28.503 17.949 27.4432 17.949 24.4573ZM47.1667 15.1429C48.7803 14.054 50.9992 13.4315 53.407 13.4315C60.2329 13.4315 65 18.0825 65 24.742C65 25.8949 64.8528 27.0161 64.5626 28.0743C64.4661 28.427 64.3525 28.7757 64.2254 29.1115C64.1614 29.2804 64.0933 29.4473 64.0215 29.6122C63.7323 30.2757 63.3796 30.9059 62.9738 31.4839C62.871 31.6303 62.7646 31.7736 62.6547 31.9144C62.4347 32.1955 62.1977 32.4678 61.9508 32.7233C59.8446 34.9022 56.8904 36.0536 53.407 36.0536C50.9992 36.0536 48.7803 35.431 47.1667 34.3416V40.7732H38.3858V13.8917H47.1667V15.1429ZM56.3347 24.742C56.3347 21.741 53.8035 20.6756 51.6353 20.6756C48.9341 20.6756 46.974 22.3379 46.974 24.6273V24.8572C46.974 27.1472 48.9341 28.809 51.6353 28.809C53.8035 28.809 56.3347 27.7436 56.3347 24.742Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.5 23.7732C70.4673 23.7732 68 26.2406 68 29.2732C68 32.3063 70.4673 34.7732 73.5 34.7732C76.5327 34.7732 79 32.3063 79 29.2732C79 26.2406 76.5327 23.7732 73.5 23.7732Z"
      fill="#8050F2"
    />
  </svg>
);
