import { BffRoutes } from '@newday/plum-types';
import axios from 'axios';
import { useQuery } from 'react-query';

export const useHashedAppIdQuery = (applicationId: string) =>
  useQuery(
    ['hashed-app-id', applicationId],
    () =>
      axios
        .get(
          `${BffRoutes.hashedApplicationId.replace(
            ':applicationId',
            applicationId
          )}`
        )
        .then((response) => response.data),
    {
      enabled: applicationId?.length > 0,
    }
  );
