import React from 'react';
import { Stepper, StepperProps } from '@newday/core';
import {
  CardUsageIcon,
  ListIcon,
  PoundSignIcon,
  TickIcon,
} from '@newday/icons';

export interface ReferStepperProps
  extends Omit<StepperProps, 'css' | 'steps' | 'variant'> {
  skipDocumentUploadedStep?: boolean;
}

const steps = [
  {
    label: 'Document uploaded',
    icon: <TickIcon />,
  },
  {
    label: 'Application in review',
    icon: <ListIcon />,
  },
  {
    label: 'Decision confirmed',
    icon: <CardUsageIcon />,
  },
  {
    label: 'Loan paid',
    icon: <PoundSignIcon />,
  },
];

export const ReferStepper: React.FC<ReferStepperProps> = ({
  activeStep,
  skipDocumentUploadedStep = false,
  ...rest
}) => {
  return (
    <Stepper
      activeStep={activeStep}
      variant="secondary"
      steps={skipDocumentUploadedStep ? steps.slice(1) : steps}
      px={{ base: 0, xs: 5 }}
      maxW={{ md: skipDocumentUploadedStep ? 'sm' : 'md' }}
      {...rest}
    />
  );
};
