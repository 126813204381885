export enum BankDetailStatuses {
  redirect = 'REDIRECT',
  referGeneral = 'REFER_GENERAL',
  retryBankValidation = 'RETRY_BANK_VALIDATION',
  success = 'SUCCESS',
}

export type BankDetailsResponseWithoutRemainingAttempts = {
  status: Exclude<BankDetailStatuses, BankDetailStatuses.retryBankValidation>;
};

export type BankDetailsResponseWithRemainingAttempts = {
  status: BankDetailStatuses.retryBankValidation;
  remainingAttempts: number;
};

export type BankDetailsResponse =
  | BankDetailsResponseWithoutRemainingAttempts
  | BankDetailsResponseWithRemainingAttempts;
