import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { TwoColorIconProps } from '../icon';

export const CreditBuilderIcon = ({
  size,
  variant,
  color,
  ...props
}: TwoColorIconProps) => {
  const styles = useIconStyleConfig('TwoColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 60 60" __css={styles} {...props}>
      <path
        d="M30.0656 58.1754C45.6043 58.1754 58.2008 45.5788 58.2008 30.0402C58.2008 14.5016 45.6043 1.90503 30.0656 1.90503C14.527 1.90503 1.93044 14.5016 1.93044 30.0402C1.93044 45.5788 14.527 58.1754 30.0656 58.1754Z"
        fill="white"
      />
      <path
        d="M30.0656 2.71024C14.9717 2.71024 2.73564 14.9463 2.73564 30.0402C2.73564 45.1341 14.9717 57.3702 30.0656 57.3702C45.1595 57.3702 57.3956 45.1341 57.3956 30.0402C57.3956 14.9463 45.1595 2.71024 30.0656 2.71024ZM1.12521 30.0402C1.12521 14.0569 14.0823 1.09982 30.0656 1.09982C46.049 1.09982 59.006 14.0569 59.006 30.0402C59.006 46.0235 46.049 58.9806 30.0656 58.9806C14.0823 58.9806 1.12521 46.0235 1.12521 30.0402Z"
        fill="currentColor"
      />
      <path d="M30.2434 18.7201C33.6241 18.7201 36.8663 20.0631 39.2567 22.4535C41.6472 24.844 42.9902 28.0862 42.9902 31.4669H50.9998C50.9998 25.9619 48.813 20.6825 44.9204 16.7899C41.0278 12.8973 35.7483 10.7105 30.2434 10.7105C24.7385 10.7105 19.459 12.8973 15.5664 16.7899C11.6739 20.6825 9.48705 25.9619 9.48705 31.4669H17.4966C17.4966 28.0862 18.8396 24.844 21.2301 22.4535C23.6206 20.0631 26.8628 18.7201 30.2434 18.7201Z" />
      <path
        d="M14.8748 16.2295C18.916 12.1883 24.3971 9.918 30.1122 9.918C35.8273 9.918 41.3083 12.1883 45.3495 16.2295C49.3907 20.2707 51.6611 25.7518 51.6611 31.4669C51.6611 31.9116 51.3005 32.2721 50.8558 32.2721H42.8505C42.6368 32.2721 42.4319 32.1872 42.2809 32.036C42.1298 31.8848 42.0451 31.6798 42.0453 31.4662C42.0468 29.7338 41.6706 28.022 40.9429 26.4499C40.7561 26.0463 40.9318 25.5677 41.3353 25.3809C41.7389 25.1941 42.2175 25.3698 42.4043 25.7734C43.1173 27.3136 43.533 28.9721 43.6324 30.6617H50.0344C49.8327 25.6663 47.7598 20.9172 44.2108 17.3683C40.4716 13.6291 35.4002 11.5284 30.1122 11.5284C24.8242 11.5284 19.7527 13.6291 16.0136 17.3683C12.4646 20.9172 10.3917 25.6663 10.19 30.6617H16.5756C16.7021 28.535 17.3286 26.4639 18.4089 24.6189C19.6252 22.5417 21.3728 20.8259 23.4719 19.648C25.5711 18.47 27.9462 17.8725 30.3529 17.9167C32.7596 17.961 35.111 18.6455 37.1655 19.8998C37.5451 20.1315 37.6649 20.6271 37.4332 21.0066C37.2014 21.3862 36.7059 21.506 36.3263 21.2743C34.516 20.169 32.444 19.5659 30.3233 19.5269C28.2026 19.4879 26.1098 20.0144 24.26 21.0524C22.4103 22.0903 20.8704 23.6022 19.7987 25.4326C18.7269 27.263 18.1621 29.3458 18.1621 31.4668C18.1622 31.6804 18.0773 31.8852 17.9263 32.0362C17.7753 32.1873 17.5705 32.2721 17.3569 32.2721H9.36851C9.15495 32.2721 8.95014 32.1873 8.79913 32.0362C8.64813 31.8852 8.56329 31.6804 8.56329 31.4669C8.56329 25.7518 10.8336 20.2707 14.8748 16.2295Z"
        fill="currentColor"
      />
      <path
        d="M30.2646 36.4072C29.4535 36.4069 28.6607 36.1662 27.9864 35.7154C27.3121 35.2646 26.7865 34.6241 26.4762 33.8747C26.1658 33.1253 26.0846 32.3008 26.2427 31.5052C26.4009 30.7097 26.7913 29.9789 27.3647 29.4052C27.9376 28.8397 28.5875 28.358 29.2951 27.9743L38.2869 23.8848C38.3064 23.8805 38.3266 23.8805 38.3461 23.8848C38.3855 23.8852 38.4233 23.9003 38.452 23.9272C38.4746 23.9483 38.4901 23.976 38.4961 24.0064C38.5022 24.0368 38.4986 24.0683 38.4858 24.0965L34.6292 33.0713C34.3083 33.8791 33.8073 34.603 33.1645 35.188C32.7861 35.5734 32.3348 35.8796 31.837 36.0889C31.3391 36.2982 30.8046 36.4065 30.2646 36.4072ZM30.1672 30.4805C29.7088 30.4806 29.2647 30.6397 28.9105 30.9306C28.5563 31.2215 28.3138 31.6262 28.2246 32.0758C28.1353 32.5253 28.2046 32.992 28.4208 33.3962C28.637 33.8003 28.9866 34.1171 29.4101 34.2923C29.8337 34.4676 30.3049 34.4907 30.7434 34.3575C31.182 34.2243 31.5609 33.9432 31.8154 33.562C32.07 33.1808 32.1845 32.7232 32.1395 32.2671C32.0944 31.8109 31.8926 31.3845 31.5685 31.0605C31.3869 30.8708 31.1697 30.7188 30.9292 30.6135C30.6887 30.5081 30.4297 30.4514 30.1672 30.4466V30.4805Z"
        fill="currentColor"
      />
      <path
        d="M38.3461 24.0119L34.4937 33.0205C34.1766 33.8107 33.6862 34.5195 33.0586 35.0949C32.3176 35.8359 31.3125 36.2522 30.2646 36.2522C29.2166 36.2522 28.2116 35.8359 27.4705 35.0949C26.7295 34.3539 26.3132 33.3488 26.3132 32.3009C26.3132 31.2529 26.7295 30.2478 27.4705 29.5068C28.0333 28.9512 28.6718 28.478 29.3671 28.1013L38.3461 24.0203V24.0119ZM30.1672 34.5615C30.6562 34.5587 31.1292 34.3866 31.5057 34.0745C31.8823 33.7625 32.1391 33.3296 32.2326 32.8496C32.3261 32.3696 32.2506 31.872 32.0187 31.4414C31.7869 31.0108 31.4131 30.6738 30.9609 30.4876C30.5087 30.3014 30.006 30.2775 29.5382 30.42C29.0703 30.5625 28.6663 30.8626 28.3947 31.2693C28.1231 31.676 28.0007 32.1642 28.0483 32.6509C28.0959 33.1376 28.3106 33.5928 28.6559 33.9392C28.854 34.1379 29.0896 34.2953 29.3491 34.4021C29.6085 34.5089 29.8866 34.5631 30.1672 34.5615ZM38.3461 23.7071C38.3094 23.7104 38.2735 23.7205 38.2403 23.7367L29.2655 27.8135H29.2401C28.5213 28.204 27.8614 28.6943 27.28 29.2697C26.6771 29.8609 26.2647 30.6189 26.0959 31.4463C25.9271 32.2736 26.0095 33.1326 26.3327 33.9127C26.6558 34.6929 27.2049 35.3585 27.9094 35.8242C28.6138 36.2898 29.4414 36.5342 30.2857 36.5258C30.8441 36.5268 31.3971 36.4173 31.9129 36.2037C32.4288 35.9901 32.8973 35.6766 33.2915 35.2812C33.9479 34.6766 34.4613 33.9332 34.7943 33.1052L38.6171 24.1304C38.6396 24.0888 38.6513 24.0422 38.6509 23.9949C38.6509 23.9152 38.6193 23.8388 38.5629 23.7824C38.5065 23.726 38.4301 23.6944 38.3504 23.6944L38.3461 23.7071ZM30.1672 34.2609C29.743 34.2603 29.3322 34.1126 29.0047 33.8429C28.6773 33.5733 28.4535 33.1984 28.3715 32.7823C28.2895 32.3661 28.3543 31.9343 28.555 31.5606C28.7557 31.1869 29.0798 30.8944 29.472 30.7329C29.8642 30.5714 30.3003 30.5509 30.706 30.6749C31.1116 30.799 31.4617 31.0599 31.6965 31.4131C31.9313 31.7664 32.0364 32.1901 31.9937 32.6122C31.9511 33.0342 31.7634 33.4284 31.4626 33.7275C31.2926 33.8976 31.0906 34.0323 30.8683 34.1238C30.6459 34.2154 30.4077 34.262 30.1672 34.2609Z"
        fill="currentColor"
      />
    </Icon>
  );
};
