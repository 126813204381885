export default {
  brand: {
    primary: {
      50: '#e6e6f6',
      100: '#8080d3',
      500: '#0000A6',
      700: '#000053',
    },
    secondary: {
      50: '#fffaeb',
      100: '#ffe699',
      500: '#FFCC32',
      600: '#EBBC00',
      700: '#806619',
    },
    tertiary: {
      50: '#fafcff',
      100: '#e4f0fd',
      500: '#C8E1FA',
      700: '#64717d',
      default: '#00D8B0',
    },
    quaternary: {
      50: '#e6fbf7',
      100: '#80ecd8',
      500: '#00D8B0',
      700: '#006c58',
    },
    grayScale: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
  },
};
