import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';
import { ReactElement, forwardRef, LegacyRef } from 'react';

export interface ButtonProps extends ChakraButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-dark-bg';
}

export const Button = forwardRef(
  (
    { children, ...otherProps }: ButtonProps,
    ref?: LegacyRef<HTMLButtonElement>
  ): ReactElement => {
    return (
      <ChakraButton ref={ref} {...otherProps}>
        {children}
      </ChakraButton>
    );
  }
);
