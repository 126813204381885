import { FeRoutes } from '@newday/plum-types';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplicationId } from '../../app';
import {
  HeaderStepper,
  Hero,
  Loading,
  ResponsivePageLayout,
} from '../../components';
import { EligibilityForm } from '../../features/eligibility-form';
import { useRedirect } from '../../shared/queries';

export const EligibilityPage: React.FC = () => {
  const navigate = useNavigate();
  const { applicationId } = useApplicationId();
  const [isSuccessfullySaved, setIsSuccessfullySaved] = React.useState(false);
  const [isEligibilityLoading, setIsEligibilityLoading] = React.useState(false);

  const handleBack = () => {
    navigate(FeRoutes.contactDetails);
  };

  const {
    data: redirectData,
    isLoading: isRedirectLoading,
    isSuccess: isRedirectSuccess,
  } = useRedirect(applicationId, isSuccessfullySaved);

  useEffect(() => {
    if (isRedirectSuccess) {
      navigate(redirectData.data.redirect);
    }
  }, [redirectData, isRedirectSuccess, navigate]);

  const handleSuccess = useCallback(() => {
    setIsSuccessfullySaved(true);
  }, []);

  const handleEligibilityLoading = useCallback((isLoading: boolean) => {
    setIsEligibilityLoading(isLoading);
  }, []);

  return (
    <ResponsivePageLayout headerStepper={<HeaderStepper activeStep={2} />}>
      <Hero
        title="Tell us a little more about yourself"
        subtitle="Your answers will help us check if you’re eligible for a personal loan – and if so, how much you could borrow."
        description="Answering these won’t impact your credit score."
        variant="dark"
      />
      <EligibilityForm
        onBack={handleBack}
        onSuccess={handleSuccess}
        onLoading={handleEligibilityLoading}
      />
      <Loading
        isLoading={
          isRedirectLoading || isEligibilityLoading
          // || isPreEligibilityLoading
        }
        title="We're checking your eligibility..."
        secondsToLoad={20}
      />
    </ResponsivePageLayout>
  );
};
