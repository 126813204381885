import { Text } from '@newday/core';
import React from 'react';
import { convertNumberToWord } from '../../utils';
export interface LoanTextSummaryProps {
  amount: number;
  time: number;
  onUpdateMyLoanDetails: () => void;
}

export const LoanTextSummary = (props: LoanTextSummaryProps) => {
  const { amount, time, onUpdateMyLoanDetails } = props;
  const yearOrYears = time > 1 ? 'years' : 'year';
  const timeInWord = convertNumberToWord(time);

  return (
    <>
      <Text>
        You&apos;re about to check your eligibility for a loan of{' '}
        <strong>£{amount}</strong> over{' '}
        <strong>
          {timeInWord} {yearOrYears}
        </strong>
        . If that&apos;s not right, update the details before going ahead.
      </Text>
      <Text
        as="button"
        type="button"
        onClick={onUpdateMyLoanDetails}
        textAlign={'left'}
        fontSize="sm"
        fontWeight="bold"
        mt={3}
        mb={8}
        pb={1}
        width={'max-content'}
        borderBottomColor="brand.tertiary"
        borderBottomWidth="2px"
      >
        Update my loan details
      </Text>
    </>
  );
};
