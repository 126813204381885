import { Box, Skeleton, Text } from '@newday/core';
import { useApplicationId } from '../../app';
import {
  ContentWrapper,
  HeaderStepper,
  Hero,
  ReferStepper,
  ResponsivePageLayout,
} from '../../components';
import { useApplicationReference, useUserDetails } from '../../shared/queries';

export const GeneralReferPage: React.FC = () => {
  const { applicationId } = useApplicationId();
  const { data, isSuccess: isSuccessAppRef } =
    useApplicationReference(applicationId);
  const { data: userDetails, isSuccess: isSuccessUserDetails } = useUserDetails(
    applicationId,
    { obfuscateEmail: true }
  );

  return (
    <ResponsivePageLayout headerStepper={<HeaderStepper activeStep={4} />}>
      <Hero
        preTitle="Nearly there"
        title="We just need to do some checks"
        subtitle="We're taking a closer look at one or two things, then we'll get back to you."
        variant="dark"
      >
        <Skeleton isLoaded={isSuccessAppRef} mt={4}>
          <Text color="white" fontWeight="semibold">
            {`Your application reference is ${data?.reference}.`}
          </Text>
        </Skeleton>
      </Hero>
      <ContentWrapper py={8}>
        <Text
          as="legend"
          mb={2}
          fontSize="lg"
          color="brand.primary"
          fontWeight="bold"
        >
          What happens next?
        </Text>
        <ReferStepper skipDocumentUploadedStep activeStep={1} py={3} />
        <Box>
          We&apos;ll review your application. This usually takes about{' '}
          <Text fontWeight="bold" display="inline">
            seven to ten working days
          </Text>
          , but we&apos;ll be as quick as we can.
        </Box>
        <Skeleton isLoaded={isSuccessUserDetails} mt={4}>
          <Box>
            We&apos;ll update you by email at{' '}
            <Text display="inline" textDecoration="underline">
              {userDetails?.emailAddress}
            </Text>
            &nbsp;once we&apos;ve finished our checks. If your loan is approved,
            it should reach your account within 48 hours of the decision.
          </Box>
        </Skeleton>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
