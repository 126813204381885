import { Heading, SimpleGrid, Stack, Text } from '@newday/core';
import {
  BalloonBox,
  BoostCreditScoreHelp,
  BoostCreditScoreTips,
  ContentWrapper,
  FaqPanel,
  ResponsivePageLayout,
} from '../../components';
import { useBrand } from '../../app';
import { ApplicationReference } from '../../features';

export const ReturnApplicationDeclinedPage: React.FC = () => {
  const { brand } = useBrand();

  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <BalloonBox variant={brand === 'aqua' ? 'light' : 'generic'}>
        <>
          <Heading fontSize="3xl" fontWeight="bold">
            Welcome back
          </Heading>
          <Text fontWeight="semibold" mt={2} mb={4} color="black">
            We’re sorry that your loan application wasn’t successful.
          </Text>
          <Text mb={4} color="black">
            We take our responsibilities seriously as a lender. Looking at all
            the information we’ve received, unfortunately we can’t offer you a
            loan right now.
          </Text>
        </>
      </BalloonBox>
      <ContentWrapper>
        {brand === 'aqua' ? (
          <>
            <Text
              mt={4}
              mb={2}
              fontSize="lg"
              fontWeight="bold"
              textAlign={{ md: 'center' }}
            >
              Helping you boost your credit score
            </Text>
            <Text fontWeight="semibold">
              You could improve your chances of getting a loan in future by
              building better credit – and we’ve got lots of tools and tips to
              support you.
            </Text>
            <SimpleGrid gap={4} mt={4} mb={8} columns={{ base: 1, md: 3 }}>
              <BoostCreditScoreHelp />
            </SimpleGrid>
          </>
        ) : (
          <Stack gap={4}>
            <BoostCreditScoreTips />
            <FaqPanel />
          </Stack>
        )}
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
