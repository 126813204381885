import { BffRoutes } from '@newday/plum-types';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

export const useGetMisthoTransaction = (
  applicationId: string,
  enabled: boolean
) =>
  useQuery(
    ['mistho-state', applicationId],
    () =>
      axios
        .get(`${BffRoutes.mistho.replace(':applicationId', applicationId)}`)
        .then((response) => response.data),
    { enabled, useErrorBoundary: false }
  );

export const useCreateMisthoTransaction = (applicationId: string) =>
  useMutation(() =>
    axios
      .post(BffRoutes.mistho.replace(':applicationId', applicationId))
      .then((response) => response.data)
  );
