import { Button, Text } from '@newday/core';
import { FeRoutes } from '@newday/plum-types';
import { useNavigate } from 'react-router-dom';
import { Hero, ResponsivePageLayout } from '../../components';
import { ApplicationReference } from '../../features';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';

export const DocUploadSkippedPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_action: FormActions.CLICK_CTA,
      form_name: FormNames.DOC_UPLOAD,
      link_text: 'Submit now',
    });

    navigate(FeRoutes.documentUpload);
  };

  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <Hero title="Your loan application is paused for now" fullWidth>
        <>
          <Text fontWeight="bold" color="black">
            Restart it by uploading proof of your income.
          </Text>
          <Text pt={2} color="black">
            Please come back and do this within 14 days. We’ve emailed you a
            link to make it easier.
          </Text>
          <Text fontWeight="semibold" pt={2} pb={2} color="black">
            Ready to upload your document(s)? Just use the button below.
          </Text>
          <Button maxW={{ md: 32 }} onClick={handleClick}>
            Submit now
          </Button>
        </>
      </Hero>
    </ResponsivePageLayout>
  );
};
