import { BffRoutes, LocalStorageTypes } from '@newday/plum-types';
import { createContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIsUserFromMobileHandoff } from '../stores/is-mobile-user';
import { useJWT } from './auth-query';
import { useApplicationId } from './use-application-id';

type AuthContextType = {
  authenticated: boolean;
  isLoading: boolean;
  setIsAuthenticated: (param: boolean) => void;
  isAuthenticatedStateSet: boolean;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

const AuthContextProvider = ({ children, authenticated = false }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(authenticated);
  const [searchParams] = useSearchParams();
  const { applicationId } = useApplicationId();
  const handoverToken = searchParams.get('token');
  const localJWT = localStorage.getItem(LocalStorageTypes.BEARER) || '';
  const { setIsUserFromMobileHandoff } = useIsUserFromMobileHandoff();
  const [isAuthenticatedStateSet, setIsAuthenticatedStateSet] = useState(false);

  const route = handoverToken
    ? BffRoutes.generateToken
    : BffRoutes.refreshToken;
  const requestToken = handoverToken ? handoverToken : localJWT;

  const { data: jwtToken, isLoading } = useJWT(
    applicationId,
    requestToken,
    route
  );

  // istanbul ignore next
  useEffect(() => {
    if (handoverToken && jwtToken) {
      setIsUserFromMobileHandoff(true);
    }
  }, [handoverToken, setIsUserFromMobileHandoff, jwtToken]);

  useEffect(() => {
    if (jwtToken) {
      setIsAuthenticated(true);
    }
  }, [jwtToken]);

  useEffect(() => {
    if (!isAuthenticatedStateSet && !isLoading) {
      setIsAuthenticatedStateSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  return (
    <AuthContext.Provider
      value={{
        authenticated: isAuthenticated,
        isLoading,
        setIsAuthenticated,
        isAuthenticatedStateSet,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
