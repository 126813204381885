import { Button } from '@newday/core';
import { DownloadIcon } from '@newday/icons';
import { BffRoutes } from '@newday/plum-types';
import { DocumentTypes } from '@newday/shared-unified-api-client';
import axios from 'axios';
import React, { useState } from 'react';
import { downloadPdfBlob } from './download-pdf-blob';

interface DownloadPdfButtonProps {
  applicationId: string;
  documentType: keyof typeof DocumentTypes;
}

const DownloadPdfButton: React.FC<DownloadPdfButtonProps> = ({
  applicationId,
  documentType,
}) => {
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);

  const fetchDocument = () => {
    setIsFetchingDocument(true);
    return axios
      .get(
        `${BffRoutes.document
          .replace(':applicationId', applicationId)
          .replace(':documentType', documentType)}`,
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'blob',
        }
      )
      .then((response) => {
        downloadPdfBlob(response.data, documentType);
        setIsFetchingDocument(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Button
      size="md"
      variant="tertiary"
      rightIcon={<DownloadIcon size="xs" />}
      onClick={fetchDocument}
    >
      {isFetchingDocument ? 'Downloading PDF...' : 'Download PDF'}
    </Button>
  );
};

export { DownloadPdfButton };
