import { ComponentStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: 'brand.primary.500',
  },
  sizes: {
    '3xl': {
      lineHeight: '28px',
      fontWeight: 'black',
      fontSize: ['3xl', null, null, '4xl'],
    },
  },
};
