import React from 'react';
import {
  Text,
  Heading,
  Accordion as CoreAccordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Stack,
  ExpandedIndex,
} from '@newday/core';

export type FaqItem = {
  id?: string;
  buttonLabel: string;
  content: string;
};

export type FaqProps = {
  title: string;
  items: FaqItem[];
  children?: React.ReactNode;
  index?: ExpandedIndex;
  onChange?: (expandedIndex: ExpandedIndex) => void;
};

export const Faqs = ({ title, items, children, index, onChange }: FaqProps) => {
  return (
    <Stack spacing={6} my={6}>
      <Heading size="xl">{title}</Heading>
      <CoreAccordion index={index} onChange={onChange}>
        {items.map((item) => {
          return (
            <AccordionItem key={item.buttonLabel} id={item.id}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    justifyContent="space-between"
                    textAlign="left"
                    _focus={{
                      border: '1px',
                      borderColor: 'brand.primary',
                      borderRadius: 'md',
                      borderStyle: 'dashed',
                    }}
                  >
                    {item.buttonLabel}
                    <AccordionIcon expanded={isExpanded} />
                  </AccordionButton>
                  <AccordionPanel>
                    <Text
                      fontSize="md"
                      lineHeight={6}
                      css={{ whiteSpace: 'pre-line' }}
                    >
                      {item.content}
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </CoreAccordion>
      {children}
    </Stack>
  );
};
