import React, { useEffect, useRef } from 'react';
import { Text, Input, Flex, NumberInput, NumberInputField } from '@newday/core';

export type DateFormat = {
  day: string;
  month: string;
  year: string;
};

export type DateOfBirthInputProps = {
  defaultValue?: DateFormat;
  isInvalid?: boolean;
  onBlur: (date: DateFormat) => void;
  onChange?: (date: DateFormat) => void;
  legend?: string;
  placeholder?: DateFormat;
};

const dayMin = 1;
const dayMax = 31;

const monthMin = 1;
const monthMax = 12;

const DateOfBirthInput: React.FC<DateOfBirthInputProps> = ({
  defaultValue,
  isInvalid,
  legend,
  onBlur,
  onChange,
  placeholder,
  ...props
}) => {
  const [day, setDay] = React.useState(defaultValue?.day || '');
  const [month, setMonth] = React.useState(defaultValue?.month || '');
  const [year, setYear] = React.useState(defaultValue?.year || '');

  const dayInputRef = useRef<HTMLInputElement>(null);
  const monthInputRef = useRef<HTMLInputElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (day || month || year) {
      onChange?.({ day, month, year });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { relatedTarget } = event;

    if (!(relatedTarget as HTMLInputElement)?.name?.includes('date-of-birth-'))
      onBlur({ day, month, year });
  };

  return (
    <fieldset
      aria-invalid={isInvalid}
      id="date-of-birth-fieldset"
      aria-errormessage="date-of-birth-error"
      className="plum-mouseflow-hidden"
    >
      <Text
        as="legend"
        mb={1}
        color="brand.primary"
        fontWeight="semibold"
        onClick={() => {
          dayInputRef.current?.focus();
        }}
      >
        {legend ? legend : 'Date of birth (DD/MM/YYYY)'}
      </Text>
      <Input
        as={Flex}
        {...props}
        isInvalid={isInvalid}
        alignItems="center"
        justifyContent="center"
      >
        <NumberInput
          aria-label="Date of birth - Day (2 digits)"
          variant="unstyled"
          id="date-of-birth-day"
          name="date-of-birth-day"
          value={day}
          max={dayMax}
          min={dayMin}
          onChange={(e) => {
            if (e.length > 1 && parseInt(e) < dayMin) {
              return setDay(dayMin.toString());
            }

            if (parseInt(e) > dayMax) {
              return setDay(dayMax.toString());
            }

            setDay(e);
          }}
          onBlur={handleBlur}
        >
          <NumberInputField
            fontWeight="semibold"
            maxLength={2}
            placeholder={placeholder?.day}
            p={0}
            w={8}
            textAlign="center"
            ref={dayInputRef}
            onChange={(e) => {
              if (e.target.value.length === e.target.maxLength) {
                monthInputRef.current?.focus();
              }
            }}
          />
        </NumberInput>
        <Text
          {...props}
          textAlign="center"
          color="brand.greyScale.600"
          px={6}
          fontWeight="semibold"
        >
          /
        </Text>
        <NumberInput
          aria-label="Date of birth - Month (2 digits)"
          variant="unstyled"
          textAlign="center"
          id="date-of-birth-month"
          name="date-of-birth-month"
          value={month}
          min={monthMin}
          max={monthMax}
          defaultValue={defaultValue?.month}
          onChange={(e) => {
            if (e.length > 1 && parseInt(e) < monthMin) {
              return setMonth(monthMin.toString());
            }

            if (parseInt(e) > monthMax) {
              return setMonth(monthMax.toString());
            }

            setMonth(e);
          }}
          onBlur={handleBlur}
        >
          <NumberInputField
            placeholder={placeholder?.month}
            maxLength={2}
            p={0}
            w={8}
            textAlign="center"
            fontWeight="semibold"
            onChange={(e) => {
              if (e.target.value.length === e.target.maxLength) {
                yearInputRef.current?.focus();
              }
            }}
            ref={monthInputRef}
          />
        </NumberInput>
        <Text
          {...props}
          textAlign="center"
          color="brand.greyScale.600"
          px={6}
          fontWeight="semibold"
        >
          /
        </Text>
        <NumberInput
          aria-label="Date of birth - Year (4 digits)"
          variant="unstyled"
          textAlign="center"
          id="date-of-birth-year"
          name="date-of-birth-year"
          value={year}
          defaultValue={defaultValue?.year}
          onChange={setYear}
          onBlur={handleBlur}
        >
          <NumberInputField
            placeholder={placeholder?.year}
            maxLength={4}
            p={0}
            w={12}
            textAlign="center"
            fontWeight="semibold"
            ref={yearInputRef}
          />
        </NumberInput>
      </Input>
    </fieldset>
  );
};

export { DateOfBirthInput };
