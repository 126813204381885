import React from 'react';
import { Heading, Skeleton, Stack, Text } from '@newday/core';
import { round } from 'lodash';

import { useAprAndRiskGroup } from '../../shared/queries';

import { useApplicationId } from '../../app';
import { useLoanBreakdown } from '../../shared/hooks/useLoanBreakdown';

export const HIGH_RISK_EXAMPLE =
  '33.9% APR representative, based on a loan of £5000 repayable over four years at a 33.94% pa (fixed) interest rate. Monthly repayment: £178.81. Total amount payable: £8583.03.';

const RepresentativeExampleBoxAndTitle: React.FC = ({ children }) => (
  <Stack
    mt={3}
    p={4}
    mb={4}
    spacing={2}
    bg="white"
    borderWidth={1}
    borderColor="brand.primary"
    borderRadius="md"
    alignItems="flex-start"
  >
    <Heading fontSize="lg" fontWeight="bold">
      Representative example
    </Heading>
    {children}
  </Stack>
);

export const RepresentativeExample: React.FC = () => {
  const { applicationId } = useApplicationId();
  const { data: aprAndRiskGroup, isSuccess } =
    useAprAndRiskGroup(applicationId);

  const loanAmount = 5000;
  const durationYears = 4;
  const apr = aprAndRiskGroup?.apr ?? 0;
  const apr1dp = round(apr, 1);

  const { totalAmount, monthlyPayment, aer } = useLoanBreakdown({
    loanAmount,
    durationYears,
    apr,
  });

  if (isSuccess && aprAndRiskGroup.riskGroup) {
    return (
      <RepresentativeExampleBoxAndTitle>
        <Text fontSize="md" fontWeight="semibold" lineHeight="base">
          {`${apr1dp}% APR representative, based on a loan of £${loanAmount} repayable over four years at a ${round(
            aer,
            2
          )}% pa (fixed) interest rate. Monthly repayment: £${round(
            monthlyPayment,
            2
          )}. Total amount payable: £${round(totalAmount, 2)}.`}
        </Text>
      </RepresentativeExampleBoxAndTitle>
    );
  }

  return (
    <RepresentativeExampleBoxAndTitle>
      <Skeleton data-testid="rep-example-skeleton">
        <Text fontSize="md" fontWeight="semibold" lineHeight="base">
          {/* this won't appear to the user, we're just
          passing it here so skeleton knows the height it
          was supposed to render */}
          {HIGH_RISK_EXAMPLE}
        </Text>
      </Skeleton>
    </RepresentativeExampleBoxAndTitle>
  );
};
