import React from 'react';
import { ExtendedThemeProvider } from './extended-theme-provider';
import { brandThemes } from '../brands';
import type { BrandName } from '../brands';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Theme = Record<string, any>;

interface BrandThemeProviderProps {
  brand: BrandName;
  themes?: Theme[];
  globalStyles?: string;
}

const BrandThemeProvider: React.FC<BrandThemeProviderProps> = ({
  brand,
  themes,
  globalStyles,
  children,
}) => {
  const brandTheme = brandThemes[brand];
  return (
    <ExtendedThemeProvider
      brandTheme={brandTheme}
      themes={themes}
      globalStyles={globalStyles}
    >
      {children}
    </ExtendedThemeProvider>
  );
};

export { BrandThemeProvider };
