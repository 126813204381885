import { FeRoutes } from '@newday/plum-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplicationId } from '../../app';
import FilesContextProvider, { useFiles } from '../../app/files-provider';
import { DocUpload as DocUploadComponent } from '../../components';
import { useEmploymentStatus } from '../../shared/queries';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';
import { useFetchFiles, useManageFile, useUploadSubmit } from './files-queries';
import { OPTIONS } from './proof-of-income-options';

export const optionsByEmploymentStatusMap = {
  fullTime: ['payslips', 'bank'],
  partTime: ['payslips', 'bank'],
  selfEmployed: ['hmrc', 'accountant'],
  homemaker: ['payslips', 'bank', 'benefit'],
  retired: ['pension', 'benefit'],
};

const DocUpload = () => {
  const navigate = useNavigate();
  const { isSubmitSuccess, isSubmitError } = useFiles();
  const { applicationId } = useApplicationId();
  const { data, isLoading, isSuccess } = useEmploymentStatus(applicationId);
  const [optionsByEmploymentStatus, setOptionsByEmploymentStatus] =
    useState(OPTIONS);

  useEffect(() => {
    setOptionsByEmploymentStatus((prev) => {
      if (
        isSuccess &&
        data.employmentStatus &&
        optionsByEmploymentStatusMap[data.employmentStatus]
      ) {
        return prev.filter(({ value }) =>
          optionsByEmploymentStatusMap[data.employmentStatus].includes(value)
        );
      }

      return prev;
    });
  }, [data, isSuccess]);

  if (isSubmitSuccess) {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_action: FormActions.SUBMIT_FORM,
      form_name: FormNames.DOC_UPLOAD,
      link_text: 'submit now',
    });

    navigate(FeRoutes.documentUploadSuccess);
  }

  if (isSubmitError) {
    navigate(FeRoutes.documentUploadUnsuccess);
  }

  return (
    <DocUploadComponent
      options={optionsByEmploymentStatus}
      isLoadingOptions={isLoading}
    />
  );
};

export const ProofOfIncomeDocuments = () => {
  return (
    <FilesContextProvider
      useFetchFiles={useFetchFiles}
      useManageFile={useManageFile}
      useUploadSubmit={useUploadSubmit}
    >
      <DocUpload />
    </FilesContextProvider>
  );
};
