import type { InputProps } from '@newday/core';
import { Input, Text } from '@newday/core';
import React from 'react';

export interface AccountNumberInputProps
  extends Omit<InputProps, 'value' | 'defaultValue' | 'placeholder'> {
  value?: string;
  defaultValue?: string;
  placeholder?: string;
}

const partialAccountNumberPattern = /^[0-9]{1,8}/;
const partialAccountNumberRegExp = new RegExp(partialAccountNumberPattern);
export const accountNumberPattern = /^[0-9]{8}$/;

const isValidAccountNumberValue = (accountNumberValue: string) =>
  partialAccountNumberRegExp.test(accountNumberValue);

const getInvalidAccountNumberErrorMessage = (propName: string) =>
  `Invalid ${propName} passed to SortCodeInput - the ${propName} must contain two "-"s and numbers`;

const sanitizeValue = (value: string): string =>
  (value.match(partialAccountNumberRegExp) || [''])[0];

const AccountNumberInput: React.FC<AccountNumberInputProps> = ({
  defaultValue,
  value: valueProp,
  onChange,
  ...props
}) => {
  const controlled = !defaultValue && valueProp && onChange;

  const [valueState, setValue] = React.useState<string>(
    defaultValue && isValidAccountNumberValue(defaultValue) ? defaultValue : ''
  );

  if (defaultValue && valueProp) {
    throw new Error(
      'An uncontrolled SortCodeInput should not receive a value prop'
    );
  }

  if (controlled && !isValidAccountNumberValue(valueProp)) {
    const message = getInvalidAccountNumberErrorMessage('value');
    throw new Error(message);
  }

  if (defaultValue && !isValidAccountNumberValue(defaultValue)) {
    const message = getInvalidAccountNumberErrorMessage('defaultValue');
    throw new Error(message);
  }

  const value = controlled ? valueProp : valueState;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = sanitizeValue(event.target.value);
    if (nextValue !== value) {
      if (!controlled) {
        setValue(nextValue);
      }
      if (onChange) {
        const changeEvent = {
          ...event,
          target: {
            ...event.target,
            value: nextValue,
          },
        };
        onChange(changeEvent);
      }
    }
  };

  return (
    <>
      <Text
        as="label"
        htmlFor="account-number"
        mb={1}
        display="block"
        color="brand.primary"
        fontWeight="semibold"
      >
        Account number
      </Text>
      <Input
        {...props}
        id="account-number"
        type="text"
        maxLength={8}
        value={value}
        onChange={handleChange}
        autoComplete="off"
      />
    </>
  );
};

export { AccountNumberInput };
