import { ComponentStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: 'brand.primary.500',
    letterSpacing: '-0.1px',
  },
  // ExtendedThemeProvider hard codes two additional breking points (xxs and xs).
  // For that reason array values stand for: [base, xxs, xs, sm, md, lg, 2xl]
  sizes: {
    lg: {
      fontWeight: 'black',
      lineHeight: ['8', null, null, null, '8', '10'],
      fontSize: ['1.625rem', null, null, null, '3xl', '2rem'],
    },
    xl: {
      fontWeight: 'black',
      lineHeight: ['2.625rem', null, null, null, '2.875rem', '3.375rem'],
      fontSize: ['2.125rem', null, null, null, '2.375rem', '2.8125rem'],
    },
    '2xl': {
      fontWeight: 'black',
      lineHeight: ['11', null, null, null, '12', '13'],
      fontSize: ['4xl', null, null, null, '5xl', '6xl'],
    },
  },
};
