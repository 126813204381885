import colors from './colors';
import typography from './typography';

const foundations = {
  colors,
  ...typography,
  radii: {
    brand: {
      cta: 0,
      field: 0,
    },
  },
};

export default foundations;
