import React, { forwardRef } from 'react';
import {
  useRadioGroup,
  UseRadioGroupProps,
  Grid,
  SystemProps,
} from '@chakra-ui/react';

export interface RadioButtonGroupProps extends UseRadioGroupProps {
  onBlur?: () => void;
  radioButtonsPerRow?: number;
  gap?: SystemProps['gridGap'];
  children: ({
    getRadioButtonProps,
  }: {
    getRadioButtonProps: Function;
  }) => JSX.Element | JSX.Element[];
}

export const RadioButtonGroup = forwardRef<
  HTMLDivElement,
  RadioButtonGroupProps
>(
  (
    { radioButtonsPerRow, children, onBlur, gap = 2, ...props },
    ref
  ): React.ReactElement => {
    const { getRootProps, getRadioProps } = useRadioGroup(props);

    const group = getRootProps();

    const templateColumns = new Array(radioButtonsPerRow || 2)
      .fill('1fr')
      .join(' ');

    return (
      <Grid
        {...group}
        onBlur={onBlur}
        ref={ref}
        templateColumns={templateColumns}
        gap={gap}
      >
        {children({ getRadioButtonProps: getRadioProps })}
      </Grid>
    );
  }
);
