import {
  Drawer as ChakraDrawer,
  DrawerProps,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react';

export const Drawer = ({
  children,
  placement = 'right',
  size = 'sm',
  ...restProps
}: DrawerProps) => {
  return (
    <ChakraDrawer placement={placement} size={size} {...restProps}>
      <DrawerOverlay />
      <DrawerContent>{children}</DrawerContent>
    </ChakraDrawer>
  );
};

export { DrawerHeader, DrawerBody, DrawerFooter };
