/* eslint-disable sonarjs/no-duplicate-string */
import { ComponentStyleConfig } from '@chakra-ui/react';

const commonCircleStyles = {
  borderWidth: 2,
  borderColor: 'brand.primary',
  fontSize: '1.125rem',
  fontWeight: 600,
};

const Step: ComponentStyleConfig = {
  parts: ['active', 'completed', 'inactive'],
  baseStyle: {
    inactive: {
      circle: {
        backgroundColor: 'white',
        color: 'brand.primary',
        ...commonCircleStyles,
      },
    },
    active: {
      circle: {
        backgroundColor: 'brand.primary',
        color: 'white',
        ...commonCircleStyles,
      },
    },
    completed: {
      circle: {
        backgroundColor: 'brand.tertiary',
        ...commonCircleStyles,
      },
      icon: {
        color: 'white',
        size: 'sm',
      },
    },
  },
  variants: {
    secondary: {
      inactive: {
        circle: {
          borderColor: 'brand.greyScale.800',
          backgroundColor: 'white',
          color: 'brand.greyScale.800',
        },
        icon: {
          color: 'brand.greyScale.800',
          size: '1.25rem',
        },
        label: {
          color: 'brand.greyScale.800',
        },
      },
      active: {
        circle: {
          backgroundColor: 'brand.primary',
          color: 'white',
        },
        icon: {
          color: 'white',
          size: '1.25rem',
        },
        label: {
          color: 'brand.primary',
        },
      },
      completed: {
        circle: {
          backgroundColor: 'brand.primary',
        },
        icon: {
          color: 'brand.tertiary',
          size: 'sm',
        },
        label: {
          color: 'brand.primary',
        },
      },
    },
    tertiary: {
      inactive: {
        circle: {
          borderColor: 'brand.primary.500',
          backgroundColor: 'white',
          color: 'brand.primary.500',
        },
        icon: {
          color: 'brand.primary.500',
          size: '1.25rem',
        },
        label: {
          color: 'brand.greyScale.800',
        },
      },
      active: {
        circle: {
          borderColor: 'brand.primary.500',
          backgroundColor: 'brand.primary.500',
          color: 'white',
        },
        icon: {
          color: 'brand.primary.500',
          size: '1.25rem',
        },
        label: {
          color: 'brand.greyScale.800',
        },
      },
      completed: {
        circle: {
          borderColor: 'brand.primary.500',
          backgroundColor: 'brand.tertiary.default',
        },
        icon: {
          color: 'white',
          size: 'sm',
        },
        label: {
          color: 'brand.greyScale.800',
        },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Step;
