import React from 'react';
import { Text, Heading } from '@newday/core';
import { BoostCreditScoreTipsList } from './boost-credit-score-tips-list';

export const BoostCreditScoreTips: React.FC = () => (
  <>
    <Heading>Getting a &apos;yes&apos; in future</Heading>
    <Text>
      A &apos;no&apos; doesn&apos;t have to be for ever. You could improve your
      chances of getting a loan at another time by building better credit.
    </Text>
    <BoostCreditScoreTipsList />
  </>
);
