import React from 'react';
import { IconProps as ChakraIconProps, useStyleConfig } from '@chakra-ui/react';

export interface BaseIconProps<Sizes = 'md', Variants = 'primary'>
  extends Omit<ChakraIconProps, 'css'> {
  size?: Sizes;
  variant?: Variants;
}

export type OneColorIconProps = BaseIconProps<'xs' | 'sm' | 'md'>;
export type TwoColorIconProps = BaseIconProps<
  'xs' | 'sm' | 'md' | 'lg',
  'primary' | 'secondary'
>;

export type IconProps = OneColorIconProps | TwoColorIconProps;

export type OneColorIconComponent = React.FC<OneColorIconProps>;
export type TwoColorIconComponent = React.FC<TwoColorIconProps>;
export type IconComponent = OneColorIconComponent | TwoColorIconComponent;

export const useIconStyleConfig = (
  themeKey: string,
  { size, variant, color }: Partial<OneColorIconProps | TwoColorIconProps>
) => {
  let styles = useStyleConfig(themeKey, { size, variant });
  if (color) {
    styles = {
      ...styles,
      color,
    };
  }
  return styles;
};
