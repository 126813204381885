import React, { useRef } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Box, Flex } from '@newday/core';
import { BankDetailsInput } from '../bank-details-input';
import { DirectDebitCheckbox } from '../direct-debit-checkbox';

export interface BankDetailsAndDirectDebitFormProps {
  bankDetailsInvalid?: boolean;
  bankDetailsInvalidMessage?: string;
  onSubmit: SubmitHandler<{
    'sort-code': string;
    'account-number': string;
    'direct-debit': boolean;
  }>;
}

const BankDetailsAndDirectDebitForm: React.FC<
  BankDetailsAndDirectDebitFormProps
> = ({
  bankDetailsInvalid = false,
  bankDetailsInvalidMessage = '',
  onSubmit,
  children,
}) => {
  const requiredFields = useRef<Array<HTMLDivElement | null>>([]);

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const scrollToRequiredIfEmpty = async () => {
    const validBankDetails = await methods.trigger([
      'sort-code',
      'account-number',
    ]);
    const validCheckbox = await methods.trigger('direct-debit');

    if (!validBankDetails && requiredFields.current[0]) {
      requiredFields.current[0].scrollIntoView({
        behavior: 'smooth',
      });
    }

    if (validBankDetails && !validCheckbox && requiredFields.current[1]) {
      requiredFields.current[1].scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <FormProvider {...methods}>
      {/* @ts-expect-error: Expected type mismatch */}
      <form
        name="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        className="plum-mouseflow-hidden"
      >
        <div ref={(el) => (requiredFields.current[0] = el)}>
          <BankDetailsInput
            invalid={bankDetailsInvalid}
            invalidMessage={bankDetailsInvalidMessage}
          />
        </div>
        <Box my={4}>{children}</Box>
        <div ref={(el) => (requiredFields.current[1] = el)}>
          <DirectDebitCheckbox />
        </div>
        <Flex direction="column" mt={8}>
          <Button
            type="submit"
            w="full"
            alignSelf="center"
            onClick={scrollToRequiredIfEmpty}
          >
            Add my loan payment details
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
};

export { BankDetailsAndDirectDebitForm };
