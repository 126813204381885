import React from 'react';

const calculateMonthlyPaymentAndTotalAmount = (
  loanAmount: number,
  durationMonths: number,
  apr: number
) => {
  const aer =
    Math.round((Math.pow(1 + apr / 100, 1 / 12) - 1) * 1200 * 100) / 100;

  const monthlyInterestRate = (aer * 0.01) / 12;
  const x = Math.pow(monthlyInterestRate + 1, durationMonths);
  const monthlyPayment = (loanAmount * x * monthlyInterestRate) / (x - 1);
  const totalAmount = monthlyPayment * durationMonths;

  return {
    aer,
    totalAmount,
    monthlyPayment,
  };
};

const useLoanBreakdown = ({
  loanAmount,
  durationYears,
  apr,
}: {
  loanAmount: number;
  durationYears: number;
  apr: number;
}) => {
  const [loanBreakdown, setLoanBreakdown] = React.useState({
    totalAmount: 0,
    monthlyPayment: 0,
    aer: 0,
  });

  React.useEffect(() => {
    const durationMonths = durationYears * 12;

    const { totalAmount, monthlyPayment, aer } =
      calculateMonthlyPaymentAndTotalAmount(loanAmount, durationMonths, apr);

    setLoanBreakdown({ totalAmount, monthlyPayment, aer });
  }, [loanAmount, durationYears, apr]);

  return loanBreakdown;
};

export { useLoanBreakdown };
