import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const UploadIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 45 45" fill="none" __css={styles} {...props}>
      <circle cx="22.5" cy="22.5" r="21" fill="white" stroke="currentColor" strokeWidth="3"/>
      <path d="M23.8011 32.3979C23.816 32.3058 23.8229 32.2126 23.8217 32.1194L23.8217 16.2461L23.9827 16.5922C24.1428 16.9306 24.3607 17.2383 24.6266 17.5018L29.0779 21.9531C29.6375 22.5393 30.5378 22.6378 31.211 22.1865C31.9283 21.6612 32.0841 20.6539 31.5588 19.9365C31.5163 19.8786 31.4701 19.8235 31.4202 19.7717L23.3709 11.7224C22.7426 11.0933 21.7233 11.0928 21.0942 11.7211C21.0938 11.7215 21.0934 11.722 21.093 11.7224L13.0437 19.7717C12.4159 20.4013 12.4173 21.4206 13.0468 22.0484C13.0964 22.0978 13.1491 22.144 13.2046 22.1865C13.8778 22.6378 14.7781 22.5393 15.3377 21.9531L19.797 17.5099C20.0335 17.2737 20.2317 17.0021 20.3846 16.7049L20.602 16.222L20.602 32.0308C20.5714 32.8532 21.1459 33.5747 21.9543 33.7292C22.8319 33.8716 23.6587 33.2755 23.8011 32.3979Z" fill="currentColor"/>
    </Icon>
  );
};
