import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import { setupGtm } from '../../utils/gtm-setup';

import { PAGE_TITLE_BY_PATH, useApplicationId, useBrand } from '../../app';
import { Events, getAppInsightsIds } from '../../utils/gtm-track-event';
import { useHashedAppIdQuery } from '../../shared/queries';

export const useGtmVirtualPageEvent = () => {
  const analyticsAllowed = Boolean(Cookies.get('analyticsCookieConsent'));
  const location = useLocation();
  const { brand } = useBrand();
  const { applicationId } = useApplicationId();
  const { data, isSuccess } = useHashedAppIdQuery(applicationId);

  useEffect(() => {
    if (!analyticsAllowed) return;

    if (!window.dataLayer) {
      setupGtm(brand);
    }

    if (location.pathname === '/') return;

    if (isSuccess) {
      window.dataLayer?.push({
        event: Events.PAGE_VIEW,
        virtualPageURL: location.pathname,
        virtualPageTitle:
          PAGE_TITLE_BY_PATH[location.pathname] || document.title,
        page: {
          brand,
        },
        application: {
          applicationId: data?.hashedApplicationId,
        },
        ...getAppInsightsIds(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, analyticsAllowed, brand, isSuccess]);
};
