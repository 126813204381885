import React from 'react';
import { ListItem, Text, UnorderedList } from '@newday/core';
import { DocUploadOptions } from '../../components';

export const OPTIONS: DocUploadOptions[] = [
  {
    value: 'payslips',
    label: 'Three months’ payslips',
    heading:
      'Attach three months’ payslips (if paid monthly) or the past six weeks (if paid weekly)',
    modalCheck: true,
    modalCheckMessage: 'Are payslips for the past three months attached?',
    description: (
      <>
        <Text>
          Paper or online payslips are fine. They MUST cover{' '}
          <Text as="span" fontWeight="semibold">
            the past three months
          </Text>{' '}
          (if paid monthly) or{' '}
          <Text as="span" fontWeight="semibold">
            the past six weeks{' '}
          </Text>
          (if paid weekly) and include:
        </Text>
        <UnorderedList pl="0.5rem">
          <ListItem>Your name</ListItem>
          <ListItem>National insurance number</ListItem>
          <ListItem>Company name</ListItem>
          <ListItem>Pay date and payment period</ListItem>
          <ListItem>Gross and net pay</ListItem>
          <ListItem>Year-to-date gross pay and deductions</ListItem>
        </UnorderedList>
      </>
    ),
  },
  {
    value: 'bank',
    label: 'Three months’ bank statements',
    heading: 'Attach three months’ bank statements',
    modalCheck: true,
    modalCheckMessage: 'Are statements for the past three months attached?',
    description: (
      <>
        <Text>
          Paper or online statements are fine. They MUST cover{' '}
          <Text as="span" fontWeight="semibold">
            the past three months
          </Text>{' '}
          and include:
        </Text>
        <UnorderedList pl="0.5rem">
          <ListItem>Your name</ListItem>
          <ListItem>Bank account number</ListItem>
          <ListItem>Statement date</ListItem>
        </UnorderedList>
      </>
    ),
  },
  {
    value: 'pension',
    label: 'Pension letters or statements',
    heading: 'Attach your pension letters or statements',
    description: (
      <>
        <Text>
          Please choose{' '}
          <Text as="span" fontWeight="semibold">
            ONE
          </Text>{' '}
          of:
        </Text>
        <UnorderedList pl="0.5rem">
          <ListItem fontWeight="semibold">Annual state pension letter</ListItem>
          <ListItem fontWeight="semibold">
            Annual private pension letter
          </ListItem>
          <ListItem fontWeight="semibold">Monthly pension payslip</ListItem>
        </UnorderedList>
      </>
    ),
  },
  {
    value: 'benefit',
    label: 'Benefit statements',
    heading: 'Attach your benefit statements',
    description: (
      <>
        <Text>You can use ONE OR MORE of these benefits:</Text>
        <UnorderedList mb="25px" pl="0.5rem">
          <ListItem>
            <Text as="span" fontWeight="semibold">
              Personal Independence Payment
            </Text>{' '}
            (PIP)
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="semibold">
              Disability Living Allowance
            </Text>{' '}
            (DLA)
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="semibold">
              Industrial Injuries Disablement Benefit
            </Text>{' '}
            (IIDB)
          </ListItem>
          <ListItem fontWeight="semibold">Carer’s Allowance</ListItem>
          <ListItem>
            <Text as="span" fontWeight="semibold">
              Disability Premium
            </Text>{' '}
            (including severe and enhanced) when receiving PIP or DLA
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="semibold">
              Constant Attendance Allowance
            </Text>{' '}
            (if receiving IIDB)
          </ListItem>
        </UnorderedList>
        <Text>Please attach:</Text>
        <UnorderedList mb="25px" pl="0.5rem">
          <ListItem>
            A letter confirming your benefit, dated within the last 12 months
            (or before, if the award is for over 12 months or has no end date),
            PLUS
          </ListItem>
          <ListItem>
            A bank statement showing your last payment, which must match the
            amount shown in the letter
          </ListItem>
        </UnorderedList>
      </>
    ),
  },
  {
    value: 'hmrc',
    label: 'Tax calculation from HMRC',
    heading: 'Attach your tax calculation from HMRC',
    description: (
      <>
        <Text mb="25px">
          Use an online or paper version of your latest HMRC tax calculation
          (sometimes known as SA302).
        </Text>
        <Text>
          <Text as="span" fontWeight="semibold">
            Online documents
          </Text>{' '}
          must include:
        </Text>
        <UnorderedList mb="25px" pl="0.5rem">
          <ListItem>HMRC logo</ListItem>
          <ListItem>Your name and Unique Tax Reference</ListItem>
          <ListItem>Tax year</ListItem>
          <ListItem>Progress bar showing 100% complete</ListItem>
          <ListItem>
            A statement that this is ‘a copy of the information held on your
            official online Self Assessment tax account with HM Revenue and
            Customs’
          </ListItem>
        </UnorderedList>
        <Text>
          <Text as="span" fontWeight="semibold">
            Paper documents{' '}
          </Text>
          must include:
        </Text>
        <UnorderedList pl="0.5rem">
          <ListItem>HMRC logo</ListItem>
          <ListItem>Your name, address and Unique Tax Reference</ListItem>
          <ListItem>Tax year</ListItem>
        </UnorderedList>
      </>
    ),
  },
  {
    value: 'accountant',
    label: 'Tax calculation/computation by an accountant',
    heading: 'Attach your tax calculation/computation by an accountant',
    description: (
      <>
        <Text>
          This must be a document produced using commercial software that
          includes:
        </Text>
        <UnorderedList pl="0.5rem">
          <ListItem>Your name</ListItem>
          <ListItem>Tax year</ListItem>
          <ListItem>Unique Tax Reference</ListItem>
        </UnorderedList>
      </>
    ),
  },
];
