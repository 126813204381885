export const HttpErrorMessages = {
  403: {
    error: 'Linked Expired',
    errorMessage: 'This link has expired',
  },
  404: {
    error: "Sorry we can't find that page",
    errorMessage: 'It may be an old link, or maybe it moved',
  },
  500: {
    error: 'System currently unavailable, please try again later.',
    errorMessage:
      "We're sorry but something went wrong when we tried to process your last action. Please try again or come back later.",
  },
  503: {
    error: 'System currently unavailable, please try again later.',
    errorMessage:
      "We're sorry but something went wrong when we tried to process your last action. Please try again or come back later.",
  },
  401: {
    error: 'Timeout!',
    errorMessage: 'For security reasons your session has expired.',
  },
};
