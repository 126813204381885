import axios from 'axios';
import { useQuery } from 'react-query';

import { BffRoutes, UserDetails } from '@newday/plum-types';

type UserDetailsOptions = {
  obfuscateEmail?: boolean;
};

const fetchUserDetails =
  ({
    applicationId,
    obfuscateEmail,
  }: {
    applicationId: string;
    obfuscateEmail: UserDetailsOptions['obfuscateEmail'];
  }) =>
  (): Promise<UserDetails> => {
    const userDetailsAddress = BffRoutes.userDetails.replace(
      ':applicationId',
      applicationId
    );

    return axios
      .get(`${userDetailsAddress}`, { params: { obfuscateEmail } })
      .then((response) => response.data);
  };

export const useUserDetails = (
  applicationId: string,
  options?: UserDetailsOptions
) => {
  return useQuery(
    ['plum', 'user-details', applicationId],
    fetchUserDetails({ applicationId, obfuscateEmail: options?.obfuscateEmail })
  );
};
