import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { TwoColorIconProps } from '../icon';

export const ExclamationMarkIcon = ({
  size,
  variant,
  color,
  ...props
}: TwoColorIconProps) => {
  const styles = useIconStyleConfig('TwoColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 60 60" __css={styles} {...props}>
      <path
        d="M29.9999 59.2105C46.1082 59.2105 59.1666 46.1522 59.1666 30.0439C59.1666 13.9356 46.1082 0.877193 29.9999 0.877193C13.8916 0.877193 0.833252 13.9356 0.833252 30.0439C0.833252 46.1522 13.8916 59.2105 29.9999 59.2105Z"
        fill="white"
      />
      <path
        d="M30 60C13.4649 60 0 46.5351 0 30C0 13.4649 13.4649 0 30 0C46.5351 0 60 13.4649 60 30C60 46.5789 46.5351 60 30 60ZM30 1.71053C14.386 1.71053 1.71053 14.4298 1.71053 30.0439C1.71053 45.6579 14.4298 58.3772 30.0439 58.3772C45.6579 58.3772 58.3772 45.6579 58.3772 30.0439C58.3333 14.4298 45.614 1.71053 30 1.71053Z"
        fill="currentColor"
      />
      <path d="M27.5624 36.6752L26.0249 11.0507L27.5624 9.51318H32.1748L34.7373 11.0507L33.1998 36.6752H27.5624Z" />
      <path d="M30.1159 48.3804C29.4332 48.374 28.7676 48.1634 28.2028 47.7751C27.6381 47.3868 27.1995 46.8381 26.9422 46.1981C26.6849 45.5581 26.6204 44.8554 26.7568 44.1783C26.8933 43.5013 27.2245 42.8803 27.709 42.3934C28.1934 41.9064 28.8093 41.5754 29.4792 41.4419C30.1491 41.3084 30.8431 41.3785 31.4737 41.6432C32.1044 41.9079 32.6436 42.3555 33.0235 42.9297C33.4034 43.5038 33.6069 44.1788 33.6086 44.8698C33.6104 45.3333 33.5211 45.7926 33.3459 46.2209C33.1707 46.6492 32.9131 47.038 32.5882 47.3647C32.2632 47.6914 31.8772 47.9494 31.4528 48.1237C31.0284 48.2981 30.574 48.3853 30.1159 48.3804Z" />
      <path
        d="M30.1293 9.00231C30.9676 9.00231 31.8068 8.99711 32.6451 9.00231C34.2075 9.01582 35.3344 10.2612 35.246 11.8351C35.0848 14.7022 34.9492 17.5704 34.7889 20.4385C34.5691 24.4107 34.3349 28.3818 34.1089 32.354C34.0616 33.1857 34.0123 34.0121 33.9815 34.8427C33.925 36.3231 32.8413 37.4125 31.3795 37.4271C30.5406 37.4354 29.7017 37.4354 28.8628 37.4271C27.4503 37.4104 26.3758 36.3491 26.3049 34.9186C26.2772 34.3479 26.2268 33.7751 26.2094 33.2085C26.1929 32.6628 26.497 32.2875 26.9428 32.2615C27.3691 32.2355 27.702 32.5526 27.7544 33.0807C27.8016 33.562 27.8088 34.0475 27.8355 34.5361C27.8879 35.4717 28.2628 35.848 29.1822 35.8594C29.8325 35.8667 30.4827 35.8677 31.134 35.8594C31.9732 35.848 32.3667 35.4904 32.4211 34.6431C32.5156 33.1929 32.5834 31.7407 32.6636 30.2894C32.8238 27.387 32.9847 24.4849 33.1464 21.5831C33.2645 19.4063 33.3744 17.2294 33.4925 15.0526C33.546 14.0847 33.6025 13.1179 33.6713 12.1511C33.7524 11.018 33.4001 10.6116 32.2999 10.6095C30.8275 10.6095 29.3551 10.6095 27.8828 10.6095C26.9264 10.6095 26.5535 10.9754 26.6008 11.9276C26.7117 14.1388 26.8555 16.3489 26.9798 18.5601C27.0949 20.6329 27.2058 22.7062 27.3126 24.7798C27.3835 26.1104 27.4616 27.44 27.5181 28.7706C27.5438 29.3715 27.29 29.7114 26.8401 29.752C26.345 29.7956 26.0183 29.5067 25.9772 28.8954C25.8694 27.36 25.7872 25.8235 25.6999 24.286C25.4992 20.7806 25.301 17.2748 25.1051 13.7687C25.0671 13.0951 25.0188 12.4173 25.0024 11.7478C24.9873 11.3921 25.0439 11.0371 25.1685 10.7042C25.2932 10.3713 25.4834 10.0675 25.7276 9.81122C25.9718 9.55496 26.2649 9.35158 26.5891 9.21342C26.9133 9.07527 27.2619 9.00521 27.6136 9.00751C28.4498 8.99607 29.2901 9.00231 30.1293 9.00231Z"
        fill="currentColor"
      />
      <path
        d="M25.076 44.8947C25.0719 43.8801 25.3655 42.8871 25.9196 42.0415C26.4737 41.196 27.2633 40.5358 28.1884 40.1448C29.1136 39.7538 30.1326 39.6494 31.1164 39.845C32.1002 40.0406 33.0045 40.5272 33.7147 41.2434C34.4249 41.9595 34.9091 42.8728 35.106 43.8677C35.3028 44.8626 35.2034 45.8942 34.8204 46.8318C34.4374 47.7695 33.788 48.571 32.9545 49.1348C32.121 49.6986 31.1408 49.9994 30.1382 49.999C29.4746 50.0005 28.8171 49.8696 28.2035 49.6139C27.5899 49.3581 27.032 48.9825 26.5619 48.5085C26.0918 48.0344 25.7186 47.4713 25.4636 46.8512C25.2086 46.2312 25.0769 45.5663 25.076 44.8947ZM30.1156 48.3804C30.8006 48.381 31.4703 48.1759 32.04 47.7911C32.6097 47.4062 33.0538 46.859 33.3159 46.2186C33.5781 45.5782 33.6466 44.8735 33.5128 44.1937C33.3789 43.5139 33.0487 42.8895 32.564 42.3998C32.0793 41.91 31.4619 41.5768 30.79 41.4423C30.118 41.3079 29.4217 41.3782 28.7893 41.6445C28.1569 41.9108 27.6168 42.3609 27.2374 42.9381C26.858 43.5152 26.6563 44.1932 26.6579 44.8864C26.6562 45.3462 26.7444 45.8018 26.9176 46.2268C27.0908 46.6519 27.3455 47.038 27.667 47.3629C27.9885 47.6877 28.3704 47.9449 28.7907 48.1196C29.211 48.2942 29.6613 48.3829 30.1156 48.3804Z"
        fill="currentColor"
      />
    </Icon>
  );
};
