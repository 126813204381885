import React, { useEffect, useState } from 'react';
import { useApplicationId } from '../../app';
import {
  SessionTimeout as SessionTimeoutComponent,
  TokenExpAndIat,
} from '../../components';
import { useRefreshToken } from './session-timeout.queries';

export const SessionTimeout = ({ children }) => {
  const { applicationId } = useApplicationId();
  const [tokenExpAndIat, setTokenExpAndIat] = useState<TokenExpAndIat>();

  const { refetch } = useRefreshToken(applicationId, false);

  useEffect(() => {
    // the below is fully covered by cypress tests
    /* istanbul ignore next */
    const cb = (data: CustomEvent) => {
      setTokenExpAndIat(data.detail.token);
    };

    document.addEventListener('bearer-refreshed', cb as EventListener);

    return () => {
      document.removeEventListener('bearer-refreshed', cb as EventListener);
    };
  });

  return (
    <SessionTimeoutComponent
      countdownDurationInMs={1000 * 60 * 10}
      tokenExpAndIat={tokenExpAndIat}
      onSessionRenewal={
        /* istanbul ignore next */ () => {
          refetch();
        }
      }
      onSessionTimeout={
        /* istanbul ignore next */ () => {
          refetch();
        }
      }
    >
      {children}
    </SessionTimeoutComponent>
  );
};
