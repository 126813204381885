import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const BinIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 14 17" fill="none" __css={styles} {...props}>
      <path d="M5.9952 0.5C4.98559 0.5 4.15386 1.33173 4.15386 2.34135V2.96154H0.461548V4.19231H1.69232V14.6538C1.69232 15.6731 2.51924 16.5 3.53847 16.5H10.9231C11.9423 16.5 12.7692 15.6731 12.7692 14.6538V4.19231H14V2.96154H10.3077V2.34135C10.3077 1.33173 9.47597 0.5 8.46636 0.5H5.9952ZM5.9952 1.73077H8.46636C8.81251 1.73077 9.07693 1.99519 9.07693 2.34135V2.96154H5.38462V2.34135C5.38462 1.99519 5.64905 1.73077 5.9952 1.73077ZM4.15386 5.42308H5.38462V14.0385H4.15386V5.42308ZM6.61539 5.42308H7.84616V14.0385H6.61539V5.42308ZM9.07693 5.42308H10.3077V14.0385H9.07693V5.42308Z" fill="#8E8E8E"/>
    </Icon>
  );
};
