import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { OneColorIconProps } from '../icon';

export const TickSolidIcon = ({
  size,
  variant,
  color,
  ...props
}: OneColorIconProps) => {
  const styles = useIconStyleConfig('OneColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 16 17" fill="none" __css={styles} {...props}>
      <circle
        cx="8"
        cy="8.5"
        r="6.5"
        fill="#276749"
        stroke="#276749"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51352 10.5409L4.56231 8.53538L3.91113 9.20231L6.51352 11.8778L12.0889 6.1471L11.4401 5.47778L6.51352 10.5409Z"
        fill="white"
        stroke="white"
      />
    </Icon>
  );
};
