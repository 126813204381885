import { whiten } from '@chakra-ui/theme-tools';

export const HeroTheme = {
  baseStyle: {
    p: 8,
    w: 'full',
  },
  variants: {
    default: {
      color: 'brand.primary',
      backgroundColor: whiten('brand.quaternary', 80),
      description: {
        color: 'brand.greyScale.800',
      },
    },
    dark: {
      backgroundColor: 'brand.primary',
      color: 'white',
      h2: {
        color: 'white',
      },
      h1: {
        color: 'white',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
