import { useEffect } from 'react';
import { whiten } from '@chakra-ui/theme-tools';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  HStack,
  Box,
  Text,
  keyframes,
  useTheme,
  useDisclosure,
  ThemeDict,
} from '@newday/core';

export type LoadingProps = {
  isLoading: boolean;
  title: string;
  secondsToLoad: number;
};

const animation = ({ colors }: ThemeDict) => keyframes`
  0% { 
    transform: scale(0.8);
    background: ${colors?.brand?.primary};
  }
  50% { 
    transform: scale(1.2);
    background: ${colors?.white};
  }
  100% { 
    transform: scale(0.8);
    background: ${colors?.brand?.primary};
  }
`;

export const Loading: React.FC<LoadingProps> = (props) => {
  const { isLoading, title, secondsToLoad } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const theme = useTheme();
  const bgColor = whiten('brand.quaternary', 80)(theme);

  useEffect(() => {
    if (isLoading) {
      return onOpen();
    }

    return onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent
        w="xs"
        p={7}
        borderRadius="lg"
        backgroundColor={bgColor}
        aria-label="loading"
        data-testid="loading"
        role="status"
      >
        <ModalHeader p={0}>
          <Heading
            textAlign="center"
            fontSize="lg"
            mb={7}
            color="brand.primary"
          >
            {title}
          </Heading>
        </ModalHeader>
        <ModalBody p={0}>
          <HStack justifyContent="center">
            {['0s', '0.2s', '0.4s'].map((delay) => (
              <Box
                h={3.5}
                w={3.5}
                border={`3px solid ${theme.colors?.brand?.primary}`}
                borderRadius={50}
                animation={`${animation(theme)} 2s ${delay} infinite`}
                key={delay}
              />
            ))}
          </HStack>
          <Text fontSize="xs" textAlign="center" mt={7} color="brand.primary">
            This could take up to {secondsToLoad} seconds.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
