export const initOptimizely = () => {
  (function () {
    const optimizely = window.document.createElement('script');
    optimizely.type = 'text/javascript';
    optimizely.defer = true;
    optimizely.src = `//cdn.optimizely.com/js/21393260226.js`;
    optimizely.crossOrigin = 'anonymous';
    window.document.getElementsByTagName('head')[0].appendChild(optimizely);
  })();
};
