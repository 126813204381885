/* istanbul ignore file */
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const useOptimizelyState = (optimizelyTogglesOnCookieAccept) => {
  const analyticsAllowed = Boolean(Cookies.get('analyticsCookieConsent'));
  const [optimizelyVariantDecided, setOptimizelyVariantDecided] =
    useState(false);

  useEffect(() => {
    if (analyticsAllowed) {
      window['optimizely'] = window['optimizely'] || [];

      /* https://docs.developers.optimizely.com/web-experimentation/reference/add-listener#campaigndecided */
      window['optimizely'].push({
        type: 'addListener',
        filter: {
          type: 'lifecycle',
          name: 'campaignDecided',
        },
        handler: () => setOptimizelyVariantDecided(true),
      });
    }
  }, [optimizelyTogglesOnCookieAccept, analyticsAllowed]);

  return optimizelyVariantDecided;
};
