import React, { useEffect } from 'react';
import { Box } from '@newday/core';
import { Helmet } from 'react-helmet';
import { ContentWrapper, ResponsivePageLayout } from '../../components';
import { ApplicationStatusDialog } from '../../components/application-status-dialog';
import { Events, gtmTrackEvent } from '../../utils/gtm-track-event';
import { useApplicationId, useBrand } from '../../app';
import { useHashedAppIdQuery } from '../../shared/queries';

type ErrorProps = {
  title: string;
  subtitle: string;
};

export const ErrorPage: React.FC<ErrorProps> = ({ title, subtitle }) => {
  const { brand } = useBrand();
  const { applicationId } = useApplicationId();
  const { data } = useHashedAppIdQuery(applicationId);

  useEffect(() => {
    gtmTrackEvent({
      event: Events.PAGE_VIEW,
      virtualPageURL:
        location.pathname === '/' ? '/error' : `${location.pathname}/error`,
      virtualPageTitle: document.title,
      error: title,
      errorInfo: subtitle,
      page: {
        brand,
      },
      application: {
        applicationId: data?.hashedApplicationId || 'unknown',
      },
    });
  }, [subtitle, title, brand, data]);

  return (
    <>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <ResponsivePageLayout>
        <Box variant="brand-quaternary-800">
          <ContentWrapper maxContentWidth="5xl" py={14}>
            <ApplicationStatusDialog title={title} subtitle={subtitle} />
          </ContentWrapper>
        </Box>
        <Box variant="brand-quaternary-600" height={40} />
      </ResponsivePageLayout>
    </>
  );
};
