import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { initializeAppInsights } from '../src/utils/app-insights';
import { App } from './app';
import { detectBrandFromUrl } from './utils/detect-brand-from-url';

declare global {
  interface Window {
    __plum_version__?: string;
  }
}

const renderApp = async () => {
  initializeAppInsights();
  setVersion();
  await loadMocks();

  const root = document.getElementById('root');
  const brand = detectBrandFromUrl(window.location.href);

  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(
    <StrictMode>
      <App brand={brand} />
    </StrictMode>,
    root
  );
};

declare const BUILD_VERSION;

const setVersion = () => {
  window.__plum_version__ = BUILD_VERSION;
};

const loadMocks = async () => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./mocks/browser-dev');
    worker.start();
  }
};

renderApp();
