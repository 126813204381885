/* istanbul ignore file */
/* eslint-disable no-var */

const injectMouseflowScript = (mouseflow_id: string) => {
  window._mfq = window._mfq || [];
  var mf = document.createElement('script');
  mf.type = 'text/javascript';
  mf.defer = true;
  mf.src = `//cdn.mouseflow.com/projects/${mouseflow_id}.js`;
  mf.crossOrigin = 'anonymous';
  document.getElementsByTagName('head')[0].appendChild(mf);
  window.mouseflowAutoStart = false;
};

export default injectMouseflowScript;
