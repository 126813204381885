import { Box, Heading, ListItem, Text, UnorderedList } from '@newday/core';
import { DirectDebitGuaranteeIcon } from '../../../icons/direct-debit-guarantee-icon';

export const DirectDebitGuarantee = () => (
  <Box>
    <Heading my={4} color="brand.primary" fontSize="xl">
      The Direct Debit Guarantee
    </Heading>
    <Text mb={4} fontSize="sm">
      This guarantee is offered by all banks and building societies that accept
      instructions to pay Direct Debits
    </Text>
    <UnorderedList spacing={4} mb={4} fontSize="sm">
      <ListItem>
        If there are any changes to the amount, date or frequency of your Direct
        Debit, Antelope Loans Funding Limited will notify you normally ten
        working days in advance of your account being debited or as otherwise
        agreed
      </ListItem>
      <ListItem>
        If you request Antelope Loans Funding Limited to collect a payment,
        confirmation of the amount and date will be given to you at the time of
        the request
      </ListItem>
      <ListItem>
        If an error is made in the payment of your Direct Debit, by Antelope
        Loans Funding Limited or your bank or building society, you are entitled
        to a full and immediate refund of the amount paid from your bank or
        building society
      </ListItem>
      <ListItem>
        If you receive a refund you are not entitled to, you must pay it back
        when Antelope Loans Funding Limited asks you to
      </ListItem>
      <ListItem>
        You can cancel a Direct Debit at any time by simply contacting your bank
        or building society. Written confirmation may be required. Please also
        notify us
      </ListItem>
    </UnorderedList>

    <Box mb={4}>
      <DirectDebitGuaranteeIcon large />
    </Box>
  </Box>
);
