import React, { ReactElement } from 'react';

import {
  Heading,
  Stack,
  StackProps,
  Text,
  useMultiStyleConfig,
} from '@newday/core';
import { ContentWrapper } from '../content-wrapper';

export interface HeroProps extends Omit<StackProps, 'css'> {
  children?: ReactElement;
  description?: string;
  icon?: ReactElement;
  subtitle?: string;
  preTitle?: string;
  title: string;
  variant?: 'default' | 'dark';
  fullWidth?: boolean;
}

export const Hero: React.FC<HeroProps> = (props) => {
  const {
    children,
    description,
    icon,
    subtitle,
    title,
    preTitle,
    variant = 'default',
    fullWidth,
    ...rest
  } = props;
  const style = useMultiStyleConfig('Hero', { variant });

  return (
    <Stack __css={style} {...rest}>
      <ContentWrapper
        maxContentWidth={fullWidth ? '5xl' : '3xl'}
        px={fullWidth ? { xl: 8 } : { xl: 0 }}
      >
        <Stack w="full" alignSelf="center" mx="auto">
          {icon ? React.cloneElement(icon, { size: 'lg' }) : null}
          {preTitle ? <Heading fontSize="lg">{preTitle}</Heading> : null}
          <Heading as="h1" fontSize="2xl" lineHeight="tall" mt={2}>
            {title}
          </Heading>
          {subtitle ? (
            <Text fontSize="md" fontWeight="semibold" lineHeight="short" mt={4}>
              {subtitle}
            </Text>
          ) : null}
          {description ? (
            <Text
              fontSize="md"
              fontWeight="bold"
              lineHeight="shorter"
              pt={2}
              sx={style.description}
            >
              {description}
            </Text>
          ) : null}
          {children ? children : null}
        </Stack>
      </ContentWrapper>
    </Stack>
  );
};
