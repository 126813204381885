import React from 'react';
import { whiten } from '@chakra-ui/theme-tools';
import { Stepper, useTheme } from '@newday/core';

export interface HeaderStepperProps {
  activeStep: number;
  mdPlus?: boolean;
}

export const HeaderStepper = ({ activeStep, mdPlus }: HeaderStepperProps) => {
  const theme = useTheme();
  const bgColor = whiten('brand.quaternary', 60)(theme);

  return (
    <Stepper
      backgroundColor={mdPlus ? 'white' : bgColor}
      px={mdPlus ? 0 : 10}
      py={mdPlus ? 0 : 3}
      activeStep={activeStep}
      steps={[
        {
          label: 'Details',
        },
        {
          label: 'Eligibility',
        },
        {
          label: 'Apply',
        },
        {
          label: 'Complete',
        },
      ]}
    />
  );
};
