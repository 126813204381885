import React, { useState } from 'react';
import {
  Flex,
  InputProps,
  NumberInput,
  NumberInputField,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ValidateResult } from 'react-hook-form';

export type DateInputData = {
  month: number | string;
  year: number | string;
};

export interface DateInputProps extends Omit<InputProps, 'onChange'> {
  month?: number | string;
  year?: number | string;
  onChange: (data: DateInputData) => void;
}

export const commonDateValidators = {
  validMonth: ({ month }: DateInputData): ValidateResult => {
    return (month >= 1 && month <= 12) || 'Please enter a valid month';
  },
  validPastYear: ({ year }: DateInputData): ValidateResult => {
    const currentYear = new Date().getFullYear();
    return year <= currentYear || 'Please enter a valid year';
  },
  validPastDate: ({ month, year }: DateInputData): ValidateResult => {
    const now = new Date();
    return (
      year < now.getFullYear() ||
      (year == now.getFullYear() && month <= now.getMonth() + 1) ||
      'Please tell us a date in the past'
    );
  },
};

export const DateInput: React.FC<DateInputProps> = ({
  onBlur,
  onChange,
  value,
  isInvalid,
  ...props
}) => {
  const [month, setMonth] = useState(props.month || '');
  const [year, setYear] = useState(props.year || '');

  return (
    <Flex gap={2}>
      <label>
        <VisuallyHidden>Month</VisuallyHidden>
        <NumberInput
          defaultValue={month}
          min={1}
          max={12}
          isInvalid={isInvalid}
          clampValueOnBlur={false}
          onChange={(m) => {
            setMonth(m);
            onChange && onChange({ month: m, year });
          }}
        >
          <NumberInputField placeholder="MM" width="5.2rem" />
        </NumberInput>
      </label>
      <label>
        <VisuallyHidden>Year</VisuallyHidden>
        <NumberInput
          defaultValue={year}
          min={1890}
          isInvalid={isInvalid}
          clampValueOnBlur={false}
          onBlur={onBlur}
          onChange={(y) => {
            setYear(y);
            onChange && onChange({ month, year: y });
          }}
        >
          <NumberInputField placeholder="YYYY" width="6.5rem" />
        </NumberInput>
      </label>
    </Flex>
  );
};
