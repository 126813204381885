import { Icon } from '@chakra-ui/react';
import { useIconStyleConfig } from '../icon';
import type { TwoColorIconProps } from '../icon';

export const MailAlertIcon = ({
  size,
  variant,
  color,
  ...props
}: TwoColorIconProps) => {
  const styles = useIconStyleConfig('TwoColorIcon', { size, variant, color });
  return (
    <Icon viewBox="0 0 60 60" __css={styles} {...props}>
      <path
        d="M29.7247 58.2216C45.276 58.2216 57.8828 45.6148 57.8828 30.0635C57.8828 14.5122 45.276 1.90543 29.7247 1.90543C14.1735 1.90543 1.56665 14.5122 1.56665 30.0635C1.56665 45.6148 14.1735 58.2216 29.7247 58.2216Z"
        fill="white"
      />
      <path
        d="M29.7248 58.9838C13.7615 58.9838 0.762207 45.9845 0.762207 30.0212C0.762207 14.0579 13.7615 1.05856 29.7248 1.05856C45.6881 1.05856 58.6874 14.0579 58.6874 30.0212C58.6874 46.0268 45.6881 58.9838 29.7248 58.9838ZM29.7248 2.70994C14.6507 2.70994 2.41358 14.9894 2.41358 30.0635C2.41358 45.1376 14.693 57.4171 29.7671 57.4171C44.8412 57.4171 57.1207 45.1376 57.1207 30.0635C57.0784 14.9894 44.7989 2.70994 29.7248 2.70994Z"
        fill="currentColor"
      />
      <path d="M34.7212 15.0318H13.7615V30.0635H34.7212V15.0318Z" />
      <path
        d="M39.2096 46.3232C38.9979 46.3232 38.7862 46.3232 38.5745 46.2809C36.8808 46.0268 35.5681 44.6718 35.2717 42.9781C35.1871 42.5547 35.4835 42.1313 35.9492 42.0466C36.3727 41.9619 36.7961 42.2583 36.8808 42.7241C37.0501 43.7403 37.8123 44.5025 38.8286 44.6718C39.4637 44.7565 40.0988 44.6295 40.607 44.2484C41.1151 43.8673 41.4538 43.3169 41.5385 42.7241C41.6232 42.3006 42.0043 42.0042 42.4701 42.0466C42.8935 42.1313 43.1899 42.5124 43.1475 42.9781C42.9782 44.0367 42.4277 44.9682 41.5385 45.561C40.8187 46.0692 40.0142 46.3232 39.2096 46.3232Z"
        fill="currentColor"
      />
      <path d="M44.7566 28.8779C44.7566 28.8779 44.926 24.686 41.3692 23.5427C39.9295 23.1193 38.4052 23.1193 36.9655 23.5427C33.4087 24.7283 33.5781 28.8779 33.5781 28.8779C33.5781 34.933 29.4285 37.0925 29.4285 37.0925V40.861H48.9486V37.0925C48.9486 37.0925 44.7566 34.933 44.7566 28.8779Z" />
      <path
        d="M48.9485 41.6655H29.4708C29.005 41.6655 28.6663 41.2844 28.6663 40.861V37.0925C28.6663 36.7961 28.8356 36.4997 29.0897 36.3726C29.2591 36.2879 32.8159 34.3402 32.8159 28.8779C32.7312 27.2689 33.4934 23.8391 36.7538 22.7805C38.3628 22.2724 40.0565 22.2724 41.6232 22.7805C44.8836 23.8391 45.6458 27.2689 45.5611 28.9203C45.5611 34.4248 49.245 36.3726 49.2873 36.415C49.5414 36.542 49.7107 36.8384 49.7107 37.1348V40.9033C49.7531 41.3268 49.372 41.6655 48.9485 41.6655ZM30.233 40.0565H48.1017V37.5582C46.9584 36.7961 43.9521 34.2555 43.9521 28.8779C43.9521 28.8779 43.9521 28.8779 43.9521 28.8356C43.9521 28.6662 44.0368 25.2364 41.1151 24.3049C39.8448 23.9238 38.4898 23.9238 37.2195 24.3049C34.2979 25.2364 34.3826 28.7085 34.3826 28.8356C34.3826 34.2131 31.3762 36.7537 30.233 37.5159V40.0565Z"
        fill="currentColor"
      />
      <path
        d="M39.1673 23.9661C37.5582 23.9661 36.2456 22.6535 36.2456 21.0445C36.2456 19.4354 37.5582 18.1228 39.1673 18.1228C40.7763 18.1228 42.0889 19.4354 42.0889 21.0445C42.0889 22.6535 40.7763 23.9661 39.1673 23.9661ZM39.1673 19.7318C38.4474 19.7318 37.8546 20.3246 37.8546 21.0445C37.8546 21.7643 38.4474 22.3571 39.1673 22.3571C39.8871 22.3571 40.4799 21.7643 40.4799 21.0445C40.4799 20.3246 39.8871 19.7318 39.1673 19.7318Z"
        fill="currentColor"
      />
      <path
        d="M29.4285 31.1221H14.3544C13.2535 31.1221 12.3643 30.1905 12.3643 29.132V15.6669C12.3643 14.566 13.2535 13.6768 14.3544 13.6768H33.4934C34.5943 13.6768 35.4835 14.6083 35.4835 15.6669V18.7579C35.4835 19.2237 35.1024 19.5625 34.679 19.5625C34.2556 19.5625 33.8745 19.1814 33.8745 18.7579V15.6669C33.8745 15.4552 33.7051 15.2858 33.4934 15.2858H14.3544C14.1427 15.2858 13.9733 15.4552 13.9733 15.6669V29.132C13.9733 29.3437 14.1427 29.5131 14.3544 29.5131H29.4708C29.9366 29.5131 30.2753 29.8941 30.2753 30.3176C30.233 30.741 29.8943 31.1221 29.4285 31.1221Z"
        fill="currentColor"
      />
      <path
        d="M13.8463 30.5293C13.6769 30.5293 13.4652 30.487 13.3382 30.3599C12.9995 30.0635 12.9571 29.5554 13.2112 29.2167L18.0806 23.331C18.377 22.9923 18.8851 22.9499 19.2239 23.204C19.5626 23.5004 19.605 24.0085 19.3509 24.3472L14.4815 30.2329C14.3121 30.4446 14.058 30.5293 13.8463 30.5293Z"
        fill="currentColor"
      />
      <path
        d="M23.9239 23.8391C23.7546 23.8391 23.5852 23.7968 23.4158 23.6697L13.0841 15.5399C12.7454 15.2435 12.6607 14.7354 12.9571 14.3966C13.2535 14.0579 13.7616 13.9732 14.1003 14.2696L23.9663 21.976L33.7475 14.1849C34.0862 13.8885 34.5944 13.9732 34.8908 14.3119C35.1872 14.6507 35.1025 15.1588 34.7637 15.4552L24.432 23.6697C24.305 23.7544 24.1356 23.8391 23.9239 23.8391Z"
        fill="currentColor"
      />
    </Icon>
  );
};
