export enum DocumentTypes {
  loanSummary = 'loanSummary',
  loanAgreement = 'loanAgreement',
  loanExplanation = 'loanExplanation',
  summary = 'summary',
}

export enum DocumentFormats {
  html = 'text/html',
  pdf = 'application/pdf',
}
